<template >
  <template v-if="inputModel && inputModel.length > 0">
    <ul class="list-group border border-light2 mb-2">
      <draggable
          :list="inputModel"
          itemKey="key_id"
          handle=".move"
          :ghost-class="getConfig('general.style.class_drag_n_drop')"
      >
        <template #item="{ index, element }">
          <li
              class="list-group-item py-3 d-flex align-items-center"
          >

            <i
                v-if="element.date_range"
                class="bi bi-calendar me-1"
            >
            </i>

            <span>{{element.title}}</span>

            <span
                v-if="element.date_range"
                class="text-secondary ms-2"
            >
              (<span v-format-date-range="element.date_range"></span>)
            </span>

            <span>
              <router-link
                  :to="'/restaurace_menu/edit/' + element.id"
                  target="_blank"
                  class="btn btn-sm  btn-outline-primary ms-2"
                  title="Editace menu"
              >
                <i class="bi bi-pen"></i>
              </router-link>
            </span>






            <span class="btn btn-outline-secondary move ms-auto">
              <i class="bi bi-arrows-move"></i>
            </span>
          </li>
        </template>
      </draggable>
    </ul>

  </template>

  <template v-else>
    {{inputObj.params.texts.empty_data}}
  </template>


</template>

<script>
import draggable from "vuedraggable";
export default {
  name: "OrderInput",
  components: {
    draggable,
  },
  props: {
    "inputObj": Object,
    "formObj": Object,
    "modelValue": [Object, Array],
  },
  data() {
    return {
      inputModel: {},


      selectModel: null,
      options:[],
      optionsArr:[],
    }
  },
  mounted() {
    this.inputModel = this.inputObj.val;
  },
  methods: {

  },
  watch: {
    inputModel: {
      handler(newVal) {

        if(newVal && newVal.length > 0) {
          //console.log('dev order watch X', newVal)
          for (const i in newVal) {
            newVal[i].poradi = (parseInt(i) + 1) * 10;
          }
        }
        this.$emit('update:modelValue', newVal)
      },
      deep: true,
      immediate: true
    },
  },
};
</script>