<template>
  <div v-if="structure && structure.length > 0" class="list-group">
    <div
        v-for="(sItem, sIndex)  in structure"
        :class="(sItem.validation_status === false? 'border-danger': 'bg-light') + ' list-group-item'"
    >
      <div class="row mb-2">
        <div
            class="col col-3"
        >
          <label
              :for="'datum_'+ sIndex"
              class="form-label"
          >
            Datum
          </label>
        </div>
        <div
            class="col col-8"
        >
          <input
              v-model="structure[sIndex].datum"
              type="date"
              :class="'form-control' + (structure[sIndex].validation_ident == 'date'? ' is-invalid': '') "
              :id="'datum_'+ sIndex"
          />
        </div>
        <div
            class="col col-1"
        >
          <i class="bi bi-trash" @click.prevent="removeDateItem(sIndex)"></i>
        </div>


      </div>

      <div
          v-if="!structure[sIndex].is_closed"
      >
        <div class="row">
          <div class="col col-6">
            <label
                :for="'ex_opening_time_'+ sIndex"
            >
              Otvírací čas
            </label>
            <input
                v-model="structure[sIndex].hour_opening"
                type="time"
                :class="'form-control' + (structure[sIndex].validation_ident == 'hour_opening'? ' is-invalid': '')"
                :id="'ex_opening_time_'+ sIndex"
            />
          </div>
          <div class="col col-6">
            <label
                :for="'ex_closing_time_'+ sIndex"
            >
              Zavírací čas
            </label>
            <input
                v-model="structure[sIndex].hour_closing"
                type="time"
                :class="'form-control' + (structure[sIndex].validation_ident == 'hour_closing'? ' is-invalid': '')"
                :id="'ex_closing_time_'+ sIndex"
            />
          </div>
        </div>

        <div class="row mt-2">
          <div
              class="col col-4"
          >
            <input
                v-model="structure[sIndex].hour_closing_kitchen"
                type="time"
                :class="'form-control' + (structure[sIndex].validation_ident == 'hour_closing_kitchen'? ' is-invalid': '')"
                :id="'closing_time_kitchen'+ sIndex"
            />
          </div>
          <div
              class="col col-8"
          >
            <label
                :for="'closing_time_kitchen'+ sIndex"
                class="form-label"
            >
              Čas zavření kuchyně
            </label>
          </div>
        </div>
     </div>

      <div class="form-check form-switch mt-2">
        <input
            v-model="structure[sIndex].is_closed"
            class="form-check-input"
            type="checkbox"
            role="switch"
            :id="'ex_is_closing_'+ sIndex"
        >
        <label
            class="form-check-label "
            :for="'ex_is_closing_'+ sIndex"
        >
          Celý den zavřeno
        </label>
      </div>

      <div v-if="sItem.validation_status === false" class="text-danger">
        <i class="bi bi-exclamation-triangle-fill me-1"></i>
        <span>{{sItem.validation_result}}</span>
      </div>

<!--
    <pre>
     sItem {{sIndex}} {{sItem}}
    </pre>

      <div
          v-if="!structure[sIndex].is_closed"
      >
        <div class="row">
          <div class="col col-6">
            <label
                :for="'opening_time_'+ sIndex"
            >
              Otvírací čas
            </label>
            <input
                v-model="structure[sIndex].hour_opening"
                type="time"
                class="form-control"
                :id="'opening_time_'+ sIndex"
            />
          </div>
          <div class="col col-6">
            <label
                :for="'closing_time_'+ sIndex"
            >
              Zavírací čas
            </label>
            <input
                v-model="structure[sIndex].hour_closing"
                type="time"
                class="form-control"
                :id="'closing_time_'+ sIndex"
            />
          </div>
        </div>

        <div class="row mt-2">
          <div
              class="col col-4"
          >
            <input
                v-model="structure[sIndex].hour_closing_kitchen"
                type="time"
                class="form-control "
                :id="'closing_time_kitchen'+ sIndex"
            />
          </div>
          <div
              class="col col-8"
          >
            <label
                :for="'closing_time_kitchen'+ sIndex"
                class="form-label"
            >
              Čas zavření kuchyně
            </label>
          </div>





        </div>



      </div>

      <div class="form-check form-switch mt-2">
        <input
            v-model="structure[sIndex].is_closed"
            class="form-check-input"
            type="checkbox"
            role="switch"
            :id="'is_closing_'+ sIndex"
        >
        <label
            class="form-check-label"
            :for="'is_closing_'+ sIndex"
        >
          Celý den zavřeno
        </label>
      </div>

      <div v-if="sItem.validation_status === false" class="text-danger">
        <i class="bi bi-exclamation-triangle-fill me-1"></i>
        <span>{{sItem.validation_result}}</span>
      </div>

   -->
    </div>

  </div>
  <div v-else>
   <i class="bi bi-window-x me-1"></i> Zatím nejsou zadány žádně změny pravidelné otvírací doby.
  </div>

  <div class="text-end">
    <div @click.prevent="addNewHours" class="btn btn-outline-secondary mt-1">
      <i class="bi bi-clock"></i> Přidat otvírací dobu
    </div>
  </div>


</template>

<script>
let timer;
export default {
  name: "OpeningHoursRegularInput",
  props: {
    "inputObj": Object,
    "formObj": Object,
    "modelValue": [Object],
  },
  data() {
    return {
      structure: [],
      validation: true,
      timer_delay: 1900,
      validation_results: {},

      inputModel: null,
      timer: null,

      is_locked: true,
    }
  },
  mounted() {
    if(this.inputObj.val) {
      this.structure = this.inputObj.val;
    }
  },
  methods: {
    removeDateItem(index) {
      if (confirm("Opravdu chcete odstranit tento den") === true){
        this.structure.splice(index, 1);
      }
    },

    addNewHours(){
      const newHours = {
        "datum": null,
        "hour_opening": null,
        "hour_closing": null,
        "hour_closing_kitchen": null,
        "is_closed": null,
      };
      this.structure.push(newHours)
    },

    validateOpenHours() {
      this.validation_results = {};
      if(this.structure) {
        for (const dIndex in this.structure){
          if(this.structure[dIndex].datum) {
            const dtToday = new Date();
            dtToday.setHours(0,0);
            const dtDatum = new Date(this.structure[dIndex].datum);
            if(dtDatum >= dtToday) {
              if(this.structure[dIndex].is_closed !== true) {
                if(this.structure[dIndex].hour_opening && this.structure[dIndex].hour_closing) {
                  if(this.structure[dIndex].hour_opening > this.structure[dIndex].hour_closing) {
                    this.validation_results[dIndex]  = {
                      status: false,
                      ident: 'hour_closing',
                      message: "Otevírací hodina je větší nez zavírací",
                    };
                  }else if(this.structure[dIndex].hour_closing_kitchen && (this.structure[dIndex].hour_closing_kitchen > this.structure[dIndex].hour_closing)){
                    this.validation_results[dIndex]  = {
                      status: false,
                      ident: 'hour_closing_kitchen',
                      message: "Kuchyně nemůže zavřít až po standartní zavírací hodině",
                    };
                  }
                }else{
                  this.validation_results[dIndex]  = {
                    status: false,
                    ident: 'hour_opening',
                    message: "Vyplňte otevírací a zavírací hodinu!",
                  };
                }
              }
            }else{
              this.validation_results[dIndex] = {
                status: false,
                ident: 'date',
                message: "Nelze zadat datum z minulosti",
              }
            }
          }else{
            this.validation_results[dIndex] = {
              status: false,
              ident: 'date',
              message: "Vyplnte datum",
            }
          }
        }
      }
    },

    createBaseStructure() {
      if(this.inputObj.templates && this.inputObj.templates.data) {
        for (const tKey in this.inputObj.templates.data) {
          const day = {
            "title": this.inputObj.templates.data[tKey].day_ident,
            "day": this.inputObj.templates.data[tKey].day_number,
            "is_closed": false,
            "hour_opening": null,
            "hour_closing": null,
            "hour_closing_kitchen": null,
          }
          this.structure[tKey] = day;
          //this.structure.push(day);
          console.log('dev createBaseStructure', tKey, this.inputObj.templates.data[tKey].day_ident)
        }
      }

    }
  },
  watch: {
    structure: {
      handler(newVal) {
        this.validateOpenHours();

        if(newVal && newVal.length > 0){
          for (const i in newVal) {
            if(typeof this.validation_results[i] != "undefined"){
              newVal[i].validation_status = this.validation_results[i].status;
              newVal[i].validation_result = this.validation_results[i].message;
              newVal[i].validation_ident = this.validation_results[i].ident;
            }else{
              delete newVal[i].validation_status;
              delete newVal[i].validation_result;
              delete newVal[i].validation_ident;
            }
          }
        }

        if(timer) clearInterval(timer);
        timer = setTimeout(
            () => {
              this.$emit('update:modelValue', newVal )
            },
            this.timer_delay
        )
      },
      deep: true,
      immediate: true
    },
  },
};
</script>