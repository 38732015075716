<template >
  <div v-show="inputObj.display">

    <VueMultiselect
        v-model="selectModel"
        :options="options"
        :multiple="true"
        :taggable="true"
        label="language"
        track-by="id"
        :custom-label="formatSelectItem"
        placeholder="Vyberte štítek"
        :close-on-select="true"
        :clear-on-select="true"
        @search-change="asyncSearch"
        @open="asyncSearch"
        @tag="addTag"
        tagPlaceholder="Vytvořit nový štítek z vyhledáváné fráze"
    >
      <template v-slot:tag="{ option, remove  }">
      <span class="btn btn-sm btn-outline-secondary me-1 mb-1">
         {{ option.title }}
        <span class="remove-tag bi bi-x" @click="remove(option)"></span>
      </span>
      </template>
    </VueMultiselect>
  </div>
</template>

<script>
import VueMultiselect from 'vue-multiselect'
//import "vue-multiselect/dist/vue-multiselect.css"
import axios from "axios";
let timer;
export default {
  name: "TagInput",
  components: {
    VueMultiselect
  },
  props: {
    "inputObj": Object,
    "formObj": Object,
    "modelValue": [String,Array, Object],
  },
  data() {
    return {
      selectModel:[
        {
          id: 1661,
          title: "mitchell brothers",
        }
      ],
      options: [],
      inputModel: null,
      timer: null,
      timer_delay: 500,
      value: [],

    }
  },
  methods: {
    async asyncSearch (query) {
      console.log("dev asyncFind", query)

      if(timer) clearInterval(timer);
      timer = setTimeout(
          () => {
            //this.is_input_change = false;
            console.log('dev delay 1000',query, this.selectModel)
            axios.defaults.baseURL = this.getBaseRoot();
            let apiPath = this.getApiConfig('tags.select_list.path') //this.cfg.api.tags.select_list.path
            if (this.inputObj.params && this.inputObj.params.search_path) apiPath = this.inputObj.params.search_path;
            if (query) apiPath += query
            axios({
              url: apiPath,
              method: "get",
              withCredentials: true,
              headers: this.getRequestHeader(),
            }).then((res) => {
              this.options = res.data.response.list.data;
              //console.log("dev tags data", res.data.response.list.data)
            })
            .catch((err) => {
              this.options = []
              console.log("DEV tags catch", err);
            })
            .finally(() => {
              //console.log("DEV tags finally");
            })
          },
          500
      )
    },
    formatSelectItem ({ id, title }) {
      return `${title}`
    },
    addTag (newTag) {
      let formData = new FormData;
      formData.append('tag_title', newTag)
      axios.defaults.baseURL = this.getBaseRoot();
      axios({
        url: this.getApiConfig('api.tags.create_simple.path'), //this.cfg.api.tags.create_simple.path,
        data: formData,
        method: "post",
        withCredentials: true,
        headers: this.getRequestHeader(),
      }).then((res) => {
        //this.options = res.data.response.list.data;
        if(res.data.status){
          const newTag = {
            'id': res.data.response.data.id,
            'title': res.data.response.data.nazev
          }
          this.options.push(newTag)
          this.selectModel.push(newTag)
        }else{
          alert("nepodarilo se pridat novy stitek")
        }
      })
      .catch((err) => {
        //console.log("DEV tags create catch", err);
      })
      .finally(() => {

        //console.log("DEV tags create finally");
      })




      const tag = {
        name: newTag,
        code: newTag.substring(0, 2) + Math.floor((Math.random() * 10000000))
      }



      //this.options.push(tag)
      //this.selectModel.push(tag)
    }
  },
  mounted() {
    this.selectModel = this.inputObj.val;
    //this.inputModel = this.inputObj.val;
  },
  watch: {
    inputObj: {
      handler() {
        this.inputModel = this.inputObj.val;
      },
      deep: true,
      immediate: true
    },
    selectModel: {
      handler(newVal) {
        this.$emit('update:modelValue', newVal )
      },
      deep: true,
      immediate: true
    },
  },
};
</script>