<template>
  <div>
    <div v-if="data.title">jmeno: {{data.title}}</div>
    <div v-if="data.email">
      email: <a
        :href="'mailto:' + data.email"
        class="btn btn-outline-primary btn-sm"
    >
      <i class="bi bi-envelope-at"></i>
      {{data.email}}
    </a>
    </div>
    <div v-if="data.telefon">telefon:
      <a
          :href="'tel:' + data.telefon"
          class="btn btn-outline-primary btn-sm"
      >
        <i class="bi bi-phone"></i>
        {{data.telefon}}
      </a>
    </div>
    <div v-if="data.ic">IC: {{data.ic}}</div>
    <div v-if="data.dic">DIC: {{data.dic}}</div>

    <address v-if="data.adresa">
      <small>Adresa</small>
      <div v-if="data.adresa.ulice">{{data.adresa.ulice}}</div>
      <div v-if="data.adresa.mesto">{{data.adresa.mesto}}</div>
      <div v-if="data.adresa.psc">{{data.adresa.psc}}</div>
      <div v-if="data.adresa.stat">{{data.adresa.stat}}</div>
    </address>

<!--
    <pre>{{data}}</pre>
  -->
  </div>
</template>
<script>
export default {
  name: "BlockAdresa",
  components: {

  },
  props: {
    "data": Object,
  },
  data() {
    return {
      inputModel: null,

    }
  },
};
</script>

