<template >
  <multiselect
      v-model="inputModel"
      :options="options"
      track-by="id"
      :searchable="true"

      label="title"
      :placeholder="inputObj.placeholder"
      @search-change="fillOptions"
      @open="fillOptions"
      :taggable="inputObj.templates.is_add_new? true: false"
      @tag="addItem"
      :multiple="inputObj.templates.is_multivalue? true: false"
      :close-on-select="true"
  >
    <template v-slot:tag="{ option, remove  }">
      <span class="btn btn-sm btn-outline-secondary me-1 mb-1">
         {{ option.title }}
        <span class="remove-tag bi bi-x" @click="remove(option)"></span>
      </span>
    </template>
  </multiselect>

  <!--


  <pre>inputModel {{inputModel}}</pre>
  <pre>inputObj {{inputObj}}</pre>

      :close-on-select="true"
      :clear-on-select="true"
  <pre>{{inputObj.templates}}</pre>
  -->
</template>

<script>
let timer;
import multiselect  from 'vue-multiselect'
import axios from "axios";

export default {
  name: "SmartSelectInput",
  components: {
    multiselect,
  },
  props: {
    "inputObj": Object,
    "formObj": Object,
    "modelValue": [String, Number, Object],
  },
  data() {
    return {
      inputModel: {},
      options: [],
    }
  },
  created() {

    if(this.inputObj.val) {
      this.inputModel = this.inputObj.val
    }else{
      if(this.inputObj.templates.is_multivalue){
        this.inputModel = []
      }else{
        this.inputModel = {}
      }
    }


  },
  methods: {
    addItem(title){
      console.log('dev addItem', title)
    },
    fillOptions(query) {
      if(timer) clearInterval(timer);
      timer = setTimeout(
          () => {
            axios.defaults.baseURL = this.getBaseRoot();
            let apiPath = this.inputObj.templates.action.list.path
            axios({
              url: apiPath,
              method: this.inputObj.templates.action.list.method,
              withCredentials: true,
              headers: this.getRequestHeader(),
            }).then((res) => {
              if(res.data.response.list){
                this.options = res.data.response.list
              }else{
                this.options = []
              }

            })
            .catch((err) => {
              this.options = []
            })
            .finally(() => {
              //console.log("DEV tags finally");
            })
          },
          250
      )
    }
  },
  watch: {
    inputObj: {
      handler() {
        this.inputModel = this.inputObj.val;

      },
      deep: true,
      immediate: true
    },

    inputModel: {
      handler(newVal, oldVal) {

        this.$emit('update:modelValue', newVal )
      },
      deep: true,
      immediate: true
    },

  },
};
</script>