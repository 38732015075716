<template >
  <!-- modal start-->
  <div v-if="modalOpen && modalOpen.config && modalOpen.config.display">

    <modal-window
        :title="modalOpen.title"
        :config="modalOpen.config"
    >
      <template #modal-content-slot>
        <!--
        <display-debug>
          <template #debugView>modalOpen {{modalOpen}}</template>
        </display-debug>
        -->
        <component
            v-bind:is="'ContentIncludeView'"
            :router="modalOpen.router"
            :return-data="modalOpen.data"
            :form-prefill="modalOpen.prefill"
            :config="modalOpen.config"
        />

      </template>
    </modal-window>
  </div>
  <!-- modal end-->

  <div class="d-flex">
    <VueMultiselect
        v-model="inputModel"
        :options="options"
        track-by="id"
        :placeholder="'Vyber hodnoty'"
        @open="asyncSearch"
        @search-change="asyncSearch"
    >
      <template v-slot:singleLabel="{ option }">
        <span>{{ option.title }}</span>
      </template>

      <template v-slot:option="{ option, search }">
        <span>{{option.title}}</span>
      </template>
    </VueMultiselect>

    <span
        v-if="config.modal.router.action_new"
        class="btn btn-outline-secondary ms-2 ratio-1x1"
        @click.prevent="openModalNew(config.modal)"
    >
      <i class="bi bi-plus"></i>
    </span>

    <span
        v-if="inputModel && inputModel.id && typeof config.modal.router.action_edit !='undefined'"
        class="btn btn-outline-secondary ms-2 ratio-1x1"
        @click.prevent="openModalEdit(inputModel.id, config.modal)"
    >
      <i class="bi bi-pen"></i>
    </span>
  </div>

  <!--
  <display-debug>
    <template #debugView>pair config {{config}}</template>
  </display-debug>
   -->
</template>


<script>
import axios from "axios";
import VueMultiselect from 'vue-multiselect'
let timer;
import CountryFlag from "vue-country-flag-next"
import ContentIncludeView from "@/views/ContentIncludeView";
import ModalWindow from "@/components/content/ModalWindow";
export default {
  name: "WikiTextareaLang",
  components: {
    CountryFlag,
    VueMultiselect,
    ContentIncludeView,
    ModalWindow,
  },
  props: {
    "modelValue": {
      type: [Object, Number, String],
      required: false,
      default: {}
    },
    "config": {
      type: Object,
      required: false,
      default: {}
    }
  },
  emits: ['update:modelValue'],
  data() {
    return {
      inputModel: null,
      options: {},
      modalOpen: {
        title: null,
        router: {
          modul: null,
          action: null
        },
        config: {
          "display": false,
          "is_header": true,
          "is_close_btn": true,
          "is_footer": true
        },
        data: {
          save: false,
          submit: false,
        },
        callback: {},
        prefill: {},
      },
    }
  },
  created() {
      this.options = [];
  },
  mounted() {

  },
  methods: {
    openModalEdit(id, config) {

      if(id) {
        this.modalOpen.config.display = true;
        this.modalOpen.title = 'Editace polozku';
        this.modalOpen.router = {
          modul: config.router.modul,
          action: config.router.action_edit + '/' + id,
        }

        if(config.prefill){
          this.modalOpen.prefill = config.prefill;
        }else{
          this.modalOpen.prefill ={
            general: {
              inputs: {}
            }
          }
        }
      }

    },
    openModalNew(config) {

      this.modalOpen.config.display = true;
      this.modalOpen.title = 'Vytvor novou polozku';
      this.modalOpen.router = {
        modul: config.router.modul,
        action: config.router.action_new,
      }

      if(config.prefill){
        this.modalOpen.prefill = config.prefill;
      }else{
        this.modalOpen.prefill ={
          general: {
            inputs: {}
          }
        }
      }
    },

    async asyncSearch (query) {
      if(timer) clearInterval(timer);
      timer = setTimeout(
          () => {

            axios.defaults.baseURL = this.getBaseRoot();
            let apiPath = '/class/' +  this.config.select.action
            if(query) apiPath += '&q=' + query;

            axios({
              url: apiPath,
              method: "get",
              withCredentials: true,
              headers: this.getRequestHeader(),
            }).then((res) => {
              //console.log("dev.asyncSearch data", res.data.response.list.data)
              this.options = res.data.response.list.data;
            }).catch((err) => {
              this.options = [];
              console.log("dev.asyncSearch catch", err);
            }).finally(() => {
              //console.log("DEV tags finally");
            })
          },
          250
      )
    },
    getDetail(value){
      if (value) {
        axios.defaults.baseURL = this.getBaseRoot();
        let apiPath = '/class/' +  this.config.select.detail +'?id=' + value;
        axios({
          url: apiPath,
          method: "get",
          withCredentials: true,
          headers: this.getRequestHeader(),
        }).then((res) => {res.data.response.detail
          if(res.data.response.detail) {
            this.inputModel = res.data.response.detail;
          }
        }).catch((err) => {
          console.log("dev.detail catch", err);
        }).finally(() => {
          //console.log("DEV tags finally");
        })
      }else{
        this.inputModel = null;
      }
    },
  },
  watch: {
    "modalOpen.data": {
      handler(newVal) {
        if(newVal){
          this.modalOpen.data.submit = true;
          this.modalOpen.data.saved = true;
          this.modalOpen.config.display = false;

          this.inputModel = newVal
        }
      },
      deep: true,
      immediate: true
    },
    modelValue: {
      handler(newVal) {
        if(newVal){
          this.getDetail(newVal);
        }
      },
      deep: true,
      immediate: true
    },


    inputModel: {
      handler(newVal) {
        if(timer) clearInterval(timer);
        timer = setTimeout(
            () => {
              if(newVal){
                this.$emit('update:modelValue', newVal.id )
              }else{
                this.$emit('update:modelValue', null )
              }
            },
            300
        )
      },
      deep: true,
      immediate: true
    },
  },
};
</script>