<template >
  <div >
    <!--
    <pre>inputObj:: {{inputObj.templates.data}}</pre>

    <pre>value: {{inputModel}}</pre>
<button @click.prevent="inputModel='14'">kat 14</button>

    -->

    <treeselect
        v-model="inputModel"
        :options="inputObj.templates.data"
        :searchable="true"
        placeholder="Vyberete kategorii"
        :flattenSearchResults="true"
        :normalizer="normalizer"
    >
      <!--
      <template #value-label="{ node }">
        <el-tooltip
            :content="node.raw.label"
            placement="bottom"

        >
          <span>[Atestovaci value-label]{{ node.raw.label }}</span>
        </el-tooltip>
      </template>



      <label slot="option-label" slot-scope="{ node, shouldShowCount, count, labelClassName, countClassName }" :class="labelClassName">
       x: {{ node.label }}

      </label>
         [{{ node.id }}] {{ node.label }} | {{ node.dev }}
      -->

      <template #value-label="{ node }">
          <span v-html="node.raw.label"></span>
      </template>


      <template #option-label="{ node }">
        <span class="custom-option">
          <span v-html="node.label"></span>
        </span>
      </template>

    </treeselect>
  </div>
  <!--
   :disabled="node.raw.label < 10"

  <pre>{{inputObj.templates.data}}</pre>
  -->
</template>


<script>

//import 'vue3-treeselect/dist/vue3-treeselect.css'
import Treeselect from 'vue3-treeselect'
export default {
  name: "TreeSelectInput",
  props: {
    "inputObj": Object,
    "formObj": Object,
    "modelValue": [String, Number],
  },
  components: {
    Treeselect ,
  },

  data() {
    return {
      inputModel: null,
    }
  },
  mounted() {
    this.inputModel = this.inputObj.val;
  },
  methods: {
    normalizer(node) {
      return {
        id: node.id,
        label: node.label + (!node.isDisabled? ' <strong>[je pro eshop]<strong>': ''),
        children: node.children,
        dev: node.isDisabled,
        isDisabled: false,
      }
    }
  },
  watch: {
    inputObj: {
      handler() {
        console.log("TREE_CHANGE", this.inputObj.val, typeof this.inputObj.val)
        this.inputModel = this.inputObj.val;
      },
      deep: true,
      immediate: true
    },
    inputModel( newVal){
      this.inputObj.val = newVal;
      this.$emit('update:modelValue', newVal )
    },
  },
};
</script>