<template >
  <div
    v-if="typeof inputObj.templates.range.render != 'undefined'"
    class="row"
  >
    <div class="col col-10">
      <input
          v-model="inputModel"
          type="range"
          class="form-range form-control border-0"
          :min="inputObj.templates.range.from"
          :max="inputObj.templates.range.to"
          :step="inputObj.templates.range.step"
      >
    </div>
    <div class="col col-2">
      <div >
        <div v-if="inputObj.templates.range.render && inputObj.templates.range.render.type  && inputObj.templates.range.render.type == 'ico_iteration'">
          <div v-if="parseInt(inputModel) >0">
            <i
                v-for="index in parseInt(inputModel)" :key="index"
                :class="inputObj.templates.range.render.item + ' fs-4'">
            </i>
          </div>
          <div v-else>
            <span v-html="inputObj.templates.range.render.empty" />
          </div>
        </div>
        <div v-else>
          {{inputModel}}
        </div>
      </div>
    </div>
  </div>
  <div
    v-else
  >
    <input
        v-model="inputModel"
        type="range"
        class="form-range form-control border-0"
        :min="inputObj.templates.range.from"
        :max="inputObj.templates.range.to"
        :step="inputObj.templates.range.step"
    >
  </div>



</template>

<script>

export default {
  name: "RangeInput",
  props: {
    "inputObj": Object,
    "formObj": Object,
    "modelValue": [String, Number],
  },
  data() {
    return {
      inputModel: null,
      timer: null,
      timer_delay: 500,
      is_locked: true,
    }
  },
  mounted() {
    this.inputModel = this.inputObj.val;
    if (this.inputObj.val && this.inputObj.val.length > 0){
      this.inputObj.is_locked = true;
    }else {
      this.inputObj.is_locked = false;
    }
    //this.$emit('update:modelValue', this.inputModel)
  },
  methods: {
  },
  watch: {
    inputObj: {
      handler() {
        this.inputModel = this.inputObj.val;
      },
      deep: true,
      immediate: true
    },
    inputModel( newVal){

      if(this.inputObj.name == 'slug' && newVal.length == 0){
        this.inputObj.is_locked = false;
      }
      //timer
      if(this.timer) clearInterval(this.timer);
      const delay = this.timer_delay
      const pseudo_this = this;
      this.timer = setTimeout(
          () => {
          //start timer function content
            //tohle je slug input a jeho prevod na spravny format
            if (
                typeof pseudo_this.inputObj.slug != 'undefined'
                && typeof pseudo_this.inputObj.slug.is_slug != 'undefined'
            ){
              //console.log("dev slug inputObj SLUG format")
              newVal = pseudo_this.createSlugFormat(newVal);
              this.inputModel = newVal;
              this.inputObj.val = newVal
            }else{
              this.inputObj.val = newVal

            }

            //pokud z inputu vytvarim dalsi inpu ve formatu slug

            if(
                typeof pseudo_this.inputObj.slug != 'undefined'
                && typeof pseudo_this.inputObj.slug.section != 'undefined'
                && typeof pseudo_this.inputObj.slug.input != 'undefined'
                && typeof pseudo_this.formObj[pseudo_this.inputObj.slug.section].inputs[pseudo_this.inputObj.slug.input].disable_auto_fill == 'undefined'
              && pseudo_this.formObj[pseudo_this.inputObj.slug.section].inputs[pseudo_this.inputObj.slug.input].is_locked == false
            ) {
              pseudo_this.formObj[pseudo_this.inputObj.slug.section].inputs[pseudo_this.inputObj.slug.input].val = newVal
            }



            //update parent
            //console.log('dev textarea', newVal)
            pseudo_this.$emit('update:modelValue', newVal )
          //end timer function content
          },
          this.timer_delay
      )
    },
  },
};
</script>