<template >
  <div class="container-fluid p-0">
        <gallery-component
            v-if="inputObj.val"
            :gallery_id="inputObj.val"
            :is_gallery_browser="false"
            :is_image_browser="true"
            :image_typ_id="inputObj.params.image_type_id"
            :is_image_upload="true"
            :is_creating="false"
            :is_image_upload_from_server="true"
        />

        <div
            v-else
            class="alert alert-danger"
            role="alert"
        >
          Obrazky lze pouzit jen v modu editace galerie
        </div>
  </div>
</template>

<script>
import GalleryComponent from "@/components/general/Galerie";
import texts from "@/components/config/texts";
export default {
  name: "GalleryInput",
  components: {
    GalleryComponent,
    texts
  },
  props: {
    "inputObj": Object,
    "formObj": Object,
    "modelValue": [String, Number],
  },
  data() {
    return {
      inputModel: null,
      timer: null,
      timer_delay: 500,
    }
  },
  mounted() {
    this.inputModel = this.inputObj.val;
  },
  methods: {

  },
  watch: {
    inputObj: {
      handler() {
        this.inputModel = this.inputObj.val;
      },
      deep: true,
      immediate: true
    },
    inputModel( newVal){

      //timer
      if(this.timer) clearInterval(this.timer);
      const delay = this.timer_delay
      const pseudo_this = this;
      this.timer = setTimeout(
          function() {
          //start timer function content
            //tohle je slug input a jeho prevod na spravny format
            if (
                typeof pseudo_this.inputObj.slug != 'undefined'
                && typeof pseudo_this.inputObj.slug.is_slug != 'undefined'
            ){
              console.log("dev slug inputObj SLUG format")
              newVal = pseudo_this.createSlugFormat(newVal);
              pseudo_this.inputModel = newVal;
            }

            //pokud z inputu vytvarim dalsi inpu ve formatu slug
            //console.log("dev slug target", pseudo_this.formObj[pseudo_this.inputObj.slug.section].inputs[pseudo_this.inputObj.slug.input])
            //pseudo_this.formObj[pseudo_this.inputObj.slug.section].inputs[pseudo_this.inputObj.slug.input].val = newVal

            if(
                typeof pseudo_this.inputObj.slug != 'undefined'
                && typeof pseudo_this.inputObj.slug.section != 'undefined'
                && typeof pseudo_this.inputObj.slug.input != 'undefined'
                && typeof pseudo_this.formObj[pseudo_this.inputObj.slug.section].inputs[pseudo_this.inputObj.slug.input].disable_auto_fill == 'undefined'
            ) {
              pseudo_this.formObj[pseudo_this.inputObj.slug.section].inputs[pseudo_this.inputObj.slug.input].val = newVal
            }

            //update parent
            pseudo_this.$emit('update:modelValue', newVal )
          //end timer function content
          },
          this.timer_delay
      )
    },
  },
};
</script>