<template>


  <!--
  <div style="position: absolute; top: 0; right: 0;">


    <div class="toast d-block mb-2" role="alert" aria-live="assertive" aria-atomic="true">
      <div class="toast-header">

        <strong class="mr-auto">Bootstrap</strong>
        <small>11 mins ago</small>
        <button type="button" class="btn btn-sm ml-2 mb-1 close" data-dismiss="toast" aria-label="Close">
          <i class="bi bi-x"></i>
        </button>
      </div>
      <div class="toast-body">
        Hello, world! This is a toast message.
      </div>
    </div>
    <div class="toast d-block mb-2" role="alert" aria-live="assertive" aria-atomic="true">
      <div class="toast-header">

        <strong class="mr-auto">Bootstrap</strong>
        <small>11 mins ago</small>
        <button type="button" class="btn btn-sm ml-2 mb-1 close" data-dismiss="toast" aria-label="Close">
          <i class="bi bi-x"></i>
        </button>
      </div>
      <div class="toast-body">
        Hello, world! This is a toast message.
      </div>
    </div>
    <div class="toast d-block mb-2" role="alert" aria-live="assertive" aria-atomic="true">
      <div class="toast-header">

        <strong class="mr-auto">Bootstrap</strong>
        <small>11 mins ago</small>
        <button type="button" class="btn btn-sm ml-2 mb-1 close" data-dismiss="toast" aria-label="Close">
          <i class="bi bi-x"></i>
        </button>
      </div>
      <div class="toast-body">
        Hello, world! This is a toast message.
      </div>
    </div>
  </div>
  -->


  <div id="content">
    <!-- Váš scrollovatelný obsah -->
    [vyvoj grafiky]
  </div>

  <div id="menu" class="d-flex justify-content-around">
    <div class="menu-item">Domů</div>
    <div class="menu-item">Profil</div>
    <div class="menu-item">Nastavení</div>
  </div>
</template>
<style>
body, html {
  height: 100%;
}
#content {
  overflow-y: scroll;
  padding-bottom: 60px; /* Výška menu */
}
#menu {
  position: fixed;
  bottom: 0;
  width: 100%;
  height: 60px; /* Výška menu */
  background-color: #333;
  z-index: 1000;
}
.menu-item {
  text-align: center;
  padding: 10px;
  color: white;
}
</style>