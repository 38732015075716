<template >
  <div>
    <pre>ROOT {{inputModel}}</pre>
    <div>
      <!--
      <tree-select-item
          v-for="(item, index)  in inputObj.templates.data"
          @key="index"
          :item-obj="item"
          :model-value="inputModel"
      />
-->
      <tree-select-item
          :item-obj="inputObj.templates.data"
          :model-value="inputModel"
      />

    </div>


<!--
    <pre>value: {{inputObj.templates.data}}</pre>
    <pre>value: {{inputModel}}</pre>
-->



  </div>
</template>

<script>
import TreeSelectItem from '@/components/general/form/inputs/TreeSelectCustom/TreeSelectCustomItem'
//import 'vue3-treeselect/dist/vue3-treeselect.css'
import Treeselect from 'vue3-treeselect'
export default {
  name: "TreeSelectInput",
  props: {
    "inputObj": Object,
    "formObj": Object,
    "modelValue": [String, Number],
  },
  components: {
    TreeSelectItem,
  },
  emits: ['update:modelValue'],
  data() {
    return {
      inputModel: null,
    }
  },
  mounted() {
    //this.inputModel = this.inputObj.val;
    this.inputModel = 14;
  },
  methods: {
  },
  watch: {
    inputObj: {
      handler() {
        console.log("TREE_CHANGE", this.inputObj.val, typeof this.inputObj.val)
        this.inputModel = this.inputObj.val;
      },
      deep: true,
      immediate: true
    },
    inputModel( newVal){
      this.inputObj.val = newVal;
      this.$emit('update:modelValue', newVal )
    },
  },
};
</script>