<template >
   <div v-if="inputModel && Object.entries(inputModel).length > 0">
    <div
        v-for="(item, index) in inputModel"
        @key="index"
        class="input-group w-100 mb-2"
    >

      <label :for="'meta_' + index" class="input-group-text">
        <i v-if="item.ico" :class="item.ico+' me-2'"></i>
        {{item.title}}
      </label>

      <input
        v-if="item.type == 'varchar'"
        v-model="item.value"
        type="text"
        class="form-control"
        :id="'meta_' + index"
      >
      <input
          v-else-if="item.type == 'int'"
          v-model="item.value"
          type="number"
          class="form-control"
          :id="'meta_' + index"
      >
      <input
          v-else-if="item.type == 'datetime'"
          v-model="item.value"
          type="datetime-local"
          class="form-control"
          :id="'meta_' + index"
      >
      <div
          v-else-if="item.type == 'range'"
        class="form-control"
      >
        <!--        -->
        <div class="w-100 input-group">

          <input
              v-model="item.value"
              type="range"
              :min="item.params.from"
              :max="item.params.to"
              :step="item.params.step"
              class="form-range form-control border-0"
              :id="'meta_' + index"
          >
          <div class="form-label input-group-append ms-2">{{item.value}}</div>
        </div>


      </div>
      <div
          v-else-if="item.type == 'select_smart'"
          class="form-control"
      >
        <multiselect
            v-model="item.value"
            :options="item.params"
            track-by="id"
            :searchable="true"
            :hideSelected="false"
            label="title"
            placeholder="Vyhledejte položku"
            @search-change="(searchQuery) => asyncSearchParametr(searchQuery, index, item)"
            @open="(searchQuery) => asyncSearchParametr(searchQuery, index, item)"

            :taggable="true"
            @tag="(newTag) => addParameter(newTag, index, item)"
            tagPlaceholder="Vytvořit nový parametr"
        >
        </multiselect>
      </div>
      <div
          v-else-if="item.type == 'select'"
          class="form-control"
      >
        <select
          v-model="item.value"
          class="form-select"
          :id="'meta_' + index"
        >
          <option
              v-for="(item, index) in item.params"
              @key="index"
              :value="item"
          >
            {{item.title}}
          </option>
        </select>
      </div>
      <div
          v-else-if="item.type == 'bool'"
          class="form-control form-check form-switch "
      >
        <div class="ps-3">
        <input
            v-model="item.value"
            class="form-check-input"
            type="checkbox"
            role="switch"
            :id="'meta_' + index"
        />
      </div>
      </div>

      <span
          @click.prevent="removeMetaItem(index)"
          class="input-group-text" style="background: none;"
      >
        <i class="bi bi-trash text-secondary"></i>
      </span>
    </div>

  </div>

  <!--
    <div v-else class="alert alert-danger fs-1">
      zadne meta informace
    <>
  <pre>{{inputModel}}</pre>



  <multiselect
      v-model="selectModel"
      :options="options"
      placeholder="Vyhledejte položku"
  >
  </multiselect>
 -->

  <multiselect
      v-model="selectModel"
      :options="optionsArr"
      track-by="ident"
      :searchable="true"
      :hideSelected="false"
      :custom-label="formatSelectItem"
      label="title"
      placeholder="Vyhledejte parametr"
  >
    <template v-slot:singleLabel="{ option }">
      <div class="d-flex align-items-center">
        <i v-if="option.ico" :class="option.ico+' me-2'"></i>
        <span>{{option.title}}</span>
      </div>
    </template>

    <template v-slot:option="{ option, search }">
      <div class="d-flex align-items-center">
        <i v-if="option.ico" :class="option.ico+' me-2'"></i>
        <span>{{option.title}}</span>
      </div>
    </template>

    <template v-slot:noResult>
      <div class="multiselect--no-results">Zadne vysledky pro tento dotaz</div>
    </template>

    <template v-slot:noOptions>
      <div class="multiselect--no-results">Zadne vysledky pro tento dotaz</div>
    </template>
  </multiselect>
</template>

<script>
import axios from "axios";

let timer;
import multiselect  from 'vue-multiselect'
export default {
  name: "ParametersInput",
  components: {
    multiselect,
  },
  props: {
    "inputObj": Object,
    "formObj": Object,
    "modelValue": [String, Number],
  },
  data() {
    return {
      inputModel: {},
      selectModel: null,
      options:[],
      optionsArr:[],
    }
  },
  mounted() {
    if(this.inputObj.templates && this.inputObj.templates.data) {
      this.options = this.inputObj.templates.data;
      for (let optionKey in this.inputObj.templates.data) {
        this.optionsArr.push(this.inputObj.templates.data[optionKey])
      }
    }

    if(this.inputObj.val) {
      const inputData = JSON.parse(this.inputObj.val)
      for (let metaItemKey in inputData) {
        inputData[metaItemKey].title = this.getMetaTitle(metaItemKey);
        inputData[metaItemKey].type = this.getMetaType(metaItemKey);
        inputData[metaItemKey].ico = this.getMetaIco(metaItemKey);
        inputData[metaItemKey].params = this.getMetaParams(metaItemKey);
        this.inputModel[metaItemKey] = inputData[metaItemKey]

      }
    }
  },
  methods: {
    getMetaType(index) {
      return this.options[index].type;
    },
    getMetaTitle(index) {
      return this.options[index].title;
    },
    getMetaIco(index) {
      return this.options[index].ico;
    },
    getMetaParams(index) {
      return this.options[index].params;
    },
    removeMetaItem(index) {
      if (confirm("Opravdu chcete odstranit tuto meta informaci?") === true){
        delete this.inputModel[index];
      }
    },
    formatSelectItem ({ id, title }) {
      return `${title}  ${id}`
    },

    async addParameter (title, index, item) {
      let formData = new FormData;
      formData.append('title', title)
      formData.append('parametr_id', item.id)

      axios.defaults.baseURL = this.getBaseRoot();
      axios({
        url: this.getApiConfig('eshop.add_parametr_value.path'), // this.cfg.api.eshop.add_parametr_value.path,
        data: formData,
        method: this.getApiConfig('eshop.add_parametr_value.method'),
        withCredentials: true,
        headers: this.getRequestHeader(),
      }).then((res) => {
        if(res.data.status){
          const newItem = {
            'id': res.data.response.detail.id,
            'title': res.data.response.detail.title
          }
          item.params.push(newItem)
          item.value = newItem;

        }else{
          alert("nepodarilo se pridat novy parametr")
        }
      })
      .catch((err) => {
        //console.log("DEV tags create catch", err);
      })
      .finally(() => {
        //console.log("DEV tags create finally");
      })
    },

    async asyncSearchParametr (query, index, item) {
      if(timer) clearInterval(timer);
      timer = setTimeout(
          () => {
            axios.defaults.baseURL = this.getBaseRoot();
            let apiPath = this.getApiConfig('eshop.get_parametr_list.path') + '/' + item.id
            if(query) {
              apiPath += "?q=" + query
            }

            axios({
              url: apiPath,
              method: this.getApiConfig('eshop.get_parametr_list.method'),
              withCredentials: true,
              headers: this.getRequestHeader(),
            }).then((res) => {
              item.params = res.data.response.list
            })
            .catch((err) => {
              item.params = []
            })
            .finally(() => {
              //console.log("DEV tags finally");
            })
          },
          250
      )
    }
  },


  watch: {
    selectModel: {
      handler(newVal) {
        if(newVal){
          if(typeof this.inputModel[newVal.ident] == "undefined"){
            this.inputModel[newVal.ident] = {
              id: newVal.id,
              title: newVal.title,
              type: newVal.type,
              ico: newVal.ico,
              params: newVal.params,
              value: null,
            };
            if(newVal.type == 'select_smart') {
              this.inputModel[newVal.ident].params = [];
            }

            this.selectModel = null;
          }else{
            newVal = null;
            alert("Tato vlastnost nejde přidat, již je přidána!")
          }

        }
      },
      deep: true,
      immediate: true
    },
    inputModel: {
      handler(newVal) {
        this.$emit('update:modelValue', JSON.stringify(newVal))
      },
      deep: true,
      immediate: true
    },
  },
};
</script>