<template>
  <div v-if="user_data" class="preview user">
    <strong :title="'posledni aktivita: '+ user_data.datum_posledni_aktivita">{{user_data.title}}</strong><br>
    <small>{{user_data.login}}</small>
    <small v-if="!user_data.je_aktivovan || !user_data.je_aktivni">(neaktivni uzivatel)</small>
  </div>
</template>

<script>
import axios from "axios";
export default {
  name: "userPreview",
  props: {
    userId: [Number, String]
  },
  data() {
    return {
      user_data: null,
    }
  },
  methods: {
    hoverItem(item) {
      //console.log("dev hoverItem", item)
    },
    async getData(){
      //console.log("dev getMenu", this.cfg.api.general.main_menu_path)
      axios.defaults.baseURL = this.getBaseRoot();
      if(this.userId) {
        await axios({
          //url: this.cfg.api.user.preview.path + this.userId,
          url: this.getApiConfig('user.preview.path') + this.userId,
          method: "get",
          withCredentials: true,
          headers: this.getRequestHeader(),
        }).then((res) => {
          if(res.data.status) {
            //console.log("image preview", res.data.response.data.data)
            this.user_data = res.data.response.data;
          }else{
            this.user_data = false;
            //this.loginStatus = false;
            //console.log("DEV RES NODATA", res.data);
          }
        });
      }else{
        this.user_data = false;
      }
    },
  },
  created() {
    this.getData();
  }
};
</script>