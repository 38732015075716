 <template>
  <router-view/>
</template>
 <script>
 import BlockAdresa from "@/components/general/view/blocks/BlockAdresa";
 import BlockCena from "@/components/general/view/blocks/BlockCena";
 export default {
   mounted() {

   },
   created() {
      //console.log('dev App created')
   },
 };
 </script>
