<template>

  <header>
    <login-logic :user="user" v-model:user="user"></login-logic>
  </header>
  <main
    class="p-3"
  >
    <dashboard :structure="dashboard_data"></dashboard>
  </main>
  <section>
    <main-menu :user="user"></main-menu>
  </section>

  <flash-messages
      v-if="getConfig('is_flash_message')"
  />

  <offline-detect></offline-detect>
</template>

<script>
import loginLogic from "@/components/logic/Login";
import flashMessage from "@/components/general/FlashMessage";
import mainMenu from "@/components/general/MainMenu";
import axios from "axios";
import general from "@/components/config/general";
import DashboardBoxShortcut from "@/dashboards/DashboardBoxShortcut"
import DashboardBoxList from "@/dashboards/DashboardBoxList"
import DashboardBoxText from "@/dashboards/DashboardBoxText"
import flashMessages from "@/components/general/FlashMessages";
import offlineDetect from "@/components/general/OfflineDetect";
import dashboard from "@/components/general/Dashboard";



export default {
  name: 'ContentView',
  components: {
    loginLogic,
    flashMessage,
    mainMenu,
    DashboardBoxShortcut,
    DashboardBoxList,
    DashboardBoxText,
    flashMessages,
    offlineDetect,
    dashboard,
  },
  data() {
    return {
      dashboard_data: [],
      user: {
        testval: "ttteeeex"
      },
    }
  },
  methods: {
    async loadMainDashboards(){

      axios.defaults.baseURL = this.getBaseRoot();
      await axios({
        url: this.getApiConfig('general.dashboards.path'),
        method: this.getApiConfig('general.dashboards.method'),
        withCredentials: true,
        headers: this.getRequestHeader(),
      }).then((res) => {
        //console.log('dev login data xx', res.data.response.boxes);
        if(res.data.status) {
          this.dashboard_data = res.data.response.structure;
        }else{
          this.err = res.data.err.message;
        }
      }).catch((err) => {
        this.err = "Chyba systemu, kontaktujte spravce"
      }).finally(() => {

      })
    },
  },
  created() {
    this.setDocumentTitle('Dashboard');
    this.loadMainDashboards();
  },
}
</script>