<template >
  <!---->
  <display-debug>
    <template #debugView>
      config: {{config.action}}
    </template>


  </display-debug>
    <InfiniteScrollList
        :keyModul="keyModul"
        :custom-config="config"
    >
    </InfiniteScrollList>




</template>

<script>


import InfiniteScrollList from '@/components/content/InfiniteScrollList'
export default {
  name: "DashoboardListWrap",
  components: {
    InfiniteScrollList,

  },
  props: {
    "input": Object,
    "keyModul": [String, Number],
  },
  data() {
    return {
      //config: null,
    }
  },
  created() {
    console.log('DEVX dev.InfiniteScrollList DWRAP ------------------------------------------------------------------------');
    console.log('DEVX dev.InfiniteScrollList DWRAP');
    this.config = this.input
  },
  mounted() {


  },
  methods: {
  },
  watch: {
    //this.config.action
    "keyModul":  {
      handler(newVal, oldVal) {
        console.log('DEVX dev.InfiniteScrollList DWRAP watch: keyModul', newVal, oldVal );

      },
      deep: true,
      immediate: true,
    },
    "input":  {
      handler(newVal, oldVal) {
        console.log('DEVX dev.InfiniteScrollList DWRAP watch: input',this.keyModul, newVal.action );

      },
      deep: true,
      immediate: true,
    },
  }
};
</script>