<template>
  <!--
<pre>
  dev_return [CHILD2-MODULE] a:{{returnData}}
  dev_return [CHILD2-MODULE] formPrefill: {{formPrefill}}
</pre>


  <button v-on:click="returnData.a++;">CHILD2 A+ ({{returnData.a}})</button>
  <button v-on:click="returnData.b++;">CHILD2 B+ ({ {returnData.b}})</button>
-->


  <h2>{{moduleData.title}}</h2>
  <form-general
      form-title="Nazev formulare"
      :form-obj="moduleData.form"
      :return-data="returnData"
      original-path="TODO odstranit original-path"
      :form-prefill="formPrefill"
  >
  </form-general>
  <!--
      original-path="$router.currentRoute.originalPath"
   -->

</template>

<script>
import FormGeneral from "@/components/general/form/FormGeneral";
//import FormGeneral from "@/components/general/form/FormGeneralDEV";
import ListGeneral from "@/components/general/list/ListGeneral";
export default {
  name: "FormModule",
  components: {
    ListGeneral,
    FormGeneral,
  },
  emits: [
    "update:reloadSignal"
  ],
  props: {
    "ident": String,
    "route": Object,
    "reloadSignal": String,
    "moduleData": Object,
    "returnData": [Object, String, Number],
    "formPrefill": [Object],
    "config": [Object],
  },
  data() {
    return {
      reloadSignalBox: null,
      reloadSignalList: null
    }
  },
  methods: {
      reloadParent(val) {
        this.$emit("update:reloadSignal", val);
      }
  },
  watch: {
    reloadSignalBox: {
      handler(val) {
        this.reloadParent(val);
      },
      immediate: true
    },
    reloadSignalList: {
      handler(val) {
        this.reloadParent(val);
      },
      immediate: true
    },
  },
  created() {

  },
  mounted() {
   // console.log("dev DashboardModule mounted x")
  }
};
</script>