<template >
  <!--
  <pre>{{inputModel}}</pre>
  -->
  <div class="input-group mb-2">
    <span v-if="inputModel.typ" class="input-group-text">
      <i v-if="inputModel.typ == 'youtube'" class="bi bi-youtube fs-5"></i>
      <i v-else-if="inputModel.typ == 'vimeo'" class="bi bi-vimeo fs-6"></i>
    </span>
    <input
      v-model="inputModel.url"
      type="text"
      placeholder="Adresa vkládaného videa"
      @input="formInputAction"
      class="form-control"
    >
  </div>
  <div
    v-if="is_submitted"
  >
    <template v-if="inputModel.typ == 'youtube'">
      <iframe
          width="560"
          height="315"
          :src="'https://www.youtube.com/embed/' + inputModel.hash"
          title="YouTube video player"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          allowfullscreen>

      </iframe>

    </template>
    <template v-else-if="inputModel.typ == 'vimeo'">
      <div style="padding:56.25% 0 0 0;position:relative;">
        <iframe
            :src="'https://player.vimeo.com/video/' + inputModel.hash +'?h=305337222e&title=0&byline=0&portrait=0'"
            style="position:absolute;top:0;left:0;width:100%;height:100%;"
            frameborder="0"
            allow="autoplay; fullscreen; picture-in-picture"
            allowfullscreen
        ></iframe>
      </div>
      <component is="script" src="https://player.vimeo.com/api/player.js" async></component>
    </template>
    <template v-else>
      <div class="alert alert-danger d-inline-block">
        <i class="bi bi-exclamation-triangle-fill fs-3 me-3"></i>
        <span v-if="inputModel.url">Pro adresu {{inputModel.url}} neni dostupne zadne video</span>
        <span v-else>Zadejte adresu videa!</span>
      </div>


    </template>
  </div>
</template>

<script>

export default {
  name: "EmbedVideoSegment",
  props: {
    "segmentsObj": [Object, Array],
    "inputObj": Object,
    "formObj": Object,
    "modelValue": [Object],
  },
  emits: ['update:modelValue'],
  data() {
    return {
      is_submitted: false,
      inputModel: {
        "url": null,
        "typ": null,
        "hash": null,
      },
    }
  },
  mounted() {
    if(this.inputObj.obsah){
      this.is_submitted = true;
      this.inputModel = this.inputObj.obsah;
    }
  },
  methods: {
    formInputAction(event){
      this.createVideoObj(event.target.value)
    },
    createVideoObj(url){
      this.is_submitted = true;
      var youtube = url.match(/(?:youtu\.be\/|youtube\.com(?:\/embed\/|\/v\/|\/watch\?v=|\/user\/\S+|\/ytscreeningroom\?v=))([\w\-]{10,12})\b/);
      var vimeo = url.match(/^(http|https)?:\/\/(www\.|player\.)?vimeo\.com\/(?:channels\/(?:\w+\/)?|groups\/([^\/]*)\/videos\/|video\/|)(\d+)(?:|\/\?)$/);
      let res = {
        url: url
      }
      if(youtube){
        res.typ = 'youtube'
        res.hash = youtube[1];
      }else if(vimeo){
        res.typ = 'vimeo'
        res.hash = vimeo[4];
      }else{
        res.typ = null;
        res.hash = null;
      }
      this.inputModel = res;
      this.$emit('update:modelValue', res )
      //console.log('dev formInputAction', url, res);
      return res;
    }
  },
};
</script>