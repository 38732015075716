<template >
  <VueMultiselect
      v-model="inputModel"
      :options="options"
      track-by="id"
      :placeholder="'Vyber hodnoty pro'+ inputObj.params.type"
      @open="asyncSearch"
      @search-change="asyncSearch"
      :custom-label="formatSelectItem"
  >
    <!-- general -->
    <template v-slot:noOptions>
      <div class="multiselect--no-results">Vyhledejte pomocí názvu</div>
    </template>

    <template v-slot:noResult>
      <div class="multiselect--no-results">Žádné výsledky pro tento dotaz.</div>
    </template>

    <!-- autor -->

    <template v-slot:option="{ option, search }" v-if="inputObj.params.type == 'autor'">
      <div
          class="d-flex align-items-center"
      >
        <image-component
            v-if="option.obrazek_id"
            :image-id="option.obrazek_id"
            :is_preview_only="true"
            thumb_class="user-thumb rounded-circle"
        />
        <div class="d-inline-block align-self-center">
          <div class="xd-inline-block" style="font-size: 1.2rem;">{{ option.title }}</div>
          <small class="fs-6 xd-inline-block">{{ option.login }}</small>
        </div>
      </div>
    </template>

    <template v-slot:singleLabel="{ option }" v-if="inputObj.params.type == 'autor'">
      <div
          class="d-flex align-items-center"
      >
        <image-component
            v-if="option.obrazek_id"
            :image-id="option.obrazek_id"
            :is_preview_only="true"
            thumb_class="user-thumb rounded-circle"

        />
        <div class="align-self-center rounded">
          <span style="font-size: 1.2rem;">{{ option.title }}</span><br>
        </div>
      </div>
    </template>

    <!-- link -->
    <template v-slot:option="{ option, search }" v-if="inputObj.params.type == 'link'">
      <div
          class="d-flex"
      >
        <image-component
            v-if="option.obrazek_id"
            :image-id="option.obrazek_id"
            :is_preview_only="true"
            thumb_class="nahled"
        />
        <div class="d-inline-block align-self-center">
          <div class="fs-6 fw-bold mb-1"> {{ option.title }}</div>
          <div v-if="option.kategorie_title">Kategorie: {{option.kategorie_title}}</div>
          <div v-if="option.user_title">Autor: {{option.user_title}}</div>
          <div v-if="option.datum_publikace">
            Datum: <dateime-preview :value="option.datum_publikace" :is_date="true" class="d-inline"/>
          </div>
          <div v-else class="text-danger">
            Nepublikováno
          </div>
        </div>
      </div>
    </template>

    <template
        v-slot:singleLabel="{ option }"
        v-if="inputObj.params.type == 'link'"
        class="d-flex text-danger"
    >
      <div
          class="d-flex"
      >
        <image-component
            v-if="option.obrazek_id"
            :image-id="option.obrazek_id"
            :is_preview_only="true"
            thumb_class="nahled"
        />
        <div class="d-inline-block align-self-center">
          <div class="fs-6 mb-1 fw-bold"> {{ option.title }}</div>
          <div v-if="option.kategorie_title">Kategorie: {{option.kategorie_title}}</div>
          <div v-if="option.user_title">Autor: {{option.user_title}}</div>
          <div v-if="option.datum_publikace">
            Datum: <dateime-preview :value="option.datum_publikace" :is_date="true" class="d-inline"/>
          </div>
          <div v-else class="text-danger">
            Nepublikováno
          </div>
        </div>
      </div>
    </template>

    <!-- interpret -->
    <template v-slot:option="{ option, search }" v-if="inputObj.params.type == 'interpret'">
      <div
          class="d-flex"
      >
        <image-component
            v-if="option.obrazek_id"
            :image-id="option.obrazek_id"
            :is_preview_only="true"
            thumb_class="nahled"
        />
        <div class="d-inline-block align-self-center">
          <div class="fs-6 fw-bold mb-1"> {{ option.title }}</div>
          <div v-if="option.user_title">Autor: {{option.user_title}}</div>
        </div>
      </div>
    </template>

    <template
        v-slot:singleLabel="{ option }"
        v-if="inputObj.params.type == 'interpret'"
        class="d-flex text-danger"
    >
      <div
          class="d-flex"
      >
        <image-component
            v-if="option.obrazek_id"
            :image-id="option.obrazek_id"
            :is_preview_only="true"
            thumb_class="nahled"
        />
        <div class="d-inline-block align-self-center">
          <div class="fs-6 mb-1 fw-bold"> {{ option.title }}</div>
          <div v-if="option.user_title">Autor: {{option.user_title}}</div>
        </div>
      </div>
    </template>


  </VueMultiselect>
  <!--
 <VueMultiselect
     v-model="selectModel"
     :options="options"
     track-by="id"

     placeholder="Vyber hodnoty"
     @search-change="asyncSearch"
 >





   <template v-slot:singleLabel="{ option }">
     <strong style="font-size: 1.2rem;">{{ option.title }}</strong><br>
     <span v-if="option.obrazek_id" style="display:inline-block; max-height: 50px;">
               <image-preview :image-id="option.obrazek_id" />
     </span>
     <span v-if="option.kategorie_title">Kategorie: {{option.kategorie_title}}</span>
     <span v-if="option.datum_publikace">datum: {{option.datum_publikace}}</span>
   </template>

   <template v-slot:option="{ option, search }">
     <strong style="font-size: 1.2rem;">{{ option.title }}</strong><br>
     <span v-if="option.obrazek_id"  style="display:inline-block; max-height: 50px;">
         <image-preview :image-id="option.obrazek_id" />
     </span>
     <span v-if="option.kategorie_title">Kategorie: {{option.kategorie_title}}</span>
     <span v-if="option.datum_publikace">datum: {{option.datum_publikace}}</span>

   </template>




  </VueMultiselect>
  -->


</template>

<script>
import ImageComponent from "@/components/general/Image";
import VueMultiselect from 'vue-multiselect'
import axios from "axios";
import DateimePreview from "@/components/previews/DatePreview";

let timer;
export default {
  name: "SelectSmartSegment",
  components: {
    VueMultiselect,
    ImageComponent,
    DateimePreview,
  },
  props: {
    "segmentsObj": [Object, Array],
    "inputObj": Object,
    "formObj": Object,
    "modelValue": [String, Object],
  },
  emits: ['update:modelValue'],
  data() {
    return {
      options: [],
      selectModel: null,
      inputModel: null,
    }
  },
  mounted() {
    this.inputModel = this.inputObj.obsah;
  },
  methods: {
    async asyncSearch (query) {
      //
      let path = null
      if(this.inputObj.params.type == 'link') {
        path = this.getApiConfig('obsah.link.path') // this.cfg.api.obsah.link.path
      }else if(this.inputObj.params.type == 'autor') {
         path = this.getApiConfig('user.select_list_service.path') // this.cfg.api.user.select_list_service.path
      }else if(this.inputObj.params.type == 'intepret') {
        path = this.getApiConfig('user.select_list_service.path') //FAKT? this.cfg.api.user.select_list_service.path
      }
      //TODO soutez, interpret, zebrik, etc
      //console.log("dev smart select asyncFind", path)

      if(typeof path != "undefined" && path){
        if(query)  path += query;


        if(timer) clearInterval(timer);
        timer = setTimeout(
            () => {
              //this.is_input_change = false;
              console.log('dev delay 1000',query, this.selectModel)
              axios.defaults.baseURL = this.getBaseRoot();
              axios({
                url: path,
                method: "get",
                withCredentials: true,
                headers: this.getRequestHeader(),
              }).then((res) => {
                this.options = res.data.response.list.data;
                //console.log("dev tags data", res.data.response.list.data)
              })
                  .catch((err) => {
                    this.options = []
                    //console.log("DEV tags catch", err);
                  })
                  .finally(() => {
                    //console.log("DEV tags finally");
                  })
            },
            500
        )
      }
    },
    formatSelectItem ({ id, title }) {
      return `${title}`
    },
  },
  watch: {
    inputObj: {
      handler() {
         this.selectModel = this.inputObj.val;

      },
      deep: true,
      immediate: true
    },
    inputModel: {
      handler(newVal) {
        if(newVal) {
          //console.log('link segment emit this.selectModel', this.selectModel)
          //console.log('link segment emit selectModel', newVal)
          //DEV
          this.$emit('update:modelValue', newVal )
        }

      },
      deep: true,
      immediate: true
    },

  },
};
</script>