<template>
  <div class="input-group list-datum">
    <span v-if="value && is_date" class="form-label list-datum">{{currentDate.getDate()}}.{{getMonthTitle((currentDate.getMonth()+1))}}.{{currentDate.getFullYear()}}</span>
    <span v-if="value && is_time" class="form-label ms-2 list-datum">{{currentDate.getHours()}}:{{String(currentDate.getMinutes()).padStart(2, '0')}}</span>
  </div>
</template>

<script>
import axios from "axios";
export default {
  name: "DatePreview",
  props: {
    is_date: [Boolean],
    is_time: [Boolean],
    value: [ String]
  },
  computed: {
    currentDate() {
      return new Date(this.value);
      let d = '';
      const dt = new Date(this.value);
      d += dt.getFullYear()
      return d;
    },
  },
  methods: {
    getMonthTitle($month_number){
      const nazvy = {
        1: "leden",
        2: "únor",
        3: "březem" ,
        4: "duben",
        5: "květen",
        6: "červen",
        7: "červenec",
        8: "srpen",
        9: "září",
        10: "říjen",
        11: "listopad",
        12: "prosinec"
      }
      return nazvy[$month_number];
   },
  },
};
</script>