<template >
  <div class="row w-100 mb-2">
    <div
      v-for="(inputItem, inputIndex)  in inputObj.templates.items"
      :class="inputItem.wrap_class"
    >
      <div class="input-group">

          <label
              :class="'input-group-text '  + (inputItem.err? ' bg-danger text-white': '')"
              :for="inputIndex"
          >
            {{inputItem.title}}
          </label>


        <input
            v-model="inputModel[inputIndex]"
            :id="inputIndex"
            :readonly="inputItem.readonly? 'readonly': null"
            :required="inputItem.required? 'required': null"
            type="number"
            min="1"
            :class="'form-control'  + (inputItem.err? ' is-invalid': '')"
            @change="validation(
                inputItem.validation? inputItem.validation.method: null,
                inputItem.validation? inputItem.validation.args: null
              )"
        >
        <div class="invalid-feedback" v-if="inputItem.err">
          {{inputItem.err}}
        </div>
      </div>
    </div>
  </div>

  <!--
  <pre>formObj {{inputObj.templates}}</pre>
  <pre>inputModel {{inputModel}}</pre>
  <pre>inputModel {{inputModel}}</pre>
  <pre>inputObj {{inputObj}}</pre>
  <pre>{{inputObj.templates}}</pre>
  -->
</template>

<script>

import axios from "axios";

export default {
  name: "StoreInput",
  props: {
    "inputObj": Object,
    "formObj": Object,
    "modelValue": [String, Number, Object],
  },
  data() {
    return {
      inputModel: {},
      tax: {},
      tax_proc_value: 21,
      timer: null,
      timer_delay: 500,
      dev_key: 'czk',
    }
  },
  created() {

    if(this.inputObj.val) {
      this.inputModel = this.inputObj.val
    }else{
      this.inputModel = {
        in_store: null,
        sold: 0,
      }
    }

    //this.inputModel.sold = 5

  },
  methods: {
    validation(ident, args) {
      if(ident == 'greaterFirstThenSecond' ) {
        if(this.inputModel[args[0]] < this.inputModel[args[1]]){
          const k = 'in_store'
          this.inputObj.templates.items[args[0]].err = 'Nelze zadat prvni hodnotu mensi nez druhou.'
          console.log("dev validatione NEVALIDNI", ident, args);
          this.inputObj.invalid = {
            'status': true,
            "message": 'Neplatne hodnoty!'
          }
        }else{
          delete this.inputObj.templates.items[args[0]].err;
          delete this.inputObj.invalid
        }
      }
    },
  },
  watch: {
    inputObj: {
      handler() {
        this.inputModel = this.inputObj.val;
      },
      deep: true,
      immediate: true
    },

    inputModel: {
      handler(newVal, oldVal) {
        this.$emit('update:modelValue', newVal )
      },
      deep: true,
      immediate: true
    },

  },
};
</script>