export default {
    beforeMount(el, binding) {
        const marker = document.createElement('i');
        marker.classList.add('bi')

        if(binding.value == 1){
            marker.classList.add('bi-check-circle-fill')
            marker.classList.add('text-success')
            marker.title = 'Zobrazeno'
        }else if (binding.value == 0) {
            marker.classList.add('bi-x-circle-fill')
            marker.classList.add('text-danger')
            marker.title = 'Nezobrazeno'
        }else{
            marker.classList.add('bi-question-circle-fill')
            marker.classList.add('text-warning')
            marker.title = 'Neznamy stav'
            //marker.classList.add('text-warning')
        }

        el.appendChild(marker);
    }
}