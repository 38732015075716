//import {translatedTexts} from "@/consts/translate";

export function translate(item) {
    const langCode = localStorage.getItem(
        this.getConfig('lang_id_local_storage_name')
    );

    if(langCode == null) {
        const langCode = this.getConfig('lang_id_default_value');
    }

/*
    console.log(
        'dev.translate',
        langCode,
        item,
        item[langCode]
    )
*/
    return item[langCode];
}

export function getTranslate(key) {
    const langCode = localStorage.getItem(
        this.getConfig('lang_id_local_storage_name')
    );
    if(langCode == null) {
        const langCode = this.getConfig('lang_id_default_value');
    }
    return this.getValueByPath(
        this[this.constsPropName]['translatedTexts'],
        key + '.' + langCode
    );
}
