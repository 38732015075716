<template>
  <template
   v-if="type == 'swiper'"
  >
    <swiper
        v-if="pagingData && pagingData.page_count > 1"
        :slidesPerView="'auto'"
        :centeredSlides="true"
        :spaceBetween="0"
        :modules="swiperModules"
        :grabCursor="true"
        :mousewheel="true"
        :scrollbar="{
          hide: true,
        }"
        :initialSlide="activePage"
        class="pagination pagination-sm pb-3 my-2 w-100s"
    >

      <swiper-slide
        v-for="(item) in pagingData.list"
        :class="'page-item text-center' + (item.is_active? ' disabled': '')"
        style="max-width: 50px;"
      >
        <router-link
            v-if="!item.is_active"
            :to="getPath(item.number)"
            class="page-link"
        >
            {{item.number}}
        </router-link>
        <span
            v-else
            class="page-link"
        >
          {{item.number}}
        </span>
      </swiper-slide>
    </swiper>

<!--
    <display-debug>
      <template #debugView>activePage: {{activePage}}</template>
    </display-debug>
    <display-debug>
      <template #debugView>pagingData {{pagingData}}</template>
    </display-debug>
    -->

  </template>
  <template
      v-else
  >
    <div class="d-sm-flex justify-content-between align-items-start w-100 my-2">
      <div class="align-self-center overflow-auto">
        <ul
            v-if="pagingData && pagingData.page_count > 1"
            class="pagination pagination-sm"
        >
          <li
              v-for="(item) in listTrim"
              :class="'page-item' + (item.is_active? ' disabled': '')"
          >
            <router-link
                v-if="!item.is_active"
                :to="getPath(item.number)"
                class="page-link"
            >
              <span
                  v-if="item.title"
                  class="d-flex"
              >
                <i v-if="item.ico_pre" :class="'' + item.ico_pre"></i>
                <span class="">{{item.title}}</span>
                <i v-if="item.ico_post" :class="'' + item.ico_post"></i>
              </span>
              <span
                  v-else
              >
              {{item.number}}
             </span>
            </router-link>

            <span
                v-else
                class="page-link"
            >
              <span
                  v-if="item.title"
              >
                <i v-if="item.ico_pre" :class="item.ico_pre"></i>
                {{item.title}}
                <i v-if="item.ico_post" :class="item.ico_post"></i>
              </span>
              <span
                  v-else
              >
              {{item.number}}
             </span>
            </span>
          </li>
        </ul>

      </div>
      <div
          v-if="isOriginalListLonger"
          class="align-self-center"
      >
        <!--
        <span>{{getTranslate('paging.goto')}}</span>
        -->
        <select
            v-model="modelPage"
            class="form-control form-control-sm"
            placeholder="prejit na stranku"
            :title="getTranslate('paging.goto')"
        >
          <option disabled>prejit na:</option>
          <option
              v-for="item in pagingData.list"
              :value="item.number"
          >
            {{item.number}}
          </option>
        </select>
      </div>

    </div>
  </template>




  <!--
 <display-debug>
    <template #debugView>pagingData {{pagingData}}</template>
  </display-debug>

    <display-debug>
      <template #debugView>[in filter] reloadFilter: {{reloadFilter}}</template>
    </display-debug>

    <display-debug>
      <template #debugView>filterModel {{filterModel}}</template>
    </display-debug>
    -->
</template>

<script>
import { Navigation, Pagination, Scrollbar } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/vue';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';

export default {
  name: "ListPaging",
  components: {
    Swiper,
    SwiperSlide
  },
  props: {
    "type": [String],
    "pagingData": [Object, Array, String, Number],
  },
  setup() {
    return {
      swiperModules: [Pagination, Scrollbar],
    };
  },
  data() {
    return {
      activePage: 1,
      isOriginalListLonger: false,
      modelPage: null,
      swiperCountPerView: 10,
    }
  },
  methods: {
    getPath(page){
      let path = this.$route.path + '?page=' + page ;
      if(this.$route.query.filters){
        path += '&filters=' + this.$route.query.filters ;
      }
      return path;
    },

  },
  computed: {
    listTrim() {
      const oneSideLimit = 10;
      if(this.pagingData.list) {
        const index  = this.pagingData.list.findIndex(obj => obj.is_active === true); // Vrátí 1

        let indexStart = index - oneSideLimit;
        let indexEnd = index + oneSideLimit;

        if(indexStart < 0){
          indexEnd = indexEnd - indexStart;
          indexStart = 0;
        }

        if(indexEnd > this.pagingData.list.length) {
          indexStart = indexStart - (indexEnd - this.pagingData.list.length);
          indexEnd = this.pagingData.list.length - 1;
        }

        if(indexStart < 0){
          indexStart = 0;
        }

        let list = this.pagingData.list.slice(indexStart, indexEnd + 1)

        if(typeof this.pagingData.list[index - 1] != "undefined" && this.pagingData.list[index - 1]) {
          list.unshift({
            'is_active': false,
            'number': this.pagingData.list[index - 1].number,
            'title': this.getTranslate('paging.prev'),
            'ico_pre': 'bi bi-caret-left',
          })
        }else{
          list.unshift({
            'is_active': true,
            'number': null,
            'title': this.getTranslate('paging.prev'),
            'ico_pre': 'bi bi-caret-left',
          })
        }

        if(typeof this.pagingData.list[index + 1] != "undefined" && this.pagingData.list[index + 1]) {
          list.push({
            'is_active': false,
            'number': this.pagingData.list[index + 1].number,
            'title': this.getTranslate('paging.next'),
            'ico_post': 'bi bi-caret-right',
          })
        }else{
          list.push({
            'is_active': true,
            'number': null,
            'title': this.getTranslate('paging.next'),
            'ico_post': 'bi bi-caret-right',
          })
        }




        if(list.length < this.pagingData.list.length){
          this.isOriginalListLonger = true;
        }
        return list;
      }else{
        this.isOriginalListLonger = false;
        return [];
      }

    }
  },
  created() {

    //console.log("dev.paging mounted", this.pagingData.list);

    if(this.pagingData.list) {
      this.activePage  = this.pagingData.list.findIndex(obj => obj.is_active === true); // Vrátí 1
      this.modelPage = this.activePage + 1;
    }
  },
  mounted() {

    //console.log("dev.paging mounted", this.pagingData.list);
/*
    if(this.pagingData.list) {
      this.activePage  = this.pagingData.list.findIndex(obj => obj.is_active === true); // Vrátí 1
      this.modelPage = this.activePage + 1;
    }
    */
  },
  watch: {
    modelPage: {
      handler(newVal, oldVal) {
        if(newVal && newVal != this.activePage && newVal != 1){
          this.$router.push(this.getPath(newVal));
        }
      },
      immediate: true
    },
  }
};
</script>