<template>
  <div>
    <pre>modalEditSlot: {{modalEditSlot}}</pre>
    <pre>modalSlot: {{modalSlot}}</pre>

    <modal-window
        v-if="modalEditSlot && modalEditSlot.config.display"
        :title="modalEditSlot.name"
        :config="modalEditSlot.config"
        @keydown="detectClose"
    >
      <template #modal-content-slot>
        <component
            v-bind:is="'ContentIncludeView'"
            :router="modalEditSlot.router"
            :return-data="modalEditSlot.data"
            :form-prefill="modalEditSlot.prefill"
            :config="{
           is_standalone: false,
           is_filter: false,
           is_paging: false,
           is_title: false,
           is_list_header: false,
           is_btn_prefill: false,
           is_unlimited: false,
         }"
        ></component>
      </template>
    </modal-window>
  </div>

  <div>
    <h1>Rezervace: {{dataObj.day_title}} <small>pro</small> {{dataObj.restaurace_title}}</h1>

    <div class="input-group mb-3">
      <div class="input-group-prepend">
        <span class="input-group-text">
          Načteno: {{dataObj.last_refresh}}
        </span>
      </div>

      <div class="input-group-prepend" v-if="countdownObj.is_active">
        <span class="input-group-text">
          <span>Další aktualizace za {{countdownObj.displayValue}}</span>
        </span>
      </div>

      <div class="input-group-append">

        <label class="input-group-text" for="countdown_is_active" >
          <input type="checkbox" class="me-2" v-model="countdownObj.is_active" id="countdown_is_active">
          Automatické obnovení
        </label>
      </div>

      <div class="input-group-append">
        <button
            type="button"
            @click="reloadData"
            class="btn btn-warning fw-medium mb-2"
            title="Obnovit data"
        >
          <i class="bi bi-arrow-repeat me-1"></i>
          <span>Obnovit</span>
        </button>
      </div>
    </div>

    <nav>
      <ul
        v-if="dataObj.days"
        class="pagination"
      >
        <li
            v-for="(dayItem, dayIndex)  in dataObj.days"
            @key="dayIndex"
            :class="'page-item' + dayItem.selected? ' active': ' x'"
        >
          <router-link
              :to="'/rezervace-slot/view-prehled/' + dataObj.restaurace_id + '?date=' + dayItem.id"
              :class="'page-link'"
          >
            {{dayItem.title}}

          </router-link>
        </li>
      </ul>
    </nav>
  </div>

<!--
<div >
  [dev.component.start]
  <component
      v-bind:is="'ContentIncludeViewRestarauraceMenu'"
      :router="{modul: 'rezervace-slot', action: 'list'}"
      :return-data="new_slot_data"
      :form-prefill="formPrefill"
  ></component>
  [dev.component.end]
</div>
  -->


  <!-- grid list start -->
  <div
      class="reservation-view-wrap"
  >
    <div
        class="body"
    >
      <div class="content-wrap">
        <div
            v-for="(spotItem, spotIndex)  in dataObj.data"
            @key="spotIndex"
            class="spot"
            @mousemove.prevent="handleSpotMouseMove($event, spotItem, spotItem.id, spotItem.position)"
            @mouseleave.prevent="handleSpotMouseOut(spotItem)"

            :ref="'spot_' + spotItem.id"
        >
          <!--
            @mouseout.prevent="handleSpotMouseOut(spotItem)"
            @mouseenter.prevent="handleSpotMouseMove($event, spotItem, spotItem.id, spotItem.position)"
          -->
          <div class="spot-wrap">
            <div
                class="slot slot-title"
            >
              <div class="slot-wrap">
                {{spotItem.nazev}}
              </div>
            </div>

            <div
                v-if="spotItem.placeholder_new && spotItem.placeholder_new.display"
                class="slot slot-create"
                :style="'left: '+ (spotItem.placeholder_new.position.left).toFixed(2)  +'%; width: '+ (spotItem.placeholder_new.position.width).toFixed(2)  +'%;'"

            >
              <a
                  @click.prevent="createNewSlot(spotItem)"
                  class="btn btn-primary"
              >
                Nová rezervace
              </a>
            </div>


            <div
                v-for="(slotItem, slotIndex)  in spotItem.slots"
                @key="slotIndex"
                :ref="'slot_' + slotItem.id"
                :class="'slot stav ' + getStavClass(slotItem.stav_id) + (slotItem.is_deactive? ' deactive':' active')"
                @mousemove.prevent="handleSlotMouseOut(spotItem)"
                @mouseenter.prevent="handleSlotHover(true, slotItem)"
                @mouseleave.prevent="handleSlotHover(false, slotItem)"
                :style="'left: '+ (slotItem.datum_od_slot * dataObj.setup.slot_sirka).toFixed(2)  +'%; width: '+ (slotItem.delka_slot * dataObj.setup.slot_sirka).toFixed(2)  +'%;'"
            >
              <div class="slot-wrap">
                <div class="slot-content">
                  <span class="time">{{slotItem.display_interval}}</span>
                  <span class="title">{{slotItem.jmeno}}</span>
                </div>

                <div class="popup-desc">

                  <ul class="list-group">
                    <li class="list-group-item active">{{slotItem.jmeno}}</li>
                    <li class="list-group-item">{{spotItem.nazev}}</li>
                    <li class="list-group-item">

                      <small>počet míst:</small>
                      {{slotItem.pocet}}

                    </li>
                    <!--
                    <li v-if="slotItem.poznamka" class="list-group-item"><small>pozn.:</small>{{slotItem.poznamka}}</li>
                    <li class="list-group-item"><a :href="'mailto:'+slotItem.email" class="btn btn-sm btn-outline-primary">{{slotItem.email}}</a></li>
                    <li class="list-group-item"><a :href="'tel:'+slotItem.telefon" class="btn btn-sm btn-outline-primary">{{slotItem.telefon}}</a></li>
                    -->
                  </ul>

                </div>
              </div>

            </div>
          </div>
        </div>
      </div>

      <div class="grid-wrap">
        <div class="grid">
          <div
              v-for="(gridItem, gridIndex)  in dataObj.grid"
              @key="gridIndex"
              class="grid-item"
              :style="'left:' + gridItem.pos.toFixed(2) + '%; width: ' + gridItem.w.toFixed(2) +'%;'"
          >
        <span class="title">
          {{gridItem.title}}<br>
        </span>

          </div>
        </div>
      </div>

      <div class="time-wrap" v-if="time_marker_position_left">
        <div class="time">
            <div
                class="time-mark"
                ref="timeMarker"
                :style="'left:' + time_marker_position_left + '%;'"
            >

            </div>
        </div>
      </div>

    </div>
    <pre>grid: {{dataObj.data[4].slots}}</pre>
    <!--
    <div
        class="footer"
    >
      <div
          v-for="(gridItem, gridIndex)  in dataObj.grid"
          @key="gridIndex"
          class="time-slot"
          :style="'left:' + gridItem.pos.toFixed(2) + '%; width: ' + gridItem.w.toFixed(2) +'%;'"
      >
        <span class="title">
          {{gridItem.title}}<br>
        </span>
      </div>
    </div>
    -->
  </div>
  <!-- grid list end -->

  <!-- table list start-->
  <div
      v-if="dataObj.list && dataObj.list.length"
  >
    <table
        class="table table-responsive table-bordered table-striped table-hover"
    >
      <thead class="thead-light">
      <tr>
        <th scope="col">Místo</th>
        <th scope="col" class="text-center">Počet</th>
        <th scope="col">Čas</th>
        <th scope="col">Stav</th>
        <th scope="col">Jméno</th>

        <!--
        <th scope="col">Kontakt</th>
        -->
        <th scope="col" class="text-end">Akce</th>
      </tr>
      </thead>
      <tbody>
      <tr
          v-for="(listItem, listIndex)  in dataObj.list"
          @key="listIndex"
          :ref="'list_slot_' + listItem.id"
          @mouseenter="handleTableRowHover(true, listItem)"
          @mouseout="handleTableRowHover(false, listItem)"
      >


        <td>{{ listItem.spot_title }}</td>
        <td class="text-center">{{ listItem.pocet }}x</td>
        <td>{{ listItem.cas_od }} - {{ listItem.cas_do }}</td>
        <td>{{ listItem.stav_title }}</td>
        <td>
          <span>{{ listItem.jmeno }}</span>
          <a v-if="listItem.telefon" :href="'tel:' + listItem.telefon" class="btn btn-outline-secondary btn-sm ms-2">
            <i class="bi bi-telephone-forward me-1"></i>
          </a>
          <a v-if="listItem.email" :href="'mailto:' + listItem.email" class="btn btn-outline-secondary btn-sm ms-2">
            <i class="bi bi-envelope-at me-1"></i>
          </a>
        </td>

        <!--
        <td>
          <a v-if="listItem.telefon" :href="'tel:' + listItem.telefon" class="btn btn-outline-secondary btn-sm ms-2">
            <i class="bi bi-phone me-1"></i>
            {{ listItem.telefon}}
          </a>
          <a v-if="listItem.email" :href="'mailto:' + listItem.email" class="btn btn-outline-secondary btn-sm ms-2">
            <i class="bi bi-envelope-at me-1"></i>
            {{ listItem.email }}
          </a>
        </td>
        -->
        <td class="text-end">

          <router-link
              :to="'/rezervace-slot/edit/' + listItem.id"
              :class="'btn btn-outline-primary btn-sm ms-2'"
              target="_blank"
              @click.prevent="displayModalSlotEdit(listItem)"
          >
            <i class="bi bi-pencil"></i>

          </router-link>



          <a
              @click.prevent="setStav(listItem, dataObj.setup.stav.uskutecneno)"
              class="btn btn-success btn-sm ms-2 border-success"
              title="Host dorazil"
          >
            <i class="bi bi-hand-thumbs-up-fill"></i>
          </a>
          <a
              @click.prevent="setStav(listItem, dataObj.setup.stav.propadle)"
              class="btn btn-danger btn-sm ms-2"
              title="Host NEDORAZIL"
          >
            <i class="bi bi-hand-thumbs-down-fill"></i>
          </a>

          <a
              v-if="listItem.stav_id == dataObj.setup.stav.potvrzena || listItem.stav_id == dataObj.setup.stav.prijata || listItem.stav_id == dataObj.setup.stav.cekajici"
              @click.prevent="setStav(listItem, dataObj.setup.stav.storno)"
              class="btn btn-primary btn-sm ms-2"
              title="Storno"
          >
            <i class="bi bi-trash"></i>
          </a>

        </td>
      </tr>

      </tbody>

    </table>
  </div>
  <div v-else>
    Pro tento den zatim nejsou zadne rezervace
  </div>
  <!-- table list end -->

  <!-- table add form start -->
  <div ref="form_create_new">
    <!--
    <pre>createForm.status {{createForm.status}}</pre>
    -->
    <span v-if="createForm.status" @click.prevent="createForm.status=false" class="btn btn-danger">zavrit formular</span>

    <component
        v-if="createForm.status"
        v-bind:is="'ContentIncludeView'"
        :router="{modul: 'rezervace-slot', action: 'new'}"
        :return-data="new_slot_data"
        :form-prefill="formPrefill"
    ></component>


    <!--
    <pre>formPrefill: {{formPrefill}}</pre>
    <pre>new_slot_data: {{new_slot_data}}</pre>
    <component
        v-if="createForm.status"
        v-bind:is="'ContentIncludeView'"
        :router="{modul: 'rezervace-slot', action: 'new'}"
        :return-data="new_slot_data"
        :form-prefill="formPrefill"
    ></component>
-->
  </div>
  <!-- table add form end -->




  <!--
  <pre>{{$refs}}</pre>
  <pre>formPrefill: {{formPrefill}}</pre>
  <pre>new_slot_data: {{new_slot_data}}</pre>


  <content-include-view
      v-if="createForm.status"
      :router="{modul: 'rezervace-slot', action: 'new'}"
      :return-data="new_slot_data"
      :form-prefill="formPrefill"
  >
  </content-include-view>
  -->
  <!--
  <pre>
    dataObj.setup.stav {{dataObj.list}}
  </pre>

    <pre>
    [dev nacteni dalsiho modulu inside]
    dev_return [PARENT] {{dev_return}}
    formPrefill [PARENT] {{formPrefill}}
  </pre>
    <pre>
    new_slot_data {{new_slot_data}}
  </pre>
    -->



  <!--
  <pre>ViewTimeline list: {{dataObj.list}}</pre>
  <pre>ViewTimeline grid: {{dataObj.grid}}</pre>

    <Timeline
        ref="timeline"
        :items="items"
        :groups="groups"
        :options="options"
        :events="['drop', 'changed']"
        @drop="myDropCallback"
        @changed="myChangedCck"
    />

    <pre>ViewTimeline items: {{items}}</pre>
    <pre>ViewTimeline groups: {{groups}}</pre>
    <pre>ViewTimeline dataObj: {{dataObj}}</pre>
    -->

</template>

<script>

const config = {
  displayType: 'day', //week, day, hour,
}

import './ViewTimeline.scss'
import ContentIncludeView from "@/views/ContentIncludeView";
import ModalWindow from '@/components/content/ModalWindow'
import axios from "axios";

let timer;
const countdown_key = 'rezervace_slot_count_status';
const timeRefreshIntervalMinutes = 10 * 60;
export default {
  name: "ViewTimeline",
  components: {
    ContentIncludeView,
    ModalWindow
  },
  props: {
    "dataObj": Object,
    "returnData": [Object, String, Number],
    "reloadSignal": [Object, String, Number],
  },
  emits:[
    'update:reloadSignal',
  ],
  data() {
    return {
      config: null,

      time_marker_position_left: 0,
      dev_modal_cfg: {
        display:false,
        is_header: true,
        is_close_btn: true,
        is_footer: true,
      },
      window: {},

      inputModel: null,
      workingSpot: null,
      new_slot_data: {
        save: false,
        submit: false,
      },
      formPrefill: {},
      createForm : {
        status: false
      },
      countdownObj: {
        is_active: false,
        is_paused: false,
        minutes: 10,
        displayValue: null
      },
      modalSlot: null,
      modalNewData: {
        save: false,
        submit: false,
      },

      modalEditSlot: null,
      modalEditData: {
        save: false,
        submit: false,
      },
    }
  },
  mounted() {
    this.onWindowResize();
  },
  created() {

    this.config = config;
    console.log('dev.reservation [viewTimeline]', this.dataObj.config)

    if(this.dataObj.is_today) {
      this.timeMarker();
      setInterval(this.timeMarker, timeRefreshIntervalMinutes * 1000);
    }

    this.countdown();

    //this.getDeviceViewport();


    //todo delete
    this.formPrefill = {
      general: {
        inputs: {
          restaurace_id: {
            readonly: true,
            value: this.dataObj.restaurace_id
          },
          stav_id: {
            readonly: true,
            value: this.dataObj.setup.stav.potvrzena,
          }
        }
      },
    },

/*
    window.addEventListener('focus', function(event) {
      console.log('Okno má fokus (je aktivní).');
      //this.countdownObj.is_paused = false;
    });

    window.addEventListener('blur', function(event) {
      console.log('Okno ztratilo fokus (není aktivní).');
      //this.countdownObj.is_paused = true;
    });
*/


    window.addEventListener('resize', this.onWindowResize);
    this.createFullSlots();
  },
  destroyed() {
    //DEV window.removeEventListener('resize', this.onWindowResize);
  },
  methods: {

    displayModalSlotEdit(item) {
      this.modalEditSlot = {
        name: 'Editace rezervace',
        router: {
          modul: 'rezervace-slot',
          action: 'edit/' + item.id
        },
        config: {
          "display": true,
          "is_header": true,
          "is_close_btn": true,
          "is_footer": true
        },
        data: {
          save: false,
          submit: false,
        },
        prefill: {
          general: {
            inputs: {
              restaurace_id: {
                readonly: true,
                //value: this.dataObj.restaurace_id
              },
              /*
              stav_id: {
                readonly: true,
                value: this.dataObj.setup.stav.potvrzena,
              }
              */
            }
          },
        },
      }
    },

    timeMarker(){
      const oneMinute = 60 * 1000;
      const currentDate = new Date(); //"2024-03-17 16:00:00"

      const otevrenoZacatek = new Date(this.dataObj.setup.datum.zacatek);
      const otevrenoKonec = new Date(this.dataObj.setup.datum.konec);

      const intervalMinutes = (otevrenoKonec.getTime() - otevrenoZacatek.getTime())/oneMinute + 60;
      const aktualniCas = (currentDate.getTime() - otevrenoZacatek.getTime())/oneMinute

      this.time_marker_position_left = Math.round((aktualniCas/intervalMinutes) * 100);
      if (this.time_marker_position_left < 0 || this.time_marker_position_left > 100){
        this.time_marker_position_left = null;
      }

      if(this.dataObj.data) {
        for(const k in this.dataObj.data) {
          for(const s in this.dataObj.data[k].slots) {
            const slotKonec = new Date(this.dataObj.data[k].slots[s].datum_do);
            if(slotKonec.getTime() < currentDate.getTime()) {
              this.dataObj.data[k].slots[s].is_deactive = true;
            }else{
              this.dataObj.data[k].slots[s].is_deactive = false;
            }
          }
        }
      }
    },

    countdown() {
      const intervalInSeconds = this.countdownObj.minutes * 60; // Pět minut v sekundách
      let remainingTime = intervalInSeconds;

      const updateCountdown = () => {
        const minutes = Math.floor(remainingTime / 60);
        const seconds = remainingTime % 60;
        const formattedTime = `${minutes}:${seconds.toString().padStart(2, '0')}`;

        this.countdownObj.displayValue = formattedTime;
        remainingTime--;

        if (remainingTime < 0) {
          if(this.countdownObj.is_active){
            clearInterval(interval);
            this.reloadData()
          }
        }
      };
      const interval = setInterval(updateCountdown, 1000);
    },

    reloadData(){
      this.$emit("update:reloadSignal", String(Math.random()));
    },

    setStav(slot, stav_id){
      let formData = new FormData;
      formData.append('slot_id', slot.id)
      formData.append('stav_id', stav_id)
      axios.defaults.baseURL = this.getBaseRoot();
      let apiPath = this.getApiConfig('rezervace.slot_change_slot_status.path');
      axios({
        url: apiPath,
        data: formData,
        method:  this.getApiConfig('rezervace.slot_change_slot_status.method'),
        withCredentials: true,
        headers: this.getRequestHeader(),
      }).then((res) => {
        slot.stav_title = res.data.response.stav.title
        slot.stav_id = res.data.response.stav.id
        //TODO vyhledat slot v grid vypisu
        //console.log('dev.setStav res', res.data.response.stav.title);
      })
    },

    createFullSlots(){
      if(this.dataObj.data) {
        for(const k in this.dataObj.data) {
          let fullSlots = [];
          for(const s in this.dataObj.data[k].slots) {
            if(this.dataObj.data[k].slots[s].delka_slot > 1) {
              for (let i = 1; i <= this.dataObj.data[k].slots[s].delka_slot; i++) {
                fullSlots.push(Math.ceil(this.dataObj.data[k].slots[s].datum_od_slot + i));
              }
            }else{
              fullSlots.push(Math.ceil(this.dataObj.data[k].slots[s].datum_od_slot + 1 ));
            }
          }
          this.dataObj.data[k].full_slots = fullSlots;
        }
      }
    },

    getStavClass(stav_id) {
      return 'stav-'+ this.dataObj.setup.stav[stav_id];
    },

    onWindowResize() {

      this.window  = {
        width: window.innerWidth,
        height: window.innerHeight,
      }

      if (this.window.width <= 480){
        this.window.type = 'mobile';
        this.window.class = 'viewport-mobile';
      }else{
        this.window.type = 'desktop';
        this.window.class = 'viewport-desktop';
      }

    },

    createNewSlot(spot) {
      this.workingSpot = spot;
      //start date
      const currentDateStart = new Date(this.dataObj.day);
      currentDateStart.setHours(
          (this.dataObj.setup.casovy_obal.zacatek.hodiny + this.dataObj.setup.casovy_obal.zacatek.js_korekce_hodiny),
          this.dataObj.setup.casovy_obal.zacatek.minuty
      )
      const minutesOffset = currentDateStart.getMinutes() + (spot.placeholder_new.slot.start -1) * this.dataObj.setup.slot_minuty
      currentDateStart.setMinutes(minutesOffset);

      //end date
      const currentDateEnd = new Date(this.dataObj.day);
      currentDateEnd.setHours(
          (this.dataObj.setup.casovy_obal.zacatek.hodiny + this.dataObj.setup.casovy_obal.zacatek.js_korekce_hodiny),
          this.dataObj.setup.casovy_obal.zacatek.minuty
      )
      currentDateEnd.setMinutes(minutesOffset);
      currentDateEnd.setMinutes(currentDateEnd.getMinutes() + (2 * this.dataObj.setup.slot_minuty));

      this.formPrefill.general.inputs.spot_id = {
        readonly: true,
        value: spot.id
      }

      this.formPrefill.general.inputs.date_range = {
        readonly: true,
        templates: null,
        value: {
          zacatek: currentDateStart.toISOString().slice(0, 19).replace("T", " "),
          konec: currentDateEnd.toISOString().slice(0, 19).replace("T", " "),
        }
      }
      this.createForm.status = true; //zobrazi formular
      if(this.$refs.form_create_new){ //scroll2form
        this.$refs.form_create_new.scrollIntoView({ behavior: 'smooth' })
      }

      this.createFullSlots()
    },

    handleSpotMouseOut(spot) {
      if(typeof spot.placeholder_new != "undefined" && typeof spot.placeholder_new.display != "undefined") {
        spot.placeholder_new.display = false;
      }
    },
    handleSlotMouseOut(spot) {
      if(typeof spot.placeholder_new != "undefined" && typeof spot.placeholder_new.display != "undefined") {
        spot.placeholder_new.display = false;
      }
    },

    handleSlotHover(display, slot) {
      if(display) {
        this.$refs['list_slot_' +  slot.id][0].classList.add('table-row-hover')
      }else{
        this.$refs['list_slot_' +  slot.id][0].classList.remove('table-row-hover')
      }
    },

    handleSpotMouseMove(event, spot, spot_id,  position) {
       if(timer) clearInterval(timer);
      timer = setTimeout(
          () => {
            //const _x = event.clientX; //TODO DEL
            //const _y = event.clientY; //TODO DEL

            const slot_pozice = Math.ceil(((event.clientX - position.x)/position.width*100)/this.dataObj.setup.slot_sirka); //sloty

            if(spot.full_slots.indexOf(slot_pozice)>= 0) {
              //console.log(`DEV EDIT Poloha myši slot OBSAZENO: ${recount}`);
              if(typeof spot.placeholder_new != "undefined") {
                spot.placeholder_new.display = false;
              }
            }else {
              spot.placeholder_new = {
                display: true,
                slot: {
                  start: slot_pozice
                },
                position: {
                  left: (slot_pozice - 1) * this.dataObj.setup.slot_sirka,
                  width: 2 * this.dataObj.setup.slot_sirka
                }
              }
              //console.log('dev.mouse', _x, _y, slot_pozice)
            }
          },
          25
      )
    },

    handleTableRowHover(display, slot) {
      if(display) {
        this.$refs['slot_' +  slot.id][0].classList.add('grid-item-hover')
      }else{
        this.$refs['slot_' +  slot.id][0].classList.remove('grid-item-hover')
      }
    },

    calcSpotPosition(){
      if(this.dataObj.data) {
        for(const k in this.dataObj.data) {
          const element = this.$refs['spot_'+ this.dataObj.data[k].id]
          if(element){
            this.dataObj.data[k].position = element[0].getBoundingClientRect();
          }
        }
      }
    },

    detectClose(event){
      console.log('[keydown]', event.key)
    },

  },
  watch: {
    modalEditSlot: {
      handler(newVal) {
        //console.log('dev.modalEditSlot', newVal);
      },
      deep: true,
      immediate: true
    },
    "modalEditSlot.data": {
      handler(newVal) {
        if(newVal && newVal.submit && newVal.save ){
          this.reloadData(); //reload
          //this.modalEditSlot.config.display = false //close modal
        }
      },
      deep: true,
      immediate: true
    },
    "modalCreateSlot.data": {
      handler(newVal) {
        if(newVal && newVal.submit && newVal.save ){
          this.reloadData(); //reload
          //this.modalCreateSlot.config.display = false //close modal
        }
      },
      deep: true,
      immediate: true
    },


    "countdownObj.is_active": {
      handler(newVal) {
        console.log('dev.countdownObj.is_active', newVal)
        localStorage.setItem(
            countdown_key,
            newVal
        )
      },
      deep: true,
      immediate: true
    },

    new_slot_data: {
      handler(newVal) {
        if(newVal && newVal.data){
          if (!this.workingSpot.slots || this.workingSpot.slots.length == 0) {
            this.workingSpot.slots = [];
          }

          let formData = new FormData;
          formData.append('form_data', JSON.stringify(newVal.data))
          axios.defaults.baseURL = this.getBaseRoot();
          let apiPath = this.getApiConfig('rezervace.view_prehled_slot_detail.path')
          axios({
            url: apiPath,
            data: formData,
            method: this.getApiConfig('rezervace.view_prehled_slot_detail.method'),
            withCredentials: true,
            headers: this.getRequestHeader(),
          }).then((res) => {
            //console.log('dev.watch.new_slot_data', res.data.response)

            const newSlotData = res.data.response.slot;
            this.workingSpot.slots.push(newSlotData)
            this.dataObj.list.push(newSlotData)

            this.createForm.status = false;

            const spotRef = 'spot_'+ res.data.response.slot.spot_id;
            if(this.$refs[spotRef] && this.$refs[spotRef][0]){
                this.$refs[spotRef][0].scrollIntoView({ behavior: 'smooth' })
            }
          })
        }
      },
      deep: true,
      immediate: true
    },
    window: {
      handler(newVal) {
        this.calcSpotPosition();
      },
      deep: true,
      immediate: true
    },
  },
};
</script>