<template>
  <h2>{{moduleData.data.title}}</h2>
  <div v-if="moduleData.data.type=='galerie'">
    <ul class="list-group d-inline-block">
      <li class="list-group-item">
        <i class="bi bi-images"></i> Počet obrázků: <span class="fw-bold ms-5">{{moduleData.data.stats.items_count}}</span>
      </li>
      <li class="list-group-item">
        <i class="bi bi-eye"></i> Celkem zobrazení: <span class="fw-bold ms-5">{{moduleData.data.stats.views_all}}</span>
      </li>
      <li class="list-group-item">
        <i class="bi bi-activity"></i> Průměrné zobrazení: <span class="fw-bold ms-5">{{moduleData.data.stats.image_average_view}}</span>
      </li>
      <li class="list-group-item">
        <i class="bi bi-arrow-up"></i> Maximálni zobrazení: <span class="fw-bold ms-5">{{moduleData.data.stats.image_max_view}}</span>
      </li>
      <li class="list-group-item">
        <i class="bi bi-arrow-down"></i> Minimálni zobrazení: <span class="fw-bold ms-5">{{moduleData.data.stats.image_min_view}}</span>
      </li>
    </ul>

    <ul class="list-group mt-3">
      <li
          v-for="(row, rowIndex)  in moduleData.data.stats.list"
          @key="rowIndex"
          class="list-group-item"
      >
        <image-component
            v-if="row.obrazek_id"
            :is_preview_only="true"
            :image-id="row.obrazek_id"
            thumb_class="list"
        />

        <div v-if="row.views_count">
          {{row.views_count}}x
        </div>
        <div v-else>
          Nezobrazeno
        </div>

        <div class="progress">

          <div
              class="progress-bar"
              role="progressbar"
              :style="'width:' + row.procent_rounded + '%'"
              aria-valuenow="50"
              :title="row.procent + '%'"
          >

          </div>
        </div>
<!--
        <pre>{{row}}</pre>
    -->
      </li>
    </ul>

  </div>

  <!--
  <pre>
    {{moduleData.data.stats}}
  </pre>
      -->

</template>

<script>
import ImageComponent from "@/components/general/Image";
export default {
  name: "StatsGalerieModule",
  components: {
    ImageComponent,
  },
  emits: [
    "update:reloadSignal"
  ],
  props: {
    "ident": String,
    "route": Object,
    "reloadSignal": String,
    "moduleData": Object
  },
  data() {
    return {
      reloadSignalBox: null,
      reloadSignalList: null
    }
  },
  methods: {
      reloadParent(val) {
        this.$emit("update:reloadSignal", val);
      }
  },
  watch: {
    reloadSignalBox: {
      handler(val) {
        this.reloadParent(val);
      },
      immediate: true
    },
    reloadSignalList: {
      handler(val) {
        this.reloadParent(val);
      },
      immediate: true
    },
  },
  created() {
   // console.log("dev DashboardModule created x")
  },
  mounted() {
   // console.log("dev DashboardModule mounted x")
  }
};
</script>