<template>
  <div class="cms5-login-wrap d-flex flex-wrap justify-content-center align-items-center p-2 w-100 h-100 g-3" style="width: 100%; ">
    <div
        class="d-md-flex flex-grow-1 px-4 px-md-0"
    >
      <div
          class="cms5-login-column  flex-md-fill"
      >

        <div class="text-center">
          <i class=" mt-4 bi bi-box-seam-fill text-secondary" style="font-size: 70px;"></i>
        </div>

        <h1 class="display-6 fw-bold lh-1 mb-2 text-center text-secondary">Vítejte v administaci webu j5.cz</h1>
        <p class="col-lg-10 ">
          Zmena zapomenuteho hesla
        </p>

      </div>
      <div
          class="cms5-login-column  flex-md-fill"

      >
        <div
            v-if="!is_password_changed"
        >

          <div>
            Zadejte ID zadosti a kontrolni kod, ktere jste dostali v emailu, bez toho nelze pokracovat.
          </div>


          <div
              v-if="error"
          >
            err: {{error}}
          </div>



          <div
            v-if="!is_valid_hash"
          >
            <input
                v-show="!is_valid_hash"
                type="text"
                v-model="user_id"
                class="form-control"
                placeholder="ID"
            >

            <input
                type="text"
                v-model="hash"
                class="form-control"
                v-show="!is_valid_hash"
                placeholder="Kod"
            >

            <div
                v-if="is_valid_input"
            >
              je platne, muzu zmenit hesla

            </div>

          </div>




          <div
              v-if="is_valid_hash"
              class=""
          >

            <div class="x">
              Nyni si nastavte svoje nove heslo!
            </div>
            <div
                class="input-group -border -border-danger"
            >


              <input
              type="password"
              v-model="password.original"
              class="form-control"
              placeholder="Nove heslo"
              >
              <input
              type="password"
              v-model="password.confirmed"
              class="form-control"
              placeholder="Nove heslo pro potvrzeni"
              >

            </div>

            <div class="text-end mt-2">
              <button
                  class="btn btn-primary"
                  :disabled="!password.is_valid"
                  @click.prevent="saveNewPassword"
              >
                Nastavit nove heslo
              </button>
            </div>
            <div v-if="!password.is_valid">
              <span class="text-danger">{{password.error}}</span>
            </div>
          </div>
        </div>

        <div
          v-else
        >
          Heslo bylo uspesne zmeneno, nyni se muzete prihlasit
          <router-link
              to="/"
              title="login"
              class="btn btn-primary"
          >
            Prihlaseni
          </router-link>
        </div>

      </div>
    </div>
  </div>
</template>

<script>


import axios from "axios";
export default {
  name: 'PasswordReset',
  components: {

  },
  data() {
    return {
      error: null,
      user_id: null,
      hash: null,
      is_valid_input: false,
      is_valid_hash: false,
      is_password_changed: false,
      password: {
        original: null,
        confirmed: null,
        is_valid: false,
        error: null,
      }
    }
  },
  methods: {
    async saveNewPassword(){
      const formData = new FormData;
      //formData.append(this.cfg.api.user.password_reset_setup_new.input.user_id, this.user_id)
      //formData.append(this.cfg.api.user.password_reset_setup_new.input.hash, this.hash)
      //formData.append(this.cfg.api.user.password_reset_setup_new.input.password, this.password.original)
      formData.append(this.getApiConfig('user.password_reset_setup_new.input.user_id'), this.user_id)
      formData.append(this.getApiConfig('user.password_reset_setup_new.input.hash'), this.hash)
      formData.append(this.getApiConfig('user.password_reset_setup_new.input.password'), this.password.original)


      axios.defaults.baseURL = this.getBaseRoot();
      await axios({
        //url: this.cfg.api.user.password_reset_setup_new.path,
        //method: this.cfg.api.user.password_reset_setup_new.method,
        url: this.getApiConfig('user.password_reset_setup_new.path'),
        method: this.getApiConfig('user.password_reset_setup_new.method'),

        data: formData,
      }).then((res) => {
        console.log("dev pass-reset / save pass", res.data.response )
          if(res.data.response.error) {
            this.is_password_changed = false;
            this.error = res.data.response.error;
          }else{
            this.is_password_changed = true;
          }
        /*
        if(res.data.status) {

        }else{

        }
        */
      });
    },

    async checkResetHash(){
      const formData = new FormData;
      //formData.append(this.cfg.api.user.password_reset_check_hash.input.user_id, this.user_id)
      //formData.append(this.cfg.api.user.password_reset_check_hash.input.hash, this.hash)
      formData.append(this.getApiConfig('user.password_reset_check_hash.input.user_id'), this.user_id)
      formData.append(this.getApiConfig('user.password_reset_check_hash.input.hash'), this.hash)

      axios.defaults.baseURL = this.getBaseRoot();
      await axios({
        //url: this.cfg.api.user.password_reset_check_hash.path,
        //method: this.cfg.api.user.password_reset_check_hash.method,
        url: this.getApiConfig('user.password_reset_check_hash.path'),
        method: this.getApiConfig('user.password_reset_check_hash.method'),

        data: formData,
      }).then((res) => {
        if(res.data.status) {
            this.is_valid_hash = true;
        }else{
          this.is_valid_hash = false;
        }
      });
    },

    async checkPassword(){
        const formData = new FormData;
        //formData.append(this.cfg.api.user.check_password.input.password, this.password.original)
        //formData.append(this.cfg.api.user.check_password.input.password_confirm, this.password.confirmed)
        formData.append(this.getApiConfig('user.check_password.input.password'), this.password.original)
        formData.append(this.getApiConfig('user.check_password.input.password_confirm'), this.password.confirmed)


        axios.defaults.baseURL = this.getBaseRoot();
        await axios({
          //url: this.cfg.api.user.check_password.path,
          //method: this.cfg.api.user.check_password.method,
          url: this.getApiConfig('user.check_password.path'),
          method: this.getApiConfig('user.check_password.method'),
          data: formData,
        }).then((res) => {
          if(res.data.status) {
            this.password.is_valid = true
          }else{
            this.password.is_valid = false;
            this.password.error = res.data.response.error;
          }
        });
      }
  },
  watch: {
    /*
    "password.original": {
      handler(newVal) {
        if(newVal){
          this.checkPassword()
        }
        //
      },
      immediate: true,
      deep: true,
    },
    "password.confirmed": {
      handler(newVal) {
        if(newVal){
          this.checkPassword()
        }
      },
      immediate: true,
      deep: true,
    },
    */
    user_id: {
      handler(newVal) {
        if(newVal){
          this.checkResetHash()
        }
      },
      immediate: true,
      deep: true,
    },
    hash: {
      handler(newVal) {
        if(newVal){
          this.checkResetHash()
        }
      },
      immediate: true,
      deep: true,
    },
  },
  created() {
    this.user_id = this.$route.params.user_id;
    this.hash = this.$route.params.hash;
    //console.log("dev pass-reset / route", this.$route.params.user_id )
  },
}
</script>