<template >
  <!--
  <pre>
    inputModel {{inputModel}}
  </pre>


  <pre>
    inputObj {{inputObj}}
  </pre>

  <pre>
    modelValue {{modelValue}}
  </pre>
  <hr>
  -->
  <div
    v-if="modelValue"
  >
   <input type="text" v-model="modelValue.title" class="form-control mb-2" />
    <div class="gallery ">
      <draggable
          :list="modelValue.images"
          itemKey="index"
          handle=".move"
          ghost-class="ghost"
          class="row row-cols-3 row-cols-sm-2 row-cols-md-3 row-cols-lg-6 row-cols-xxl-6 m-0 p-0"
      >
        <template #item="{ index, element }">
          <div
              class="__gallery-item __move"
          >
            <image-component
                :image-id="element"
                :is_preview_only="true"
                thumb_class="none w-100"
            />
            <div class="d-flex mt-1">
              <span @click.prevent="removeGalleryItem(index)" class="">
                <i class="bi bi-trash"></i>
                <span class="d-none d-sm-inline-block">Odstranit</span>
              </span>
              <span class="move ms-2 ms-auto">
                <i class="bi bi-arrows-move"></i>
              </span>
            </div>
          </div>
        </template>
      </draggable>
    </div>
  </div>



<div v-if="imageCountLeft > 0" class="">
  <p v-if="imageCountLeft == 1" class="text-info"><i class="bi bi-info-circle"></i> Můžete nahrát ještě jeden obrázek</p>
  <p v-else class="text-info"><i class="bi bi-info-circle"></i> Můžete nahrát ještě {{imageCountLeft}} obrázky.</p>

  <div class="input-group mb-1">
    <input
        type="file"
        ref="files_gallery_upload"
        accept="image/*"
        placeholder="vyber obrazky"
        multiple
        @change="preUpload($event.target.files)"
        class="form-control"
    >
    <button
        @click.prevent="imagesUpload()"
        ref="btnSubmit"
        disabled
        class="btn btn-outline-secondary"
    >
      Nahrát!
    </button>
  </div>

  <div v-if="loadingStatus">Nahrávám data, počkejte moment.</div>

  <multiselect
      v-model="browser_image_id"
      :options="browser_list"
      track-by="id"
      :searchable="true"
      :hideSelected="false"
      :custom-label="formatSelectItem"
      :placeholder="this.getText('image_component.placeholder_browser')"
      @search-change="asyncSearch"
      @open="asyncSearch"
      @select="onImageInput"
  >
    <template v-slot:singleLabel="{ option }">
      <div style="display: inline-block;">
        <image-component
            :image-id="option.id"
            :is_preview_only="true"
        />
      </div>
      <div style="display: inline-block; margin-left: 1rem;">
        [mingala option]
        <strong v-if="option.title" style="font-size: 1.2rem;">{{ option.title }}</strong><br>
        <span v-if="option.copyright">copyright: {{ option.copyright }}</span><br>&nbsp;
        <span v-if="option.user_title">autor: {{ option.user_title }}</span>&nbsp;
      </div>
    </template>

    <template v-slot:option="{ option, search }">
      <image-component
          :image-id="option.id"
          :is_preview_only="true"
      />
      <div style="display: inline-block; margin-left: 1rem;">
        <strong v-if="option.title" style="font-size: 1.2rem;">{{ option.title }}</strong><br>
        <span v-if="option.copyright">copyright: {{ option.copyright }}</span><br>&nbsp;
        <span v-if="option.user_title">autor: {{ option.user_title }}</span>&nbsp;
      </div>
      <!--
            <pre>{{option}}</pre>
            -->
    </template>

    <template v-slot:noResult>
      <div class="multiselect--no-results">Zadne vysledky pro tento dotaz</div>
    </template>

    <template v-slot:noOptions>
      <div class="multiselect--no-results">Zadne vysledky pro tento dotaz [noOption]</div>
    </template>
  </multiselect>
  <span v-if="imagesUploadErr" style="color: darkred;">{{imagesUploadErr}}</span>
</div>
<div v-else class="text-danger">
  <i class="bi bi-exclamation-triangle"></i> Do mini galerie se dá nahrát pouze {{inputObj.params.max_image_count}} obrázků.
</div>



</template>

<script>
import ImageComponent from "@/components/general/Image";
import multiselect  from 'vue-multiselect'
//import "vue-multiselect/dist/vue-multiselect.css"
import axios from "axios";
import draggable from "vuedraggable";
let timer;
export default {
  name: "GalerieMiniSegment",
  components: {
    multiselect,
    ImageComponent,
    draggable,
  },
  props: {
    "segmentsObj": [Object, Array],
    "inputObj": Object,
    "formObj": Object,
    "modelValue": [String, Object],
  },
  emits: ['update:modelValue'],
  data() {
    return {
      loadingStatus: false,
      inputModel: null,
      uploadFiles: [],
      imagesUploadErr: null,
      imagesErrArr: [],
      browser_list: [
      ],
      browser_image_id: null
    }
  },
  computed: {
    // a computed getter
    imageCountLeft() {
      let countLeft = this.inputObj.params.max_image_count
      if(this.inputObj.obsah && this.inputObj.obsah.images) {
        countLeft = countLeft - this.inputObj.obsah.images.length;
      }
      return countLeft
    }
  },
  mounted() {
    this.inputModel = this.inputObj.obsah;
  },
  methods: {
    removeGalleryItem(index, item) {
      if (confirm("Chcete smazat polozku?") == true) {
        this.modelValue.images.splice(index, 1);
      }
    },
    async imagesUpload(){
      if(this.uploadFiles && this.uploadFiles.length > 0) {
        for (let i = 0; i < this.uploadFiles.length; i++) {
            let formData = new FormData();
            this.loadingStatus = true;
            formData.append('image_file', this.uploadFiles[i] );
            formData.append('image_type', this.inputObj.params.image_type_id);

            axios.defaults.baseURL = this.getBaseRoot();
            await axios.post(
                this.getApiConfig('image.upload_service.path'),//this.cfg.api.image.upload_service.path,
                formData,
                {
                  headers: this.getRequestHeaderUpload()
                }
            ).then((res) => {
              if(typeof res.data.err == 'undefined') {
                this.modelValue
                if(!this.modelValue) {
                  this.modelValue = {
                    "title": this.inputObj.params.title_prefill,
                    "images": []
                  }
                }
                this.modelValue.images.push(res.data.response.response.id)
              }
            }).catch((err) =>{
              //console.log('gal upload err', err)
            }).finally(() => {
              this.loadingStatus = false;
            })
        }
      }
    },
    async preUpload(fileList) {

      if(fileList.length <= this.imageCountLeft) {
        this.uploadFiles = fileList
        let errArr = [];
        this.imagesErrArr = []
        for (let i = 0; i < fileList.length; i++) {
          let formData = new FormData();
          formData.append('image_file', fileList[i] );
          formData.append('image_type', this.inputObj.params.image_type_id);
          formData.append('image_validate', 1 );

          axios.defaults.baseURL = this.getBaseRoot();
          await axios.post(
            this.getApiConfig('image.upload_service.path'), //this.cfg.api.image.upload_service.path,
            formData,
            {
              headers: this.getRequestHeaderUpload()
            }
          ).then((res) => {
            if(typeof res.data.err == 'object') {
              errArr.push(fileList[i].name +": " + res.data.err.message);
            }
          }).catch((err) =>{
            errArr.push(fileList[i].name +": asi se rozbilo API")
          }).finally(() => {
            this.loadingStatus = false;
          })
        }

        if (errArr && errArr.length > 0) {
          this.$refs['btnSubmit'].disabled = true
          this.imagesUploadErr = errArr.join(", ");
        }else{
          this.imagesUploadErr = null;
          this.$refs['btnSubmit'].disabled = false
        }
      }else{
        this.imagesUploadErr = "Lze nahrat pouze " + this.imageCountLeft + "obrazky, nyni se pokousite nahrat " + fileList.length + "ks";
      }
    },
    onImageInput(selectedOption, id) {
      this.modelValue.images.push(selectedOption.id)
      this.browser_image_id = null;
    },
    formatSelectItem ({ id, title, copyright, user_title }) {
      return `${title}  ${id} ${copyright} ${user_title}`
    },
    async asyncSearch (query) {
      //console.log("dev asyncFind", query)
      if(timer) clearInterval(timer);
      timer = setTimeout(
          () => {
            //this.is_input_change = false;
            //console.log('dev delay 1000',query, this.selectModel)
            axios.defaults.baseURL = this.getBaseRoot();
            //let apiPath = this.cfg.api.image.browse_service.path + "?" + this.cfg.api.image.browse_service.input.type + "=" + this.inputObj.params.image_type_id
            //if(query) apiPath += "&" + this.cfg.api.image.browse_service.input.query + "=" + query
            let apiPath = this.getApiConfig('image.browse_service.path') + "?" + this.getApiConfig('image.browse_service.input.type') + "=" + this.inputObj.params.image_type_id
            if(query) apiPath += "&" + this.getApiConfig('image.browse_service.input.query') + "=" + query



            axios({
              url: apiPath,
              method: this.getApiConfig('image.browse_service.method'),
              withCredentials: true,
              headers: this.getRequestHeader(),
            }).then((res) => {
              this.browser_list = res.data.response.list.data;
              //console.log("dev image browser data", res.data.response.list.data)
            })
            .catch((err) => {
              this.browser_list = []
              console.log("DEV tags catch", err);
            })
            .finally(() => {
              //console.log("DEV tags finally");
            })
          },
          500
      )
    },
  },
  watch: {
    inputObj: {
      handler() {
        //  this.inputModel = this.inputObj.val;
      },
      deep: true,
      immediate: true
    },
    modelValue: {
      handler(newVal) {

        if(newVal == null){
          console.log("dev watch MIniGal modelValue NULL", newVal)
         /**/
          newVal = {
            "title" : this.inputObj.params.title_prefill,
            "images": []
          }

        }


        this.$emit('update:modelValue', newVal )
      },
      deep: true,
      immediate: true
    },
    inputModel( newVal){
      //console.log("dev MIniGal inputModel", newVal)
      //this.$emit('update:modelValue', newVal )
    },
  },
};
</script>
