<template >
  <div class=" ">
    <ul class="list-group border border-light2">
      <draggable
          :list="segmentStructure"
          itemKey="key_id"
          handle=".move"
          ghost-class="ghost"
      >
        <template #item="{ index, element }">
          <li
              class="list-group-item px-0 px-md-3 border-0"
          >
            <span class="fw-bold text-secondary ms-1 ms-md-0">{{element.title}}</span>
            <span class="right-top-btns">
              <span
                  @click.prevent="removeSegment(index, element)"
                  class="btn btn-outline "
              >
                <i class="bi bi-trash"></i>
                <!-- &nbsp;Odstranit-->

              </span>
              <span
                  v-if="segmentStructure.length>1"
                  class="move btn btn-outline"
              >
                <i class="bi bi-arrows-move"></i>
                <!-- &nbsp;Přesunout-->

              </span>
            </span>
<!--
            <textarea
                v-model="element.obsah"
                :placeholder="'textarea key:' + index"
            ></textarea>
            <pre>[{{element.component_name}}]</pre>
-->
            <component
                v-bind:is="element.component_name"
                v-model="element.obsah"
                :inputObj="element"
                :segmentsObj="modelValue"
            ></component>
          </li>
        </template>
      </draggable>
    </ul>
  </div>

  <div class="d-flex flex-wrap -dev-bg-secondary-subtle">
    <a
      v-for="(segmentBtnItem, segmentBtnIndex)  in inputObj.templates.data"
      @key="segmentBtnIndex"
      @click.prevent="addSegment(segmentBtnItem)"
      class="btn btn-outline-primary btn-sm mb-1 me-1"
      >
        {{segmentBtnItem.title}}
    </a>
  </div>
</template>

<script>

import draggable from "vuedraggable";
import TextAreaSegment from "@/components/general/form/inputs/segment/TextAreaSegment";
import TextSegment from "@/components/general/form/inputs/segment/TextSegment";
import EmbedVideoSegment from "@/components/general/form/inputs/segment/EmbedVideoSegment";
import EmbedSocialNetworkSegment from "@/components/general/form/inputs/segment/EmbedSocialNetowrkSegment";
import PlaceholderSegment from "@/components/general/form/inputs/segment/PlaceholderSegment";
import ObsahLinkSegment from "@/components/general/form/inputs/segment/ObsahLinkSegment";
import ImageSegment from "@/components/general/form/inputs/segment/ImageSegment";
import GalerieMiniSegment from "@/components/general/form/inputs/segment/GalerieMiniSegment";
import SpotifySegment from "@/components/general/form/inputs/segment/SpotifySegment";
import GalerieSegment from "@/components/general/form/inputs/segment/GalerieSegment";
import SelectSmartSegment from "@/components/general/form/inputs/segment/SelectSmartSegment";
import axios from "axios";
let key_id = 1;
export default {
  name: "SegmentInput",
  components: {
    draggable,
    TextAreaSegment,
    TextSegment,
    EmbedVideoSegment,
    PlaceholderSegment,
    EmbedSocialNetworkSegment,
    ObsahLinkSegment,
    ImageSegment,
    GalerieMiniSegment,
    SpotifySegment,
    GalerieSegment,
    SelectSmartSegment,
  },
  props: {
    "inputObj": Object,
    "formObj": Object,
    "modelValue": [Object, Array],
  },
  data() {
    return {
      segmentStructure: [],
    }
  },
  mounted() {

    if(this.inputObj.val && this.inputObj.val.length > 0) this.segmentStructure = this.inputObj.val;

    if( this.segmentStructure && this.segmentStructure.length > 0){
      for (var i = 0; i < this.segmentStructure.length; i++) {
        this.segmentStructure[i].key_id = key_id++;
        this.segmentStructure[i].poradi = key_id;
      }
    }
  },
  methods: {
    addSegment(segment) {
      if(segment.params && segment.params.is_multi && segment.params.items && segment.params.items.length > 0) {
        let formData = new FormData
        formData.append('ids', segment.params.items)
        let apiPath = this.getApiConfig('obsah.segments_prefill.path')


        axios({
          url: apiPath,
          data: formData,
          method: this.getApiConfig('obsah.segments_prefill.method'),
          withCredentials: true,
          headers: this.getRequestHeader(),
        }).then((res) => {
          if(res.data.response.data) {
            for (let i = 0; i < res.data.response.data.length; i++) {
              this.addSegment(res.data.response.data[i]);
            }
          }
        })
        .catch((err) => {
          console.log("dev addSegment multi catch", err);
        })
        return false;
      }

      const newSegment = {
        "segment_id": segment.segment_id,
        "key_id": key_id++,
        "title": segment.title,
        "slug": segment.slug,
        "component_name": segment.component_name,
        "data_id": null,
        "obsah": null,
        "poradi": key_id,
        "params": segment.params,
        "je_json": segment.je_json,
      };

      if(segment.params && segment.params.max_count){
        let count = 0;
        this.modelValue.filter(function (el){
          if(newSegment.segment_id == el.segment_id && newSegment.params.ident == el.params.ident) {
            count++
          }
        });
        if(count >= segment.params.max_count){
          alert("Segment '" + segment.title + "' lze použít jen " + segment.params.max_count + "x!")
        }else{
          this.segmentStructure.push(newSegment)
        }
      }else{
        this.segmentStructure.push(newSegment)
      }
    },
    removeSegment(index,segment) {
      if (confirm("Opravdu chcete odstranit tento segment?") === true){
        this.segmentStructure.splice(index, 1);
      }
    },
  },
  watch: {
    inputObj: {
      handler(newVal) {
        if(this.inputObj.val) this.segmentStructure = this.inputObj.val;
      },
      deep: true,
      immediate: true
    },



    /*
    modelValue: {
      handler(newVal) {
        console.log("watch modelValue", newVal)
      },
      deep: true,
      immediate: true
    },
    */

    segmentStructure: {
      handler(newVal) {
        //console.log("watch segmentStructure", newVal)
        if(newVal){
          let novePoradi = 100;
          for (var i = 0; i < newVal.length; i++) {
            newVal[i].poradi = novePoradi++;
          }
        }
        this.inputObj.val = newVal
        this.$emit('update:modelValue', newVal)
      },
      deep: true,
      immediate: true
    },
  },
};
</script>
