<template >
  <div class="input-group mb-2">
    <span v-if="inputModel.typ" class="input-group-text">
      <i v-if="inputModel.typ == 'fb_post'" class="bi bi-facebook fs-5"></i>
      <i v-else-if="inputModel.typ == 'fb_video'" class="bi bi-facebook fs-5"></i>
      <i v-else-if="inputModel.typ == 'ig_post'" class="bi bi-instagram fs-6"></i>
      <i v-else-if="inputModel.typ == 'tiktok_post'" class="bi bi-tiktok fs-6"></i>
      <i v-else-if="inputModel.typ == 'tiktok_profile'" class="bi bi-tiktok fs-6"></i>
      <i v-else-if="inputModel.typ == 'twitter_tweet'" class="bi bi-twitter fs-6"></i>
      <i v-else-if="inputModel.typ == 'twitter_profile'" class="bi bi-twitter fs-6"></i>
    </span>
    <input
        v-model="inputModel.url"
        type="text"
        placeholder="adresa pripsevku ze socialni site"
        @input="createEmbedObj($event.target.value)"
        class="form-control"
    >
  </div>

  <div v-if="inputModel && inputModel.url">
    <div v-if="inputModel.typ == 'fb_post'">
      <iframe
          :src="'https://www.facebook.com/plugins/post.php?href=' + encodeURIComponent(inputModel.url) + '&show_text=true&width=500'"
          width="500"
          height="754"
          style="border:none;overflow:hidden"
          scrolling="no"
          frameborder="0"
          allowfullscreen="true"
          allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"
      >
      </iframe>
    </div>
    <div v-else-if="inputModel.typ == 'fb_video'">
      <iframe
          :src="'https://www.facebook.com/plugins/video.php?height=314&href=' + encodeURIComponent(inputModel.url) + '%2F&show_text=true&width=560&t=0'"
          width="560"
          height="429"
          style="border:none;overflow:hidden"
          scrolling="no"
          frameborder="0"
          allowfullscreen="true"
          allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"
          allowFullScreen="true"
      >
      </iframe>
    </div>
    <div v-else-if="inputModel.typ == 'ig_post'">

      <blockquote
          class="instagram-media"
          :data-instgrm-permalink="'https://www.instagram.com/' + inputModel.sub_id + '/' + inputModel.id + '/?utm_source=ig_embed&amp;utm_campaign=loading'"
          data-instgrm-version="14"
          style=" background:#FFF; border:0; border-radius:3px; box-shadow:0 0 1px 0 rgba(0,0,0,0.5),0 1px 10px 0 rgba(0,0,0,0.15); margin: 1px; max-width:540px; min-width:326px; padding:0; width:99.375%; width:-webkit-calc(100% - 2px); width:calc(100% - 2px);"
      >
        <div style="padding:16px;">
          <a
              :href="'https://www.instagram.com/' + inputModel.sub_id + '/' + inputModel.id + '/?utm_source=ig_embed&amp;utm_campaign=loading'"
              style=" background:#FFFFFF; line-height:0; padding:0 0; text-align:center; text-decoration:none; width:100%;"
              target="_blank"
          >
            <div
                style=" display: flex; flex-direction: row; align-items: center;"
            >
              <div
                  style="background-color: #F4F4F4; border-radius: 50%; flex-grow: 0; height: 40px; margin-right: 14px; width: 40px;"
              >

              </div>
              <div
                  style="display: flex; flex-direction: column; flex-grow: 1; justify-content: center;"
              >
                <div
                    style=" background-color: #F4F4F4; border-radius: 4px; flex-grow: 0; height: 14px; margin-bottom: 6px; width: 100px;"
                >

                </div>
                <div
                    style=" background-color: #F4F4F4; border-radius: 4px; flex-grow: 0; height: 14px; width: 60px;"
                ></div>
              </div>
            </div>
            <div style="padding: 19% 0;"></div>
          </a>
        </div>
      </blockquote>

      <component is="script" src="//www.instagram.com/embed.js" async></component>
    </div>
    <div v-else-if="inputModel.typ == 'tiktok_post'">

      <blockquote
          class="tiktok-embed"
          :cite="inputModel.url"
          :data-video-id="inputModel.id"
          style="max-width: 605px;min-width: 325px;" > <section> <a target="_blank" title="@martin_mikyska" href="https://www.tiktok.com/@martin_mikyska?refer=embed">@martin_mikyska</a> Mám rád všechny ❤️ Celý rozhovor v biu  <a title="mikyr" target="_blank" href="https://www.tiktok.com/tag/mikyr?refer=embed">#mikyr</a> <a title="mupi" target="_blank" href="https://www.tiktok.com/tag/mupi?refer=embed">#mupi</a> <a title="mikyrovomoudro" target="_blank" href="https://www.tiktok.com/tag/mikyrovomoudro?refer=embed">#mikyrovomoudro</a> <a title="mupitalk" target="_blank" href="https://www.tiktok.com/tag/mupitalk?refer=embed">#mupitalk</a> <a title="brichta" target="_blank" href="https://www.tiktok.com/tag/brichta?refer=embed">#brichta</a> <a title="brichtabomby" target="_blank" href="https://www.tiktok.com/tag/brichtabomby?refer=embed">#brichtabomby</a> <a title="brichtalk" target="_blank" href="https://www.tiktok.com/tag/brichtalk?refer=embed">#brichtalk</a> <a target="_blank" title="♬ původní zvuk - Martin Mikyska" href="https://www.tiktok.com/music/původní-zvuk-7262387130524125984?refer=embed">♬ původní zvuk - Martin Mikyska</a> </section> </blockquote>
      <component is="script" src="https://www.tiktok.com/embed.js" async></component>
    </div>
    <div v-else-if="inputModel.typ == 'tiktok_profile'">
      <blockquote
          class="tiktok-embed"
          :cite="inputModel.url"
          :data-unique-id="inputModel.id"
          data-embed-type="creator"
          style="max-width: 780px; min-width: 288px;"
      >
        <section>  </section>
      </blockquote>
      <component is="script" src="https://www.tiktok.com/embed.js" async></component>
    </div>
    <div v-else-if="inputModel.typ == 'twitter_tweet'">
      <blockquote class="twitter-tweet">
        <p lang="cs" dir="ltr"> </p>&mdash;
        <a :href="inputModel.url + '?ref_src=twsrc%5Etfw'">August 1, 2023</a>
      </blockquote>
      <component is="script" src="https://platform.twitter.com/widgets.js" async></component>
    </div>
    <div v-else-if="inputModel.typ == 'twitter_profile'">
      <a class="twitter-timeline" :href="inputModel.url + '?ref_src=twsrc%5Etfw'">Tweets by ilblog</a>
      <component is="script" src="https://platform.twitter.com/widgets.js" async></component>
    </div>
    <div v-else class="alert alert-danger">
      Adresa {{inputModel.url}} neodpovida zadny vkladany prvek.
    </div>
  </div>


</template>

<script>

export default {
  name: "EmbedSocialNetworkSegment",
  props: {
    "segmentsObj": [Object, Array],
    "inputObj": Object,
    "formObj": Object,
    "modelValue": [Object, String ],
  },
  emits: ['update:modelValue'],
  data() {
    return {
      dev_url: 'https://twitter.com/MercuryBarbora',
      is_submitted: false,
      inputModel: {
        "url": null,
        "typ": null,
        "hash": null,
      },
    }
  },
  mounted() {
    if(this.inputObj.obsah){
      this.inputModel = this.inputObj.obsah;
    }
  },
  methods: {
    createEmbedObj(url){
      var fbPost = url.match(/^(https?:\/\/){0,1}(www\.){0,1}facebook\.com\/([a-z0-9_\.]+)\/(posts)\/(\w+)/);
      var fbVideo = url.match(/^(https?:\/\/){0,1}(www\.){0,1}facebook\.com\/(\d+)\/(videos)\/(\d+)/);
      var instagramPost = url.match(/^(http|https):\/\/(www\.instagram\.com)\/(p|reel)\/([\w\-]+)/); ///(?:https?:\/\/www\.)?instagram\.com\S*?\/p\/(\w{11})\/?/
      var tiktokPost = url.match(/https?:\/\/(?:m|www|vm)\.tiktok\.com\/(.*\b)\/(?:(?:usr|v|embed|user|video)\/|\?shareId=|\&item_id=)(\d+)/); ///(?:https?:\/\/www\.)?instagram\.com\S*?\/p\/(\w{11})\/?/
      var tiktokProfile = url.match(/https?:\/\/(?:m|www|vm)\.tiktok\.com\/@(\w+)/); ///(?:https?:\/\/www\.)?instagram\.com\S*?\/p\/(\w{11})\/?/
      var twitterTweet = url.match(/https?:\/\/twitter\.com\/(\w+)\/status\/(\d+)/); ///(?:https?:\/\/www\.)?instagram\.com\S*?\/p\/(\w{11})\/?/
      var twitterProfile = url.match(/https?:\/\/twitter\.com\/(\w+)/); ///(?:https?:\/\/www\.)?instagram\.com\S*?\/p\/(\w{11})\/?/
      //TODO

      let res = {
        url: url
      }
      if(fbPost){
        console.log('dev socials FBB', fbPost)
        res.typ = 'fb_post'
        res.autor_slug = fbPost[3];
        res.id = fbPost[5];
      }else if(fbVideo){
        res.typ = 'fb_video'
        res.autor_id = fbVideo[3];
        res.id = fbVideo[5];
      }else if(instagramPost){
        res.typ = 'ig_post'
        res.sub_id = instagramPost[3];
        res.id = instagramPost[4];
      }else if(tiktokPost){
        res.url = tiktokPost[0];
        res.typ = 'tiktok_post'
        res.autor_id = tiktokPost[1];
        res.id = tiktokPost[2];
      }else if(tiktokProfile){
        res.url = tiktokProfile[0];
        res.typ = 'tiktok_profile'
        res.id = tiktokProfile[1];
      }else if(twitterTweet){
        res.url = twitterTweet[0];
        res.id = twitterTweet[2];
        res.autor_id = twitterTweet[1];
        res.typ = 'twitter_tweet'

      }else if(twitterProfile){
        res.url = twitterProfile[0];
        res.typ = 'twitter_profile'
        res.id = twitterProfile[1];
      }

      this.inputModel = res;
      this.$emit('update:modelValue', res )
    }
  },
};
</script>