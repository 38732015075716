<template >
  <div class="bg-light p-3">
    <form-general
        v-model="resultb"
        form-title="Vkladany formular"
        :form-obj="formGeneralEdit"
        original-path="$router.currentRoute.originalPath"
    />
  </div>
</template>

<script>
import FormGeneral from "@/components/general/form/FormGeneralDEV";
export default {
  name: "IncludeForm",
  components: {
    FormGeneral,
  },
  props: {
    "refreshKey": [String, Number],
    "formDefinition": [Object, Array],
    "prefill": [Object, Array],
    "result": [Object, Array,String, Number],
    "modelValue": [Object, Array,String, Number],
  },
  emits: [
    'update:result',
    'update:modelValue',
  ],
  data() {
    return {
      xmodelValue: null,
      formGeneralEdit: null,
      resultb: null
    }
  },
  methods: {
    cleanInputs() {
      if(this.formDefinition) {
        for(const sectionKey in this.formDefinition.body){
          for(const inputKey in this.formDefinition.body[sectionKey].inputs){
            if(this.formDefinition.body[sectionKey].inputs[inputKey].readonly != true) {
              const inputType = typeof this.formDefinition.body[sectionKey].inputs[inputKey].val
              if (inputType == 'number' || inputType == 'string') {
                this.formDefinition.body[sectionKey].inputs[inputKey].val = null;
              }else if(inputType == 'object' && this.formDefinition.body[sectionKey].inputs[inputKey].langs ) {
                for (const objectInputKey in this.formDefinition.body[sectionKey].inputs[inputKey].val) {
                  this.formDefinition.body[sectionKey].inputs[inputKey].val[objectInputKey] = null;
                }
              }else if (inputType == 'object') {
                this.formDefinition.body[sectionKey].inputs[inputKey].val = {};
              }else if (inputType == 'boolean') {
                this.formDefinition.body[sectionKey].inputs[inputKey].val = false;
              }
              //console.log("dev created ERASE INPUT ", inputKey, inputType) //, this.formDefinition.body[sectionKey].inputs[inputKey]
            }
          }
        }
      }
    },
    prefillInputs() {
      if(this.prefill){
        for(const sectionKey in this.prefill.body){
          if(this.prefill.body[sectionKey].inputs) {
            for(const inputKey in this.prefill.body[sectionKey].inputs){
              if(this.formGeneralEdit.body[sectionKey].inputs[inputKey] != "undefined") {
                for(const propKey in this.prefill.body[sectionKey].inputs[inputKey]){
                  this.formGeneralEdit.body[sectionKey].inputs[inputKey][propKey] = this.prefill.body[sectionKey].inputs[inputKey][propKey]
                }
              }
            }
          }
        }
      }
    }
  },
  computed: {

  },
  created() {

    this.cleanInputs()

  },


  mounted() {
    this.formGeneralEdit = this.formDefinition;
    this.cleanInputs()
    this.prefillInputs()

  },
  watch: {
    /*
    devx: {
      handler(val) {
        this.$emit("update:modelValue", val);
      },
      deep: true,
      immediate: true,
    },
*/
    resultb: {
      handler(val) {
        this.$emit("update:modelValue", val);
        //this.$emit("update:modelValue", val);
        //this.$emit("update:resultb", val);
      },
      deep: true,
      immediate: true,
    },
  }
};
</script>