<template >
  <div
      v-if="err === null || err.code === null"
      class="h-100 w-100 bg-light py-3 px-4 border rounded-3"
  >
    <div v-if="loading == false" class="cms5-custom-scrollbar">
      <h2 class="mb-3 fs-4 fw-medium text-secondary">
        {{inputObj.title}}
        <i v-if="inputObj.ico" :class="inputObj.ico + ' ms-2 fs-5'"></i>
      </h2>

      <div
          v-if="data.pocet > 0"
          class="--border border-danger" style="max-height: 300px;">
        <list-simple
            :list-obj="data"
        >
        </list-simple>
      </div>
      <div v-else class="text-danger">
        <div>No data</div>
      </div>
    </div>
    <div v-else>
      [loading]
    </div>
  </div>

</template>

<script>

import axios from "axios";
//import ListSimple from "@/components/general/list/ListSimple";
import ListSimple from "@/components/general/list/ListGeneral";
export default {
  name: 'DashboardBoxList',
  components: {
    ListSimple
  },
  props: [
    "inputObj"
  ],
  data() {
    return {
      loading: false,
      err: null,
      data: [],
      inputModel: null,
    }
  },
  methods: {
    async loadData() {
      this.loading = true;
      axios.defaults.baseURL = this.getBaseRoot();
      let apiPath = this.inputObj.params.path
      await axios({
        url: apiPath,
        method: this.inputObj.params.method,
        withCredentials: true,
        headers: this.getRequestHeader(),
      }).then((res) => {
        if(typeof res.data.err == "undefined") {
         this.data = res.data.response.list //
        }else{
          this.err = res.data.err
        }
        //console.log('dev dashboar box', res.data);

      }).catch((err) => {
        this.err = "Chyba systemu, kontaktujte spravce"
      }).finally(() => {
        this.loading = false;
      })
    }
    /*
    setParentData(event) {
      console.log('dev Parent set data ', this.parentData.name)
      this.$emit("listenerChild", "I'm here my Daddy!");
    },
    */
  },
  created() {
    this.loadData();
  },
  watch: {
    inputModel( newVal){
      //this.$emit('update:modelValue', newVal ) // model vole!
    },
  },
}
</script>


