<template>

  <div
      class="d-grid d-md-flex flex-wrap mb-2"
  >
    <div class="d-none d-sm-block d-md-flex p-0">
      <label for="street" class="form-label d-flex align-self-center m-0">Ulice</label>
    </div>
    <div class="mb-sm-2 mb-md-0 ms-md-2">
      <input
          type="text"
          v-model="address.street"
          id="street"
          class="form-control d-md-flex"
          placeholder="Ulice"
      />
    </div>



    <div class="d-none d-sm-blockW d-md-flex p-0 ms-md-2">
      <label for="s_number" class="form-label d-flex align-self-center m-0">Cislo</label>
    </div>
    <div class="ms-md-2">
      <input
          type="text"
          v-model="address.number"
          class="form-control d-md-flex "
          placeholder="Cislo ulice"
          id="s_number"
      />
    </div>

  </div>

  <div
      class="d-grid d-md-flex flex-wrap mb-2"
  >
    <div class="d-none d-sm-block d-md-flex p-0">
      <label for="city" class="form-label d-flex align-self-center m-0">Mesto</label>
    </div>
    <div class="mb-sm-2 mb-md-0 ms-md-2">
      <input
          type="text"
          v-model="address.city"
          id="city"
          class="form-control d-md-flex"
          placeholder="mesto"
      />
    </div>



    <div class="d-none d-sm-blockW d-md-flex p-0 ms-md-2">
      <label for="zip" class="form-label d-flex align-self-center m-0">Zip</label>
    </div>
    <div class="ms-md-2">
      <input
          type="text"
          v-model="address.zip_code"
          class="form-control d-md-flex "
          placeholder="PSC"
          id="zip"
      />
    </div>

  </div>

  <div
      class="d-grid d-md-flex flex-wrap mb-2"
  >
    <div class="d-none d-sm-block d-md-flex p-0">
      <label for="gps_lat" class="form-label d-flex align-self-center m-0">Lat</label>
    </div>
    <div class="mb-sm-2 mb-md-0 ms-md-2">
      <input
          type="number"
          v-model="address.lat"
          id="gps_lat"
          class="form-control d-md-flex"
          placeholder="LAT"
          :step="latLngStep"
      />
    </div>
    <div class="d-none d-sm-blockW d-md-flex p-0 ms-md-2">
      <label for="gps_lng" class="form-label d-flex align-self-center m-0">Lng</label>
    </div>
    <div class="ms-md-2">
      <input
          type="number"
          v-model="address.lng"
          :step="latLngStep"
          class="form-control d-md-flex "
          placeholder="LNG"
          id="gps_lng"
      />
    </div>

  </div>

  <div
      class="d-grid d-md-flex flex-wrap mb-2"
  >
    <div class="d-none d-sm-block d-md-flex p-0">
      <label for="district" class="form-label d-flex align-self-center m-0">Kraj</label>
    </div>
    <div class="mb-sm-2 mb-md-0 ms-md-2">
      <input
          type="text"
          v-model="address.district"
          id="district"
          class="form-control d-md-flex"
          placeholder="Kraj"

      />
    </div>
    <div class="d-none d-sm-blockW d-md-flex p-0 ms-md-2">
      <label for="code" class="form-label d-flex align-self-center m-0">Code</label>
    </div>
    <div class="ms-md-2">
      <input
          type="text"
          v-model="address.code"

          class="form-control d-md-flex "
          placeholder="Plus code"
          id="code"
      />
    </div>

  </div>

<!--
  <pre>{{address}}</pre>
  <pre>inputModel: {{inputModel}}</pre>
-->

  <GoogleMap
      ref="mapa"
      :api-key="inputObj.options.api_key"
      style="width: 100%; height: 500px"
      :center="mapCenter"
      :zoom="18"
      map-type-id="terrain"
  >
    <Marker
        @drag="handleMarkerDrag"
        @dragend="handleMarkerDragEnd"
        :options="{
          position: mapCenter,
          //label: 'L',
          //title: 'LADY LIBERTY',
          draggable: true,

      }"
    >

    </Marker>

    <!--
    <div style="text-align: center">
      <img src="https://vuejs.org/images/logo.png" width="50" height="50" style="margin-top: 8px" />
    </div>
    -->

  </GoogleMap>

<div>



  <input v-model="address_search_input" type="text" class="form-control" placeholder="Hledat podle adresy"/>

  <div v-if="address_search_result.length > 0">
    <h3>Vysledky vyhledavani</h3>

    <ul
        v-if="address_search_result"
        class="list-group"
    >
      <li
          v-for="item in address_search_result"
          @click.prevent="setVenue(item)"
          @mouseover.prevent="moveMap(item.geometry.location.lat, item.geometry.location.lng)"
          class="list-group-item list-group-item-action pseudo-link"
      >
        <i class="bi bi-pin-angle-fill me-2"></i>
        <span>{{item.formatted_address}}</span>
      </li>

    </ul>

    <!--
    <div v-for="item in address_search_result" @click.prevent="setVenue(item)" @mouseover.prevent="moveMap(item.geometry.location.lat, item.geometry.location.lng)">
      <h5>{{item.formatted_address}}</h5>
    </div>
    -->
  </div>
<!--
  <pre>{{address_search_result}}</pre>

  <pre>{{address_search_input}}</pre>
  -->


</div>







  <!--

    <span @click.prevent="devMapPos(10,20)">10,20</span>
    <span @click.prevent="devMapPos(40,15)">10,20</s


    <div >
      <div
          class="d-grid d-md-flex flex-wrap mb-2"
      >
        <div class="d-none d-sm-block d-md-flex p-0">
          <label for="pass_original" class="form-label d-flex align-self-center m-0">Heslo</label>
        </div>
        <div class="mb-sm-2 mb-md-0 ms-md-2">
          <input
              type="password"
              v-model="password.original"
              @input="password.generated = false;"
              id="pass_original"
              class="form-control d-md-flex"
              placeholder="heslo"
          />
        </div>
        <div class="d-none d-sm-blockW d-md-flex p-0 ms-md-2">
          <label for="pass_confirm" class="form-label d-flex align-self-center m-0">Heslo confirm</label>
        </div>
        <div class="ms-md-2">
          <input
              type="password"
              v-model="password.confirm"
              @input="password.generated = false;"
              class="form-control d-md-flex "
              placeholder="heslo potvrzeni"
              id="pass_confirm"
          />
        </div>

      </div>

      <div class="d-md-flex align-items-start">
        <button @click.prevent="passGenerate" class="btn btn-secondary p-1 me-3"><i class="bi bi-balloon"></i> Generovat heslo</button>
        <div v-if="password.original && password.err" class="alert alert-danger p-1 me-3">Chyba: {{password.err}}</div>
        <div v-if="password.generated" class="alert alert-info p-1">Heslo: <strong>{{password.original}}</strong></div>

      </div>

    </div>
    -->
</template>

<script>
let timer;
const timer_delay = 750;
const latLngDigit = 5
import axios from "axios";
import multiselect  from 'vue-multiselect'
import { GoogleMap, Marker, InfoWindow, CustomMarker } from "vue3-google-map";

//const GOOGLE_MAPS_API_KEY = 'AIzaSyCBIZYkBnui4cNdywYaxSY-dpjWCk38ay4';


export default {
  name: "AddressInput",
  components: {
    axios,
    multiselect,
    //Loader,
    GoogleMap,
    Marker,
    InfoWindow,
    CustomMarker,
  },
  props: {
    "inputObj": Object,
    "formObj": Object,
    "modelValue": [Object],
  },
  data() {
    return {


      address : {
        "lat": null,
        "lng": null,
        "city": null,
        "district": null,
        "country": null,
      },

      cursor: null,
      //mapCenter: { lat: 50.07834352012642, lng: 14.442663210043822 },
      mapCenter: { lat: null, lng: null },
      mapObject: null,
      address_search_input: null,
      address_search_result: [],
      inputModel: null,
    }
  },
  setup() {

  },
  mounted() {



    if(this.inputObj.val){
      this.address = this.inputObj.val;
      this.mapCenter = {
        lat: parseFloat(this.address.lat),
        lng: parseFloat(this.address.lng),
      }
//.toFixed(this.inputObj.options.latLngDigits)
    }else{
      this.address = {
        "lat": null,
        "lng": null,
        "street": null,
        "number": null,
        "city": null,
        "district": null,
        "country": null,
        "zip_code": null,
        "code": null,
      };

      navigator.geolocation.getCurrentPosition(
          position => {
            this.mapCenter = {
              lat: position.coords.latitude,
              lng: position.coords.longitude
            }
          },
          error => {
            this.mapCenter = cfgGeneral.modul.venue.default_position
          },
      )
    }


  },
  computed: {
    latLngStep() {
      return 1/Math.pow(10, this.inputObj.options.latLngDigits)
    }
  },
  methods: {
    moveMap(lat, lng) {
      this.mapCenter = {
        lat: lat,
        lng: lng
      }
    },

    createSlug(venue){
      let slug = [];
      slug.push(venue.street);
      slug.push(venue.number);
      slug.push(venue.city);
      slug.push(venue.country);
      return slug.join('-');

    },

    async setVenue(place) {
      this.moveMap( place.geometry.location.lat, place.geometry.location.lng)

      this.formObj.general.inputs.address_title.val = place.formatted_address

      if(!this.formObj.general.inputs.title.val) {
        this.formObj.general.inputs.title.val = place.formatted_address;
      }

      //slug
      if(!this.formObj.general.inputs.slug.val) {

      }
      //this.formObj.general.inputs.slug.val = place.custom.street + '-' + place.custom.number + '-' + place.custom.city + '-' + place.custom.country;
      this.formObj.general.inputs.slug.val = this.createSlug(place.custom);

      const formData = new FormData
      formData.append(this.getApiConfig('venue.get_venue_formated.input'), JSON.stringify(place))
      axios({
        url: this.getApiConfig('venue.get_venue_formated.path'),
        data: formData,
        method: this.getApiConfig('venue.get_venue_formated.method'),
        withCredentials: true,
        headers: this.getRequestHeader(),
      }).then((res) => {
        console.log("dev venue RES", res.data.response);

        this.address.lat = place.custom.lat.toFixed(this.inputObj.options.latLngDigits);
        this.address.lng = place.custom.lng.toFixed(this.inputObj.options.latLngDigits);
        this.address.city = place.custom.city;
        this.address.street = place.custom.street;
        this.address.number = place.custom.number;
        this.address.district = place.custom.district;
        this.address.country = place.custom.country;
        this.address.zip_code = place.custom.zip_code;
        this.address.code = place.custom.code;
      })
      .catch((err) => {
        console.log("dev addSegment multi catch", err);
      })

      console.log('dev setVenue this.formObj', this.formObj.general.inputs.title_address);

    },

    handleMarkerDrag(e) {
      this.cursor = { lat: e.latLng.lat(), lng: e.latLng.lng() };
    },

    handleMarkerDragEnd(e) {

      this.mapCenter = { lat: e.latLng.lat(), lng: e.latLng.lng() };
      this.address.lat = e.latLng.lat().toFixed(latLngDigit);
      this.address.lng = e.latLng.lng().toFixed(latLngDigit);


      //console.log('dev handleMarkerDragEnd', e.latLng)
      console.log('dev handleMarkerDragEnd mapa', this.$refs['mapa'])

      if(timer) clearInterval(timer);
      timer = setTimeout(
          () => {
            this.getGeocodingByLatLng(e.latLng.lat(), e.latLng.lng())
          },
          timer_delay
      )
    },

    async getGeocodingByLatLng (lat, lng) {
      this.getGeocodingCore("latlng=" + lat + "," + lng) ;
    },

    async getGeocodingByAddress (address) {
      this.getGeocodingCore("address=" + address) ;
    },

    async getGeocodingCore (queryPath) {

      const apiPath = "https://maps.googleapis.com/maps/api/geocode/json?key=" + this.getConfig('venue.google_api_key') + "&" + queryPath

      await axios({
        url: apiPath,
        method: "GET",
      }).then((res) => {
        //this.galerie.browser = res.data.response.list.data;
        if(res.data.status == "OK"){
          console.log('dev getGeocodingByAddress', res.data.results)
          //this.address_search_result = res.data.results


          //TODO ZPRACOVANI

          const formData = new FormData
          formData.append(this.getApiConfig('venue.get_result_list.input'), JSON.stringify(res.data.results))

          axios({
            url: this.getApiConfig('venue.get_result_list.path'),
            data: formData,
            method: this.getApiConfig('venue.get_result_list.method'),
            withCredentials: true,
            headers: this.getRequestHeader(),
          }).then((res) => {
            console.log("dev list", res.data.response.list);
            this.address_search_result = res.data.response.list
          })
          .catch((err) => {
            console.log("dev list err", err);
          })


          /*
                    console.log("dev image browser data", res.data.results)
                    //this.address_search_result = res.data.status.results;
                    res.data.results.forEach((item)=> {
                      console.log("dev image browser data item", item)
                      let nItem = {

                        "place_id" : item.place_id,
                        //"address_components" : item.address_components,
                        "formatted_address": item.formatted_address,
                        //"geometry": item.geometry,
                        "types": item.types,
                        "partial_match": item.partial_match,
                      }
                      list.push(item)

          });
          console.log("dev getGeocodingByAddress", list)
          this.address_search_result = list
      */


        }else{
          this.address_search_result = [];
        }

        }).catch((err) => {
          console.log("DEV tags catch", err);
        })

    },

    formatSelectItem ({ id, title, email, login }) {
      return `${title}  ${id} ${email} ${login}`
    },

  },
  watch: {
    address_search_input: {
      handler(newVal) {
        if(newVal && newVal.length > 0){
          if(timer) clearInterval(timer);
          timer = setTimeout(
              () => {
                 this.getGeocodingByAddress(newVal);
              },
              500
          )
        }else{
          this.address_search_result = [];
        }
        console.log('watch address_search_input', newVal)
      },
      //deep: true,
      //immediate: true
    },
/*
    inputObj: {
      handler() {
        //this.inputModel = this.inputObj.val;
      },
      deep: true,
      immediate: true
    },
*/
    address: {
      handler(newVal) {
        this.$emit('update:modelValue', newVal )
      },
      deep: true,
      immediate: true
    },

  },
};
</script>

<style scoped type="text/scss">
.mapa-wrapper {
  background-color: #35495e;
  color: #dedede;
  width: 100%;
  height: 40vh;
  min-height: 200px;
}
</style>

