<template>
  <pre>[preview]</pre>
  <img
      v-if="image_path"
      :src="image_path"
  />
  <span v-else>
    tento obrazek neexistuje, zrejme byl smazan
  </span>
</template>

<script>
import axios from "axios";
export default {
  name: "ImagePreview",
  props: {
    imageId: [Number, String]
  },
  data() {
    return {
      image_path: null,
    }
  },
  methods: {
    hoverItem(item) {
      //console.log("dev hoverItem", item)
    },
    async getData(){
      //console.log("dev getMenu", this.cfg.api.general.main_menu_path)
      axios.defaults.baseURL = this.getBaseRoot();
      await axios({
        url: this.getApiConfig('image.preview.path')+ this.imageId,
        method: this.getApiConfig('image.preview.path'),
        withCredentials: true,
        headers: this.getRequestHeader(),
      }).then((res) => {
        if(res.data.status) {
          //console.log("image preview", res.data.response.data.data)
          this.image_path = res.data.response.data.data;
        }else{
          this.image_path = false;
          //this.loginStatus = false;
          //console.log("DEV RES NODATA", res.data);
        }
      });

    },
  },
  created() {
    this.getData();
  }
};
</script>