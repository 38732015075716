<template>
  <div v-if="modalOpen && modalOpen.config && modalOpen.config.display">

    <modal-window
        :title="modalOpen.title"
        :config="modalOpen.config"
    >
      <template #modal-content-slot>
        <component
            v-bind:is="'ContentIncludeView'"
            :router="modalOpen.router"
            :return-data="modalOpen.data"
            :form-prefill="modalOpen.prefill"
            :config="modalOpen.config"
        ></component>
      </template>
    </modal-window>
  </div>

  <div
      v-if="dataObj.restaurace"
      class="reservation-wrap"
  >
    <header>
      <h3>{{dataObj.restaurace.title}}</h3>
      <div class="input-group input-group-lg">
        <!--
        selectedDay: {{selectedDay}}
        -->
        <input
            v-model="selectedDay"
            type="date"
            class="form-control"
        />
        <div class="input-group-text">
          <input
              v-model="is_auto_refresh"
              id="checkbox_auto_refresh"
              class="form-check-input mt-0"
              type="checkbox"
              value=""
              aria-label="Checkbox for following text input"
          >
          <label
              class="form-check-label ms-2"
              for="checkbox_auto_refresh"
          >
            <small>Automatický refresh stránky</small>
          </label>
        </div>

        <div class="input-group-text">
          <small>Poslední aktualizace:
            <span
                v-format-date.showTime="dataObj.last_update"
            ></span>
          </small>
        </div>
      </div>
    </header>


    <div class="grid-wrap">
      <div
        v-for="(item) in dataObj.grid.items"
        class="grid"
      >

        <header>
          <div v-if="dataObj.grid.type=='hours'" v-format-date="item.dates.from"></div>
          <div v-if="dataObj.grid.type=='days'" v-format-date-range.displayCorrection="{from: item.dates.from, to: item.dates.to}"></div>
        </header>

        <div
            v-if="item.isOpen"
            class="content"

        >
            <div class="spots-wrap">
              <div
                  v-for="(spots) in item.spots"
                  class="spot"
              >
                <div
                    class="spot-title"
                >
                  <span>{{spots.nazev}}</span>
                </div>
                <div class="slots-wrap">

                  <div
                      v-for="(slot) in spots.slots"
                      class="slot-item full"
                      :style="'left: ' + slot.calculated.offest_proc + '%; width: ' + slot.calculated.width_proc + '%;'"
                      @mouseenter="mouseOverSlot(slot)"
                      @mouseleave="mouseOutSlot()"
                  >
                    <!--
                    <span>slot_id: {{slot.id}}</span>
                    -->
                    <div :class="getBackgroundClassByStavId(slot.stav_id)">
                      <div>
                        <div class="jmeno">{{slot.jmeno}}</div>
                        <div
                            v-if="dataObj.grid.type=='hours'"
                            v-format-date-range.timerange.displayCorrection="{from: slot.datum_od, to: slot.datum_do}"
                            class="time"
                        ></div>
                        <div
                            v-if="dataObj.grid.type=='days'"
                            v-format-date-range.hideYear.displayCorrection="{from: slot.datum_od, to: slot.datum_do}"
                            class="time"
                        ></div>
                      </div>

                    </div>


                    <!--
                    <pre>slots: {{slot}}</pre>
                    -->
                  </div>
                  <div
                      v-for="(freeSlot) in spots.free_slots"
                      class="slot-item free"
                      :style="'left: ' + freeSlot.offset_proc + '%; width: ' + freeSlot.width_proc + '%;'"
                  >
                    <span
                        @click.prevent="openDialogWindow(spots.id, freeSlot.date)"
                        class="btn-reservation-create btn btn-primary"
                        title="Pridat novou rezervaci"
                    >
                      <i class="bi bi-plus"></i>
                    </span>

                  </div>
                </div>

                <div class="free-slots-wrap">
                  [TODO free slots]
                </div>

              </div>
            </div>

            <div class="title-wrap">
              <div
                  v-for="(title) in item.titles"
                  class="title-item"
              >
                <div v-if="dataObj.grid.type=='hours'">{{title.dt}}</div>
                <div v-if="dataObj.grid.type=='days'" v-format-date.hideYear="title.dt"></div>
              </div>
            </div>
        </div>
        <div v-else>
          todo: zavreno
        </div>

<!--


        <display-debug>
          <template #debugView>item {{item}}</template>
        </display-debug>
-->

      </div>


    </div>

    <div
        v-if="dataObj.list"
        class="list-wrap"
    >

      <table class="table table-responsive table-striped">
        <thead>
          <tr>
            <th>misto</th>
            <th>stav</th>
            <th>lidi</th>
            <th>cas</th>
            <th>jmeno</th>
            <th>poznamka</th>
            <th class="text-end">akce</th>
          </tr>
        </thead>
        <tbody>
          <tr
              v-for="(row) in dataObj.list"
              @mouseenter="mouseOverRow(row)"
              @mouseleave="mouseOutRow(row)"
          >
            <!--
            <td>{{row.id}}</td>
            -->
            <td>{{row.spot_title}}</td>
            <td>{{row.stav_title}}</td>
            <td>{{row.pocet}}x</td>
            <td>
              <span
                  v-if="dataObj.restaurace.config.display.type=='hours'"
                  v-format-date-range.timerangePlusDateStart.displayRange="{from: row.datum_od, to: row.datum_do}"
              >
              </span>
              <span
                  v-else-if="dataObj.restaurace.config.display.type=='days'"
                  v-format-date-range.displayRange="{from: row.datum_od, to: row.datum_do}"
              ></span>
            </td>
            <td>{{row.jmeno}}</td>
            <td>{{row.poznamka}}</td>
            <td class="text-end">
              <a
                  @click.prevent="openDialogWindowEdit(row)"
                  class="btn btn-outline-primary btn-sm ms-2"
                  title="Upravit rezervaci"
              >
                <i class="bi bi-pencil"></i>
              </a>

              <a
                  @click.prevent="setStav(row, dataObj.setup.stav.uskutecneno)"
                  class="btn btn-success btn-sm ms-2 border-success"
                  title="Host dorazil"
              >
                <i class="bi bi-hand-thumbs-up-fill"></i>
              </a>
              <a
                  @click.prevent="setStav(row, dataObj.setup.stav.propadle)"
                  class="btn btn-danger btn-sm ms-2"
                  title="Host NEDORAZIL"
              >
                <i class="bi bi-hand-thumbs-down-fill"></i>
              </a>


              <a
                  v-if="row.stav_id == dataObj.setup.stav.potvrzena || row.stav_id == dataObj.setup.stav.prijata || row.stav_id == dataObj.setup.stav.cekajici"
                  @click.prevent="deleteListSlot(row)"
                  class="btn btn-primary btn-sm ms-2"
                  title="Storno"
              >
                <i class="bi bi-trash"></i>
              </a>
<!--

-->
<!--
              <router-link
                  :to="'/rezervace-slot/edit/' + listItem.id"
                  :class="'btn btn-outline-primary btn-sm ms-2'"
                  target="_blank"
                  @click.prevent="displayModalSlotEdit(listItem)"
              >
                <i class="bi bi-pencil"></i>

              </router-link>
              <a
                  @click.prevent="setStav(listItem, dataObj.setup.stav.uskutecneno)"
                  class="btn btn-success btn-sm ms-2 border-success"
                  title="Host dorazil"
              >
                <i class="bi bi-hand-thumbs-up-fill"></i>
              </a>
              <a
                  @click.prevent="setStav(listItem, dataObj.setup.stav.propadle)"
                  class="btn btn-danger btn-sm ms-2"
                  title="Host NEDORAZIL"
              >
                <i class="bi bi-hand-thumbs-down-fill"></i>
              </a>

              <a
                  v-if="listItem.stav_id == dataObj.setup.stav.potvrzena || listItem.stav_id == dataObj.setup.stav.prijata || listItem.stav_id == dataObj.setup.stav.cekajici"
                  @click.prevent="setStav(listItem, dataObj.setup.stav.storno)"
                  class="btn btn-primary btn-sm ms-2"
                  title="Storno"
              >
                <i class="bi bi-trash"></i>
              </a>
-->

            </td>
            <!--
            <td>
              <pre>{{row}}</pre>
            </td>
            -->
          </tr>

        </tbody>


      </table>


    </div>


  </div>
  <div
    v-else
  >
    nejsou data pro restauraci
  </div>

  <!--
    <display-debug>
      <template #debugView>is_viewport_active: {{is_viewport_active}}</template>
    </display-debug>

    <display-debug>
      <template #debugView>is_auto_refresh: {{is_auto_refresh}}</template>
    </display-debug>

    <display-debug>
      <template #debugView>dataObj {{dataObj.grid.items}}</template>
    </display-debug>

    <display-debug>
      <template #debugView>setup: {{dataObj.setup}}</teme>
    </display-debug>



      <div
        v-for="(item) in display"
      >
        <header>
          <span v-if="item.type=='day'" v-format-date="item.day">[day]</span>
          <span v-else-if="item.type=='week'" v-format-date-range="{from: item.week.start, to: item.week.end}">[week]</span>
        </header>

        <display-debug>
          <template #debugView>item {{item}}</template>
        </display-debug>
      </div>

      <display-debug>
        <template #debugView>config {{config}}</template>
      </display-debug>
    -->
  <!--

     -->
</template>

<script>
import './ViewTimeline.scss'
import ContentIncludeView from "@/views/ContentIncludeView";
import ModalWindow from '@/components/content/ModalWindow'
import axios from "axios";

let timer;

const autorefresh_name = 'autoRefresh_v1';

export default {
  name: "ViewReservations",
  components: {
    ContentIncludeView,
    ModalWindow
  },
  props: {
    "dataObj": Object,
    "returnData": [Object, String, Number],
    "reloadSignal": [Object, String, Number],
  },
  emits:[
    'update:reloadSignal',
  ],
  data() {
    return {
      display: {},
      selectedDay: null,
      is_auto_refresh: null,
      is_viewport_active: null,
      modals: {
        'reservation_create': {
          title: 'Vytvorit novou rezervaci',
          router: {
            modul: 'rezervace-slot',
            action: 'new'
          },
          config: {
            "display": true,
            "is_header": true,
            "is_close_btn": true,
            "is_footer": true
          },
          data: {
            save: false,
            submit: false,
          },
          prefill: {
            general: {
              inputs: {
                restaurace_id: {
                  readonly: true,
                  value: this.dataObj.restaurace.id
                },
                spot_id: {
                  value: null
                },
                date_range: {
                  value: {
                    zacatek: null,
                    konec: null,
                  }
                },
              }
            },
          },
        },
        'reservation_edit': {
          title: 'Editace rezervace',
          router: {
            modul: 'rezervace-slot',
            action: null,
          },
          config: {
            "display": true,
            "is_header": true,
            "is_close_btn": true,
            "is_footer": true
          },
          data: {
            save: false,
            submit: false,
          },
          prefill: {
            general: {
              inputs: {
                restaurace_id: {
                  readonly: true,
                  value: this.dataObj.restaurace.id
                },
              }
            },
          },
        },
      },
      modalOpen: {},
    }
  },
  mounted() {
    this.selectedDay = this.dataObj.day.day;

    //active viewport
    this.is_viewport_active = false;

    window.addEventListener('focus', () => {
      this.is_viewport_active = true;
    });

    window.addEventListener('blur', () => {
      this.is_viewport_active = false;
    });

    if(localStorage.getItem(autorefresh_name)){
      this.is_auto_refresh = localStorage.getItem(autorefresh_name);
    }

    setInterval(() => this.autoRefresh() , this.dataObj.auto_refresh.minutes * 60 * 1000)

    //todo get param spot_id - highlight
  },
  methods: {
    autoRefresh(){
      if(this.is_auto_refresh) {
        if(!this.is_viewport_active) {
          this.$router.go(0);
        }
      }
    },

    mouseOverRow(spot) {
      //console.log('dev.mouseOverRow', spot)
    },
    mouseOutRow(spot) {
      //console.log('dev.mouseOutRow', spot)
    },

    deleteListSlot(slot) {
      if (confirm("Opravdu chcete odstranit tuto rezervaci?") === true){
        this.setStav(slot, this.dataObj.setup.stav.storno)
      }
    },

    setStav(slot, stav_id){
      let formData = new FormData;
      formData.append('slot_id', slot.id)
      formData.append('stav_id', stav_id)
      axios.defaults.baseURL = this.getBaseRoot();

      let apiPath = this.getApiConfig('rezervace.slot_change_slot_status.path')
      axios({
        url: apiPath,
        data: formData,
        method: this.getApiConfig('rezervace.slot_change_slot_status.method'),
        withCredentials: true,
        headers: this.getRequestHeader(),
      }).then((res) => {
        slot.stav_title = res.data.response.stav.title
        slot.stav_id = res.data.response.stav.id
      })
    },


    openDialogWindowEdit(slot) {
      this.modals.reservation_edit.router.action = 'edit/' + slot.id
      this.modalOpen = this.modals.reservation_edit;
    },

    getBackgroundClassByStavId(stav_id){
      return 'stav-' + stav_id + ' stav-' + this.dataObj.setup.stav[stav_id];
    },

    mouseOverSlot(spot) {
      this.highlightListRow(spot.id);
    },

    mouseOutSlot(spot = null) {
      this.highlightListRow(spot);
    },

    highlightListRow(item_id) {
      if(this.dataObj.list) {
        for(const r in this.dataObj.list) {
          if(this.dataObj.list[r].id == item_id) {
            this.dataObj.list[r].highLight = true;
          }else{
            this.dataObj.list[r].highLight = false;
          }
        }
      }
    },

    openDialogWindow(spot_id, datetime_from) {
      let datumObj = new Date(datetime_from);
      datumObj.setMinutes(datumObj.getMinutes() + this.dataObj.restaurace.config.slot.length_minutes);
      const datetime_to = datumObj.getFullYear() + '-' + ('0' + (datumObj.getMonth() + 1)).slice(-2) + '-' + ('0' + datumObj.getDate()).slice(-2) + ' ' + ('0' + datumObj.getHours()).slice(-2)+ ':' + ('0' + datumObj.getMinutes()).slice(-2)

      this.modals.reservation_create.prefill.general.inputs.date_range.value.zacatek = datetime_from
      this.modals.reservation_create.prefill.general.inputs.date_range.value.konec = datetime_to
      this.modals.reservation_create.prefill.general.inputs.spot_id.value = spot_id;

      this.modalOpen = this.modals.reservation_create;

    },

    dateRedirect(params) {
     let url = this.$route.fullPath;

      if(params) {
        for(const i in params) {
          const regex = new RegExp('('+encodeURIComponent(i)+')=([^&]*)', 'g');
          const newParam = i + '=' + encodeURIComponent(params[i]);
          url = url.replace(regex, newParam);

          if (!url.includes(i + '=')) {
            url += (url.indexOf('?') >= 0 ? '&' : '?') + newParam;
          }
        }
        this.$router.push(url)
      }
    }
  },
  watch: {
    "is_auto_refresh": {
      handler(newVal) {
        if(newVal !== null){
          localStorage.setItem(
              autorefresh_name,
              newVal
          )
          console.log('dev.is_auto_refresh', newVal)
        }

      },
      deep: true,
      immediate: true
    },

    "selectedDay": {
      handler(newVal) {
        if(newVal && newVal != this.dataObj.day.day) {
          this.dateRedirect({date: newVal});
        }
      },
      deep: true,
      immediate: true
    },

    "modals.reservation_edit.config.display": {
      handler(newVal) {
        if(newVal == false) {

          this.modalOpen = {}
          this.modals.reservation_edit.config.display = true;
        }
      },
      deep: true,
      immediate: true
    },

    "modals.reservation_edit.data.data": {
      handler(newVal) {
        if(newVal){
          this.modalOpen = {}
          this.modals.reservation_edit.config.display = true;
          this.dateRedirect(
              {
                date: newVal.datum_od,
                spot_id: newVal.id
              }
          );
        }
      },
      deep: true,
      immediate: true
    },

    "modals.reservation_create.config.display": {
      handler(newVal) {
        if(newVal == false) {
          this.modalOpen = {}
          this.modals.reservation_create.config.display = true;
        }
      },
      deep: true,
      immediate: true
    },

    "modals.reservation_create.data.data": {
      handler(newVal) {
        if(newVal){
          this.modalOpen = {}
          this.modals.reservation_create.config.display = true;

          this.dateRedirect(
              {
                date: newVal.datum_od,
                spot_id: newVal.id
              }
          );
        }
      },
      deep: true,
      immediate: true
    },
  },
};
</script>