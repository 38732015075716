<template>
  <nav v-if="moduleData.types">
    <div
        v-for="typeItem in moduleData.types"
        class="pseudo-link"
        @click.prevent="createStat(typeItem)"
    >
      <i v-if="typeItem.ico" :class="typeItem.ico"></i>
      <span>{{typeItem.title}}</span>
    </div>

  </nav>


    <Line
        id="my-chart-id-line"
        :options="moduleData.graph_data.option"
        :data="moduleData.graph_data.data"
    />

    <Bar
        id="my-chart-id-line-bar"
        :options="moduleData.graph_data.option"
        :data="moduleData.graph_data.data"
    />

  <Bar
      id="my-chart-id-line-bar2"
      :options="moduleData.graph_data2.option"
      :data="moduleData.graph_data2.data"
  />

  <Doughnut
      id="my-chart-id-donut"
      :options="moduleData.graph_data.option"
      :data="moduleData.graph_data.data"
  />

  <pre>
    nu: {{moduleData.graph_data.data}}
  </pre>
  <!--
    <pre>
      dev: {{chartData}}
    </pre>
   -->

<hr>

  <Bar
      id="my-chart-id"
      :options="chartOptions"
      :data="chartData"
  />
  <pre>
    data: {{chartData}}
  </pre>

  <Line
      id="my-chart-id-line"
      :options="chartOptions"
      :data="chartData"
  />
<!-- -->
  <Doughnut
      id="my-chart-id-donut"
      :options="chartOptions"
      :data="chartData"
  />



  <pre>
    {{statConfig}}
  </pre>
  <pre>
    {{statData}}
  </pre>

  <!---->
  <pre>
    {{moduleData}}
  </pre>


</template>

<script>
import ImageComponent from "@/components/general/Image";
import axios from "axios";
import { Bar, Line, Doughnut } from 'vue-chartjs'
import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  BarElement,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  ArcElement,
} from 'chart.js'
ChartJS.register(Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale, PointElement, LineElement, ArcElement)

export default {
  name: "StatsModule",
  components: {
    ImageComponent,
    Bar,
    Line,
    Doughnut,
  },
  emits: [
    "update:reloadSignal"
  ],
  props: {
    "ident": String,
    "route": Object,
    "reloadSignal": String,
    "moduleData": Object
  },
  data() {
    return {
      statConfig: null,
      statData: null,
      reloadSignalList: null,
      chartData: {
        labels: [ 'January', 'February', 'March' ],
        datasets: [
            {
              label: 'Data One',
              //backgroundColor: '#f87979',
              borderColor: 'rgba(75, 192, 192, .5)',
              weight: 0.0125,
              data: [

                40,
                20,
                12
              ],
              backgroundColor: [
                'rgb(255, 99, 132)',
                'rgb(54, 162, 235)',
                'rgb(255, 205, 86)'
              ],
            },
          {
            label: 'Data Two',
            backgroundColor: '#179f79',
            weight: 0.25,
            data: [

              80,
              75,
              1
            ],
          },
        ]
      },
      chartOptions: {
        responsive: true,
        indexAxis: 'y',
        interaction: {
          intersect: false,
          axis: 'x'
        },
        plugins: {
          legend: {
            position: 'top',
          },
          title: {
            display: true,
            //text: 'Chart.js Bar Chart',
            text: (ctx) => 'Step ' + ctx.chart.data.datasets[0].stepped + ' Interpolation',
          }
        }
      }
    }
  },
  methods: {
    async createStat(type) {
      this.statConfig = type;
      const data = {
        'id': this.moduleData.id,
        'type': type
      }
      const formData = new FormData;
      formData.append('input', data)

      axios.defaults.baseURL = this.getBaseRoot();
      await axios({
        url: type.params.path,
        method: type.params.method,
        data: formData,
        withCredentials: true,
        headers: this.getRequestHeader(),
      }).then((res) => {

        console.log("dev createStat res", res)
      }) .catch((err) => {

        console.log("dev createStat catch", err)

      })
      .finally((f) => {

      })


      console.log('dev createStat', type);
    },

      reloadParent(val) {
        //this.$emit("update:reloadSignal", val);
      }
  },
  watch: {
    reloadSignalBox: {
      handler(val) {
        this.reloadParent(val);
      },
      immediate: true
    },
    reloadSignalList: {
      handler(val) {
        this.reloadParent(val);
      },
      immediate: true
    },
  },
  created() {
   // console.log("dev DashboardModule created x")
  },
  mounted() {
   // console.log("dev DashboardModule mounted x")
  }
};
</script>