<template >

  <input
      :type="options.inputType"
      v-model="inputModel"
      class="form-control"
  />

  <!--
  <display-debug>
    <template #debugView>options {{options}}</template>
  </display-debug>
-->
</template>

<script>


import axios from "axios";

let timer;

export default {
  name: "WikiInputSimple",
  components: {

  },
  props: {
    "modelValue": {
      type: [String, Number, Object],
      required: false,
      default: null
    },
    "langs": [String, Object],
    "parentIndex": [String, Number],
    "options": {
      type: Object,
      required: false,
      default: {}
    }
  },
  emits: ['update:modelValue'],
  data() {
    return {
      writeStatus: false,
      inputModel: null,
    }
  },
  created() {

  },
  mounted() {
    this.inputModel = this.modelValue;
  },
  methods: {
      async formatSlug(value) {
        if(value) {
          for(const l in value) {
            axios.defaults.baseURL = this.getBaseRoot();
            await axios({
              url: this.getApiConfig('general.format_slug.path') + "?" + this.getApiConfig('general.format_slug.input.slug') +"=" + value[l],
              method: this.getApiConfig('general.format_slug.method'),
              withCredentials: true,
              headers: this.getRequestHeader(),
            }).then((res) => {
              if(res.data.status){
                if(res.data.response.slug && res.data.response.slug != "null"){
                  value[l] = res.data.response.slug;
                }
              }
            }).catch((err) => {
              console.log("dev format slug catch", err)
            })
          }
        }

        return value;
      }
  },
  watch: {
    /*
    options: {
      handler(newVal) {
        if(newVal && newVal.is_slug){
          for(const l in newVal.makeFrom) {
          }
        }
      },
      deep: true,
      immediate: true
    },*/
    inputModel: {
      handler(newVal) {
        if(timer) clearInterval(timer);
        timer = setTimeout(
            () => {
              this.$emit('update:modelValue', newVal )
            },
            300
        )
      },
      deep: true,
      immediate: true
    },

  },
};
</script>