<template
>
  <InfiniteScrollList
    :custom-config="listConfig"
    ref="listWrapCompomenet"
  >
    <!-- :default-data="devData" -->
    <template #listItem="{itemData}" :ref="'listWrapCompomenetItem' + itemData.flash_id">
      <td>{{itemData.flash_id}}</td>
      <td @click.prevent="markAsRead(itemData); itemData.show_full = !itemData.show_full">{{itemData.nazev}}</td>

      <td @click.prevent="markAsRead(itemData); itemData.show_full = !itemData.show_full">
        <template
            v-if="itemData.obsah_plain"
            class="btn"
        >

          <template v-if="itemData.show_full">
            {{itemData.obsah_plain}} <span v-if="itemData.trim">...</span>
          </template>
          <template
              v-else
          >
            <span v-html="itemData.obsah"></span>
          </template>
        </template>
        <template
            v-else
            v-html="itemData.obsah"
        >
        </template>

      </td>

      <!--
      <td
          v-if="itemData.obsah_plain"

      >
        <template v-if="itemData.show_full">
          {{itemData.obsah_plain}} <span v-if="itemData.trim">...</span>
        </template>
        <template v-else v-html="itemData.obsah">

        </template>

      </td>

      <td
          v-else
          v-html="itemData.obsah"
      >
      </td>
      -->
      <!--

      <pre>template table: {{itemData}}</pre>
      -->
    </template>

    <template #listItemDEV="{itemData}">
      <pre>template itemData: {{itemData}}</pre>
    </template>


    <template #listEmpty>
      <pre>nejsou zadna data</pre>
    </template>

    <template #tableHead>
      <thead>
        <tr>
          <th>id</th>
          <th>predmet</th>
          <th>telo</th>
        </tr>
     </thead>
    </template>
  </InfiniteScrollList>

</template>

<script>
import './ViewTimeline.scss'
import InfiniteScrollList from '@/components/content/InfiniteScrollList'

import axios from "axios";

let timer;
let scrollTimer;
let autoRefreshTimer;
const countdown_key = 'rezervace_slot_count_status';
const timeRefreshIntervalMinutes = 10 * 60;
export default {
  name: "ViewFlashMessageUserList",
  components: {
    InfiniteScrollList
  },
  props: {
    "dataObj": Object,
    "returnData": [Object, String, Number],
    "reloadSignal": [Object, String, Number],
  },
  emits:[
    'update:reloadSignal',
  ],
  data() {
    return {
      listConfig: {
        type: 'table', //list, table
        //wrap_class_name: 'testovaci-infinite-scroll',
        is_preload: true,
        list: {
          auto_refresh: {
            status: true,
            time: 15 * 1000,
          },
          is_next_btn: true,
          is_end_notification: true,
        },
        action: {
          path: '/class/flash-message/user-list?v=default&page=',
          method: 'get',
        },
        cols: {
          uid: 'flash_id',
          sort: {
            name: 'ts',
            order: 'desc'
          }
        }
      }

    }
  },
  mounted() {

  },
  beforeUnmount() {

  },
  created() {

  },

  methods: {
    //listWrapCompomenet

    markAsRead(item) {
        console.log('dev.list markAsRead', item);
      console.log('dev.list markAsRead refs', this.$refs);
    }
  },
  watch: {

  },
};
</script>

<style scoped type="text/scss">
  .cms5-infinite-scroll-list-wrap {
   height: 45%;
    width: 50%;
  }
</style>