<template>
  <ul class="flashes" v-if="list.length > 1">
    <li v-for="item in list" :key="item">
      Flash: <strong>{{item.type}}</strong> {{item.title}}
    </li>
  </ul>
  <!--
  <pre>{{is_auto_delete}}
    {{list}}</pre>
    -->
</template>

<script>
import axios from "axios";
export default {
  name: "FlashMessage",
  props: {
    "is_auto_delete": Boolean,
  },
  data() {
    return {
      refresh: true,
      list: [],
    }
  },
  methods: {
    getList(){
      //const template = this.cfg.general.flash_messages.root_local_storage_name + "__";
      const template = this.getConfig('flash_messages.root_local_storage_name ')+ "__";

      for (var i = 0; i<localStorage.length; i++) {
        if(localStorage.key(i).startsWith(template) && localStorage.getItem(localStorage.key(i))) {
          //console.log('dev FlashMessage', localStorage.key(i), localStorage.getItem(localStorage.key(i))) ;
          this.list.push({
            type: localStorage.key(i).replace(template, ""),
            title: localStorage.getItem(localStorage.key(i))
          })
        }
        //TODO nacteni z API
        if(this.is_auto_delete == true) {
          for (var i = 0; i<localStorage.length; i++) {
            if(localStorage.key(i).startsWith(template)) {
              //console.log('dev FlashMessage cleare', localStorage.key(i) ) ;
              localStorage.setItem(localStorage.key(i), null);
            }
          }
        }

      }
    },

  },
  created() {
    this.getList();
  }
};
</script>