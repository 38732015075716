<template>
  <ul v-if="image_data" class="p-0 list-group">
    <li class="list-group-item w-100">
      <p>Nahrát všechny rozměry</p>
      <image-component
          v-model="sizeModel"
          :image-id="image_data.id"
          :is_upload="true"
          :is_form_only="true"
          :type-id="image_data.id_typ_obrazek"
      />
    </li>
    <template
        v-for="(imageTypeItem, imageTypeIndex)  in image_data['thumbs']"
        @key="imageTypeIndex"
    >
      <li
        v-if="imageTypeItem.images && imageTypeItem.images.length > 0"
        class="list-group-item p-1"
      >
        <div class="text-secondary fw-light">{{imageTypeItem.title}}</div>
        <ul
            class="ps-0 ps-md-5"
        >
          <li
              v-for="(imageItem, imageIndex)  in imageTypeItem['images']"
              @key="imageIndex"
              class="mt-2"
          >
            <h6>{{imageItem.name}}</h6>
            <img
                :src="imageItem.path + '?r=' + Math.floor((Math.random() * 100000000))"
                :alt.attr="imageItem.title"
                class="img-fluid"
            />
            <image-component
              v-model="sizeModel"
              :image-id="image_data.id"
              :is_upload="true"
              :is_form_only="true"
              :size_id="imageItem.size_id"
              :type-id="image_data.id_typ_obrazek"
              class="mt-2 p-0"
            />
          </li>
        </ul>
      </li>
    </template>
  </ul>
</template>

<script>
import axios from "axios";
import ImageComponent from "@/components/general/Image";
export default {
  name: "ImageDetailPreview",
  components: {
    ImageComponent
  },
  props: {
    imageId: [Number, String]
  },
  data() {
    return {
      sizeModel: null,
      image_data: null,
    }
  },
  methods: {
    hoverItem(item) {
      //console.log("dev hoverItem", item)
    },
    async getData(){
      //console.log("dev getData detail preview", this.getApiConfig('image.detail_sizes.path') + this.imageId)
      axios.defaults.baseURL = this.getBaseRoot();
      if(this.imageId) {
        await axios({
          //url: this.cfg.api.image.detail_sizes.path + this.imageId,
          url: this.getApiConfig('image.detail_sizes.path') + this.imageId,
          method: "get",
          withCredentials: true,
          headers: this.getRequestHeader(),
        }).then((res) => {
          if(res.data.status) {
            //console.log("image detail", res.data.response)
            this.image_data = res.data.response.detail;
          }else{
            this.image_data = false;
          }
        });
      }else{
        this.user_data = false;
      }
    },
  },
  created() {
    this.getData();
  },

  watch: {
    sizeModel: {
      handler(val) {
        this.getData();
        this.sizeModel = null;
      },
      deep: true,
      immediate: true
    },

  },
};
</script>