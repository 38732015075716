<template>

  <div
      v-if="window.type != 'desktop'"
      class="d-flex d-md-none "
  >
    <swiper
        :modules="modules"
        :slides-per-view="5"
        :space-between="20"
        :scrollbar="{ draggable: true }"
        class="nav"
        style="height: 60px;width: 99vw; max-width: 99vw;"
    >
      <!-- global dashboard -->
      <swiper-slide
          class="nav-item d-flex align-items-center d-flex justify-content-center"
      >
        <router-link to="/dashboard" title="Home" class="fs-3"><i class="bi bi-house"></i></router-link>
      </swiper-slide>

      <!-- user moduls -->
      <swiper-slide
          v-for="item in menu" :key="item"
          class="nav-item d-flex align-items-center d-flex justify-content-center"

      >
        <router-link
            :to="'/' + item.slug + '/' + ((item.je_dashboard == '1')? 'dashboard':'list')"
            active-class="active active-5-x"
            class="nav-link "
            v-slot="{ href, route, navigate, isActive, isExactActive }"
        >
          <i
              v-if="item.ico_class"
              :class="'fs-3 bi '+item.ico_class"
          ></i>&nbsp;
        </router-link>
      </swiper-slide>

      <!-- user settings -->
      <swiper-slide
          class="nav-item align-content-between d-flex align-items-center ms-3"
      >
        <image-component
            :image-id="user.obrazek_id"
            :is_preview_only="true"
            thumb_class="user-thumb mobile rounded-circle me-2"
        />
        <router-link
            to="/user/nastaveni"
            title="Nastavení vašeho profilu"
            class="link-secondary fw-medium"
            style="text-decoration: none;"
        >
          {{user.title}}
        </router-link>
      </swiper-slide>
      <!-- logout -->
      <swiper-slide
          class="nav-item align-items-center d-flex align-items-center">
        <router-link to="/user/logout" title="Odhlaseni" class="fs-3 ms-2"><i class="bi bi-power link-danger"></i></router-link>
      </swiper-slide>
  </swiper>
  </div>


<div
    class="row d-none d-sm-flex mt-2"
>
  <div class="col col-12 text-end">
    <span
        @click.prevent="displayOpen=!displayOpen"
        class="pseudo-link"
    >
        <i v-if="displayOpen" class="bi bi-arrows-angle-contract rounded-circle p-1 bg-secondary text-white"></i>
        <i v-else class="bi bi-arrows-angle-expand rounded-circle p-1 bg-secondary text-white"></i>
    </span>
  </div>
</div>

  <ul
      v-if="window.type == 'desktop'"
      class="cms5-main-menu d-none d-sm-flex flex-column justify-content-lg-start nav p-2"
  >
    <!-- -->
    <li
      v-if="displayOpen"
      class="main-tile nav-item"
    >
      CMS5 Admin
    </li>

    <li
        class="nav-item mt-2"
    >
      <router-link
          to="/dashboard" title="Home"
          class="nav-link text-secondary"
      >
          <span>
            <i
                class="bi bi-house"
            ></i>&nbsp;
            <span v-if="displayOpen">Dashboard</span>
          </span>
      </router-link>
    </li>


    <li
        v-for="item in menu" :key="item"
        class="nav-item mt-2"
    >
      <router-link
          :to="'/' + item.slug + '/' + ((item.je_dashboard == '1')? 'dashboard':'list')"
          active-class="active active-5-x"
          class="nav-link text-secondary"
          v-slot="{ href, route, navigate, isActive, isExactActive }"
          :title="item.title"
      >

          <i
              v-if="item.ico_class"
              :class="'bi '+item.ico_class"
          ></i>&nbsp;

        <span v-if="displayOpen">{{item.title}}</span>


      </router-link>
    </li>


    <li class="user nav-item mt-auto mb-4 p-0">
      <router-link
          to="/user/nastaveni"
          title="Nastavení vašeho profilu"
          style="text-decoration: none;"
          :class="displayOpen? 'badge d-flex  align-items-center text-primary-emphasis -bg-primary-subtle rounded-pill bg-warning': ''"
      >
        <image-component
            :image-id="user.obrazek_id"
            :is_preview_only="true"
            thumb_class="menu-user-thumb rounded-circle me-1"
        />
        <span v-if="displayOpen" class="fs-6 me-2 fw-light">{{user.title}}</span>
      </router-link>

      <span
          @click.prevent="logout"
          :class="displayOpen? 'pseudo-link badge d-flex p-2 align-items-center text-primary-emphasis bg-primary-subtle rounded-pill mt-2': 'fs-3'"
      >
        <i class="bi bi-power pseudo-link fs-3 link-secondary"></i>
        <span v-if="displayOpen" class="ms-2 link-secondary fs-6 fw-light">Odhlasit se</span>
      </span>
    </li>

  </ul>

</template>

<script>
import axios from "axios";
import ImageComponent from "@/components/general/Image";
import { Navigation, Pagination, Scrollbar } from 'swiper/modules';

// Import Swiper Vue.js components
import { Swiper, SwiperSlide } from 'swiper/vue';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
const lsMenuDisplay = 'menu_display';

export default {
  name: "MainMenu",
  components:{
    Swiper,
    SwiperSlide,
    ImageComponent,
  },
  props: {
    user: Object
  },
  data() {
    return {
      menu: [],
      displayOpen: null,
      window: null,
    }
  },
  methods: {
    async logout() {
      if (confirm("Opravdu se chcete odhlásit z administrace?") === true){

        axios.defaults.baseURL = this.getBaseRoot();
        await axios({
          //url: this.cfg.api.user.logout_path,
          //method: "post",
          url: this.getApiConfig('user.logout.path'),
          method: this.getApiConfig('user.logout.method'),

          withCredentials: true,
          headers: this.getRequestHeader(),
        }).then((res) => {
          if(res.data) {
            this.$router.push('/')
          }else{
            //this.loginStatus = false;
            //console.log("DEV RES NODATA", res.data);
          }
        });
      }
    },
    async getMenu(){
      axios.defaults.baseURL = this.getBaseRoot();
        await axios({
          //url: this.cfg.api.general.main_menu_path,
          //method: "get",
          url: this.getApiConfig('general.main_menu.path'),
          method: this.getApiConfig('general.main_menu.method'),

          withCredentials: true,
          headers: this.getRequestHeader(),
        }).then((res) => {
          if(res.data) {
            if(res.data.response.menu) {
              this.menu = res.data.response.menu;
            }
          }else{
            //this.loginStatus = false;
            //console.log("DEV RES NODATA", res.data);
          }
        });
    },
  },
  setup() {
    return {
      modules: [Navigation, Pagination, Scrollbar],
    };
  },

  watch: {
    displayOpen: {
      handler(newVal) {
        if(newVal !== null) {
          localStorage.setItem(
              lsMenuDisplay,
              newVal? 1: 0
          )
        }
      },
      immediate: true
    },
  },
  created() {
    this.getMenu();
    this.window = this.getDeviceViewport();

    window.addEventListener('resize',() => {
      this.window = this.getDeviceViewport();
    });

  },
  mounted() {

    if(localStorage.getItem(lsMenuDisplay) == 0) {
      this.displayOpen = false;
    }else{
      this.displayOpen = true;
    }
  }
};
</script>