<template

>
  <div
      v-if="config.display"
      :class="'modal-window-wrap ' + (config.display? ' open': ' hide')"
      @keydown.esc="config.display_esc = false"
  >
    <div class="content-wrap">
      <!--
      <span class="close-wrap" @click.prevent="config.display=false">
        <i class="bi bi-x"></i>
      </span>

-->
      <div class="content list-group border border-light ">
        <header
            v-if="config.is_header || config.is_close_btn"
            class="list-group-item"
        >
          <div class="row">
            <div class="col col-9">
              <h2 class="h4"  v-if="config.is_header"> {{title}}</h2>
            </div>
            <div class="col col-3 text-end">
              <button
                  v-if="config.is_close_btn"
                  @click.prevent="config.display=false"
                  type="button" class="btn-close"
              ></button>
            </div>
          </div>
        </header>

        <div class="list-group-item px-0 px-md-1">

<!--
          <span class="btn btn-primary" @click.prevent="config.display=!config.display;">prepnout stav</span>
          <pre>{{config}}</pre>
          <pre>window {{window}}</pre>
-->
          <slot name="modal-content-slot"></slot>
        </div>

        <footer v-if="config.is_footer" class="list-group-item px-0"><slot name="modal-content-footer"></slot></footer>

      </div>

    </div>
    <div class="bg">
      [bg]
    </div>

  </div>

</template>

<script>

import "./ModalWindow.scss"

export default {
  name: "ModalWindow",
  props: {
    "title": [String, Number],
    "config": [Object],
  },
  components: {

  },
  emits: [
    //'update:modelValue',
  ],
  data() {
    return {
      dev:{},
      window: null
    }
  },
  methods: {
    onWindowResize() {
      /*
      this.window  = {
        resolution: {
          width: window.innerWidth,
          height: window.innerHeight,
        }

      }
      const mediaQuerySmall = window.matchMedia('screen and (max-width: 480px)')
      const mediaQueryMedium = window.matchMedia('screen and (max-width: 768px)')
      const mediaQueryOrientation = window.matchMedia('screen and (orientation: portrait)')
      const mediaQueryTouch = window.matchMedia('hover: none')

      if (mediaQuerySmall.matches) {
        this.window.type = 'mobile';
      }else if(mediaQueryMedium.matches){
        this.window.type = 'medium';
      }else{
        this.window.type = 'desktop';
      }

      if (mediaQueryOrientation.matches) {
        this.window.orientation = 'portrait';
      }else{
        this.window.orientation = 'landscape';
      }

      if (mediaQueryTouch.matches) {
        this.window.is_touch = true;
        this.window.is_mouse = false;
      }  else{
        this.window.is_touch = false;
        this.window.is_mouse = true;
      }
      */
      //console.log('dev getDeviceViewport', this.getDeviceViewport())

    },
  },
  computed: {

  },
  mounted() {
    this.onWindowResize();
  },
  created() {
    window.addEventListener('resize', this.onWindowResize);

    document.querySelector("body").addEventListener("keyup", (e) => {
      if(e.key == 'Escape'){
        this.config.display = false;
      }
    });
  },
  watch: {

  }
};
</script>