<template>
  <input
      type="text"
      v-model="inputModel"
      readonly
  >
</template>
<script>

export default {
  name: "HiddenInput",
  props: {
    "inputObj": Object,
    "formObj": Object,
    "modelValue": [String, Number],
  },
  data() {
    return {
      inputModel: null,
    }
  },
  mounted() {
    this.inputModel = this.inputObj.val;
  },
  watch: {
    inputModel( newVal){
      this.$emit('update:modelValue', newVal ) // model vole!
    },
  },
};
</script>