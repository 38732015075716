export function getBaseRoot() {
    if(process.env.VUE_APP_ROOT_URL) {
        return process.env.VUE_APP_ROOT_URL;
    }else{
        return this.getApiConfig('root');
    }
}

export function getRequestHeader() {
    return  {
        "X-fifth-cms-user" : localStorage.getItem(this.getConfig('login_local_storage_name'))
    }
}

export function getRequestHeaderUpload() {
    return  {
        'Content-Type': 'multipart/form-data',
        "X-fifth-cms-user" : localStorage.getItem(this.getConfig('login_local_storage_name'))
    }
}