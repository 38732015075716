<template>

  <!--

  <pre v-if="formObj">formObj._load {{formObj._load}}</pre>
  <pre v-if="formObj && formObj.body && formObj.body.general">formObj.dev {{formObj.body.general.inputs}}</pre>
  <pre>formData.dev {{formData}}</pre>
  -->

  <div v-if="formStatus.showForm && formObj && formObj.templates">
    <div class="d-flex flex-wrap align-items-center mb-2">
      <span class="fw-medium me-1 mb-1 btn btn-dark">Vzory</span>
      <div
          v-for="(templatesItem, templatesIndex)  in formObj.templates"
          @key="templatesIndex"
          class="me-2"
      >
        <a
            @click.prevent="prefillTempaltesFormData(templatesIndex)"
            :class="'btn me-1 mb-1' + (templatesItem.is_active? ' btn-danger': ' btn-warning')"
        >
          <i v-if="templatesItem.ico" :class="templatesItem.ico"></i> {{templatesItem.title}}
        </a>
        <!--
        <router-link :to="'?template=' + templatesIndex">{{templatesItem.title}}</router-link>
        -->
      </div>
    </div>
  </div>

  <div ref="formResultPosition"></div>
  <template v-if="formObj && formObj.save.options.saved_screen">
zobrazuji vysledek
  </template>
  <template v-else>
    [nezobrazuji vystup]
  </template>
    <div
        v-if="formStatus.is_submit && formStatus.is_loading && this.formObj.save.options.saved_screen"
        class="fullscreen-message d-flex align-items-center justify-content-center"

    >
      <div class="">
        <div class="text-center">
          <div class="spinner-border text-secondary" role="status">
            <span class="visually-hidden">Loading...</span>
          </div>
        </div>
        <span>Moment, systém zpracovává váš požadavek</span>
      </div>
    </div>
    <div
        v-else-if="formStatus.is_submit && !formStatus.is_loading && formStatus.is_validate && this.formObj.save.options.saved_screen"
        class="fullscreen-message d-flex align-items-center justify-content-center"
    >
      <div class="">
        <div class="text-center">
          <i class="bi bi-check-circle-fill fs-1 text-success mb-3"></i>
        </div>
        <div class="text-success text-center fs-2">V pořádku uloženo.</div>
        <ul v-if="hook_action" class="mt-2 list-group">
          <li
              v-for="(hookItem, hookIndex)  in hook_action"
              @key="hookIndex"
              class="list-group-item"
          >
            <strong>{{hookItem.title}}</strong>&nbsp;
            <span v-if="hookItem.status">
            {{hookItem.status}}<i class="bi bi-check"></i>
          </span>
            <span v-else>
             Čeká na spuštení, vyčkejte moment. <i class="bi bi-clock-fill"></i>
          </span>
          </li>
        </ul>
        <div
            v-else
            class="mt-3"
        >
          <div
              v-for="(linkItem, linkIndex)  in after_links"
              @key="linkIndex"
          >
            <router-link
                :to="linkItem.path"
                class="btn-outline-secondary link-secondary text-decoration-none mt-2"
            >
              <i v-if="linkItem.ico" :class="linkItem.ico+ ' me-2'"></i> {{linkItem.title}}
            </router-link>
          </div>
        </div>
        <div v-if="hook_action && hook_status" class=" mt-2">
          <div class="alert alert-success">
            <i class="bi bi-check-circle"></i> <span>{{hook_status}}</span>
          </div>

          <div
              class="mt-3"
          >
            <div
                v-for="(linkItem, linkIndex)  in after_links"
                @key="linkIndex"
            >
              <router-link
                  :to="linkItem.path"
                  class="btn-outline-secondary link-secondary text-decoration-none mt-2"
              >
                <i v-if="linkItem.ico" :class="linkItem.ico+ ' me-2'"></i> {{linkItem.title}}
              </router-link>
            </div>
          </div>
        </div>

      </div>
    </div>

    <div
        v-else-if="formStatus.is_submit && !formStatus.is_loading && !formStatus.is_validate"
        class="fullscreen-message invalid d-flex align-items-center justify-content-center"
    >
      <div>
        <div class="text-center">
          <i class="bi bi-exclamation-triangle-fill fs-1 text-danger"></i>
        </div>
        <span class="text-danger text-center fs-2">{{err.message}}</span>
        <!--
        <pre>{{err.message}}</pre>
        -->
      </div>
    </div>

  <form
      v-if="formObj && formStatus.showForm && !formStatus.is_loading"
      @submit.prevent="submitForm"
      class="d-xxl-flex flex-wrap g-2 "
  >
    <fieldset
        v-for="(sectionItem, sectionIndex)  in formObj.body"
        @key="sectionIndex"
        class="border border-light rounded-4 flex-fill p-2 p-sm-4"
    >

      <legend
          v-if="Object.keys(formObj.body).length > 1"
          class="fs-5 fw-medium"
      >
        {{sectionItem.title}} - {{sectionItem.display}}
      </legend>



      <div
          v-for="(inputItem, inputIndex)  in sectionItem.inputs"
          @key="inputIndex"
          v-show="inputItem.display"
          :style="inputItem.invalid?' ':null"
          :class="'mb-2 p-2' + (inputItem.invalid?' border border-danger rounded-3':'')"
          :data-component="inputItem.component_name"
      >


        <strong v-if="inputItem.type!= 'hidden'" class="fs-4 text-secondary fw-lighter">{{inputItem.title}}</strong>&nbsp;<br>

        <!--
                     <pre>inputItem x {{inputItem}}<


                     <pre>formObj x rmObj}}</pre>
        -->
                <component
                    v-bind:is="inputItem.component_name"
                    v-model="formData[inputItem.name]"
                    :inputObj="inputItem"
                    :formObj="formObj.body"
                ></component>

        <div
            v-if="inputItem.invalid && inputItem.invalid.message"
            class="alert alert-danger fs-6 mt-1"
        >
          <i class="bi bi-exclamation-circle-fill me-2 p-0"></i>
          <span>{{inputItem.invalid.message}}</span>
        </div>


        <div v-if="inputItem.hint" class="mt-1">
          <i class="bi bi-info-circle me-1"></i>
          <small class="fs-6" v-html="inputItem.hint"></small>
        </div>


      </div>

    </fieldset>

    <div class="btn-wrap text-start w-100">
      <div
          v-for="(btntItem, btnIndex)  in formObj.btns"
          @key="inputIndex"
      >
        <button
            :type="btntItem.type"
            class="btn btn-dark btn-lg"
        >
          {{btntItem.title}}
        </button>
      </div>
    </div>
  </form>




<!--
  <pre>formData DEV: {{formData}}</pre>
  <pre>formObj.body: {{formObj.body.general.inputs}}</pre>

  -->
</template>

<script>
import { nextTick } from 'vue'
import HiddenInput from "@/components/general/form/inputs/HiddenInput";
import TextInput from "@/components/general/form/inputs/TextInput";
import TextInputLang from "@/components/general/form/inputs/TextInputLang";
import TextareaInput from "@/components/general/form/inputs/TextareaInput";
import TextareaInputLang from "@/components/general/form/inputs/TextareaInputLang";
import SelectInput from "@/components/general/form/inputs/SelectInput";
import SegmentInput from "@/components/general/form/inputs/SegmentInput";
import ImageInput from "@/components/general/form/inputs/ImageInput";
import BooleanInput from "@/components/general/form/inputs/BooleanInput";
import TreeSelectInput from "@/components/general/form/inputs/TreeSelectInput";
import TreeSelectCustomInput from "@/components/general/form/inputs/TreeSelectCustomInput";
import TagsInput from "@/components/general/form/inputs/TagsInput";
import DateTimeInput from "@/components/general/form/inputs/DateTimeInput";
import CheckboxSimpleInput from "@/components/general/form/inputs/CheckboxSimpleInput";
import CheckboxMultiInput from "@/components/general/form/inputs/CheckboxMultiInput";
import RadioInput from "@/components/general/form/inputs/RadioInput";
import ImageThumb from "@/components/general/form/inputs/ImageThumb";
import GalleryInput from "@/components/general/form/inputs/GalleryInput";
import PasswordInput from "@/components/general/form/inputs/PasswordInput";
import MetaInput from "@/components/general/form/inputs/MetaInput";
import RangeInput from "@/components/general/form/inputs/RangeInput";
import DateRangeInput from "@/components/general/form/inputs/DateRangeInput";
import RestaraceJidloVariantaInput from "@/components/general/form/inputs/restaurace/RestaraceJidloVariantaInput";
import RestauraceSekceJidloInput from "@/components/general/form/inputs/restaurace/RestauraceSekceJidloInput";
import OrderInput from "@/components/general/form/inputs/OrderInput";
import axios from "axios";
export default {
  name: "FormGeneral",
  components: {
    axios,
    HiddenInput,
    TextInput,
    TextInputLang,
    TextareaInput,
    TextareaInputLang,
    SelectInput,
    SegmentInput,
    ImageInput,
    BooleanInput,
    TreeSelectInput,
    TagsInput,
    DateTimeInput,
    CheckboxSimpleInput,
    CheckboxMultiInput,
    RadioInput,
    ImageThumb,
    GalleryInput,
    PasswordInput,
    MetaInput,
    TreeSelectCustomInput,
    RangeInput,
    DateRangeInput,
    RestaraceJidloVariantaInput,
    RestauraceSekceJidloInput,
    OrderInput,
  },
  props: {
    "modelValue": [Object, Array],
    "formTitle": String,
    "formObj": Object,
    "originalPath": String,
  },
  emits:[
    'update:reloadSignal',
    'update:modelValue'
  ],
  data() {
    return {
      formData: {},
      result_save: null,
      err: null,
      hook_status: null,
      hook_action: [],
      after_links: [],
      formStatus: {
        "showForm": true,
        "is_loading": false,
        "is_submit": false,
        "is_validate": false,
        "is_saved": false,
      },
    }
  },
  methods: {
    setTemplate(key) {
      //this.prefillTempaltesFormData(key);
    },
    prefillTempaltesFormData(template_key){
      for (const t_key in this.formObj.templates) {
        this.formObj.templates[t_key].is_active = false;
      }
      if(template_key && this.formObj.templates && this.formObj.templates[template_key]) {
        this.formObj.templates[template_key].is_active = true;
        //console.log('dev prefillTempaltesFormData V2', this.formObj.templates[template_key])

        let aTemplate = this.formObj.templates[template_key]
        //console.log('dev prefillTempaltesFormData V2 template', aTemplate)
        for (const section_key in this.formObj.body) {
          for (const input_key in this.formObj.body[section_key].inputs) {

            //console.log('dev prefillTempaltesFormData V2', typeof aTemplate.inputs[input_key], aTemplate.inputs[input_key])
            if(typeof aTemplate.inputs[input_key] != 'undefined') {
              //TODO pokud je prazdna hodnota?
              //console.log('dev prefillTempaltesFormData V2 fill', input_key, aTemplate.inputs[input_key])
              this.formObj.body[section_key].inputs[input_key].val = aTemplate.inputs[input_key]
            }else{
              //console.log('dev prefillTempaltesFormData V2 none', input_key)
              //this.formObj.body[section_key].inputs[input_key].val = null;
            }
          }
        }
      }
    },

    async submitForm(){

      this.formStatus.is_submit = true;
      //TODO valiaace
      //TODO ulozeni

      let formData = new FormData();
      formData.append('form_data', JSON.stringify(this.formData));
      this.loadingStatus = true;
      const pseudothis = this

      //console.log("dev submit form", formData, this.formObj.path, formData);

      axios.defaults.baseURL = this.getBaseRoot();
      this.formStatus.is_loading = true
      let apiPath = this.formObj.save.path
      let apiQuery = [];
      if(
          this.formObj.save.options
          && this.formObj.save.options.saved_screen
      ) {
        apiQuery.push(this.formObj.save.input.is_saved_screen + '=1')
      }

      if(
          this.formObj.save.options
          && this.formObj.save.options.edit_id
      ) {
        apiQuery.push(this.formObj.save.input.id + '=' + this.formObj.save.options.edit_id)
      }

      if(apiQuery.length > 0) {
        apiPath += '?' + apiQuery.join('&')
      }

      await axios({
        url: apiPath,
        method: this.formObj.save.method,
        data:  formData,
        withCredentials: true,
        headers: this.getRequestHeader(),
      }).then((res) => {
        //console.log("dev save OK", res.data.status)
        this.formStatus.is_submit = true
        if(res.data.status) {
          this.formStatus.is_validate = true;
          this.formStatus.showForm = false;
          this.hook_action = res.data.response.action_hook
          this.after_links = res.data.response.after_links;
          this.result_save = {
            "id": res.data.response.id,
            "status": true,
            "result": res.data.response.form,
          }

          this.$emit("update:modelValue", this.result_save);
          this.formData = null;

          //TODO vymazani
          nextTick();
        }else{
          console.log('form nevalidni', res.data.response.form_invalid)
          this.formStatus.is_validate = false;
          this.err = res.data.err;
          this.formStatus.showForm = true;
          this.overwriteFormData();
          this.setInvalidateFormInputs(res.data.response.form_invalid);
          this.result_save = {
            //"id": res.data.response.id,
            "status": false,
            "err": res.data.err,
          }
          this.$emit("update:modelValue", this.result_save);
        }
      }) .catch((err) => {
        //pseudothis.err = err;
        //console.log("dev save catch", err)
        this.$refs.formResultPosition.scrollIntoView({ behavior: 'smooth' });
      })
      .finally((f) => {
        if(this.formObj.save.options.saved_screen) {
          this.$refs.formResultPosition.scrollIntoView({ behavior: 'smooth' });
        }


        pseudothis.formStatus.is_loading = false;
        //console.log("dev save finally")
        pseudothis.runHooks()
      })
    },

    overwriteFormData(){
      //console.log('dev overwriteFormData', this.formObj.body);
      for (const section_key in this.formObj.body) {
        for (const input_key in this.formObj.body[section_key].inputs) {
          console.log('DEV overwriteFormData input',input_key, typeof this.formObj.body[section_key].inputs[input_key]);
        }
      }
    },

    setInvalidateFormInputs(invalidate) {
      for (const section_key in this.formObj.body) {
        //console.log('dev this.formObj.body section_key', section_key)
        for (const input_key in this.formObj.body[section_key].inputs) {
          if(invalidate[input_key]){

            this.formObj.body[section_key].inputs[input_key].invalid = {
              "status": true,
              "message": invalidate[input_key].title
            }
            this.formObj.body[section_key].inputs[input_key].val = invalidate[input_key].val;
            //console.log('dev setInvalidateFormInputs',input_key, invalidate[input_key].title)
            //console.log('dev this.formObj.body section_key X', input_key, this.formObj.body[section_key].inputs[input_key])
          }
        }
      }
    },

    runHooks() {
      if (this.hook_action && this.hook_action.length > 0) {
        this.hook_status = 'Nezavirejte toto okno doku nedobehnou tyto akce. Dame vam vedet jakmile bude hotovo'
        this.runHookItem(0);
      }
    },
    async runHookItem(i) {
      if(this.hook_action[i]) {
        const pseudothis = this
        this.hook_action[i].status = "spusteno"
        await axios({
          url: this.hook_action[i].path,
          method: "get",
          withCredentials: true,
          headers: this.getRequestHeader(),
        }).then((res) => {
          pseudothis.hook_action[i].status = "spusteno2"
        }) .catch(function(err){
          pseudothis.hook_action[i].status = "CHYBA"
          //console.log('dev hook run item ERR',err,this.hook_action[i] )
        })
        .finally(function(){
          //console.log('dev hook run item finally',i, pseudothis.hook_action.length )
          pseudothis.hook_action[i].status = "Hotovo"
          i++;
          pseudothis.runHookItem(i);
        })
      }else{
        this.hook_status = 'Hotovo. muze zavrit okno'
      }
    },
    reloadData(){
      this.$emit("update:reloadSignal", String(Math.random()));
    },
    variableForm(){
      console.log('dev variableForm', this.formObj)

      /*
      if(this.formData.nazev_select == 3) {
        //console.log('dev variableForm', this.formData.nazev_select)
        //this.formObj.inputs.nazev_textara.display = false;
      }else{
        //this.formObj.inputs.nazev_textara.display = true;
      }
      */
    },
    prefillFormData(){
      console.log('form dev prefillFormData XXXX', this.formObj.body)
      for (const section_key in this.formObj.body) {
        for (const input_key in this.formObj.body[section_key].inputs) {
          console.log('dev ccc prefillFormData', input_key, this.formObj.body[section_key].inputs[input_key].val)
          this.formData[input_key] = this.formObj.body[section_key].inputs[input_key].val;
        }
      }
    }
  },
  watch: {
    formData: {
      handler(newVal, oldVal) {
        //Restaurace
        if(this.formObj) {
          if(this.formObj.internal_id == "restaurant-form") {
            //horni panel
            if (
                this.formObj.body.general
                && this.formObj.body.general.inputs
                && this.formObj.body.general.inputs.je_info_panel
            ) {
              if(this.formData['je_info_panel']){
                this.formObj.body.general.inputs.obsah_info_panel.display = true;
              }else{
                this.formObj.body.general.inputs.obsah_info_panel.display = false;
              }
            }
            //rozvoz
            if (
                this.formObj.body.settings
                && this.formObj.body.settings.inputs
                && this.formObj.body.settings.inputs.je_rozvoz
            ) {
              if(this.formData['je_rozvoz']){
                this.formObj.body.settings.inputs.rozvoz_url.display = true;
              }else{
                this.formObj.body.settings.inputs.rozvoz_url.display = false;
              }
            }
            //rezervace
            if (
                this.formObj.body.settings
                && this.formObj.body.settings.inputs
                && this.formObj.body.settings.inputs.je_rezervace
            ) {
              if(this.formData['je_rezervace']){
                this.formObj.body.settings.inputs.rezervace_ident.display = true;
              }else{
                this.formObj.body.settings.inputs.rezervace_ident.display = false;
              }
            }
          }

          //Jidelnicek
          if(this.formObj.internal_id == "restaurace-menu-form") {
            //pokud je nastaveno
            if (
                this.formObj.body.general
                && this.formObj.body.general.inputs
                && this.formObj.body.general.inputs.restaurace_id
            ) {
              if(this.formData['restaurace_id']){
                this.formObj.body.general.inputs.nazev.display = true;
                this.formObj.body.general.inputs.obsah.display = true;
                this.formObj.body.general.inputs.je_zobrazeno.display = true;
              }else{
                this.formObj.body.general.inputs.nazev.display = false;
                this.formObj.body.general.inputs.obsah.display = false;
                this.formObj.body.general.inputs.je_zobrazeno.display = false;
              }
            }

            if(this.formObj.internal_id == "jidlo-form") {
              console.log('dev watrch formdata je_zobrazeno', typeof this.formData['je_zobrazeno'])
              if(typeof this.formData['je_zobrazeno'] == "undefined") {
                this.formData['je_zobrazeno'] = this.formObj.body.general.inputs.je_zobrazeno.val;
              }
            }

            //data rozsah
            /*
            if(this.formData['typ_id'] == 2){
              this.formObj.body.general.inputs.date_range.display = true;
            }else{
              this.formObj.body.general.inputs.date_range.display = false;
            }
  */
          }

          //Obsah
          if(this.formObj.internal_id == "obsah-form") {
            //publikace obsahu
            if (this.formObj.body.publication && this.formObj.body.publication.inputs && this.formObj.body.publication.inputs.akce_publikace) {
              var akce_publikace_display = false;
              if(newVal.stav_id == 3){
                akce_publikace_display = true;
                this.formObj.body.publication.inputs.akce_publikace.val = true;
              }
              this.formObj.body.publication.inputs.akce_publikace.display = akce_publikace_display;
            }
          }
        }

        //this.$emit("update:modelValue", newVal);
        //this.variableForm();
        //
      },
      deep: true,
      immediate: true
    },
  },
  /*
  created() {
    console.log('XXXXXXX dev form general created')
    if(this.formObj) {
      console.log('dev form general created')
      this.prefillFormData();
      //this.prefillTempaltesFormData(this.$route.query.template);
    }
  },
  */
  mounted() {
    if(this.formObj) {
      this.prefillFormData();
      this.prefillTempaltesFormData(this.$route.query.template);
    }
  }
};
</script>