<template>
  <div
      v-if="inputObj.items && inputObj.items.length > 0"
      class="h-100 w-100 bg-light py-3 px-4 border rounded-3"
  >
    <h2 class="mb-3 fs-4 fw-medium text-secondary">
      {{inputObj.title}}
      <i v-if="inputObj.ico" :class="inputObj.ico + ' ms-2'"></i>
    </h2>
    <div class="d-grid d-md-flex flex-wrap">
      <div
          v-for="(shortcutItem, shortcutIndex)  in inputObj.items"
          @key="shortcutIndex"
          class="list-group-item me-2 mb-2"
      >
        <router-link
            :to="shortcutItem.link"
            class="d-block w-100 btn btn-warning btn-lg"
        >
          <i :class="shortcutItem.ico"></i>&nbsp;
          <span>{{shortcutItem.title}}</span>
        </router-link>
<!--
        <pre>{{shortcutItem}}</pre>
        -->
      </div>
    </div>
  </div>

</template>

<script>
export default {
  name: 'DashboardBoxShortcut',
  components: {
    // ModelListSelect
  },
  props: [
    "inputObj"
  ],
  data() {
    return {
      inputModel: null,
    }
  },
  methods: {
    /*
    setParentData(event) {
      console.log('dev Parent set data ', this.parentData.name)
      this.$emit("listenerChild", "I'm here my Daddy!");
    },
    */
  },
  created() {
  },
  watch: {
    inputModel( newVal){
      //this.$emit('update:modelValue', newVal ) // model vole!
    },
  },
}
</script>


