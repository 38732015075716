<template >
[ViewGeneral]

  <!--  -->
  <pre>ViewGeneral: {{dataObj}}</pre>

</template>

<script>

import axios from "axios";

export default {
  name: "ViewGeneral",
  props: {
    "dataObj": Object,
    "returnData": [Object, String, Number],
  },
  data() {
    return {
      inputModel: null,
    }
  },
  mounted() {

  },
  methods: {
  },
  watch: {

  },
};
</script>