<template >
  <div >
    <ul>
      <li
          v-for="(inputItem, index)  in inputObj.templates.data"
          @key="index"
          :ref="'checkbox_item' + inputItem.id"
          class="form-check"
      >
        <label :for="'radio_' + inputItem.id" class="form-check-label">
          <input
              v-model="inputModel"
              type="radio"
              :value="inputItem.id"
              :id="'radio_' + inputItem.id"
              class="form-check-input"
          />

          <span>{{inputItem.title}}</span>
        </label>
      </li>
    </ul>
  </div>

  <!--
        <pre>inputItem:: {{inputItem}}</pre>
<pre>inputObj:: {{inputModel}}</pre>
  -->
</template>
<script>

export default {
  name: "CheckboxMultiInput",
  props: {
    "inputObj": Object,
    "formObj": Object,
    "modelValue": [Array, Number],
  },
  data() {
    return {
      inputModel: null,
    }
  },
  mounted() {
      this.inputModel = this.inputObj.val;
  },
  methods: {
  },
  watch: {
    inputModel: {
      handler(newVal) {
        this.$emit('update:modelValue', newVal )
      },
      deep: true,
      immediate: true
    },
  },
};
</script>