<template >
  <VueMultiselect
      v-model="inputModel"
      :options="options"
      track-by="id"
      :placeholder="'Vyber hodnoty'"
      @open="asyncSearch"
      @search-change="asyncSearch"
  >
    <template v-if="meta.display == 'standart'" v-slot:singleLabel="{ option }">
      <span>{{ option.title }}</span>
    </template>

    <template v-if="meta.display == 'standart'" v-slot:option="{ option, search }">
      <span>{{option.title}}</span>
    </template>
  </VueMultiselect>



  <!--
  <display-debug>
    <template #debugView>inputModel {{inputModel}}</template>
  </display-debug>


  <display-debug>
    <template #debugView>meta {{meta.display}}</template>
  </display-debug>
  <VueMultiselect
      v-model="inputModel"
      :options="options"
      track-by="id"
      :placeholder="'Vyber hodnoty pro'+ inputObj.params.type"

      :custom-label="formatSelectItem"
  >

  </VueMultiselect>
  -->


</template>

<script>
import ImageComponent from "@/components/general/Image";
import VueMultiselect from 'vue-multiselect'
import axios from "axios";
import DateimePreview from "@/components/previews/DatePreview";

let timer;
export default {
  name: "SelectSmartSegment",
  components: {
    VueMultiselect,
    ImageComponent,
    DateimePreview,
  },
  props: {
    "meta": Object,
    "modelValue": [String, Object, Number],
  },
  emits: ['update:modelValue'],
  data() {
    return {
      options: [],
      selectModel: null,
      inputModel: null,
    }
  },
  mounted() {
    console.log("dev.asyncSearch mount", this.modelValue);

    if (this.modelValue) {
        this.loadDefaultData(this.modelValue);
    }
  },
  methods: {
    loadDefaultData(value){
      if (value) {
        axios.defaults.baseURL = this.getBaseRoot();
        let apiPath = '/class/' +  this.meta.modul + '/'+  this.meta.detail +'?id=' + value;
        axios({
          url: apiPath,
          method: "get",
          withCredentials: true,
          headers: this.getRequestHeader(),
        }).then((res) => {res.data.response.detail
          if(res.data.response.detail) {
            this.inputModel = res.data.response.detail;
          }
        }).catch((err) => {
          console.log("dev.detail catch", err);
        }).finally(() => {
          //console.log("DEV tags finally");
        })
      }else{
        this.inputModel = null;
      }
    },
    async asyncSearch (query) {
      if(timer) clearInterval(timer);
      timer = setTimeout(
          () => {

            axios.defaults.baseURL = this.getBaseRoot();
            let apiPath = '/class/' +  this.meta.modul + '/'+  this.meta.action
            if(query) apiPath += '?q=' + query;
            console.log("dev.asyncSearch start",apiPath, query)

            axios({
              url: apiPath,
              method: "get",
              withCredentials: true,
              headers: this.getRequestHeader(),
            }).then((res) => {
              console.log("dev.asyncSearch data", res.data.response.list.data)
              this.options = res.data.response.list.data;
            }).catch((err) => {
              this.options = [];
                console.log("dev.asyncSearch catch", err);
            }).finally(() => {
              //console.log("DEV tags finally");
            })
          },
          250
      )
    },
  },
  watch: {
    modelValue: {
      handler(newVal) {
        if(newVal){
          this.loadDefaultData(newVal);
        }
      },
      deep: true,
      immediate: true
    },

    inputModel: {
      handler(newVal) {
        if(newVal){
          this.$emit('update:modelValue', newVal.id )
        }else{
          this.$emit('update:modelValue', null )
        }
      },
      deep: true,
      immediate: true
    },

  },
};
</script>