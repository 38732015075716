<template>
  <header>
    <login-logic :user="user" v-model:user="user"></login-logic>
  </header>
  <main
    class="p-3"
  >
    <flash-messages
        v-if="getConfig('is_flash_message')"
    />

    <div v-if="!status.is_err">
      <div v-if="!status.is_loading">
        <component
            :ident="$route.params.modul"
            v-bind:is="moduleObj.component"
            :moduleData="moduleObj"
            v-model:reloadSignal="reloadSignal"
            :returnData="returnData"
            :route="$route"
        ></component>
      </div>
      <div
          v-else
          class="fullscreen-message d-flex align-items-center justify-content-center"
      >
        <div>
          <div class="text-center">
            <div class="spinner-border text-secondary" role="status">
              <span class="visually-hidden">Loading...</span>
            </div>
          </div>
          <span>Načítám obsah, chvilku vydržte</span>
        </div>
      </div>
    </div>
    <div  v-else-if="status.is_err" class="fullscreen-message d-flex align-items-center justify-content-center">
      <div class="alert alert-danger p-2">
        <div class="text-center">
          <i class="bi bi-exclamation-triangle-fill fs-1 text-danger"></i>
        </div>
        <span class="text-danger mt-2">{{status.err.message}}</span>
        <!--
        <pre>{{err}}</pre>
        -->
      </div>
    </div>
  </main>

  <section>
    <main-menu :user="user"></main-menu>
  </section>

<!-- DEV  FLASH
  <flash-messages></flash-messages>
  -->
  <offline-detect></offline-detect>
</template>
<script>
import loginLogic from "@/components/logic/Login";
import flashMessage from "@/components/general/FlashMessage";
import mainMenu from "@/components/general/MainMenu";
import flashMessages from "@/components/general/FlashMessages";
//content component
import DashboardModule from "@/modules/DashboardModule";
import ListModule from "@/modules/ListModule";
import FormModule from "@/modules/FormModule";
import StatsModule from "@/modules/StatsModule";
import StatsGalerieModule from "@/modules/StatsGalerieModule";
import ViewModule from "@/modules/ViewModule";
import offlineDetect from "@/components/general/OfflineDetect";
import axios from "axios";
export default {
  name: 'ContentView',
  components: {
    DashboardModule,
    ListModule,
    FormModule,
    StatsModule,
    StatsGalerieModule,
    ViewModule,
    loginLogic,
    flashMessages,
    mainMenu,
    offlineDetect,

  },
  data() {
    return {
      status:{
        is_loading: false,
        is_err: false,
        err: null,
      },
      reloadSignal: null,
      listFilters: null,
      moduleObj: {},
      user: {},
      err: {},
      contentLoadingStatus: false,
      routes: {},
      returnData: {},
      filterValues: {},
    }
  },
  methods: {
    async getModule(name){
      //console.log("dev CONTENT VIEW  getModule", name, this.listFilters)
      //sestaveni cesty k API endpointu
      let apiPath = "/class/" + this.$route.params.modul + "/" + this.$route.params.action;

      if (typeof this.$route.params.id != undefined && this.$route.params.id){
        apiPath += "/" + this.$route.params.id;
      }
      //povolene queries
      if (this.$route.query != undefined && this.$route.query){
        let pathQuery = []
        const qObj = this.$route.query;
        for (let key in qObj) {
         if(this.getConfig('allowed_query_names').includes(key)) {
           pathQuery.push(key + "=" + this.$route.query[key])
         }
        }

        //Filtry - start
        try {
          const filters = {};
          if(this.$route.params.action == 'list') {

            if(this.$route.query.filters){
              const queryJson = JSON.parse(this.$route.query.filters);
              for (const i in queryJson){
                if(queryJson[i]){
                  filters[i] = queryJson[i];
                }
              }
            }


            let filtersLocalStorage = localStorage.getItem(this.$route.params.modul + this.getConfig('filter_ident'))
            if(filtersLocalStorage){
              filtersLocalStorage = JSON.parse(filtersLocalStorage);
              for (const i in filtersLocalStorage){
                if(typeof filters[i] == "undefined"){
                  filters[i] = filtersLocalStorage[i];
                }
              }
            }

            this.filterValues = filters;

            let url =  '#' + this.$route.path ;
            let params = [];
            if(this.$route.query.page && this.$route.query.page > 1){
              params.push("page=" + this.$route.query.page);
            }
            //
            if(filters && Object.keys(filters).length > 0){
              params.push("filters=" + JSON.stringify(filters));
              pathQuery.push( "filters=" + JSON.stringify(filters)); //build path
            }
            if(params && params.length > 0){
              url += "?" + params.join('&');
            }


            //history.pushState({}, null, url);
          }

        }catch (error) {
          console.log('dev.listFilters LOAD ERR', error);
        }

        //build path
        if(pathQuery.length > 0) apiPath += "?" + pathQuery.join("&");
      }

        this.contentLoadingStatus = false; //TODO del
        this.status.is_loading = true;
        axios.defaults.baseURL = this.getBaseRoot();
        await axios({
          url: apiPath,
          method: "get",
          withCredentials: true,
          headers: this.getRequestHeader(),
        }).then((res) => {

          //console.log("dev getModule DATA v2", res.data.response.title)

          if (res.data.status) {
            this.status.is_err = false;
            this.moduleObj = res.data.response;
            //filter values
            if (this.moduleObj.filters){
              for (const f in this.moduleObj.filters) {
                //console.log('dev.listFilters LOAD DATA 2',f, this.filterValues[f], this.moduleObj.filters[f]);
                if (typeof this.filterValues[f] != "undefined"){
                  this.moduleObj.filters[f].value = this.filterValues[f];
                }
              }
            }

            this.moduleObj.filterValues = this.filterValues;
            //console.log('dev.listFilters LOAD DATA 1', this.moduleObj.filters);

            if (res.data.response.title) this.setDocumentTitle(res.data.response.title, res.data.response.modul);
          }else{
            this.status.is_err = true;
            this.status.err = res.data.err;
            //document.title = 'CHYBA!';
            this.setDocumentTitle('CHYBA!');
          }
        })
        .catch((err) => {
          this.status.is_err = true;
          //this.status.err = res.data.err;
          this.status.err = err;
          this.setDocumentTitle('CHYBA!(2)');
        })
        .finally(() => {


          //console.log("DEV finally", res);
          this.status.is_loading = false;
        })
    },
  },
  watch: {
    reloadSignal: {
      handler() {
        this.getModule('change signal');
      },
      immediate: true
    },

    '$route' (to, from) {
      this.getModule('change route');
    }
  },
  created() {
    //this.routes = this.$route.params;
    //this.getModule('created');
    //console.log("dev content created / route", this.$route.params, this.$route.query)
    //this.getModule();
  },
  mounted() {
    //console.log("dev content mounted / route", this.$route.params)
    //console.log('dev view mounted')
    //this.getModule();
  },
}
</script>