<template >
  <div
      v-for="(langItem, langIndex)  in inputObj.langs"
  >
    <!--
    <div v-html="langItem.svg" style="max-height: 30px;"></div>
    -->

    <label :for="inputObj.name + '_lang_' + langIndex">
      <country-flag
          :country='langItem.flag'
          size="small"
      />
      {{langItem.title}}
    </label>
    <quill-editor
        v-if="inputObj.type=='wysiwig'"
        v-model:content="inputModel[langIndex]"
        content-type="html"
        theme="snow"
    >
      <!--  :modules="modules" -->
    </quill-editor>
    <textarea
        v-else
        v-model="inputModel[langIndex]"
        :id="inputObj.name + '_lang_' + langIndex"
        :required="this.inputObj.required"
        class="form-control"
        @focus="is_locked=true;"
        :maxlength="(inputObj.params && inputObj.params.maxlength)? inputObj.params.maxlength : false"
    >
    </textarea>
  </div>
</template>

<script>
import axios from "axios";
import { QuillEditor } from '@vueup/vue-quill'
import { QuillPlaceholderModule } from 'quill-placeholder-module'
import {QuillHtmlEditButton} from "quill-html-edit-button";
import CountryFlag from "vue-country-flag-next"
let timer;
let timerModel;
export default {
  name: "TextareaInputLang",
  components: {
    QuillEditor,
    QuillPlaceholderModule,
    //QuillMention,
    QuillHtmlEditButton,
    CountryFlag,
  },
  props: {
    "inputObj": Object,
    "formObj": Object,
    "modelValue": Object, Array,
  },
  data() {
    return {
      inputModel: null,
      is_locked: false,
      timer: null,
      timer_delay: 700,
    }
  },
  mounted() {
    //console.log('dev textare anotace', this.formObj.general.inputs.segment.val)
    if(this.inputObj.val != '' && this.inputObj.val != null ) {
      this.is_locked = true;
    }
    this.inputModel = this.inputObj.val;
  },
  methods: {
    async createAnotationFromSegment(segment){
      axios.defaults.baseURL = this.getBaseRoot();
      let apiPath = this.getApiConfig('obsah.create_anotation.path') // this.cfg.api.obsah.create_anotation.path;
      let formData = new FormData;
      formData.append(this.getApiConfig('obsah.create_anotation.input.segment'), JSON.stringify(segment))
      axios({
        url: apiPath,
        method: this.getApiConfig('obsah.create_anotation.method'),
        data: formData,
        withCredentials: true,
        headers: this.getRequestHeader(),
      }).then((res) => {
        if(res.data.status && res.data.response.anotace){
          if (this.inputObj.params.maxlength &&  res.data.response.anotace.length > this.inputObj.params.maxlength) {
            this.inputModel = res.data.response.anotace.substring(0, this.inputObj.params.maxlength) + "..."
          } else {
            this.inputModel = res.data.response.anotace;
          }
        }
      })
      .catch((err) => {
        //console.log("DEV createAnotationFromSegment ERR ", err);
      })
    }
  },
  watch: {
    "formObj.general.inputs.segment.val": {
      handler(newVal) {
        if(this.inputObj.type && this.inputObj.type == "anotation" && !this.is_locked) {
          if(timer) clearInterval(timer);
          timer = setTimeout(
              () => {
                this.createAnotationFromSegment(newVal)
             },
              this.timer_delay
          )
        }
      },
      deep: true,
      immediate: true
    },
    inputObj: {
      handler() {
        this.inputModel = this.inputObj.val;
      },
      deep: true,
      immediate: true
    },
    inputModel: {
      handler(newVal) {
        if(timerModel) clearInterval(timerModel);
        timerModel = setTimeout(
            () => {
              this.$emit('update:modelValue', newVal )
            },
            this.timer_delay
        )
      },
      deep: true,
      immediate: true
    },
  },
};
</script>