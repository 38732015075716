<template>
  <div
      v-if="is_display"
      class="offline-wrap">

    <div class="d-flex align-items-center justify-content-center w-100 vh-100 bg-black text-white">
      <div class="text-white rounded-2 px-2 py-1">
        <div class="text-center">
          <i class="bi bi-wifi-off fs-1"></i>
        </div>
        <div class="mt-3">
          {{`getTranslate('general.offline')`}}
        </div>
      </div>

    </div>
  </div>

</template>

<script>
import axios from "axios";
import { gsap, Back, Power1 } from "gsap"
//import { useMouse, useWindowFocus, useElementVisibility } from '@vueuse/core'

export default {
  name: "OfflineDetect",
  components: [
    gsap,
    axios,
  ],
  data() {
    return {
      is_display: false,
    }
  },
  methods: {
    ringBell() {
      const bell = gsap.timeline({ repeat: 5, yoyo: true, onComplete: this.pauseBell });
      bell.to("#bell", { rotation: 20, duration: 0.15 })
          .to("#bell", { rotation: -20, duration: 0.15 });
    },

  },
  mounted() {

  },
  watch: {

  },
  created() {
    //console.log('dev.offline.detect created', navigator.onLine);

    window.addEventListener('online', function(e) {
      console.log('dev.offline.detect Prohlížeč je nyní online', e);
      this.is_display = false;
    });

    window.addEventListener('offline', function(e) {
      console.log('dev.offline.detect Prohlížeč je nyní offline', e);
      this.is_display = true;
    });
    //this.is_display = true;
    //console.log('dev.offline.detect is_display', this.is_display);


    let is_active_viewport;
/*
    window.addEventListener('focus', () => {
      //this.setViewPortActive(true)
      console.log('dev.offline.detect Okno má fokus (je aktivní).');
      is_active_viewport = true;
    });

    window.addEventListener('blur', () => {
      //this.setViewPortActive(false)
      console.log('dev.offline.detect  Okno ztratilo fokus (není aktivní).');
      //this.countdownObj.is_paused = true;
      is_active_viewport = false;
    });
*/
  }
};
</script>
<style scoped type="text/css">
.offline-wrap {
  position: absolute;
  z-index: 900;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: red;
}
</style>