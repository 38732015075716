const cfgGeneral = {
    "autologin_delay": 120000, //2minuty? @miliseconds 30000
    "messages_check_interval": 30000, //2minuty? @miliseconds 30000
    "login_header_name": "x-fifth-cms-user",
    "login_local_storage_name": "login_token",
    "lang_id_local_storage_name": "lang_token",
    "lang_id_default_value": "cz",
    "path": {
        "login_form": "/",
        "dashboard": "/dashboard"
    },
    "flash_messages":{
        "root_local_storage_name": "flashes_v_dev",
    },
    "allowed_query_names": [
        "id",
        "page",
        "order",
        "filter",
        "date",
    ],
    "venue": {
        "google_api_key": "AIzaSyDUo3liD5bxUo2NP33HR2bMkqxP7ihV_RA"
    },
    "modul": {
        "venue": {
            "filter_types": [
                "street_address",
                "point_of_interest",
                //"locality",
                "premise",
            ],
            "last_position_key": "venue_last_position_key_dev1",
            "default_position": {
                lat:49.19558079835405,
                lng: 16.605630092021983
            }
        },
    },
    "style": {
        "class_drag_n_drop": "ghost-drag-n-drop"
    }
}
export default cfgGeneral;