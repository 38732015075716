<template >
  <div >
    <input
        type="datetime-local"
        v-model="inputModel"
        :required="this.inputObj.required"
        @click="inputClick"
        class="form-control"
    />
  </div>
  <!--
  <pre>inputObj:: {{inputObj}}</pre>
  <pre>inputModel:: {{inputModel}}</pre>
  -->
</template>

<script>

export default {
  name: "DateTimeInput",
  props: {
    "inputObj": Object,
    "formObj": Object,
    "modelValue": [String, Number],
  },
  data() {
    return {
      inputModel: null,
      timer: null,
      timer_delay: 500,
    }
  },
  mounted() {
    this.inputModel = this.inputObj.val;
  },
  methods: {
    createSlugFormat(input){
      if(input) {
        input = input.toLowerCase().trim()
        input = input.replace(/[^\w\s-]/g, '')
        input = input.replace(/[\s_-]+/g, '-')
        input = input.replace(/^-+|-+$/g, '')
      }
      return input;
    },
    inputClick() {
      if(this.inputObj.slug && this.inputObj.slug.is_slug){
        this.inputObj.slug.disable_auto_fill = true;
      }
    }
  },
  watch: {
    inputObj: {
      handler() {
        this.inputModel = this.inputObj.val;
      },
      deep: true,
      immediate: true
    },
    inputModel( newVal){
      this.inputObj.val = newVal
      this.$emit('update:modelValue', newVal )
    },
  },
};
</script>