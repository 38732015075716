<template>
  <!--
  <h2>[title] {{moduleData.title}}</h2>
-->
  <list-general
      :ident="ident"
      :is_header="true"
      :is_animation="true"
      :is_paging="true"
      :is_filter="true"
      :list-obj="moduleData.list"
      :btn-obj="moduleData.list_btns"
      :filters="moduleData.filters"
      :modulName="moduleData.modul"
      v-model:reloadSignal="reloadSignalList"
      :config="config? config: defaultConfig"
  >
  </list-general>
</template>

<script>
import ListGeneral from "@/components/general/list/ListGeneral";
export default {
  name: "ListModule",
  components: {
    ListGeneral,

  },
  emits: [
    "update:reloadSignal",
    //"update:listFilters"
  ],
  props: {
    "ident": String,
    "route": Object,
    "filters": [Object, Array],
    "reloadSignal": [Object, Array, String],
    "moduleData": Object,
    "returnData": [Object, String, Number],
    "config": [Object],
  },
  data() {
    return {
      reloadSignalBox: null,
      reloadSignalList: null,
      listFilters: null,
      defaultConfig: {
        is_standalone: true,
        is_filter: true,
        is_paging: true,
        is_title: true,
        is_list_header: true,
        is_btn_prefill: true,
        is_unlimited: false,
      }
    }
  },
  methods: {
      reloadParent(val) {
        this.$emit("update:reloadSignal", val);
      }
  },
  watch: {
    reloadSignalBox: {
      handler(val) {
        this.reloadParent(val);
      },
      immediate: true
    },
    reloadSignalList: {
      handler(val) {
        this.reloadParent(val);
      },
      immediate: true
    },
    listFilters: {
      handler(val) {
        //this.$emit("update:listFilters", val);
      },
      immediate: true
    },
  },
  created() {
   // console.log("dev DashboardModule created x")
  },
  mounted() {
   // console.log("dev DashboardModule mounted x")
  }
};
</script>