import { createRouter, createWebHashHistory } from 'vue-router'
import LoginView from '@/views//LoginView.vue';
import DashboardView from "@/views/DashboardView";
import ContentView from "@/views/ContentView";
import NotFound from "@/views/NotFoundView";
import PasswordReset from "@/views/PasswordReset";
import Vyvojove from "@/views/VyvojoveView";
const routes = [
  {
    path: '/',
    name: 'login',
    component: LoginView
  },
  {
    path: '/dashboard',
    name: 'dashboard',
    component: DashboardView
  },
  {
    path: '/dev-grafika67',
    name: 'vyvoj',
    component: Vyvojove
  },
  {
    path: '/password-reset',
    name: 'passwordReset',
    component: PasswordReset
  },
  {
    path: '/password-reset/:user_id(\\d+)/:hash',
    name: 'passwordResetSubmited',
    component: PasswordReset
  },

  {
    path: '/:modul/:action/:id(\\d+)',
    name: 'adminInterfaceItem',
    component: ContentView
  },
  {
    path: '/:modul/:action/page/?:page(\\d+)',
    name: 'adminInterfaceList',
    component: ContentView
  },
  {
    path: '/:modul/:action/page',
    name: 'adminInterfaceListFirstPage',
    params: {
      page: "xxx"
    },
    component: ContentView
  },
  {
    path: '/:modul/:action',
    name: 'adminInterfaceDashboard',
    component: ContentView
  },
  {
    path: '/:pathMatch(.*)*',
    name: 'NotFound',
    component: NotFound
  },
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

export default router
