<template>
  <div class="h-100 p-4 pt-3 bg-white border rounded-3">
    <h2 class="mb-3 fs-4 fw-medium text-secondary">
      {{inputObj.title}}
      <i v-if="inputObj.ico" :class="inputObj.ico + ' ms-2'"></i>
    </h2>
    <p v-html="inputObj.content"></p>
  </div>
</template>

<script>
export default {
  name: 'DashboardBoxText',
  components: {
    // ModelListSelect
  },
  props: [
    "inputObj"
  ],
  data() {
    return {
      inputModel: null,
    }
  },
  methods: {
    /*
    setParentData(event) {
      console.log('dev Parent set data ', this.parentData.name)
      this.$emit("listenerChild", "I'm here my Daddy!");
    },
    */
  },
  created() {
  },
  watch: {
    inputModel( newVal){
      //this.$emit('update:modelValue', newVal ) // model vole!
    },
  },
}
</script>


