<template >
   <div v-if="inputModel && Object.entries(inputModel).length > 0">
    <div
        v-for="(item, index) in inputModel"
        @key="index"
        class="input-group w-100 mb-2"
    >

      <label :for="'meta_' + index" class="input-group-text">
        <i v-if="item.ico" :class="item.ico+' me-2'"></i>
        {{item.title}}
      </label>

      <input
        v-if="item.type == 'varchar'"
        v-model="item.value"
        type="text"
        class="form-control"
        :id="'meta_' + index"
      >
      <input
          v-else-if="item.type == 'int'"
          v-model="item.value"
          type="number"
          class="form-control"
          :id="'meta_' + index"
      >
      <input
          v-else-if="item.type == 'datetime'"
          v-model="item.value"
          type="datetime-local"
          class="form-control"
          :id="'meta_' + index"
      >
      <div
          v-else-if="item.type == 'range'"
        class="form-control"
      >
        <!--        -->
        <div class="w-100 input-group">

          <input
              v-model="item.value"
              type="range"
              :min="item.params.from"
              :max="item.params.to"
              :step="item.params.step"
              class="form-range form-control border-0"
              :id="'meta_' + index"
          >
          <div class="form-label input-group-append ms-2">{{item.value}}</div>
        </div>


      </div>
      <div
          v-else-if="item.type == 'select'"
          class="form-control"
      >
        <select
          v-model="item.value"
          class="form-select"
          :id="'meta_' + index"
        >
          <option
              v-for="(item, index) in item.params"
              @key="index"
              :value="item"
          >
            {{item.title}}
          </option>
        </select>
      </div>
      <div
          v-else-if="item.type == 'bool'"
          class="form-control form-check form-switch "
      >
        <div class="ps-3">
        <input
            v-model="item.value"
            class="form-check-input"
            type="checkbox"
            role="switch"
            :id="'meta_' + index"
        />
      </div>
      </div>

      <span
          @click.prevent="removeMetaItem(index)"
          class="input-group-text" style="background: none;"
      >
        <i class="bi bi-trash text-secondary"></i>
      </span>
    </div>

  </div>

  <!--
    <div v-else class="alert alert-danger fs-1">
      zadne meta informace
    <>
  <pre>{{inputModel}}</pre>



  <multiselect
      v-model="selectModel"
      :options="options"
      placeholder="Vyhledejte položku"
  >
  </multiselect>
 -->

  <multiselect
      v-model="selectModel"
      :options="optionsArr"
      track-by="ident"
      :searchable="true"
      :hideSelected="false"
      :custom-label="formatSelectItem"
      label="title"
      placeholder="Vyhledejte položku"
  >
    <template v-slot:singleLabel="{ option }">
      <div class="d-flex align-items-center">
        <i v-if="option.ico" :class="option.ico+' me-2'"></i>
        <span>{{option.title}}</span>
      </div>
    </template>

    <template v-slot:option="{ option, search }">
      <div class="d-flex align-items-center">
        <i v-if="option.ico" :class="option.ico+' me-2'"></i>
        <span>{{option.title}}</span>
      </div>
    </template>

    <template v-slot:noResult>
      <div class="multiselect--no-results">Zadne vysledky pro tento dotaz</div>
    </template>

    <template v-slot:noOptions>
      <div class="multiselect--no-results">Zadne vysledky pro tento dotaz</div>
    </template>
  </multiselect>

<!--
  <select
      v-model="selectModel"
      class="form-select"
      placeholder="Vyberte meta informaci"
  >
    <option
        v-for="(item, index) in options"
        @key="index"
        :value="item"
    >
      {{item.title}}
    </option>
  </select>
-->
</template>

<script>
import multiselect  from 'vue-multiselect'
export default {
  name: "TextInput",
  components: {
    multiselect,
  },
  props: {
    "inputObj": Object,
    "formObj": Object,
    "modelValue": [String, Number],
  },
  data() {
    return {
      inputModel: {},
      selectModel: null,
      options:[],
      optionsArr:[],
    }
  },
  mounted() {
    if(this.inputObj.templates && this.inputObj.templates.data) {
      this.options = this.inputObj.templates.data;
      for (let optionKey in this.inputObj.templates.data) {
        this.optionsArr.push(this.inputObj.templates.data[optionKey])
        //console.log('dev option arr X', optionKey, this.inputObj.templates.data[optionKey])
      }
    }

    if(this.inputObj.val) {
      const inputData = JSON.parse(this.inputObj.val)
      //const inputData = this.inputObj.val
      for (let metaItemKey in inputData) {
        //inputData[metaItemKey].start = true;
        inputData[metaItemKey].title = this.getMetaTitle(metaItemKey);
        inputData[metaItemKey].type = this.getMetaType(metaItemKey);
        inputData[metaItemKey].ico = this.getMetaIco(metaItemKey);
        inputData[metaItemKey].params = this.getMetaParams(metaItemKey);
        this.inputModel[metaItemKey] = inputData[metaItemKey]

      }
    }
  },
  methods: {
    getMetaType(index) {
      return this.options[index].type;
    },
    getMetaTitle(index) {
      return this.options[index].title;
    },
    getMetaIco(index) {
      return this.options[index].ico;
    },
    getMetaParams(index) {
      return this.options[index].params;
    },
    removeMetaItem(index) {
      if (confirm("Opravdu chcete odstranit tuto meta informaci?") === true){
        delete this.inputModel[index];
      }
    },
    formatSelectItem ({ id, title }) {
      return `${title}  ${id}`
    },
  },


  watch: {
    selectModel: {
      handler(newVal) {
        if(newVal){
          if(typeof this.inputModel[newVal.ident] == "undefined"){
            this.inputModel[newVal.ident] = {
              title: newVal.title,
              type: newVal.type,
              ico: newVal.ico,
              params: newVal.params,
              value: null,
            };
            this.selectModel = null;
          }else{
            newVal = null;
            alert("Tato meta informace nejde pridat, jiz je nastavena!")
          }

        }
      },
      deep: true,
      immediate: true
    },
    inputModel: {
      handler(newVal) {
        this.$emit('update:modelValue', JSON.stringify(newVal))
      },
      deep: true,
      immediate: true
    },
  },
};
</script>