<template>
  <component
      v-bind:is="moduleData.view"
      :dataObj="moduleData.data"
      v-model:reloadSignal="reloadSignalList"
  ></component>

  <!--
  [--component start2]
  <view-custom
      :dataObj="moduleData.data"
  >

  </view-custom>
  [--component end2]


  <h2>{{moduleData.title}}</h2>






  -->
  <!--
  [component start]
  <component
      v-bind:is="moduleData.view"
      :dataObj="moduleData.data"
  ></component>

  [component end]
  -->
  <!--
        :dataObj="moduleData.data"
  <form-general
      form-title="Nazev formulare"
      :form-obj="moduleData.form"
      original-path="$router.currentRoute.originalPath"

  >
  </form-general>
  -->
</template>

<script>
import { defineAsyncComponent } from 'vue';
import ViewGeneral from "@/components/general/view/ViewGeneral";
import ViewOrder from "@/components/general/view/ViewOrder";
import ViewTimeline from "@/components/general/view/ViewTimeline";
import ViewReservations from "@/components/general/view/ViewReservations";

import ViewFlashMessageDetail from "@/components/general/view/ViewFlashMessageDetail";
import ViewFlashMessageUserList from "@/components/general/view/ViewFlashMessageUserList";

export default {
  name: "ViewModule",
  components: {
    ViewGeneral,
    ViewOrder,
    ViewTimeline,
    ViewReservations,
    ViewFlashMessageDetail,
    ViewFlashMessageUserList
    /*
    LatestBox: defineAsyncComponent(() => import('@/components/general/view/ViewOrder.vue')),
    ViewCustom: () => import('@/components/general/view/'+ 'ViewOrder' +'.vue').catch((error) => {
      console.error('Chyba při načítání komponenty:', error); //this.moduleData.view
    })*/
  },
  emits: [
    "update:reloadSignal"
  ],
  props: {
    "ident": String,
    "reloadSignal": String,
    "moduleData": Object,
    "route": Object,
    "config": [Object],
  },
  data() {
    return {
      myComponentName: 'Goal', // Název komponenty uložený v proměnné
      reloadSignalBox: null,
      reloadSignalList: null
    }
  },
  methods: {
      reloadParent(val) {
        this.$emit("update:reloadSignal", val);
      }
  },
  watch: {
    reloadSignalBox: {
      handler(val) {
        //this.reloadParent(val);
      },
      immediate: true
    },
    reloadSignalList: {
      handler(val) {
        this.reloadParent(val);
      },
      immediate: true
    },
  },
  created() {

  },
  mounted() {
   // console.log("dev DashboardModule mounted x")
  }
};
</script>