<template >
  <pre>modelValue {{modelValue}}</pre>

  <div class="d-inline-block border border-success ms-3"
       v-if="itemObj && itemObj.length > 0"
       v-for="(item, index)  in itemObj"
       @key="index"
  >
    <!--
  <pre>[ITEMx ] itemObj: {{itemObj}}</pre>
    <pre>{{item}}</pre>
    -->
    <label>
      <pre>#{{item.id}} modelValue {{modelValue}}</pre>
      <input
        v-model="inputModel"
        type="radio"
        :value="item.id"
      >
      #{{item.id}} {{item.label}}
    </label>

    <tree-select-custom-item
        v-if="item.children && item.children.length > 0"
        :item-obj="item.children"
        :model-value="modelValue"
    />
  </div>
</template>

<script>
import TreeSelectItem from '@/components/general/form/inputs/TreeSelectCustom/TreeSelectCustomItem'
export default {
  name: "TreeSelectCustomItem",
  props: {
    "itemObj": Object,
    "modelValue": [String, Number],
  },
  components: {
    TreeSelectItem,
  },
  emits: ['update:modelValue'],
  data() {
    return {
      inputModel: null,
    }
  },
  mounted() {
    this.inputModel = this.modelValue;
  },
  methods: {
  },
  watch: {
    inputModel: {
      handler(newVal) {
        //console.log("TREE_CHANGE inputModel", this.itemObj.id, newVal)
        //this.modelValue = newVal
        this.$emit('update:modelValue', newVal )
      },
      deep: true,
      immediate: true
    },

    modelValue: {
      handler(newVal) {
        console.log("TREE_CHANGE modelValue", this.itemObj[0].id, newVal)
        //this.$emit('update:modelValue', newVal )
      },
      deep: true,
      immediate: true
    },
/*
    xmodelValue( newVal){

    },
    xinputModel( newVal){
      //this.inputObj.val = newVal;
      this.$emit('update:modelValue', newVal )
    },

     */
  },
};
</script>