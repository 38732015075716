<template >
  <div class="form-check form-switch">
    <input
        v-model="inputModel"
        class="form-check-input"
        type="checkbox"
        role="switch"
        :id="inputObj.name + '_switch'"
        :disabled="inputObj.readonly"
    >
    <label
        v-if="inputObj.templates && inputObj.templates.title"
        class="form-check-label"
        :for="inputObj.name + '_switch'"
    >
      {{ inputObj.templates.title }}
    </label>
  </div>

  <!--
  <div >
    <input type="radio" v-model="inputModel" :id="inputObj.name + '_true'" value="1" ref="boolTrue" :required="this.inputObj.required"><label :for="inputObj.name + '_true'">Ano</label>
    <input type="radio" v-model="inputModel" :id="inputObj.name + '_false'" value="0" ref="boolFalse" :required="this.inputObj.required"><label :for="inputObj.name + '_false'">Ne</label>
  </div>

  -->

</template>

<script>

export default {
  name: "BooleanInput",
  props: {
    "inputObj": Object,
    "formObj": Object,
    "modelValue": [Boolean, Number, String],
  },
  data() {
    return {
      inputModel: null,
      timer: null,
      timer_delay: 500,
    }
  },
  mounted() {
    if(this.inputObj.val == 1 || this.inputObj.val == true ) {
      this.inputModel = true;
    }else{
      this.inputModel = false;
    }
    this.$emit('update:modelValue', this.inputModel )
  },


  methods: {
  },
  watch: {
    inputObj: {
      handler() {
        this.inputModel = this.inputObj.val;
      },
      deep: true,
      immediate: true
    },
    inputModel( newVal){
      this.$emit('update:modelValue', newVal )
    },
  },
};
</script>