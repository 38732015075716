<template>

<div class="border border-danger p-3 my-5">
  [htmlEditor]
  <div
      v-if="displayHtmlEditor"
  >

    <div
    v-if="btnList"
    >
      <div
        v-for="(btn, index) in btnList"
        :class="'btn btn-sm btn-outline-primary me-1 mb-1 ' + ((btn.is_for_selection && !cursor.selection)? ' disabled':'')"
      >
        <span
            @click.prevent="btnToolAction(btn)"
        >
          {{btn.title}}
        </span>
      </div>



    </div>

    <span @click.prevent="searchAndSelect();" class="btn btn-sm btn-primary mb-2">dev seach and select</span>


    <div
        ref="htmlEditorBody"
        v-html="inputModel"
        contenteditable="true"
        class="border border-secondary p-3"
        @input="updateInputModel"
        @click="checkCursor(); checkTag()"
        @keyup="checkCursor(); checkTag()"
        @mouseup="checkCursor(); checkTag()"
        @paste="handlePaste()"
    />






    <div
        v-if="tagDialog && tagDialog.display"
        class="bg bg-warning"
    >
      <display-debug>
        <template #debugView>tagDialog {{tagDialog}}</template>
      </display-debug>
    </div>


  </div>
  <div
      v-else
  >
    <textarea
        v-model="inputModel"
        class="form-control w-100 h-25"
    />
  </div>
  <div
      @click.prevent="displayHtmlEditor=!displayHtmlEditor"
      class="btn btn-sm btn-outline-primary"

  >
    <span v-if="displayHtmlEditor">display HTML</span>
    <span v-else>display EDITOR</span>
  </div>

  <display-debug>
    <template #debugView>cursor {{cursor}}</template>
  </display-debug>



  <display-debug>
    <template #debugView>modelValue {{modelValue}}</template>
  </display-debug>
</div>


</template>

<script>
import {type} from "@vueuse/core/index";

let timer;
const actions = {
  a: {
    'title': 'Odkaz',
    'tag': 'a',
    'is_for_selection': true,
    "dialog": {
      "href": {
        "title": "Odkaz",
        "type": "text",
        "is_required": true,
      },
      "target": {
        "title": "Cil",
        "type": "select",
        "select_options": [
          {
            id: "_self",
            value: "_self",
          },
          {
            id: "_blank",
            value: "_blank",
          },

        ],
        "is_required": false,
      },
      "class": {
        "title": "CSS trida",
        "type": "text",
        "is_required": false,
      }
    }
  },
  strong: {
    'title': 'Tucne',
    'tag': 'strong ',
    'is_for_selection': false,
    "dialog": {
      "class": {
        "title": "CSS trida",
        "type": "text",
        "is_required": false,
      }
    }
  },
  testovaci: {
    'title': 'Testovaci',
    'content': '<span class="bg-warning">[xxxx]</span>',

  },
};
import axios from "axios";

export default {
  name: "htmlEditor",
  props: {
    "modelValue": {
      type: [String, Number],
      required: false,
    },

  },
  emits: ['update:modelValue'],
  components: {

  },
  data() {
    return {
      cursor: {
        active: false,
        start: null,
        end: null,
        selection: null,
      },

      //old
      refBody: null,
      inputModel: null,
      displayHtmlEditor: true,
      tagDialog: {
        display: false,
      },
      selection: {
        active: false,
      },




    }
  },
  created() {

  },
  mounted() {
    //this.inputModel = this.modelValue;
    this.inputModel = 'pet <strong>Muradovův advokát</strong> <a href="https://seznam.cz">Václavek</a> chce jít kvůli případu k soudu. Tvrdí, že jeho klient je poškozený na svých právech. Muradov má v Česku se zpěvačkou Monikou Bagárovou čtyřletou dceru,<br><hr/> se kterou se stýká a chce se o ni nadále starat. Se svou současnou partnerkou žije ve společné domácnosti. Nakonec <strong>Muradovův advokát</strong> opravdu konec.';
    this.refBody = this.$refs.htmlEditorBody;

  },

  computed: {
    btnList() {
      return actions;
    },
    editorObj() {
      return this.$refs.htmlEditorBody;
    },
  },

  methods: {
    searchAndSelect() {
      console.log('[dev.html] searchAndSelect')
      //dceru
      const text = 'dceru';
      //const text = 'et';
      //const text = '<a href="https://seznam.cz">Václavek</a>';
      //const text = '<strong>Muradovův advokát</strong>';
      //this.editorObj.setSelectionRange(0, 2);

      //let startIndex = this.editorObj.innerHTML.indexOf(text);
      //let endIndex = startIndex + text.length;

      let startIndex = 0;
      let endIndex = 4;

      console.log('[dev.html] searchAndSelect', startIndex,endIndex)//, this.editorObj.innerHTML


      if (startIndex !== -1) {
        //const range = document.createRange();
        const range = new Range();
        console.log('[dev.html] node', this.editorObj)


        range.setStart(this.editorObj, startIndex);
        range.setEnd(this.editorObj, endIndex)

        console.log('[dev.html] range', range)

        //selection
        const sel = window.getSelection();
        sel.removeAllRanges();
        sel.addRange(range);
        console.log('[dev.html] sel', sel)
        /*


        let sel = window.getSelection();
        range.setStart(contentEditableDiv.firstChild, startIndex);
        range.setEnd(contentEditableDiv.firstChild, startIndex + text.length);
        sel.removeAllRanges();
        sel.addRange(range);
        */

      }

      //this.editorObj.focus();

      /*
      let textarea = this.editorObj;
      let textValue = textarea.value;
      let startIndex = textValue.indexOf(text);
      if (startIndex !== -1) {
        textarea.focus();
        textarea.setSelectionRange(startIndex, startIndex + text.length);
      }
*/

      //this.devSelectText('dceru')

    },

    updateInputModel(event) {
      this.inputModel = event.target.innerHTML;
      //todo format nl2br?
    },

    checkCursor() {
      const el = this.editorObj;
      let cursorPos = 0;
      const sel = window.getSelection();
      if (sel.rangeCount) {
        const range = sel.getRangeAt(0);
        const preCaretRange = range.cloneRange();
        preCaretRange.selectNodeContents(el);
        preCaretRange.setEnd(range.endContainer, range.endOffset);
        cursorPos = preCaretRange.toString().length;
      }
      this.cursor.start = cursorPos;
      this.cursor.end = cursorPos;
      this.cursor.selection = null
      console.log('[dev.html] checkCursor [html]', cursorPos);

//v2
      const contentEle = this.editorObj;
      const selection = window.getSelection();
      const range = selection.getRangeAt(0);
      const clonedRange = range.cloneRange();
      clonedRange.selectNodeContents(contentEle);
      clonedRange.setEnd(range.endContainer, range.endOffset);

      //const cursorPosition = clonedRange.toString().length;
      console.log('[dev.html] checkCursor [html][v2]', clonedRange.toString().length);




    },
    checkTag() {

      const selection = window.getSelection();

      if (selection.rangeCount > 0) {
        const range = selection.getRangeAt(0);
        const selectedNode = range.startContainer;

        const clonedRange = range.cloneRange();
        clonedRange.selectNodeContents(this.editorObj);
        clonedRange.setEnd(range.endContainer, range.endOffset);


        if(selectedNode.parentNode.innerHTML != this.editorObj.innerHTML) {
          console.log('[dev.html] checkTag selectedNode', selectedNode.parentNode.outerHTML, selectedNode.parentNode.innerHTML)
        }
      }
    },

    devSelectText(text) {
      let node = this.editorObj;
      if (document.body.createTextRange) { // pro IE
        const range = document.body.createTextRange();
        range.moveToElementText(node);
        range.findText(text);
        range.select();
      } else if (window.getSelection) { // ostatní prohlížeče
        const selection = window.getSelection();
        const range = document.createRange();
        range.selectNodeContents(node);
        if (range.toString().indexOf(text) !== -1) {
          const textNode = document.createTextNode(node.innerText);
          let i = range.toString().indexOf(text);
          range.setStart(textNode, i);
          range.setEnd(textNode, i + text.length);
          selection.removeAllRanges();
          selection.addRange(range);
        }
      }
    },


    checkSelection() {
      const selection = window.getSelection();

      if (selection.rangeCount > 0) {
        const range = selection.getRangeAt(0);
        const selectedText = range.toString();
        if (selectedText.length > 0) {
          this.cursor.selection = selectedText
          this.cursor.start = this.cursor.end - selectedText.length;
        } else {
          this.cursor.selection = null;
        }
      }
    },
    btnToolAction(config){

      if(typeof config.dialog !="undefined"){
        console.log('[dev.html] btnToolAction DIALOG', config);
        //todo otevreni dialogu
      }else{
        this.createNewTag(config)
        console.log('[dev.html] btnToolAction SIMPLE', config);
      }
    },

    createNewTag(config) {
      let elementString;
      if(typeof config.tag !="undefined"){
        console.log('[dev.html] createNewTag TAG', config, this.cursor);
      }else if(typeof config.content !="undefined") {
        elementString = config.content
        console.log('[dev.html] createNewTag CONTENT', config.content, this.cursor);
      }

      if(elementString) {
        if(this.cursor.selection){
          //todo nahrazeni
          console.log('[dev.html] createNewTag SELECTION', elementString);
        }else if(this.cursor.start && this.cursor.start == this.cursor.end) {
          let selection = window.getSelection().getRangeAt(0);
          let selectedText = selection.extractContents();
          const span = document.createElement("span");
          span.classList.add('bg-danger');
          span.innerText = '[test vkladani]';

          console.log('[dev.html] createNewTag INSERT selectedText', selectedText);

          const space = document.createTextNode(' ');
          selection.insertNode(space);

          span.appendChild(selectedText);
          selection.insertNode(span);



          console.log('[dev.html] createNewTag INSERT', selection.endContainer);


          //this.inputModel = this.inputModel.slice(0, this.cursor.start) + elementString + this.inputModel.slice(this.cursor.start);
        }

      }

    },

    handlePaste(event) {
      const clipboardData = event.clipboardData || window.clipboardData;
      const pastedData = clipboardData.getData('Text');
      console.log('[dev.html] paste',pastedData);
      // Zde můžete zpracovat vložená data
    },


    //--------- old ------------



    getSelection() {
      const selection = window.getSelection();
      if (selection.rangeCount > 0) {
        const range = selection.getRangeAt(0);
        const selectedText = range.toString();
        if (selectedText.length > 0) {
          this.selection ={
            active: true,
            text: selectedText,
            start: this.getSelectStartPosition(range),
            lenght: selectedText.length ,
          }
        } else {
          this.selection ={
            active: false
          }
        }
      }
    },
    getSelectStartPosition(range) {
      const preCaretRange = range.cloneRange();
      preCaretRange.selectNodeContents(this.$refs.htmlEditorBody);
      preCaretRange.setEnd(range.startContainer, range.startOffset);
      return preCaretRange.toString().length;
    },



    creatNewTag(tagDefiniton){
      console.log('[dev.html] tagDefiniton', tagDefiniton.dialog.tag);
      let element = document.createElement(tagDefiniton.dialog.tag);
      element.id = 'myId';
      element.className = 'myClass';
      element.style.color = 'blue';

      console.log('[dev.html] tagDefiniton', element.outerHTML);


      let newTag = "<i>" + this.selection.text + "<\i>";
      console.log('[dev.html] newTag:', newTag);
      console.log('[dev.html] creatNewTag:', tagDefiniton);
      console.log('[dev.html] selection:', this.selection);

      let regex = new RegExp(`(\\b)${this.selection.text }(\\b)`, 'g');
      //let newText = text.replace(regex, nova);

      //this.inputModel = this.inputModel.replace(regex, newTag);
    },


    checkCursorTag() {
      const tag = this.getCursorTag();
      //console.log('[dev.html] Kurzor je v tagu:', tag);
    },
    openTagDialog(tag, position = null) {
      const parser = new DOMParser();
      const parsedEl = parser.parseFromString(tag, 'text/html');
      const rootElement = parsedEl.body.firstChild;
      let attributes = {};
      for (let attr of rootElement.attributes) {
        attributes[attr.name] = attr.value;
      }

      this.tagDialog = {
        display: true,
        origin_text: tag,
        position: position,
        doc: {
          text: parsedEl.body.textContent,
          root: rootElement.tagName.toLowerCase(),
          attributes: attributes
        },
        form: this.getActionDefinition(rootElement.tagName),
      };
      console.log('[dev.html] openTagDialog', tag, position);
    },

    getActionDefinition(ident) {
      ident = ident.toLowerCase();
      if (typeof actions != "undefined" && typeof actions[ident] != "undefined"){
          return actions[ident];
      }
    },




    getCursorTag() {
      const editableDiv = this.$refs.htmlEditorBody;
      const selection = window.getSelection();

      if (selection.rangeCount > 0) {
        const range = selection.getRangeAt(0);
        const selectedNode = range.startContainer;

        const clonedRange = range.cloneRange();
        clonedRange.selectNodeContents(editableDiv);
        clonedRange.setEnd(range.endContainer, range.endOffset);

        this.cursor.start = clonedRange.toString().length - selection.baseOffset;


        if(selectedNode.parentNode.outerHTML != editableDiv.outerHTML) {
          this.openTagDialog(
              selectedNode.parentNode.outerHTML,
              (clonedRange.toString().length - selection.baseOffset)
          )
        }
      }
    }
  },
  watch: {
    inputModel: {
      handler(newVal) {
        if(timer) clearInterval(timer);
        timer = setTimeout(
            () => {

              this.$emit('update:modelValue', newVal )
            },
            300
        )
      },
      deep: true,
      immediate: true
    },
  },
}
</script>