<template>
  <image-component
      v-model="inputModel"
      :type-id="inputObj.params.image_type_id"
      :is_browser="true"
      :is_upload="true"
      :is_image_props="true"
      :is_input_title="true"
      :is_input_copyright="true"
      :is_delete="true"
      :is_preview_only="false"
      thumb_class="nahled"
  />
</template>



<script>
import ImageComponent from "@/components/general/Image";
export default {
  name: "ImageInput",
  components: {
    ImageComponent,
  },
  props: {
    "inputObj": Object,
    "formObj": Object,
    "modelValue": [String, Number],
  },
  data() {
    return {
      inputModel: 20,
    }
  },
  mounted() {
   this.inputModel = this.inputObj.val;
  },
  methods: {
  },
  watch: {
    inputObj: {
      handler() {
        this.inputModel = this.inputObj.val;
      },
      deep: true,
      immediate: true
    },
    inputModel( newVal){
      this.$emit('update:modelValue', newVal )
    },
  },
};
</script>