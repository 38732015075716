<template>
  <div class="list-general-wrap">
    <div class=" mb-2" v-if="listObj.title">
      <h2
          v-if="config.is_title"
          class="fw-bolder text-secondary">
        {{listObj.title}}
      </h2>
      <div
          v-if="btnObj"
          class=""
      >
        <router-link
            v-if="config.is_btn_prefill"
            v-for="(btnItem, btnIndex)  in btnObj"
            @key="btnIndex"
            :to="btnItem.path"
            class="btn btn-warning me-2 mb-2 fw-medium"
        >
          <i v-if="btnItem.ico" :class="btnItem.ico + '  me-1'"></i>
          <span v-if="btnItem.title">{{btnItem.title}}</span>
        </router-link>

        <button
            type="button"
            @click="reloadData"
            class="btn btn-warning fw-medium mb-2"
            title="Obnovit data"
        >
          <i class="bi bi-arrow-repeat me-1"></i>
          <span>Obnovit</span>
        </button>
      </div>
    </div>

    <list-filter
      v-if="filters && is_filter && config.is_filter"
      :filter-data="filters"
      :ident="ident"
      v-model:reloadFilter="dataFilter"
    />



    <div
        v-if="listObj.pocet > 0"
        class="container-fluid table-responsive"
    >
      <!-- -->
      <list-paging
          type="no-swiper"
          :paging-data="listObj.paging"
      />

      <table
          class="table table-hover align-middle w-100"
      >
        <thead
            v-if="is_header && listObj.header && config.is_list_header"
            class="table-secondary"
            style="font-size: .85rem;"
        >
          <tr>
            <template
              v-for="(headerItem, headerIndex)  in listObj.header"
              @key="headerIndex"
            >
              <td
                  v-if="headerIndex != '__actions'"
                  :class="((headerItem.data.class_head)? ' ' + headerItem.data.class_head: '')"
              >
                <span class="title" v-html="headerItem.data.title"/>
              </td>
            </template>

          </tr>
        </thead>

        <tbody>
        <tr
            v-for="(row, rowIndex)  in listObj.data"
            @key="rowIndex"
            class="animated-list-row"
        >
          <!-- todo __actions -->
          <template
              v-for="(cell, cellIndex)  in row"
              @key="cellIndex"
          >
            <td

                v-if="cellIndex != '__actions'"
                :class="'list-animation' + ((listObj.header[cellIndex].data.class)? ' ' + listObj.header[cellIndex].data.class: '')"
            >
            <span
                v-if="listObj.header[cellIndex].data.type == 'id'"
                class="text-sm-center"
            >
              <span>#{{cell}}</span>
            </span>
            <span
                  v-else-if="listObj.header[cellIndex].data.type == 'text'"
                  class="text-sm-center"
              >
              {{cell}}
            </span>
              <span
                  v-else-if="listObj.header[cellIndex].data.type == 'slug'"
                  class=""
              >
                <span class="cms5-display-slug" :title="cell">{{cell}}</span>
            </span>

              <span
                  v-else-if="listObj.header[cellIndex].data.type == 'datum_cas'"
                  class="cell-date"
              >
              <span v-format-date:custom.time="cell"></span>

              <!--
              <dateime-preview
                  :value="cell"
                  :is_date="true"
                  :is_time="true"
              />
                -->
            </span>
              <span
                  v-else-if="listObj.header[cellIndex].data.type == 'datum'"
                  class="cell-date"
              >
              <span v-format-date="cell"></span>

            </span>
              <span
                  v-else-if="listObj.header[cellIndex].data.type == 'cas'"
                  class="cell-date"
              >
              <span v-format-date:custom.time.timeOnly="cell"></span>
               <!--
              <dateime-preview
                  :value="cell"
                  :is_time="true"
              />
                -->
            </span>

              <span
                  v-else-if="listObj.header[cellIndex].data.type == 'bool'"
                  class="cell-text"
              >
              <span v-marker-bool="cell"></span>

  <!--
              <i v-if="cell==1" class="bi bi-check-circle-fill text-success"></i>
              <i v-else class="bi bi-x-circle-fill text-danger"></i>
  -->
            </span>
            <span
                  v-else-if="listObj.header[cellIndex].data.type == 'btn_edit'"
                  class="cell-btn"
              >
              <router-link
                  :to="cell"
                  class="btn btn-primary"
              >
                <i class="bi bi-pen"></i>
                <span class="d-none d-md-inline ms-2">{{listObj.header[cellIndex].data.params.btn_title}}</span>
              </router-link>
            </span>
            <span
                  v-else-if="listObj.header[cellIndex].data.type == 'btn_view'"
                  class="cell-btn"
              >
              <router-link
                  :to="cell"
                  class="btn btn-primary"
              >
                <i class="bi bi-eye"></i>
                <span class="d-none d-md-inline ms-2">{{listObj.header[cellIndex].data.params.btn_title}}</span>
              </router-link>
            </span>
              <span
                  v-else-if="listObj.header[cellIndex].data.type == 'btn_clone'"
                  class="cell-btn"
              >
              <router-link
                  :to="cell"
                  class="btn btn-warning"
              >
                <i class="bi bi-boxes"></i>
                <span class="d-none d-md-inline ms-2">{{listObj.header[cellIndex].data.params.btn_title}}</span>
              </router-link>
            </span>
            <span
                  v-else-if="listObj.header[cellIndex].data.type == 'btn_stats'"
                  class=""
                  @click.prevent="statsItem(cell,listObj.header[cellIndex].data.module)"
              >
              <router-link
                  :to="cell"
                  class="btn btn-outline-primary"
              >
               <i class="bi bi-graph-up"></i>
                <span class="d-none d-md-inline ms-2">{{listObj.header[cellIndex].data.params.btn_title}}</span>
              </router-link>
                <!--
                <pre>{{listObj.header[cellIndex].data}}</pre>
                -->
            </span>
              <span
                  v-else-if="listObj.header[cellIndex].data.type == 'btn_delete'"
                  class="btn btn-danger"
                  @click.prevent="deleteItem(rowIndex, cell)"
              >
              <i class="bi bi-trash"></i>
              <span class="d-none d-md-inline ms-2">{{listObj.header[cellIndex].data.params.btn_title}}</span>
            </span>
              <span
                  v-else-if="listObj.header[cellIndex].data.type == 'btn_detail'"

              >
              <router-link
                  :to="cell"
                  class="btn btn-info"
              >
                <i class="bi bi-eye"></i>
                {{listObj.header[cellIndex].data.params.btn_title}}
              </router-link>
            </span>
            <span
                v-else-if="listObj.header[cellIndex].data.type == 'btn_custom'"
                class="cell-image"
            >
              <span
                  @click.prevent="customBtnFunction(cell, listObj.header[cellIndex].data.params, row)"
                  class="btn btn-outline-primary"
              >
                <i
                    v-if="listObj.header[cellIndex].data.params && listObj.header[cellIndex].data.params.ico"
                    :class="listObj.header[cellIndex].data.params.ico"
                ></i>
                {{listObj.header[cellIndex].data.params.btn_title}}
              </span>
            </span>

            <span
                  v-else-if="listObj.header[cellIndex].data.type == 'image_preview'"
                  class="cell-image"
              >
              <image-component
                  v-if="cell"
                  :is_preview_only="true"
                  :image-id="cell"
                  thumb_class="list"
              />
            </span>
            <span
                  v-else-if="listObj.header[cellIndex].data.type == 'user_preview'"
                  class="cell-image"
              >
              <user-preview
                  v-if="cell"
                  :user-id="cell"
              />
            </span>
              <span
                  v-else-if="listObj.header[cellIndex].data.type == 'minuta2hodina'"
                  class="cell-image"
              >
                <span>{{(cell/60).toFixed(1)}}</span>
            </span>

              <span
                  v-else
                  class="col-non-defined"
              >
              [{{listObj.header[cellIndex].data.type}}] {{cell}}
            </span>

            </td>
          </template>


        </tr>
        </tbody>

      </table>
      <!--
      <list-paging
        :paging-data="listObj.paging"
      />
      -->
    </div>

    <div v-else class="d-flex align-items-center justify-content-center w-100 vh-50">
      <div class="bg-danger text-white rounded-2 px-2 py-1">
        <div class="text-center">
          <i class="bi bi-eye-slash"></i>
        </div>
        <div>
          Žádné výsledky
        </div>
      </div>

    </div>
  </div>
</template>

<script>
import axios from "axios";
import { gsap, Back, Power1 } from "gsap"
import ImagePreview from "@/components/general/ImagePreview";
import UserPreview from "@/components/previews/UserPreview";
//import DateimePreview from "@/components/previews/DatePreview";
import ImageComponent from "@/components/general/Image";
import 'vue3-treeselect/dist/vue3-treeselect.css'
import Treeselect from 'vue3-treeselect'
import Multiselect from 'vue-multiselect'
import { Navigation, Pagination, Scrollbar } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/vue';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import ListFilter from"@/components/general/list/ListFilter"
import ListPaging from"@/components/general/list/ListPaging"
let swiperInstance;
export default {
  name: "ListGeneral",
  components: {
    gsap,
    Back,
    Power1,
    axios,
    ImagePreview,
    UserPreview,
    ImageComponent,
    //DateimePreview,
    Treeselect,
    Multiselect,
    Swiper,
    SwiperSlide,
    ListFilter,
    ListPaging,
  },
  props: {
    "ident": String,
    "returnData": [Object, String, Number],
    "listObj": Object,
    "btnObj": Object,
    "modulName": String,
    "reloadSignal": String,
    "filters": [Object, Array],
    "is_animation": [Boolean],
    "is_filter": [Boolean],
    "is_header": [Boolean],
    "is_paging": [Boolean],
    "is_minimal": [Boolean],
    "config": [Object, Array],
  },
  setup() {
    /*
    const onSwiper = (swiper) => {
      swiperInstance = swiper;
    };
    return {
      onSwiper,
      modules: [Navigation, Pagination, Scrollbar],
    };
    */
  },
  emits:[
    'update:reloadSignal',
  ],
  data() {
    return {
      list: [],
      filterModel: {},
      isSetupFilters: false,
      cursor: null,

      dataFilter: null,
    }
  },
  methods: {
    async customBtnFunction(id, params, row) {
      if(params.is_confirm && params.confirm_message) {
        if(confirm(params.confirm_message)) {
          if(params.path){
            const postData = new FormData
            postData.append('id', id)
            postData.append('row', JSON.stringify(row))
            postData.append('params', JSON.stringify(params))

            axios.defaults.baseURL = this.getBaseRoot();
            await axios({
              url: params.path,
              method: "post",
              data: postData,
              withCredentials: true,
              headers: this.getRequestHeader(),
            }).then((res) => {
              if(res.data.response.is_row_delete) {
                console.log('dev delete row ')

              }else if(res.data.response.is_row_change) {
                for (const row_key in row) {
                  row[row_key] = res.data.response.row[row_key];
                }
              }
            });
          }else{
            console.error('Neni zadana cesta k methode')
          }
        }
      }else{
        //
      }


    },

    animateListLoading() {
      //console.log("list animateListLoading")
      if(this.is_animation) {
        gsap.from(".animated-list-row", {
          //y: 300,
          opacity: 0,
          duration: .05,
          stagger: 0.05,
        });
      }
    },
    reloadData(){
      this.$emit("update:reloadSignal", String(Math.random()));
    },

    btnCall(method, index, item) {
      if(method == 'editItem') {
        const path = "/" + this.modulName + '/edit/' + item.id;
        //console.log("dev btnCall", method, path);
        this.$router.push(path);
      }else if(method == 'deleteItem') {
        const path = "/" + this.modulName + '/delete/' + item.id;
        const deleteText = "Opravdu chcete smazat zaznam #"+ item.id +"?"
        if (confirm(deleteText) == true) {
          //todo delete
          delete this.listObj.content[index];
        }
      }else{
        console.log("dev btnCall", method, index, item)
        console.log("dev this.$route.params", this.$route.params)
      }

    },

    deleteItem(row_index, item) {
      if (confirm("Chcete smazat polozku?") == true) {
        axios({
          url: '/class' + item,
          method: "get",
          withCredentials: true,
          headers: this.getRequestHeader(),
        }).then((res) => {
          if (res.data.status) {
            this.listObj.data.splice(row_index, 1);
          }else{
            alert("chyba mazani " + res.data.err.message)
          }
          console.log("dev deleteItem OK", res.data.err)

          //console.log("dev deleteItem OK row_index", this.listObj.data[row_index])

        }) .catch(function(err){
          //pseudothis.err = err;
          console.log("dev deleteItem  catch", err)
        })
        .finally((data) =>{
          console.log("dev deleteItem  finally", data)

        })

        console.log("dev editItem", item)
      }

    },
    getCellType(index){
      for (let i = 0; i < this.listObj.header.length; i++) {
        if(this.listObj.header[i].ident == index) {
            return this.listObj.header[i].type;
        }
      }
    },

    getList(){
      //const template = this.cfg.general.flash_messages.root_local_storage_name + "__";
      const template = this._getConfig('general.flash_messages.root_local_storage_name') + "__";


      for (var i = 0; i < localStorage.length; i++) {
        if(localStorage.key(i).startsWith(template) && localStorage.getItem(localStorage.key(i))) {
          //console.log('dev FlashMessage', localStorage.key(i), localStorage.getItem(localStorage.key(i))) ;
          this.list.push({
            type: localStorage.key(i).replace(template, ""),
            title: localStorage.getItem(localStorage.key(i))
          })
        }
        //TODO nacteni z API
        if(this.is_auto_delete == true) {
          for (var i = 0; i < localStorage.length; i++) {
            if(localStorage.key(i).startsWith(template)) {
              //console.log('dev FlashMessage cleare', localStorage.key(i) ) ;
              localStorage.setItem(localStorage.key(i), null);
            }
          }
        }

      }
    },

  },
  computed: {
    smartPaging(){
      if (typeof this.listObj.paging != "undefined") {
      let active = this.listObj.paging.list.filter(function(item){
        if(item.is_active == true){
          return item;
        }
      })
      const activeNumber = parseInt(active[0].number)
      if(!this.cursor) {
        this.cursor = activeNumber;
      }

      let smartPaging = {
        cursor: this.cursor,
        max: this.listObj.paging.page_count,
        offset: 5,
        from_item: 0,
        to_item: 0,
        list: [],
      }

      if(smartPaging.cursor >= smartPaging.max) {
        smartPaging.cursor = smartPaging.max;
        this.cursor = smartPaging.cursor
      }
      if(smartPaging.cursor <= 1) {
        //console.log('cursor 1', smartPaging.cursor)
        smartPaging.cursor = 1;
        this.cursor = smartPaging.cursor
      }
      if(this.listObj.paging.page_count > 2 * smartPaging.offset){
        if ((smartPaging.cursor  - smartPaging.offset) < 0) {
          smartPaging.from_item = 1;
          smartPaging.to_item = smartPaging.cursor + smartPaging.offset + Math.abs(activeNumber - smartPaging.offset);
        }else if((smartPaging.cursor + smartPaging.offset) > this.listObj.paging.page_count){
          smartPaging.to_item = this.listObj.paging.page_count;
          smartPaging.from_item = smartPaging.cursor - smartPaging.offset - Math.abs(this.listObj.paging.page_count - (smartPaging.cursor + smartPaging.offset));
        }else{
          smartPaging.from_item = smartPaging.cursor - smartPaging.offset;
          smartPaging.to_item = smartPaging.cursor + smartPaging.offset;
        }
      }else{
        smartPaging.from_item = 1;
        smartPaging.to_item = this.listObj.paging.page_count;
      }

      smartPaging.list = this.listObj.paging.list.filter((item) => {
        //console.log('list limit', smartPaging.cursor, smartPaging.from_item, smartPaging.to_item)
        if(item.number >= smartPaging.from_item && item.number <= smartPaging.to_item ){ // && item.number < smartPaging.to
          return item;
        }
      })

      return smartPaging;
      }
    },
  },
  mounted() {
    if(this.listObj.page && this.listObj.page > 1) {
      //swiperInstance.slideTo(this.listObj.page)
    }


    //filters value
    /**
    if(this.filters) {
      for (const key in this.filters) {
        if(this.filters[key].definition.type== 'checkboxes'){
          if(!this.filterModel[key]) this.filterModel[key] = [];
        }else{
          if(!this.filterModel[key]) this.filterModel[key] = null;
        }
      }

      if (JSON.parse(localStorage.getItem(this.ident +"_filter"))){
        const loadedFilters = JSON.parse(localStorage.getItem(this.ident +"_filter"))
        for (const fKey in loadedFilters) {
          if(loadedFilters[fKey]) {
            this.filterModel[fKey] = loadedFilters[fKey];
          }
        }
      }
    }
     */
    this.animateListLoading()
  },
  watch: {
    dataFilter: {
      handler(newval) {
        //this.$emit("update:reloadSignal", String(Math.random()));
        this.$emit("update:reloadSignal", newval);
      },
      deep: true,
      immediate: true
    }
  }
};
</script>