<template >

  <div
      v-if="options.meta"
      class="d-flex"
  >
    <div
      v-for="(item, index) in options.meta"
      :class="'flex-grow-0 w-50 px-2'"
    >
      <div class="text-secondary">{{item.title.cz}}</div>
      <template v-if="item.type == 'select'">

          <wiki-pair-select
              v-model="inputModel[index]"
              :model-value="inputModel[index]"
              :config="item"
          />


      </template>
      <template v-else-if="item.type == 'text'">
        <wiki-pair-text
            v-model="inputModel[index]"
            :langs="langs"
        />
      </template>

    </div>

  </div>


  <!--

  <display-debug>
    <template #debugView>pair inputModel {{inputModel}}</template>
  </display-debug>

  <display-debug>
    <template #debugView>pair options {{options}}</template>
  </display-debug>

      <display-debug>
        <template #debugView>pair item {{item}}</template>
      </display-debug>
  <display-debug>
    <template #debugView>inputModel {{inputModel}}</template>
  </display-debug>
  <display-debug>
    <template #debugView>writeStatus {{writeStatus}}</template>
  </display-debug>

  <display-debug>
    <template #debugView>langs {{langs}}</template>
  </display-debug>


-->
</template>

<script>



import CountryFlag from "vue-country-flag-next"
import WikiPairSelect  from "@/components/general/form/inputs/wiki/PairInputSelect";
import WikiTextLang  from "@/components/general/form/inputs/wiki/TextLang";
import WikiPairText  from "@/components/general/form/inputs/wiki/PairInputText";
let timer;

export default {
  name: "WikiTextareaLang",
  components: {
    CountryFlag,
    WikiTextLang,
    WikiPairSelect,
    WikiPairText,
  },
  props: {
    "modelValue": {
      type: Object,
      required: false,
      default: {}
    },
    "langs": [String, Object],
    "parentIndex": [String, Number],
    "options": {
      type: Object,
      required: false,
      default: {}
    }
  },
  emits: ['update:modelValue'],
  data() {
    return {
      selectOptions: {},
      writeStatus: false,
      inputModel: {},
      dev_config: {
        "wiki_role": {
          "type": "select",
          "title": {
            "cz": "Role",
            "en": "Role"
          },
          "select": {
            "action": "wiki/get-list-parents?typ_id=4",
            "detail": "wiki/get-detail-parents"
          },
          "modal": {
            "router": {
              "modul": "wiki",
              "action_new": "new",
              "action_edit": "edit",
            }
          }
        },
        "wiki_person": {
          "type": "select",
          "title": {
            "cz": "Umelec",
            "en": "Artist"
          },
          "select": {
            "action": "wiki/get-list-parents?typ_id=3",
            "detail": "wiki/get-detail-parents"
          },
          "modal": {
            "router": {
              "modul": "wiki",
              "action_new": "new",
              "action_edit": "edit"
            }
          }
        }
      },

    }
  },
  created() {
    if(this.options.meta){
      for(const i in this.options.meta) {
        this.selectOptions[i] = [];
        this.inputModel[i] = null;
      }
    }

  },
  mounted() {

    if(this.modelValue && Object.keys(this.modelValue).length > 0){
      for(const i in this.modelValue) {
        this.inputModel[i] = this.modelValue[i];
      }
    }
  },
  methods: {

  },
  watch: {
    inputModel: {
      handler(newVal) {
        if(timer) clearInterval(timer);
        timer = setTimeout(
            () => {

              this.$emit('update:modelValue', newVal )
            },
            300
        )
      },
      deep: true,
      immediate: true
    },
  },
};
</script>