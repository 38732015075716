<template >
  <!--
  <pre>{{inputModel}}</pre>
  -->

  <input
      v-model="inputModel.url"
      type="text"
      placeholder="adresa pripsevku ze socialni site"
      @input="createEmbedObj($event.target.value)"
  ><br/>

  <div v-if="inputModel && inputModel.url">
    <iframe
        v-if="inputModel.typ"
        style="border-radius:12px"
        :src="'https://open.spotify.com/embed/' + inputModel.typ + '/' + inputModel.id + '?utm_source=generator'"
        width="100%"
        height="352"
        frameBorder="0" allowfullscreen=""
        allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"
        loading="lazy"
    >
    </iframe>
    <div v-else>
     Toto neni platny link pro spotify obsah
    </div>
  </div>
</template>

<script>

export default {
  name: "SpotifySegment",
  props: {
    "segmentsObj": [Object, Array],
    "inputObj": Object,
    "formObj": Object,
    "modelValue": [Object, String ],
  },
  emits: ['update:modelValue'],
  data() {
    return {
      is_submitted: false,
      inputModel: {
        "url": null,
        "typ": null,
        "hash": null,
      },
    }
  },
  mounted() {
    if(this.inputObj.obsah){
      this.inputModel = this.inputObj.obsah;
    }
  },
  methods: {
    createEmbedObj(url){
      //https://open.spotify.com/track/1eOwR2Sjo0srIGABrsSR1S?si=69d390523b9e4c6c
      //https://open.spotify.com/album/1dqNlGoacvaUG86gU7B6hU?si=tVLr8Ie0STi4pz74BUcyqQ
      //https://open.spotify.com/artist/1v9mneHZBOqfEnPxcoJDDa?si=Vsrd4uTSTw6Ax9m4MfbY7A
      //https://open.spotify.com/playlist/42SZbsMvFCRUo6dO50eFoq?si=347529400f484ed0
      //https://open.spotify.com/show/5CZZyRHnCmFWgVDYO0cuiJ?si=b8dffc9d11ea424d
      //https://open.spotify.com/episode/2B1zAqaWLDaaL6SF8gbL7U?si=c786df63ce2a46cf
      var Spotify = url.match(/^(https?:\/\/){0,1}(open\.){0,1}spotify\.com\/(track|album|playlist|artist|show|episode)\/(\w+)/);


      let res = {
        url: url
      }
      //console.log('dev Spotify', Spotify)
      if(Spotify){
        res.url = Spotify[0];
        res.typ = Spotify[3];
        res.id = Spotify[4];
      }
/*
      if(fbPost){
        console.log('dev socials FBB', fbPost)
        res.typ = 'fb_post'
        res.autor_slug = fbPost[3];
        res.id = fbPost[5];
      }else if(fbVideo){
        res.typ = 'fb_video'
        res.autor_id = fbVideo[3];
        res.id = fbVideo[5];
      }else if(instagramPost){
        res.typ = 'ig_post'
        res.sub_id = instagramPost[3];
        res.id = instagramPost[4];
      }else if(tiktokPost){
        res.url = tiktokPost[0];
        res.typ = 'tiktok_post'
        res.autor_id = tiktokPost[1];
        res.id = tiktokPost[2];
      }else if(tiktokProfile){
        res.url = tiktokProfile[0];
        res.typ = 'tiktok_profile'
        res.id = tiktokProfile[1];
      }else if(twitterTweet){
        res.url = twitterTweet[0];
        res.id = twitterTweet[2];
        res.autor_id = twitterTweet[1];
        res.typ = 'twitter_tweet'

      }else if(twitterProfile){
        res.url = twitterProfile[0];
        res.typ = 'twitter_profile'
        res.id = twitterProfile[1];
      }
*/
      this.inputModel = res;
      this.$emit('update:modelValue', res )
    }
  },
};
</script>