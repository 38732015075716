<template>
  <div class="d-flex align-items-center justify-content-center w-100 vh-100 bg-danger text-white">
    <div class="bg-danger text-white rounded-2 px-2 py-1">
      <div class="text-center">
        <i class="bi bi-emoji-dizzy fs-1"></i>
      </div>
      <div class="mt-3">Nenalezen žádný obsah</div>
    </div>

  </div>
</template>
