export const translatedTexts = {
    x: {
        cz: "testovaci preeklad EXPORT",
        en: "testovaci preeklad EN EXPORT",
    },
    main_menu: {
        logout_title: {
            cz: "Odhlásit se",
            en: "Logout",
        },
        profile_settings_title: {
            cz: "Nastavení vašeho profilu",
            en: "Your settings",
        },
    },


    general: {
        offline: {
            cz: "Momentalne neni dostupne pripojeni, aplikace bohuzel nemuze fungovat",
            en: "Momentalne neni dostupne pripojeni, aplikace bohuzel nemuze fungovat [EN]",
        },
        list: {
            edit_btn_title: {
                cz: "Upravit",
                en: "Save",
            },
            delete_btn_title: {
                cz: "Smazat",
                en: "Save",
            },
            clone_btn_title: {
                cz: "Smazat",
                en: "Save",
            },
            reload_btn_title: {
                cz: "Smazat",
                en: "Save",
            },
        },
        form: {
            save_btn_title: {
                cz: "Ulozit",
                en: "Save",
            },

        },

    },
    paging: {
        title: {
            cz: "Stránkování",
            en: "Paging",
        },
        prev: {
            cz: "Předchozí",
            en: "Previous",
        },
        next: {
            cz: "Další",
            en: "Next",
        },
        goto: {
            cz: "Přejít",
            en: "Go to",
        },
    },
    filters: {
        head: {
            title: {
                cz: "Filtry",
                en: "Filters",
            },
        },
        element: {
            text: {
                placeholder: {
                    cz: "Zadejte hodnotu",
                    en: "Enter value",
                }
            },
            select: {
                placeholder: {
                    cz: "Vyberte možnost",
                    en: "Select option",
                }
            },
            tree_select: {
                placeholder: {
                    cz: "Vyberte možnost",
                    en: "Select option",
                }
            },
        },
        btn: {
            reset: {
                cz: "Vymazat",
                en: "Clear",
            },
            submit: {
                cz: "Filtrovat",
                en: "Filter!",
            },
        },
    },


    restaurace: {
        menu: {
            title_empty_sections: {
                cz: "Přidejte nejdřív alespoň jednu sekci",
                en: "Pridejte nejdriv alespon jednu sekci EN",
            },
            title_empty_restaurant: {
                cz: "Musí být vybraná restaurace",
                en: "musi byt vybrana restaurace EN",
            },
            section: {
                title_presun: {
                    cz: "Přesunout sekci",
                    en: "Presunout sekci EN",
                },
                title_empty_meals: {
                    cz: "V sekci nejsou zadne jídla",
                    en: "V sekci nejsou zadne jidla EN",
                },
                title_create_new_section: {
                    cz: "Vytvořit novou sekci jídelního lístku",
                    en: "Vytvorit novou sekci EN",
                },
                title_empty_section: {
                    cz: "V této sekci není žádné jídlo!",
                    en: "V této sekci není žádné jídlo! EN",
                },
                title_hidden_meals: {
                    cz: "Sekce je v editaci skryta",
                    en: "Sekce je v editaci skryta EN",
                },
                title_meals_count: {
                    cz: "ks",
                    en: "pc",
                },
            },
            jidlo: {
                title_not_display: {
                    cz: "Toto jídlo se nebude zobrazovat",
                    en: "Toto jidlo se nebude zobrazovat EN",
                },
                hint_not_display: {
                    cz: "Zobrazení mužete změnit v editaci jídla",
                    en: "Zobrazeni mužete změnit v editaci jidla EN",
                },
                title_no_price: {
                    cz: "Jídlo bez ceny se nebude zobrazovat",
                    en: "Jidlo neni zobrazene, mužete ho editovat EN",
                },
                title_nezobrazeni: {
                    cz: "Jídlo není zobrazene, mužete ho editovat",
                    en: "Jidlo neni zobrazene, mužete ho editovat EN",
                },
                title_presun: {
                    cz: "Presunout jídlo",
                    en: "Presunout jidlo EN",
                },
                cena: {
                    placeholder: {
                        cz: "Cena jídla",
                        en: "Meal price EN",
                    },
                },
                varianta: {
                    cena: {
                        title_no_price: {
                            cz: "Jídlo bez ceny se nebude zobrazovat",
                            en: "Jidlo neni zobrazene, mužete ho editovat EN",
                        },
                        placeholder: {
                            cz: "Cena varianty jídla",
                            en: "Cena varianty jidla",
                        },
                    }
                }
            },

        }
    }
};
