<template >
  <!--
  <pre>placehodler inputObj{{inputObj}}</pre>
   -->
  <h3>{{inputObj.params.title}}</h3>
  <p v-if="inputObj.params.desc">{{inputObj.params.desc}}</p>

</template>

<script>

export default {
  name: "PlaceholderSegment",
  props: {
    "segmentsObj": [Object, Array],
    "inputObj": Object,
    "formObj": Object,
    "modelValue": [Number, String],
  },
  emits: ['update:modelValue'],
  mounted() {
    this.$emit('update:modelValue', 1)
  },
};
</script>