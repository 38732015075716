<template>
  <div class="cms5-login-wrap d-flex flex-wrap justify-content-center align-items-center p-2 w-100 h-100 g-3" style="width: 100%; ">
    <div
      class="d-md-flex flex-grow-1 px-4 px-md-0"
    >
      <div
       class="cms5-login-column  flex-md-fill"
      >

        <div class="text-center">
          <i class=" mt-4 bi bi-box-seam-fill text-secondary" style="font-size: 70px;"></i>
        </div>

        <h1 class="display-6 fw-bold lh-1 mb-2 text-center text-secondary">Vítejte v administaci webu j5.cz</h1>
        <p class="col-lg-10 ">
          Nejdřív se přihlaste, pokud nemáte účet kontaktuje správce s příslušným oprávněním.
        </p>
        <p class="col-lg-10 d-none">
          Zapomenute heslo si vyresetuje <span @click.prevent="activateReset()">zde</span>
        </p>
      </div>
      <div
          class="cms5-login-column  flex-md-fill"
      >
        <LoginForm :is_password_reset="is_password_reset"></LoginForm>
      </div>
    </div>
  </div>
</template>

<script>
import LoginForm from "@/modules/LoginForm";

import axios from "axios";
export default {
  name: 'ContentView',
  components: {
    LoginForm
  },
  data() {
    return {
      is_password_reset: false
    }
  },
  methods: {
    activateReset(){
      console.log("activateReset");
      this.is_password_reset = true;
    },
    async getModule(){
      const apiPath = "/dev-data/modul-" + this.$route.params.modul + "-" + this.$route.params.action + ".json";
      try {
        await axios({
          url: apiPath,
          method: "get",
          withCredentials: true,
          //headers: headers,
        }).then((res) => {
          if(res.data) {
            //document.title = res.data.title

            this.moduleObj = res.data;
            console.log("dev getModule res", res.data)
            this.err = null;
          }else{
            this.err = {
              "type": "data_err",
              "title": "Neexistuji data pro tent modul, overte ze jste na spravne adrese a mate k mpodulu pristup"
            };
          }
        });
      } catch (err) {
        //console.log("DEV FAIL", err);
        this.err = {
          "type": "system_err",
          "title": "systemova chyba, konktujte IT support"
        };
      }
    },
  },
  watch: {
    reloadSignal: {
      handler() {
        console.log('dev view reloadSignal')
        //this.getModule();
      },
      immediate: true
    },
    '$route' (to, from) {
      //this.getModule();
    }
  },
  created() {
    //console.log("dev content created / route", this.$route.params, this.$route.query)
    //this.getModule();
  },
  mounted() {
    //console.log("dev content mounted / route", this.$route.params)
    //console.log('dev view mounted')
    //this.getModule();
  },
}
</script>
