<template >
  <div >
    <image-detail-preview
      v-if="inputObj.val"
      :image-id="inputObj.val"
    />
  </div>
</template>

<script>
import ImageDetailPreview from "@/components/previews/ImageDetailPreview";
export default {
  name: "ImageThumb",
  components: {
    ImageDetailPreview,
  },
  props: {
    "inputObj": Object,
    "formObj": Object,
    "modelValue": [String, Number],
  },
  data() {
    return {
      inputModel: 20,
    }
  },
  mounted() {
   this.inputModel = this.inputObj.val;
  },
  methods: {
  },
  watch: {
    inputObj: {
      handler() {
        this.inputModel = this.inputObj.val;
      },
      deep: true,
      immediate: true
    },
    inputModel( newVal){
      this.$emit('update:modelValue', newVal )
    },
  },
};
</script>