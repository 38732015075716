<template >

  <div
    v-if="formObj.general.inputs.restaurace_id.val"
  >
      <!-- structure -->
    <ul
        v-if="structure.length"
        class="list-group border border-light2"
    >
      <draggable
          :list="structure"
          itemKey="id"
          handle=".move"
          :ghost-class="getConfig('general.style.class_drag_n_drop')"
          group="descr"
      >
        <template #item="{ index, element }">
          <li
              class="list-group-item"
          >
          <!-- section header start -->
          <header class="w-100">
            <div class="row">
             <span class="col col-12 col-md-9 ps-md-0 mb-1 mb-md-0">
              <span class="badge bg-primary fs-5 fw-light">{{element.nazev}}</span>
            </span>
              <div class="col col-12 col-md-3 pe-md-0 text-end">
              <span
                  @click.prevent="mealAddDialog(index)"
                  class="btn btn-outline-secondary fs-6 text-secondary "
                  title="Pridat jidlo"
              >
                  <i class="bi bi-plus"></i>
              </span>

                <span
                    v-if="inputObj.config.id_deletable"
                    @click.prevent="deleteSection(index, element)"
                    class="btn btn-outline-secondary fs-6 text-secondary ms-1"
                >
                  <i class="bi bi-trash"></i>
                </span>

                <span
                    @click.prevent="element._is_open=!element._is_open"
                    class="btn btn-outline-secondary fs-6 text-secondary ms-1"
                >
                  <i
                      v-if="element._is_open"
                      class="bi bi-eye-slash"
                  ></i>
                   <i
                       v-if="!element._is_open"
                       class="bi bi-eye"
                   ></i>
              </span>

                <span class="move btn btn-outline-secondary fs-6 text-secondary ms-1">
                  <i class="bi bi-arrows-move"></i>
              </span>
              </div>
            </div>

          </header>
          <!-- section header end -->
            <!-- body -->
            <div
                v-show="element._is_open"
            >
              <ul
                  class="list-group border border-light2 mt-2"
                  style="min-height: 30px;"
              >
                <draggable
                    :list="element.meals"
                    itemKey="id"
                    handle=".move"
                    :ghost-class="getConfig('general.style.class_drag_n_drop')"
                    group="people"
                >
                  <template #item="{ index, element }">
                    <li
                        :class="'list-group-item' + (element.is_invalid? ' bg-danger text-white pt-2 rounded':'')"
                    >
                      <header class="row">
                        <div class="col col-12 col-md-9">
                          <h6 class="fs-6">
                            <span v-if="element.is_invalid">
                              <i class="bi bi-eye-slash me-2"></i>
                            </span>
                            {{this.translate(element.nazev)}}
                          </h6>
                        </div>
                        <div class="col col-12 col-md-3 text-end mb-1">
                          <span :class="'move btn btn-sm ms-1'  + (element.is_invalid? ' btn-outline-light':' btn-outline-secondary')">
                            <i class="bi bi-arrows-move"></i>
                        </span>
                          <span
                              @click.prevent="mealEdit(element.section_index, index, element.id)"
                              :class="'btn btn-sm ms-1'  + (element.is_invalid? ' btn-outline-light':' btn-outline-secondary')"
                              title="editace jidla"
                          >
                            <i class="bi bi-pencil"></i>
                          </span>
                          <span
                              @click.prevent="mealRemove(element.section_index, element.id)"
                              :class="'btn btn-sm ms-1'  + (element.is_invalid? ' btn-outline-light':' btn-outline-secondary')"
                              title="editace jidla"
                          >
                            <i class="bi bi-trash"></i>
                          </span>
                        </div>
                      </header>

                      <div
                          v-if="element.je_zobrazeno"
                          class="row  p-0 mt-1 mt-md-0"
                      >
                        <div
                            v-if="element.warning"
                            class="col col-12 col-md-6 col-md-6 btn-group text-md-end"
                        >
                          <span
                              v-if="element.warning == warning.noMealPrice"
                              class="alert alert-danger text-danger p-1 px-2"
                          >
                            <i class="bi bi-exclamation-triangle-fill me-2"></i> {{getTranslate('restaurace.menu.jidlo.title_no_price')}}
                          </span>
                        </div>
                        <div
                            v-if="element.je_zobrazeno && (!element.varianty_jidla || element.varianty_jidla.length == 0) "
                            :class="'col col-8 offset-4  col-md-4' + (!element.warning? ' offset-md-8':' offset-md-2')"
                        >
                          <div class="input-group">
                            <input
                                v-model="element.price"
                                type="number"
                                min="0"
                                placeholder="cena jidla"
                                class="form-control text-end"

                            >
                            <span class="input-group-text">
                              {{inputObj.templates.display_currency}}
                            </span>
                          </div>
                        </div>
                        <!-- todo vlastnosti jidla -->
                        <div
                          v-if="element.je_zobrazeno && element.varianty_jidla && Object.entries(element.varianty_jidla).length > 0"
                          class="col col-12 p-0 bg-transparent"
                        >
                          <ul
                              class="list-group border border-0 bg-transparent"
                          >
                            <li
                                v-for="(varianta, indexVarianta) in element.varianty_jidla"
                                class="list-group-item border border-0 ps-1 bg-transparent"
                            >
                              <div
                                  :class="'row ms-1 mt-1' + ((!element.is_invalid && varianta.warning)? ' border border-danger rounded px-1 py-2 text-danger': '') + ((element.is_invalid && varianta.warning)? ' border border-danger rounded px-1 py-2 text-white': '')"
                              >
                                <div class="col col-12 text-end col-md-8 order-md-0 text-md-end">
                                  <span
                                      class="--input-group-text"
                                  >
                                    {{this.translate(varianta.nazev)}}
                                  </span>
                                </div>

                                <div
                                    v-if="varianta.warning"
                                    class="col col-12 order-md-2 text-md-end "
                                >
                                  <i class="bi bi-exclamation-triangle-fill me-2"></i> {{getTranslate('restaurace.menu.jidlo.varianta.cena.title_no_price')}}
                                </div>
                                <div class="col col-8 offset-4 col-md-4 offset-md-0 order-md-1">
                                  <div class="input-group">
                                    <input
                                        v-model="varianta.price"
                                        type="number"
                                        :placeholder="getTranslate('restaurace.menu.jidlo.varianta.cena.placeholder')"
                                        :class="'form-control d-inline-block text-end pe-1'+ (varianta.warning? ' is-invalid pe-3':'')"
                                        min="0"
                                    >
                                    <!-- @input.prevent="editMeal(index, element)" -->
                                    <span
                                        class="input-group-text"
                                    >
                                  {{inputObj.templates.display_currency}}
                                </span>
                                  </div>
                                </div>

                              </div>
                            </li>
                          </ul>
                        </div>
                      </div>

                      <div
                          v-else
                          class="list-group-item text-danger text-center"
                      >
                        <div>
                          <i class="bi bi-exclamation-triangle me-2"></i> {{getTranslate('restaurace.menu.jidlo.title_not_display')}}
                        </div>
                        <small>{{getTranslate('restaurace.menu.jidlo.hint_not_display')}}</small>
                      </div>

                      <!-- todo varovani mishmash restaurace -->
                    </li>
                  </template>
                </draggable>

                <li
                  v-if="element.meals.length <= 0"
                  class="list-group-item w-100 text-center"
                >
                  <span class="w-100  text-danger p-2 my-2">
                   <i class="bi bi-exclamation-triangle-fill ms-2"></i> {{getTranslate('restaurace.menu.section.title_empty_section')}}
                  </span>
                </li>
              </ul>
            </div>

            <div
                v-show="!element._is_open"
                class="row"
            >
              <div class="col col-12 text-center">


                <div class="text-danger">
                  <i class="bi bi-eye-slash me-2"></i> {{getTranslate('restaurace.menu.section.title_hidden_meals')}}
                </div>
                <small class="text-light">{{element.meals.length}} {{getTranslate('restaurace.menu.section.title_meals_count')}}</small>

              </div>
            </div>

            <!-- dialog new meal -->
            <div
                v-if="element.dialog_new"
                class="btn-group mt-2"
            >
             <span
                 class="btn btn-outline-primary"
                 @click.prevent="mealAddExist(index)"
             >
               <i class="bi bi-zoom-in"></i>
               Vybrat stavajici jidlo
             </span>

              <span
                  class="btn btn-outline-primary"
                  @click.prevent="mealAddNew(index)"
              >
               <i class="bi bi-plus"></i>
               Vytvorit nove jidlo
             </span>

              <span
                  class="btn btn-outline-primary"
                  @click.prevent="element.dialog_new=false; element.dialog_select=false;"
              >
               <i class="bi bi-eye-slash"></i>
             </span>

            </div>
            <!-- select existing meal -->
            <div v-if="element.dialog_select">
              <VueMultiselect
                  v-model="meal_add.meal"
                  :options="meals_option"
                  track-by="id"
                  :placeholder="'Vyhledat existujicí jídlo podle názvu'"
                  @open="searchMeal"
                  @search-change="searchMeal"
                  :custom-label="formatMealItem"
              >
                <template v-slot:noOptions>
                  <div class="multiselect--no-results">Vyhledejte jidlo pomocí názvu</div>
                </template>

                <template v-slot:noResult>
                  <div class="multiselect--no-results">Žádné výsledky pro tento dotaz.</div>
                </template>

                <template v-slot:option="{ option, search }">
                  <div
                      class="d-flex align-items-center"
                  >

                    <div class="d-inline-block align-self-center">
                      <div class="xd-inline-block">
                        <strong v-if="option.nazev">{{option.nazev[inputObj.templates.lang]}}</strong>

                        <span v-if="option.varianty_jidla && Object.entries(option.varianty_jidla).length > 0" class="ms-1"> {{Object.entries(option.varianty_jidla).length}} varianty</span>
                        <i v-if="option.poznamka_interni" class="ms-1">pozn.: {{option.poznamka_interni}}</i>
                      </div>

                    </div>
                  </div>
                </template>

                <template v-slot:singleLabel="{ option }">
                  <div
                      class="d-flex align-items-center"
                  >
                    <div class="align-self-center rounded">
                <span style="font-size: 1.2rem;">
                        <span v-if="option.nazev">{{option.nazev[inputObj.templates.lang]}}</span>
                </span>
                    </div>
                  </div>
                </template>

              </VueMultiselect>
            </div>

            <!-- body end-->
          </li>
        </template>
      </draggable>

    </ul>
    <div
        v-else
        class="text-center"
    >
      <span class="text-danger my-2 my-md-4">
        <i class="bi bi-exclamation-triangle-fill"></i>
        {{getTranslate('restaurace.menu.title_empty_sections')}}

      </span>
    </div>

    <!-- btn new section -->
    <div
      v-if="btnSectionNew"
      class="dev-list-group mt-2"
    >
      <span
        v-for="(btnItem, btnindex ) in btnSectionNew"
        class="dev-list-group-item"
      >
        <span
            @click.prevent="addSection(btnItem)"
            class="btn btn-primary me-1 mb-1"
        >
          {{btnItem.nazev}}
        </span>
      </span>

      <span>
        <span
            @click.prevent="displayModalSectionNew"
            class="btn btn-secondary me-1 mb-1"
        >
          <i class="bi bi-plus"></i> {{getTranslate('restaurace.menu.section.title_create_new_section')}}


        </span>

      </span>
    </div>

    <!-- modal start-->
    <div v-if="modalOpen && modalOpen.config && modalOpen.config.display">

      <modal-window
          :title="modalOpen.title"
          :config="modalOpen.config"
      >
        <template #modal-content-slot>
          <component
              v-bind:is="'ContentIncludeView'"
              :router="modalOpen.router"
              :return-data="modalOpen.data"
              :form-prefill="modalOpen.prefill"
              :config="modalOpen.config"
          ></component>
        </template>
      </modal-window>
    </div>
    <!-- modal end-->
  </div>
  <div
    v-else
    class="text-center"
  >
    <span class="text-danger">
      <i class="bi bi-exclamation-triangle-fill"></i>
      {{getTranslate('restaurace.menu.title_empty_restaurant')}}
    </span>
  </div>





  <!--
  <pre>structure: {{structure}}</pre>
  <hr>
  <pre>modalOpen: {{modalOpen}}</pre>


  <pre>inputModel {{inputModel}}</pre>
  <pre>inputObj {{inputObj.config}}</pre>


  <pre>data_new_section: {{data_new_section}}</pre>
  <pre>structure: {{structure}}</pre>

  <pre>structure: {{structure}}</pre>

     <pre>{{addForm}}</pre>

   -->



</template>

<script>
import IncludeForm from "@/components/general/IncludeForm";
import draggable from "vuedraggable";
import CountryFlag from "vue-country-flag-next"
import axios from "axios";
import VueMultiselect from "vue-multiselect";
import ContentIncludeView from "@/views/ContentIncludeView";
import ModalWindow from "@/components/content/ModalWindow";
//import { nextTick } from 'vue'
let timer;
const warningNoMealPrice = 'warning-no-meal-price';
const warningNoMealVariantPrice = 'warning-no-meal-variant-price';

export default {
  name: "RestauraceSekceJidloInput",
  components: {
    CountryFlag,
    draggable,
    IncludeForm,
    VueMultiselect,
    ContentIncludeView,
    ModalWindow
  },
  props: {
    "inputObj": Object,
    "formObj": Object,
    "modelValue": [Object, Array, Number],
  },
  data() {
    return {
      structure: [],
      btnSectionNew: [],
      modals: {
        'section_create': {
          title: 'Vytvoreni nove sekce',
          router: {
            modul: 'restaurace-menu-sekce',
            action: 'new'
          },
          config: {
            "display": true,
            "is_header": true,
            "is_close_btn": true,
            "is_footer": true
          },
          data: {
            save: false,
            submit: false,
          },
          prefill: {
            general: {
              inputs: {
                restaurace_id: {
                  readonly: true,
                  value: this.formObj.general.inputs.restaurace_id.val
                },
              }
            },
          },
        },
        'section_edit': {

        },
        'meal_create': {
          title: 'Vytvoreni noveho jidlo',
          router: {
            modul: 'meal',
            action: 'new'
          },
          config: {
            "display": true,
            "is_header": true,
            "is_close_btn": true,
            "is_footer": true
          },
          data: {
            save: false,
            submit: false,
          },
          prefill: {
            general: {
              inputs: {
                restaurace_id: {
                  readonly: true,
                  value: this.formObj.general.inputs.restaurace_id.val
                },
              }
            },
          },
        },
        'meal_edit': {
          title: 'Editace jidla',
          router: {
            modul: 'meal',
            action: 'edit'
          },
          config: {
            "display": true,
            "is_header": true,
            "is_close_btn": true,
            "is_footer": true
          },
          data: {
            save: false,
            submit: false,
          },
          prefill: {
            general: {
              inputs: {
                restaurace_id: {
                  readonly: true,
                  value: this.formObj.general.inputs.restaurace_id.val
                },
              }
            },
          },
        },
      },
      modalOpen: {},
      meals_option: [],
      meal_add: {
        section: null,
        meal: null
      },
      warning: {
        noMealPrice: warningNoMealPrice,
      }
    }
  },
  created() {
    if(this.inputObj.val) {
      this.structure = this.inputObj.val;
    }
    this.updateStructure();
  },
  mounted() {

  },
  methods: {
    updateStructure(){

      for (const s in this.structure) {
        //TODO update sekce
        for (const m in this.structure[s].meals) {
          axios.defaults.baseURL = this.getBaseRoot();
          axios({
            url: this.getApiConfig('restaurace.meal_detail.path') + '?' + this.getApiConfig('restaurace.meal_detail.input.id') + '=' + this.structure[s].meals[m].id,
            method: this.getApiConfig('restaurace.meal_detail.method'),
            withCredentials: true,
            headers: this.getRequestHeader(),
          }).then((res) => {
            if(res.data.status){
              this.structure[s].meals[m] = this.mealMergeData(
                  this.structure[s].meals[m],
                  res.data.response.data
              );
            }else{
              this.structure[s].meals.splice(m, 1);
            }
          });
        }
      }
    },

    mealAddDialog(section_index) {
      for (const indexSection in this.structure) {
        if(indexSection == section_index) {
          this.structure[indexSection].dialog_new = true;
          this.meal_add.section = this.structure[indexSection]
        }else{
          this.structure[indexSection].dialog_new = null;
          this.structure[indexSection].dialog_select = null;
        }
      }
    },

    mealAddNew(section_index) {
      this.modals.meal_create.data.submit = false;
      this.modals.meal_create.data.saved = false;
      this.modals.meal_create.prefill.general.inputs.restaurace_id.value = this.formObj.general.inputs.restaurace_id.val;
      this.modals.meal_create.data.section_index = section_index;
      this.modalOpen = this.modals.meal_create;
    },

    mealAddExist(section_index) {
      this.structure[section_index].dialog_select = true;
    },

    async searchMeal (query) {
      let apiPath = this.getApiConfig('restaurace.meal_search.path');
      let apiQuery = [];
      if(
          this.restaurace_id
      ) {
        apiQuery.push(this.getApiConfig('restaurace.meal_search.input.restaurace') + '=' + this.formObj.general.inputs.restaurace_id.val)
      }

      if(
          query
      ) {
        apiQuery.push(this.getApiConfig('restaurace.meal_search.input.query') + '=' + query)
      }

      if(apiQuery.length > 0) {
        apiPath += '?' + apiQuery.join('&')
      }

      if(timer) clearInterval(timer);
      timer = setTimeout(
          () => {
            axios.defaults.baseURL = this.getBaseRoot();
            axios({
              url: apiPath,
              method: "get",
              withCredentials: true,
              headers: this.getRequestHeader(),
            }).then((res) => {

              this.meals_option = res.data.response.list;
            })
            .catch((err) => {
              this.meals_option = []
            })
            .finally(() => {
              //console.log("DEV tags finally");
            })
          },
          500
      )
    },

    formatMealItem ({ id, title }) {
      return `${title}`
    },

    checkMealInMenu(jidlo_id) {
      for (const s in this.structure) {
        for (const m in this.structure[s].meals) {
          //console.log('dev.menu check exist', this.structure[s].meals[m].id, jidlo_id)
          if(this.structure[s].meals[m].id == jidlo_id) {
            return true;
          }
        }
      }
      return false;
    },

    mealEdit(section_index, jidlo_index, jidlo_id) {

      this.modals.meal_edit.prefill.general.inputs.restaurace_id.value = this.formObj.general.inputs.restaurace_id.val;

      this.modals.meal_edit.data.section_index = section_index;
      this.modals.meal_edit.data.meal_index = jidlo_index;

      this.modalOpen = this.modals.meal_edit;
      this.modalOpen.config.display = true;
      this.modals.meal_edit.data.submit = false;
      this.modals.meal_edit.data.saved = false;

      this.modalOpen.router.action = 'edit/' + jidlo_id;
      //this.modalOpen.router.action = this.modals.meal_edit.router.action + '/' + jidlo_id;
      this.modals.meal_edit.data.original = this.structure[section_index].meals[jidlo_index];
    },

    mealRemove(section_index, jidlo_id) {
      for (const indexMeal in this.structure[section_index].meals) {
        if(this.structure[section_index].meals[indexMeal].id == jidlo_id) {
          this.structure[section_index].meals.splice(indexMeal, 1);
          break;
        }
      }
    },

    mealMergeData(originalData, updateData) {
      for (const i in updateData) {
        if(i != "varianty_jidla"){
          originalData[i] = updateData[i];
        }else{
          for (const v in updateData[i]) {
            for (const vi in updateData[i][v]) {
              if(typeof originalData[i][v] == "undefined"){
                originalData[i][v] = {
                  price: null,
                };
              }
              originalData[i][v][vi] = updateData[i][v][vi]
            }
          }
        }
      }
      return originalData;
    },

    displayModalSectionNew() {
        this.modals.section_create.prefill.general.inputs.restaurace_id.value = this.formObj.general.inputs.restaurace_id.val;
        this.modalOpen = this.modals.section_create;
    },

    addSection(section) {
      if(!this.checkSectionExists(section.id)){
        let sectionItem = {
          'id': section.id,
          'nazev': section.nazev,
          'meals': [],
          'form_display': false,
          '_is_publish': false,
          '_is_open': true,
        };
        this.structure.push(sectionItem)
      }else{
        alert('Sekce je jiz pouzita!');
      }
      //console.log('dev.menu addSection', section)
    },

    deleteSection(section_index) {
      if (confirm('Opravdu chcete odstranit tuto sekci?') == true) {
        this.structure.splice(section_index, 1);
      }
    },

    checkSectionExists(section_id) {
        if(this.structure.length > 0) {
          for (const key in this.structure) {
            if(section_id == this.structure[key].id){
              return true;
            }
          }
        }
        return false;
      },

    async createSectionNewBtns(){
        if(this.inputObj.config.id_deletable){

          this.btnSectionNew = [];
          axios.defaults.baseURL = this.getBaseRoot();
          await axios({
            url: this.getApiConfig('restaurace.get_structure.path') + '?' + this.getApiConfig('restaurace.get_structure.input.id') + '=' + this.formObj.general.inputs.restaurace_id.val,
            method: this.getApiConfig('restaurace.get_structure.method') ,
            withCredentials: true,
            headers: this.getRequestHeader(),
          }).then((res) => {
            if(res.data.status && res.data.response.list) {

              for (const key in res.data.response.list) {
                //console.log('dev.menu createSectionNewBtns ITEM', res.data.response.list[key])
                //TODO pokud je v strukce nezobrazovat



                if(!this.checkSectionExists(res.data.response.list[key].id)) {
                  this.btnSectionNew.push(res.data.response.list[key])
                }

                /*
                                const isInStructure = this.structure.filter((item) => item.id === res.data.response.list[key].id);
                                if(isInStructure && isInStructure.length == 0) {
                                  const newSection = {
                                    "id": res.data.response.list[key].id,
                                    "nazev": res.data.response.list[key].nazev,
                                    "meals": [],
                                    "form_display": false,
                                    "_is_publish": false,
                                  }
                                  this.structure.push(newSection);
                                }
                                 */
              }
            }
          });


          console.log('dev.menu createSectionNewBtns', this.formObj.general.inputs.restaurace_id.val)
        }

      },

    exportStructure(structure){
      let exportData = structure;
      return exportData;
    }
  },
  watch: {
    "meal_add": {
      handler(newVal) {
        if(newVal && newVal.meal && newVal.section){

          console.log('dev.menu watch meal_add', newVal.meal);

          if(!this.checkMealInMenu(newVal.meal.id)) {
            newVal.section.meals.push(newVal.meal);
            newVal.section.dialog_select = null;
            this.meal_add.meal = null;
          }else{
            alert("Jidlo je jiz v tomto menu"); //todo lepsi dialog
          }


        }
      },
      deep: true,
      immediate: true
    },

    "formObj.general.inputs.restaurace_id.val": {
      handler(newVal) {
        if(newVal){
          //console.log('dev.menu resaurace', this.formObj.general.inputs.restaurace_id.val)
         //this.modals.section_create.prefill.general.inputs.restaurace_id.value = this.formObj.general.inputs.restaurace_id.val;
          //todo prefil new meal
          this.createSectionNewBtns();
        }else{
          this.btnSectionNew = [];
        }
      },
      deep: true,
      immediate: true
    },

    inputObj: {
      handler() {
        this.inputModel = this.inputObj.val;
      },
      deep: true,
      immediate: true
    },

    structure: {
      handler(newVal) {
        //check meals
        for (const indexSection in newVal) {
          for (const indexMeal in newVal[indexSection].meals) {
            let isHide = false;
            //setup section index for meal
            newVal[indexSection].meals[indexMeal].section_index = indexSection;

            //check prices
            if(newVal[indexSection].meals[indexMeal].varianty_jidla.length) {
              let isEmptyVarPrices = true
              for (const indexVar in newVal[indexSection].meals[indexMeal].varianty_jidla) {
                if(!newVal[indexSection].meals[indexMeal].varianty_jidla[indexVar].price) {
                  newVal[indexSection].meals[indexMeal].varianty_jidla[indexVar].warning = warningNoMealVariantPrice;

                }else {
                  if(newVal[indexSection].meals[indexMeal].varianty_jidla[indexVar].warning ) {
                    delete newVal[indexSection].meals[indexMeal].varianty_jidla[indexVar].warning;
                  }
                  isEmptyVarPrices = false;
                }
              }
              isHide = isEmptyVarPrices;
            }else{
              if(!newVal[indexSection].meals[indexMeal].price) {
                isHide = true;
                newVal[indexSection].meals[indexMeal].warning = warningNoMealPrice;
              }else if(newVal[indexSection].meals[indexMeal].warning ){
                delete newVal[indexSection].meals[indexMeal].warning;
              }
            }
            newVal[indexSection].meals[indexMeal].is_invalid = isHide;
          }
        }

        //todo delay emit
        //this.$emit('update:modelValue', newVal)
        this.$emit('update:modelValue', this.exportStructure(newVal))
      },
      deep: true,
      immediate: true
    },

    "modals.section_create.data.data": {
      handler(newVal) {
        if(newVal){
          const btn = {
            'id': newVal.id,
            'nazev': newVal.nazev.cz,
          };

          const section = {
            'id': newVal.id,
            'nazev': newVal.nazev.cz,
            'meals': [],
            'form_display': false,
            '_is_publish': false,
            '_is_open': true,
          };

          this.btnSectionNew.push(btn);
          this.structure.push(section);

          this.modals.section_create.data.submit = true;
          this.modals.section_create.data.saved = true;
          this.modals.section_create.config.display = false;
        }

        //console.log('dev.menu modals modals.section_create.data', newVal)
      },
      deep: true,
      immediate: true
    },

    "modals.meal_edit.data.data": {
      handler(newVal) {
        if(newVal){
          //console.log('dev.menu modals modals.meal_edit.data.data EDIT NEW', newVal)

          const meal = {
            "id":  newVal.id,
            "title": newVal.nazev.cz, //nazev.cz
            "nazev": newVal.nazev,
            "varianty_jidla": newVal.varianty_jidla,
            "poznamka_interni": newVal.poznamka_interni,
            "popis": newVal.popis,
            //"restaurace_id": newVal.restaurace_id,
            "je_varianta_lepek": newVal.je_varianta_lepek,
            "je_gluten_free": newVal.je_gluten_free,
            "id_palivost": newVal.id_palivost,
            "je_zobrazeno": newVal.je_zobrazeno,
            "je_zvyrazneno": newVal.je_zvyrazneni,
            //"price": null,
            //"_groupKey": "18",
            //"_is_filled": false
            //"_dev": "edit test1"
          };
/**/
          this.structure[this.modals.meal_edit.data.section_index].meals[this.modals.meal_edit.data.meal_index] = this.mealMergeData(
              this.structure[this.modals.meal_edit.data.section_index].meals[this.modals.meal_edit.data.meal_index],
              meal
          );


          this.modals.meal_edit.config.display = false;
          this.modals.meal_edit.data.submit = false;
          this.modals.meal_edit.data.saved = false;

          //


        }
      },
      deep: true,
      immediate: true
    },

    "modals.meal_create.data.data": {
      handler(newVal) {
        if(newVal){

          //this.modals.meal_create.data.submit = true;
          //this.modals.meal_create.data.saved = true;
          this.modals.meal_create.config.display = false;

          //console.log('dev.menu modals modals.meal_create.data NEW MEAL', newVal)

          const meal = {
            "id":  newVal.id,
            "title": newVal.nazev.cz, //nazev.cz
            "nazev": newVal.nazev,
            "varianty_jidla": newVal.varianty_jidla,
            "poznamka_interni": newVal.poznamka_interni,
            "popis": newVal.popis,
            "restaurace_id": newVal.restaurace_id,
            "je_varianta_lepek": newVal.id_palivost,
            "je_gluten_free": newVal.id_palivost,
            "id_palivost": newVal.id_palivost,
            "je_zobrazeno": newVal.je_zobrazeno,
            "price": null,
            //"_groupKey": "18",
            "_is_filled": false
          };
          this.structure[this.modals.meal_create.data.section_index].meals.push(meal)
        }



        //console.log('dev.menu modals modals.meal_create.data', this.modals.meal_create.data.section_index)
      },
      deep: true,
      immediate: true
    },
  },
};
</script>