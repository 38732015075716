<template>
  <div v-if="structure" class="list-group">
    <div
    v-for="(sItem, sIndex)  in structure"
    class="list-group-item"
    >
      <h5>{{sItem.title}}</h5>

      <div
          v-if="!structure[sIndex].is_closed"
      >
       <div class="row">
         <div class="col col-6">
           <label
               :for="'opening_time_'+ sIndex"
           >
             Otvírací čas
           </label>
           <input
               v-model="structure[sIndex].hour_opening"
               type="time"
               :class="'form-control' + (structure[sIndex].validation_ident == 'hour_opening'? ' is-invalid': '')"
               :id="'opening_time_'+ sIndex"
           />
         </div>
         <div class="col col-6">
           <label
               :for="'closing_time_'+ sIndex"
           >
             Zavírací čas
           </label>
           <input
               v-model="structure[sIndex].hour_closing"
               type="time"
               :class="'form-control' + (structure[sIndex].validation_ident == 'hour_closing'? ' is-invalid': '')"
               :id="'closing_time_'+ sIndex"
           />
         </div>
       </div>


        <div v-if="inputObj.templates.is_kitchen" class="row mt-2">
          <div
            class="col col-4"
          >
            <input
                v-model="structure[sIndex].hour_closing_kitchen"
                type="time"
                :class="'form-control' + (structure[sIndex].validation_ident == 'hour_closing_kitchen'? ' is-invalid': '')"
                :id="'closing_time_kitchen'+ sIndex"
            />
          </div>
          <div

              class="col col-8"
          >
            <label
                :for="'closing_time_kitchen'+ sIndex"
                class="form-label"
            >
              Čas zavření kuchyně
            </label>
          </div>
        </div>
      </div>

      <div class="form-check form-switch mt-2">
        <input
            v-model="structure[sIndex].is_closed"
            class="form-check-input"
            type="checkbox"
            role="switch"
            :id="'is_closing_'+ sIndex"
        >
        <label
            class="form-check-label"
            :for="'is_closing_'+ sIndex"
        >
          Celý den zavřeno
        </label>
      </div>
      <div v-if="sItem.validation_status === false" class="text-danger">
        <i class="bi bi-exclamation-triangle-fill me-1"></i>
        <span>{{sItem.validation_result}}</span>
      </div>
    </div>
  </div>



  <!--
  <pre>structure {{structure}}</pre>
  <pre>{{inputObj}}</pre>
  -->
</template>

<script>

export default {
  name: "OpeningHoursRegularInput",
  props: {
    "inputObj": Object,
    "formObj": Object,
    "modelValue": [Object],
  },
  data() {
    return {
      structure: [],
      validation: true,

      inputModel: null,
      timer: null,
      timer_delay: 500,
      is_locked: true,
    }
  },
  mounted() {
    //this.inputModel = this.inputObj.val;

    if(this.inputObj.val) {
      this.structure = this.inputObj.val;
    }else{
      this.createBaseStructure()
    }


    //console.log('dev otv doba vstup', this.inputObj)
  },
  methods: {
    validate() {
      this.validation = true;
        for (const dIndex in this.structure){
          if(this.structure[dIndex].is_closed !== true) {
            if(this.structure[dIndex].hour_opening && this.structure[dIndex].hour_closing) {
              if(this.structure[dIndex].hour_opening > this.structure[dIndex].hour_closing) {
                this.structure[dIndex].validation_status = false;
                this.structure[dIndex].validation_ident = 'hour_closing';
                this.structure[dIndex].validation_result = "Otevírací hodina je větší nez zavírací";
                this.validation = false;
              }else if(this.structure[dIndex].hour_closing_kitchen && (this.structure[dIndex].hour_closing_kitchen > this.structure[dIndex].hour_closing)){
                this.structure[dIndex].validation_status = false;
                this.structure[dIndex].validation_ident = 'hour_closing_kitchen';
                this.structure[dIndex].validation_result = "Kuchyně nemůže zavřít až po standartní zavírací hodině";
                this.validation = false;
              }else{
                delete this.structure[dIndex].validation_status
                delete this.structure[dIndex].validation_result
                delete this.structure[dIndex].validation_ident
              }
            }else{
              this.structure[dIndex].validation_status = false;
              this.structure[dIndex].validation_result = "Vyplňte otevírací a zavírací hodinu!";
              this.structure[dIndex].validation_ident = 'hour_opening';
              this.validation = false;
            }
          }else{
            delete this.structure[dIndex].validation_status
            delete this.structure[dIndex].validation_result
            delete this.structure[dIndex].validation_ident
          }
        }
    },
    createBaseStructure() {
      if(this.inputObj.templates && this.inputObj.templates.data) {
        for (const tKey in this.inputObj.templates.data) {
          const day = {
            "title": this.inputObj.templates.data[tKey].day_ident,
            "day": this.inputObj.templates.data[tKey].day_number,
            "is_closed": false,
            "hour_opening": null,
            "hour_closing": null,
            "hour_closing_kitchen": null,
          }
          this.structure[tKey] = day;
          //this.structure.push(day);
          console.log('dev createBaseStructure', tKey, this.inputObj.templates.data[tKey].day_ident)
        }
      }

    }
  },
  watch: {
    structure: {
      handler(newVal) {
        //clear?
        this.validate();
        this.$emit('update:modelValue', newVal )


        //this.inputModel = this.inputObj.val;
      },
      deep: true,
      immediate: true
    },

    inputObj: {
      handler() {
        this.inputModel = this.inputObj.val;
      },
      deep: true,
      immediate: true
    },
    inputModel( newVal){

      if(this.inputObj.name == 'slug' && newVal.length == 0){
        this.inputObj.is_locked = false;
      }
      //timer
      if(this.timer) clearInterval(this.timer);
      const delay = this.timer_delay
      const pseudo_this = this;
      this.timer = setTimeout(
          () => {
          //start timer function content
            //tohle je slug input a jeho prevod na spravny format
            if (
                typeof pseudo_this.inputObj.slug != 'undefined'
                && typeof pseudo_this.inputObj.slug.is_slug != 'undefined'
            ){
              //console.log("dev slug inputObj SLUG format")
              newVal = pseudo_this.createSlugFormat(newVal);
              this.inputModel = newVal;
              this.inputObj.val = newVal
            }else{
              this.inputObj.val = newVal

            }

            //pokud z inputu vytvarim dalsi inpu ve formatu slug

            if(
                typeof pseudo_this.inputObj.slug != 'undefined'
                && typeof pseudo_this.inputObj.slug.section != 'undefined'
                && typeof pseudo_this.inputObj.slug.input != 'undefined'
                && typeof pseudo_this.formObj[pseudo_this.inputObj.slug.section].inputs[pseudo_this.inputObj.slug.input].disable_auto_fill == 'undefined'
              && pseudo_this.formObj[pseudo_this.inputObj.slug.section].inputs[pseudo_this.inputObj.slug.input].is_locked == false
            ) {
              pseudo_this.formObj[pseudo_this.inputObj.slug.section].inputs[pseudo_this.inputObj.slug.input].val = newVal
            }



            //update parent
            //console.log('dev textarea', newVal)
            pseudo_this.$emit('update:modelValue', newVal )
          //end timer function content
          },
          this.timer_delay
      )
    },
  },
};
</script>