<template>
  <div
      v-if="Object.keys(filterData).length > 0"
      class="d-md-flex justify-content-md-between align-self-md-center flex-md-nowrap align-items-md-center"
  >

    <div class="d-md-inline-flex align-items-center flex-md-wrap">
      <div class="me-2">
        <i class="bi bi-filter"></i>
        {{getTranslate('filters.head.title')}}
      </div>
      <div
          v-for="(filterItem, filterIndex)  in filterData"
          @key="filterIndex"
          class="me-1 mb-1"
      >
        <input
            v-if="filterItem.definition.type=='text'"
            v-model="filterModel[filterItem.key]"
            type="search"
            :placeholder="filterItem.definition.placeholder? filterItem.definition.placeholder: this.getTranslate('filters.element.text.placeholder')"
            class="form-control me-md-2"
            style="min-width: 10rem;"

        >
        <treeselect
            v-else-if="filterItem.definition.type=='tree_select'"
            v-model="filterModel[filterItem.key]"
            :options="filterItem.select"
            :searchable="true"
            :placeholder="filterItem.definition.placeholder? filterItem.definition.placeholder: this.getTranslate('filters.element.tree_select.placeholder')"
            style="max-width: 300px; border:none;"
            class="form-control p-0"
        />
        <multiselect
            v-else-if="filterItem.definition.type=='select'"
            v-model="filterModel[filterItem.key]"
            :options="filterItem.select"
            track-by="id"
            label="title"
            :searchable="true"
            :hideSelected="false"
            :placeholder="filterItem.definition.placeholder? filterItem.definition.placeholder: this.getTranslate('filters.element.select.placeholder')"
        >

        </multiselect>
        <div
            v-else-if="filterItem.definition.type=='bool'"
            class="mt-2 mt-md-0 ms-2 d-flex pt-2 me-2"
        >
          <div class="form-check border border-danger border-0 ">
            <input
                type="checkbox"
                v-model="filterModel[filterItem.key]"
                :value="1"
                :id="'f_' + filterItem.key"
                class="form-check-input"
            />
            <label
                :for="'f_' + filterItem.key"
                class="form-check-label"
            >
              {{filterItem.title}}
            </label>
          </div>


        </div>
        <div
            v-else-if="filterItem.definition.type=='checkboxes'"
            class="mt-2 mt-md-0 ms-2"
        >
          <div
              v-for="(optionItem, optionIndex)  in filterItem.select"
              @key="optionIndex"
              class="form-check-inline"
          >
            <label
                class="form-check-label"
                :for="filterItem.key + '_' + optionItem.id"
            >
              <input
                  v-model="filterModel[filterItem.key]"
                  :value="optionItem.id"
                  type="checkbox"
                  :id="filterItem.key + '_' + optionItem.id"
                  class="form-check-input"
              />
              <span class="ms-1">{{optionItem.title}}</span>
            </label>
          </div>
        </div>
      </div>
    </div>
    <div
        class="d-flex flex-wrap align-self-end"
    >
      <button
          v-if="isSetupFilters"
          @click.prevent="clearFilters"

          class="btn btn-outline-secondary ms-md-2"
      >
        {{getTranslate('filters.btn.reset')}} <i class="bi bi-x "></i>
      </button>

      <button
          @click.prevent="applyFilters()"
          class="btn btn-outline-secondary ms-2"
      >
        <span>{{getTranslate('filters.btn.submit')}}</span>
      </button>
    </div>
  </div>

<!--
  <display-debug>
    <template #debugView>filterModel {{filterModel}}</template>
  </display-debug>

  <display-debug>
    <template #debugView>[in filter] reloadFilter: {{reloadFilter}}</template>
  </display-debug>

  <display-debug>
    <template #debugView>filterData {{filterData}}</template>
  </display-debug>


  -->
</template>

<script>
import 'vue3-treeselect/dist/vue3-treeselect.css'
import Treeselect from 'vue3-treeselect'
import Multiselect from 'vue-multiselect'



export default {
  name: "ListFilter",
  components: {
    Treeselect,
    Multiselect
  },
  props: {
    "reloadFilter": [Object],
    "filterData": [Object, String, Number],
    "ident": [String, Number],
  },
  emits:[
    'update:reloadFilter',
  ],
  setup() {

  },
  data() {
    return {
      isSetupFilters: null,
      filterModel: {},
    }
  },
  methods: {
    getStorageKey(){
      return this.ident + this.getConfig('filter_ident');
    },
    applyFilters(){
      this.isSetupFilters = true;
      this.generateFitlerData();
    },
    clearFilters(){
      this.filterModel = {}
      this.isSetupFilters = false;
      localStorage.removeItem(this.getStorageKey());
      this.generateFitlerData();
    },

    generateFitlerData(){
      let filterData = {};
      for (const i in this.filterData) {
        if(this.filterData[i].definition.type == 'select' || this.filterData[i].definition.type == 'tree_select') {
          if(this.filterModel[i]){
            filterData[i] = this.filterModel[i].id;
          }
        }else{
          filterData[i] = this.filterModel[i];
        }

        if(filterData[i] === null) {
          delete filterData[i];
        }
      }

      let redirectPath = this.$route.path
      if(filterData && Object.keys(filterData).length > 0){
        localStorage.setItem(this.getStorageKey(),  JSON.stringify(filterData));
        redirectPath += "?filters=" + JSON.stringify(filterData);
      }else{
        localStorage.removeItem(this.getStorageKey());
      }

      this.$router.push(redirectPath);
      //this.$emit("update:reloadFilter", filterData);
    },

    loadFilters() {
      this.isSetupFilters = false;
      if(this.filterData) {
        for(const f in this.filterData) {
          //console.log('dev-fitler-item', f, this.filterData[f])
          if(typeof this.filterData[f].value != "undefined") {
            if(this.filterData[f].definition.type == 'select') {
              if(this.filterData[f].select) {
                for(const s in this.filterData[f].select) {
                  if(this.filterData[f].select[s].id == this.filterData[f].value){
                    this.filterModel[f] = this.filterData[f].select[s];
                  }
                }
              }
            }else if(this.filterData[f].definition.type == 'tree_select') {
              //todo
              this.filterModel[f] = this.filterData[f].value;
            }else if(this.filterData[f].definition.type == 'checkboxes') {
              this.filterModel[f] = this.filterData[f].value;
            }else{
              this.filterModel[f] = this.filterData[f].value;
            }
          }
        }
      }

      if(this.filterModel && Object.keys(this.filterModel).length > 0 ) {
        let isSetupFilters = false
        for(const f in this.filterModel) {
            if (typeof this.filterModel[f] == "object") {
              if(this.filterModel[f] && Object.keys(this.filterModel[f]).length > 0) {
                isSetupFilters = true
              }
            }else{
              if(typeof this.filterModel[f]) {
                isSetupFilters = true
              }
            }
        }
        this.isSetupFilters = isSetupFilters;

      }else{
        //remove ls
        this.isSetupFilters = false;
      }
    },

    setDefaultModelValues() {
      if(this.filterData) {
        for(const i in this.filterData){
          if(this.filterData[i].definition.type == 'checkboxes') {
            this.filterModel[i] = [];
          }else{
            this.filterModel[i] = null;
          }
        }
      }
    }
  },
  computed: {

  },
  mounted() {

      this.setDefaultModelValues();
      this.loadFilters();
  },
  watch: {

  }
};
</script>