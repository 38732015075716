<template >
  <image-component
      v-model="inputModel"
      type-id="14"
      :is_browser="true"
      :is_upload="true"
      :is_input_title="true"
      :is_input_copyright="true"
      :is_delete="false"
      :is_preview_only="false"
      :thumb_type_id="60"
      :is_image_props="true"
      thumb_class="segment img-thumbnail img-fluid"
  />

</template>

<script>
import ImageComponent from "@/components/general/Image";
import ImagePreview from "@/components/general/ImagePreview";
export default {
  name: "ImageSegment",
  components: {
    //ImagePreview,
    ImageComponent,
  },
  props: {
    "segmentsObj": [Object, Array],
    "inputObj": Object,
    "formObj": Object,
    "modelValue": [Number,String, Object],
  },
  emits: ['update:modelValue'],
  data() {
    return {
      inputModel: null,
    }
  },
  mounted() {
    this.inputModel = this.inputObj.obsah;
  },
  methods: {

  },
  watch: {
    inputObj: {
      handler() {
        //  this.inputModel = this.inputObj.val;
      },
      deep: true,
      immediate: true
    },
    inputModel( newVal){
      //console.log("dev inputModel ImageSegment xxxx", newVal)
      this.$emit('update:modelValue', newVal )
    },
  },
};
</script>