<template>
  <div class="container">
    <div class="row">
      <div class="col col-12 col-md-4">
        <small>{{dataObj.objednavka_general.vars.number.title}}</small> {{dataObj.objednavka_general.vars.number.value}}
      </div>
      <div class="col col-12 col-md-4">
        <small>{{dataObj.objednavka_general.vars.stav.title}}</small>: {{dataObj.objednavka_general.vars.stav.value}}
      </div>
      <div class="col col-12 col-md-4">
        <small>{{dataObj.objednavka_general.vars.datum_vlozeno.title}}</small>: {{dataObj.objednavka_general.vars.datum_vlozeno.value}}

      </div>
    </div>

    <div class="row">
      <div class="col col-12 col-md-6">
        <small>{{dataObj.objednavka_general.vars.zakaznik.title}}</small>
        <block-adresa
        v-bind:data="dataObj.objednavka_general.vars.zakaznik.value"
        />
      </div>
      <div class="col col-12 col-md-6">
        <small>{{dataObj.objednavka_general.vars.dodavatel.title}}</small>
        <block-adresa
            v-bind:data="dataObj.objednavka_general.vars.dodavatel.value"
        />
      </div>
    </div>

    <div class="row">
      <div class="col col-12 col-md-6" v-if="dataObj.objednavka_general.vars.doprava">
        <small>{{dataObj.objednavka_general.vars.doprava.title}}</small><br>
        <div
            v-if="dataObj.objednavka_general.vars.doprava.value.adresa"
        >
          <block-adresa
              v-bind:data="dataObj.objednavka_general.vars.doprava.value"
          />
        </div>
        <div v-else>
          <strong>{{dataObj.objednavka_general.vars.doprava.value.title}}</strong><br>
        </div>

        <a
            v-if="dataObj.objednavka_general.vars.doprava.value.tracking_link"
            :href="dataObj.objednavka_general.vars.doprava.value.tracking_link"
            target="_blank"
            class="btn btn-outline-primary btn-sm"
        >
          <i class="bi bi-truck"></i>
          sledovat zasilku
        </a>

      </div>

      <div class="col col-12 col-md-6" v-if="dataObj.objednavka_general.vars.platba">
        <small>{{dataObj.objednavka_general.vars.platba.title}}</small><br/>
        <strong>{{dataObj.objednavka_general.vars.platba.value.title}}</strong>

      </div>
    </div>

    <div class="row">
      <div class="col col-12" v-if="dataObj.objednavka_general.vars.produkty">
        <small>{{dataObj.objednavka_general.vars.produkty.title}}</small>

        <table class="table table-striped table-borderless w-100 table-responsive" style="width: 100%;">
          <tbody>
          <tr
              v-for="(productItem, productIndex)  in dataObj.objednavka_general.vars.produkty.value"
              class="list-group-item"
          >
            <td class="w-100">
              <div class="row">
                <div class="col col-1">
                  {{productItem.pocet}}x
                </div>
                <!-- TODO image? -->
                <div class="col col-7">
                  <router-link
                      :to="'/eshop-produkt/edit/' + productItem.produkt_id"
                      active-class="active active-5-x"
                      class="nav-link text-secondary"
                      v-slot="{ href, route, navigate, isActive, isExactActive }"
                      :title="productItem.produkt_titulek"
                  >
                  {{productItem.produkt_titulek}}
                  </router-link>
                </div>
                <div class="col col-4">
                  <block-cena v-bind:data="productItem.cena" />
                </div>
              </div>
            </td>
          </tr>
          </tbody>
        </table>
      </div>

    </div>

    <div class="row">
      <div
          v-if="dataObj.objednavka_general.vars.cena"
          class="col col-12 col-sm-6"
      >
        <h4>{{dataObj.objednavka_general.vars.cena.title}}</h4>
        <block-cena v-bind:data="dataObj.objednavka_general.vars.cena.value" />
      </div>
    </div>

    <div class="row">
      <div class="col col-12">
        <h4>{{dataObj.objednavka_general.vars.historie.title}}</h4>
        <ul class="list-group">
          <li
              v-for="(historyItem, historyIndex)  in dataObj.objednavka_general.vars.historie.value"
              class="list-group-item"
          >
            <div>
              <small>{{historyItem.datum}}</small>
            </div>
            <div>
              <span>{{historyItem.stav_id_to}}</span> -
              <strike>{{historyItem.stav_id_from}}</strike>
            </div>

            <p v-if="historyItem.text">{{historyItem.text}}</p>

            <div>

              <span v-if="historyItem.autor_title">autor title:{{historyItem.autor_title}}</span>
              <span v-else-if="historyItem.autor_id">autor id: {{historyItem.autor_id}}</span>
            </div>


          </li>
        </ul>
      </div>
    </div>

  </div>


  <!--  -->
  <pre>Order dataObj: {{dataObj}}</pre>
</template>
<script>
import BlockAdresa from "@/components/general/view/blocks/BlockAdresa";
import BlockCena from "@/components/general/view/blocks/BlockCena";
export default {
  name: "ViewOrder",
  components: {
    BlockCena,
    BlockAdresa,
  },
  props: {
    "dataObj": Object,
    "returnData": [Object, String, Number],
  },
  data() {
    return {
      source: 'promena source',
      inputModel: null,
    }
  },
  mounted() {

  },
  methods: {
  },
  watch: {

  },
};
</script>