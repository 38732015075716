<template >
  <gallery-component
    v-model="inputModel"
    :gallery_id="modelValue"
    :is_gallery_browser="true"
    :is_image_browser="true"
    :image_typ_id="inputObj.params.image_type_id"
    :is_image_upload="true"
    :is_creating="true"
    :is_image_upload_from_server="true"
  />
</template>

<script>
import GalleryComponent from "@/components/general/Galerie";
import texts from "@/components/config/texts";
export default {
  name: "GalerieSegment",
  components: {
    GalleryComponent,
    texts
  },
  props: {
    "segmentsObj": [Object, Array],
    "inputObj": Object,
    "formObj": Object,
    "modelValue": [String, Number],
  },
  emits: ['update:modelValue'],
  data() {
    return {
      lengthLimit: 100,
      inputModel: null,
    }
  },
  mounted() {
    this.inputModel = this.inputObj.obsah;
  },
  watch: {
    inputModel( newVal){
      this.$emit('update:modelValue', newVal )
    },
  },
};
</script>