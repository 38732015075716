<template >

  <!--
  inputObj.type = simple
  inputObj.type = simple_search
  inputObj.type =  ajax_search
  -->
  <div v-if="inputObj.type == selectTyp.simple_search">
    <multiselect
        v-model="selectModel"
        :options="inputObj.templates.data"
        track-by="id"
        :searchable="true"
        :hideSelected="false"
        :custom-label="formatSelectItem"
        :disabled="inputObj.readonly"
        :placeholder="inputObj.placeholder? inputObj.readonly: 'Vyberte možnost'"
    >
      <template v-if="inputObj.templates.option_template_type == 'user'" v-slot:singleLabel="{ option }">
        <div class="d-flex align-items-center">
          <image-component
              v-if="option.obrazek_id"
              :image-id="option.id"
              :is_preview_only="true"
              thumb_class="user-thumb rounded-circle"
              class="flex-shrink-0 nahled "
          />
          <div class="flex-grow-1 ms-1">
            <div v-if="option.title" class="fw-light fs-6">
              <span class="fw-medium">{{ option.title }}</span>
            </div>

            <!--
              <span v-if="option.login" class="ms-2"><i class="bi bi-credit-card-2-front" style="font-size: .8rem;"></i> {{ option.login }}</span>
              <div v-if="option.email" class="mt-3 mb-1 text-secondary"><i class="bi bi-envelope-at" style="font-size: .7rem;"></i> {{ option.email }}</div>
              <div v-if="option.login" class="text-secondary"><i class="bi bi-credit-card-2-front" style="font-size: .7rem;"></i> Login: {{ option.login }}</div>
            -->
          </div>
        </div>
      </template>

      <template v-else-if="inputObj.templates.option_template_type == 'venue'" v-slot:singleLabel="{ option }">
        <i class="bi bi-pin-map  me-1"></i>
        <span>{{ option.title }}</span>
      </template>

      <template v-else-if="inputObj.templates.option_template_type == 'rezervation_spot'" v-slot:singleLabel="{ option }">
        <strong>{{ option.title }}</strong> | {{option.pocet_mist}}<i class="bi bi-person-standing"></i>
      </template>

      <template v-else-if="inputObj.templates.option_template_type == 'lang'" v-slot:singleLabel="{ option }">
        <country-flag
            :country='option.flag'
            size="small"
            class="me-1"
        />
        <span>{{ option.title }}</span>
      </template>

      <template v-else v-slot:singleLabel="{ option }">
        <span>{{ option.title }}</span>
      </template>

      <template v-if="inputObj.templates.option_template_type == 'lang'" v-slot:option="{ option, search }">
        <span>
          <country-flag
              :country='option.flag'
              size="small"
          />
        {{ option.title }}
        </span>
      </template>

      <template v-if="inputObj.templates.option_template_type == 'rezervation_spot'" v-slot:option="{ option, search }">
        <i class="bi bi-marker-tip me-1"></i>
        <span>
          [spot]{{ option.title }} | {{ option.pocet_mist }}
        </span>
      </template>

      <template v-if="inputObj.templates.option_template_type == 'venue'" v-slot:option="{ option, search }">
        <i class="bi bi-marker-tip me-1"></i>
        <span>
          {{ option.title }}
        </span>
      </template>

      <template v-if="inputObj.templates.option_template_type == 'simple'" v-slot:option="{ option, search }">
        <span>
          {{ option.title }}
        </span>
      </template>


      <template v-if="inputObj.templates.option_template_type == 'rezervation_spot'" v-slot:option="{ option, search }">

        <strong>{{ option.title }}</strong> | {{option.pocet_mist}}<i class="bi bi-person-standing"></i>

      </template>

      <template v-if="inputObj.templates.option_template_type == 'status'" v-slot:option="{ option, search }">
        <strong style="font-size: 1.1rem;">
          <i v-if="option.ico == 'concept'" class="bi bi-pen"></i>
          <i v-if="option.ico == 'waiting'" class="bi bi-clock-history"></i>
          <i v-if="option.ico == 'success'" class="bi bi-check2-circle"></i>

          {{ option.title }}
        </strong>
        <!--
        <div>
          <span>slug: {{option.slug}}, ico: {{option.ico}}</span>
        </div>
        -->
      </template>

      <template v-if="inputObj.templates.option_template_type == 'user'" v-slot:option="{ option, search }">
        <div class="d-flex align-items-center">
          <image-component
              v-if="option.obrazek_id"
              :image-id="option.id"
              :is_preview_only="true"
              thumb_class="user-thumb rounded-circle"
              class="flex-shrink-0 nahled "
          />
          <div class="flex-grow-1 ms-1">
            <div v-if="option.title" class="fw-light fs-6">
              <span class="fw-medium">{{ option.title }}</span>
              <span v-if="option.login" class="ms-2"><i class="bi bi-credit-card-2-front" style="font-size: .8rem;"></i> {{ option.login }}</span>
            </div>
          <!--
            <div v-if="option.email" class="mt-3 mb-1 text-secondary"><i class="bi bi-envelope-at" style="font-size: .7rem;"></i> {{ option.email }}</div>
            <div v-if="option.login" class="text-secondary"><i class="bi bi-credit-card-2-front" style="font-size: .7rem;"></i> Login: {{ option.login }}</div>
          -->
          </div>
        </div>
      </template>

      <template v-slot:noResult>
        <div class="multiselect--no-results">Zadne vysledky pro tento dotaz</div>
      </template>

      <template v-slot:noOptions>
        <div class="multiselect--no-results">Zadne vysledky pro tento dotaz [noOption]</div>
      </template>
    </multiselect>
  </div>

  <div v-else-if="inputObj.type == selectTyp.ajax_search">

    <multiselect
        v-model="selectModel"
        :options="options"
        label="select input"
        track-by="id"
        :custom-label="formatAjaxItem"
        :searchable="true"
        :hideSelected="false"
        :disabled="inputObj.readonly"
        :placeholder="inputObj.templates.placeholder.select"
        @search-change="asyncSearch"
        @open="asyncSearch"
    >

      <template v-if="inputObj.templates.option_template_type == 'simple'" v-slot:singleLabel="{ option }">
          <span>{{option.title}}</span>
      </template>

      <template v-if="inputObj.templates.option_template_type == 'simple'" v-slot:option="{ option, search }">
        <span>{{option.title}}</span>
      </template>


      <template v-if="inputObj.templates.option_template_type == 'zakaznik'" v-slot:singleLabel="{ option }">
        <strong>{{ option.jmeno }} </strong>
        <small v-if="option.je_vip" class="ms-2 badge bg-success">VIP</small>
        <small class="ms-2">{{option.email}}</small>
        <small class="ms-2">{{option.telefon}}</small>
      </template>


      <template v-if="inputObj.templates.option_template_type == 'zakaznik'" v-slot:option="{ option, search }">
        <strong>{{option.jmeno}}</strong>
        <small v-if="option.je_vip==1" class="ms-2 badge bg-success">VIP</small>
        <small class="ms-2">{{option.email}}</small>
        <small class="ms-2">{{option.telefon}}</small>
      </template>


      <template v-slot:noResult>
        <div class="multiselect--no-results">{{inputObj.templates.placeholder.empty_list_search}}</div>
      </template>

      <template v-slot:noOptions>
        <div class="multiselect--no-results"> {{inputObj.templates.placeholder.empty_list_start}}</div>
      </template>


    </multiselect>
  </div>

  <div v-else-if="inputObj.type == selectTyp.simple">
    <pre>selectTyp {{selectTyp}}</pre>
    <pre>{{modelValue}}</pre>
    <select
        class="x"
        v-model="inputModel"
      >
        <!---->
        <option
            v-for="(optionItem, optionIndex)  in inputObj.templates.data"
            @key="optionIndex"
            :value="optionItem.id"
        >
          {{optionItem.title}}
        </option>


      </select>

  </div>

  <div v-if="inputObj.type=='select_search'">
    <pre>{{x_value}}</pre>
    <pre>[select_search]</pre>
    <!--
    <multiselect
        v-model="x_value"
        :options="items"
        :searchable="false"
        :close-on-select="false"
        :show-labels="false"
        placeholder="Pick a value">

    </multiselect>

    <multiselect
        v-model="x_value"
        :options="title"
        :searchable="false"
        :close-on-select="false"
        :show-labels="false"
        placeholder="Pick a value"
    />



    <multiselect
        v-model="x_value"
        :options="inputObj.templates.data"
        :searchable="false"
        :close-on-select="false"
        :show-labels="false"
        placeholder="Pick a value"
    />


    -->
  </div>

  <div v-else-if="inputObj.type=='select_ajax'">
    <pre>[select_search]</pre>
  </div>


<!--
  <pre>{{inputModel}}</pre>
  <pre>{{inputObj}}</pre>
  -->
</template>

<script>
let timer;
import multiselect  from 'vue-multiselect'
import axios from "axios";
import ImageComponent from "@/components/general/Image";
import CountryFlag from "vue-country-flag-next"
export default {
  name: "SelectInput",
  components: {
    multiselect,
    ImageComponent,
    CountryFlag,
    },
  props: {
    "inputObj": Object,
    "formObj": Object,
    "modelValue": [String, Number, Object],
  },
  data() {
    return {
      selectModel: null,
      options: [],
      selectTyp: {
        "simple": "simple",
        "simple_search": "simple_search",
        "ajax_search": "ajax_search",
      },
    }
  },
  methods: {
    async asyncSearch (query) {
      if(timer) clearInterval(timer);
      timer = setTimeout(
          () => {
            axios.defaults.baseURL = this.getBaseRoot();
            let apiPath = this.inputObj.templates.search_path
            if (query) apiPath += query
            axios({
              url: apiPath,
              method: "get",
              withCredentials: true,
              headers: this.getRequestHeader(),
            }).then((res) => {
              this.inputObj.templates.data = res.data.response.list.data;
              this.options = res.data.response.list.data;
            })
            .catch((err) => {
              this.inputObj.templates.data = []
              console.log("[dev.asyncSearch] catch", err);
            })
            .finally(() => {
              //console.log("DEV tags finally");
            })
          },
          1
      )
    },
    formatSelectItem ({ id, title, email, login }) {
      return `${title}  ${id} ${email} ${login}`
    },
    formatAjaxItem ({ id, title, email, je_vip }) {
      return `${title}  ${id} ${email} ${je_vip}`
    },


    getSimpleDetail(id){
      axios.defaults.baseURL = this.getBaseRoot();
      axios.get(
          this.inputObj.templates.detail_path + id,
          {
            headers: this.getRequestHeaderUpload()
          }
      ).then((res) => {
        if (res.data.status) {
          const selectModel = {};
          for(const k in res.data.response.detail) {
            selectModel[k] = res.data.response.detail[k];
          }
          this.selectModel = selectModel;
          /*
          this.selectModel = {
            id: res.data.response.detail.id,
            title: res.data.response.detail.title,
            obrazek_id: res.data.response.detail.obrazek_id
          }
          */
          //console.log("dev getSimpleDetail result",id, this.inputObj.templates.detail_path, res.data.response.detail)
        }else{
          this.selectModel = null;
        }
      }).catch((err) =>{
        this.selectModel = null;
      }).finally(() => {

      })
    }
  },

  mounted() {
    if (this.inputObj.val && this.inputObj.templates && this.inputObj.templates.detail_path){
      this.getSimpleDetail(this.inputObj.val)
    }
  },
  watch: {
    inputObj: {
      handler(newVal) {
        //console.log('dev.select.input watch inputObj',this.inputObj.val, newVal)
        if(this.inputObj.val){
          this.getSimpleDetail(this.inputObj.val)
        }
        this.inputModel = this.inputObj.val;
      },
      deep: true,
      immediate: true
    },
    inputModel( newVal){
       //todo overit zbytecnost?
      //console.log('dev.select.input watchinputModel', newVal)

      if(inputObj.type == this.selectTyp.simple){
        this.$emit('update:modelValue', newVal )
        this.inputObj.val = newVal
      }else if(inputObj.type == this.selectTyp.simple_search){
        this.$emit('update:modelValue', newVal )
        this.inputObj.val = newVal
      }
    },
    selectModel( newVal){
      //console.log('dev.select.input selectModel', newVal)
      if (newVal && typeof newVal == 'object'){
        this.$emit('update:modelValue', newVal.id )
        this.inputObj.val = newVal.id
      }else{
        this.inputObj.val = newVal
        this.$emit('update:modelValue', newVal )
      }
    },
  },
};

</script>