<template >
  <div >
    <div
        class="d-grid d-md-flex flex-wrap mb-2"
    >
      <div class="d-none d-sm-block d-md-flex p-0">
        <label for="pass_original" class="form-label d-flex align-self-center m-0">Heslo</label>
      </div>
      <div class="mb-sm-2 mb-md-0 ms-md-2">
        <input
            type="password"
            v-model="password.original"
            @input="checkPassword(); password.generated = false;"
            id="pass_original"
            class="form-control d-md-flex"
            placeholder="heslo"
        />
      </div>
      <div class="d-none d-sm-blockW d-md-flex p-0 ms-md-2">
        <label for="pass_confirm" class="form-label d-flex align-self-center m-0">Heslo confirm</label>
      </div>
      <div class="ms-md-2">
        <input
            type="password"
            v-model="password.confirm"
            @input="checkPassword(); password.generated = false;"
            class="form-control d-md-flex "
            placeholder="heslo potvrzeni"
            id="pass_confirm"
            value=""
        />
      </div>

    </div>

    <div class="d-md-flex align-items-start">
      <button @click.prevent="passGenerate(); checkPassword();" class="btn btn-secondary p-1 me-3"><i class="bi bi-balloon"></i> Generovat heslo</button>
      <div v-if="password.original && password.err" class="fs-6 text-danger p-1 me-3">Chyba: {{password.err}}</div>
      <div v-if="password.generated" class="alert alert-info p-1">Heslo: <strong>{{password.original}}</strong></div>

    </div>

<!--
    <hr/>

    <div
        class="d-grid d-md-flex flex-wrap mb-2 border border-secondary rounded-2"
        xclass="input-group flex-nowrap"
    >
      <span class="border border-warning --input-group-text">Heslo</span>
      <input
          type="password"
          v-model="password.original"
          @input="password.generated = false;"
          class="form-control d-md-flex"
          placeholder="heslo"
      />
      <span class="flex-fill --input-group-text">Heslo znovu</span>
      <input
          type="password"
          v-model="password.confirm"
          @input="password.generated = false;"
          class="form-control d-md-flex "
          placeholder="heslo potvrzeni"
      />
      <button @click.prevent="passGenerate" class="btn btn-secondary"><i class="bi bi-balloon"></i> Generovat heslo</button>
    </div>
    <div v-if="password.original && password.err" class="alert alert-danger">Chyba: {{password.err}}</div>
    <div v-if="password.generated" class="alert alert-info">Heslo: <strong>{{password.original}}</strong></div>
-->
  </div>
</template>

<script>
import axios from "axios";

let timer;
export default {
  name: "PasswordInput",
  props: {
    "inputObj": Object,
    "formObj": Object,
    "modelValue": [String, Number],
  },
  data() {
    return {
      password: {
        original: null,
        confirm: null,
        status: null,
        is_valid: false,
        min_lenght: 5,
        err: null,
        generated: false,
      },
      inputModel: null,
      timer: null,
      timer_delay: 500,
    }
  },
  mounted() {
    this.inputModel = this.inputObj.val;
  },
  methods: {
    passGenerate() {
      var pass = '';
      var str = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ' +
          'abcdefghijklmnopqrstuvwxyz0123456789@#$';

      for (let i = 1; i <= 20; i++) {
        var char = Math.floor(Math.random() * str.length + 1);

        pass += str.charAt(char)
      }


      this.password.original = pass;
      this.password.confirm = pass;
      this.password.generated = true;
    },
    async checkPassword(){
      if(timer) clearInterval(timer);
      timer = setTimeout(
          () => {
            //console.log('dev_zmena_hesla 2', this.password)
            this.checkPasswordCore();
          },
          750
      )
    },
    async checkPasswordCore(){
      const formData = new FormData;
      //formData.append(this.cfg.api.user.check_password.input.password, this.password.original)
      //formData.append(this.cfg.api.user.check_password.input.password_confirm, this.password.confirm)
      formData.append(this.getApiConfig('user.check_password.input.password'), this.password.original)
      formData.append(this.getApiConfig('user.check_password.input.password_confirm'), this.password.confirm)

      axios.defaults.baseURL = this.getBaseRoot();
      await axios({
        url: this.getApiConfig('user.check_password.path'),
        method: this.getApiConfig('user.check_password.method'),
        data: formData,
      }).then((res) => {
        if(res.data.status) {
          this.password.is_valid = true
          this.password.status = 'ok';
          this.password.err = null;
          this.$emit('update:modelValue', this.password.original)
        }else{

          this.password.is_valid = false;
          //this.password.error = res.data.response.error;
          this.password.status = 'fail';
          this.password.err = res.data.response.error;
          this.$emit('update:modelValue', null)
        }
      });
    }
  },
  watch: {
    password: {
      handler(val) {

      },
      deep: true,
      immediate: true
    },


    inputObj: {
      handler() {
        this.inputModel = this.inputObj.val;
      },
      deep: true,
      immediate: true
    },
    inputModel( newVal){

      //timer
      if(this.timer) clearInterval(this.timer);
      const delay = this.timer_delay
      const pseudo_this = this;
      this.timer = setTimeout(
          function() {
          //start timer function content
            //tohle je slug input a jeho prevod na spravny format
            if (
                typeof pseudo_this.inputObj.slug != 'undefined'
                && typeof pseudo_this.inputObj.slug.is_slug != 'undefined'
            ){
              console.log("dev slug inputObj SLUG format")
              newVal = pseudo_this.createSlugFormat(newVal);
              pseudo_this.inputModel = newVal;
            }

            //pokud z inputu vytvarim dalsi inpu ve formatu slug
            //console.log("dev slug target", pseudo_this.formObj[pseudo_this.inputObj.slug.section].inputs[pseudo_this.inputObj.slug.input])
            //pseudo_this.formObj[pseudo_this.inputObj.slug.section].inputs[pseudo_this.inputObj.slug.input].val = newVal

            if(
                typeof pseudo_this.inputObj.slug != 'undefined'
                && typeof pseudo_this.inputObj.slug.section != 'undefined'
                && typeof pseudo_this.inputObj.slug.input != 'undefined'
                && typeof pseudo_this.formObj[pseudo_this.inputObj.slug.section].inputs[pseudo_this.inputObj.slug.input].disable_auto_fill == 'undefined'
            ) {
              pseudo_this.formObj[pseudo_this.inputObj.slug.section].inputs[pseudo_this.inputObj.slug.input].val = newVal
            }

            //update parent
            pseudo_this.$emit('update:modelValue', newVal )
          //end timer function content
          },
          this.timer_delay
      )
    },
  },
};
</script>