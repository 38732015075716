<template >
  <div
      v-for="(langItem, langIndex)  in langs"
  >
    <label :for="parentIndex + '_lang_' + langIndex">
      <country-flag
          :country='langItem.flag'
          size="small"
      />
      <span class="ms-2 d-inline-block">{{langItem.title}}</span>
    </label>
    <textarea
        type="text"
        v-model="inputModel[langIndex]"
        :id="parentIndex + '_lang_' + langIndex"
        :ref="'input_lang_' + langIndex"
        class="form-control"
        @input.prevent="writeStatus=true;"
    />
  </div>


  <!--
  <display-debug>
    <template #debugView>options {{options}}</template>
  </display-debug>

  <display-debug>
    <template #debugView>inputModel {{inputModel}}</template>
  </display-debug>
  <display-debug>
    <template #debugView>writeStatus {{writeStatus}}</template>
  </display-debug>

  <display-debug>
    <template #debugView>langs {{langs}}</template>
  </display-debug>


-->
</template>

<script>


import axios from "axios";

let timer;
import CountryFlag from "vue-country-flag-next"
export default {
  name: "WikiTextareaLang",
  components: {
    CountryFlag
  },
  props: {
    "modelValue": {
      type: Object,
      required: false,
      default: {}
    },
    "langs": [String, Object],
    "parentIndex": [String, Number],
    "options": {
      type: Object,
      required: false,
      default: {}
    }
  },
  emits: ['update:modelValue'],
  data() {
    return {
      writeStatus: false,
      inputModel: null,
    }
  },
  created() {
    const emptyValue = {}
    for(const l in this.langs) {
      emptyValue[l] = null;
    }
    this.inputModel = emptyValue;
  },
  mounted() {
    //naplneni hodnotami
    if(this.modelValue && Object.keys(this.modelValue).length > 0){
      for(const l in this.langs) {
        if(typeof this.modelValue[l] != "undefined"){
          this.inputModel[l] = this.modelValue[l];
        }
      }
    }
  },
  methods: {
      async formatSlug(value) {
        if(value) {
          for(const l in value) {
            axios.defaults.baseURL = this.getBaseRoot();
            await axios({
              url: this.getApiConfig('general.format_slug.path') + "?" + this.getApiConfig('general.format_slug.input.slug') +"=" + value[l],
              method: this.getApiConfig('general.format_slug.method'),
              withCredentials: true,
              headers: this.getRequestHeader(),
            }).then((res) => {
              if(res.data.status){
                if(res.data.response.slug && res.data.response.slug != "null"){
                  value[l] = res.data.response.slug;
                }
              }
            }).catch((err) => {
              console.log("dev format slug catch", err)
            })
          }
        }

        return value;
      }
  },
  watch: {
    options: {
      handler(newVal) {
        if(newVal && newVal.is_slug){
          for(const l in newVal.makeFrom) {
            /*
            if(!this.writeStatus ){

            }

            if(this.inputModel[l].length == 0 ){

            }
            */
          }
        }
      },
      deep: true,
      immediate: true
    },
    inputModel: {
      handler(newVal) {
        if(timer) clearInterval(timer);
        timer = setTimeout(
            () => {
              if(this.options.is_slug) {
                this.formatSlug(newVal);
              }
              this.$emit('update:modelValue', newVal )
            },
            300
        )
      },
      deep: true,
      immediate: true
    },
  },
};
</script>