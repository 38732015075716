<template>
  <ul class="list-group">
    <li v-if="data.per_kus" class="list-group-item">
      a {{data.per_kus}} {{data.mena}}
    </li>
    <li v-if="data.produkty" class="list-group-item">
      Produkty: {{data.produkty}} {{data.mena}}
    </li>
    <li v-if="data.doprava" class="list-group-item">
      Doprava: {{data.doprava}} {{data.mena}}
    </li>
    <li v-if="data.platba" class="list-group-item">
      Platba: {{data.platba}} {{data.mena}}
    </li>
    <li v-if="data.celkem_bez_dane" class="list-group-item">
      Bez dane: {{data.celkem_bez_dane}} {{data.mena}}
    </li>
    <li v-if="data.dph" class="list-group-item">
      DPH: {{data.dph}} {{data.mena}}
    </li>


    <li
        v-if="data.sleva"
        class="list-group-item"
    >
      Sleva: {{data.sleva}} {{data.mena}}
    </li>



    <li v-if="data.celkem" class="list-group-item">
      Celkem: {{data.celkem}} {{data.mena}}
    </li>
  </ul>

<!--
  <pre>cena: {{data}}</pre>
-->

</template>
<script>
export default {
  name: "BlockCena",
  props: {
    "data": Object,
  }
};
</script>
