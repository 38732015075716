<template >

  <!--
   [ObsahLinkSegment]
  <pre>
    inputObj {{inputObj}}
  </pre>
  <pre v-if="selectModel">
    selectModel {{selectModel}}
  </pre>

   -->



  <div v-if="selectModel" class="nahled">
    <image-preview
        v-if="selectModel.obrazek_id"
        :image-id="selectModel.obrazek_id"
    >
    </image-preview>
    <h2>{{selectModel.title}}</h2>
    <template v-if="selectModel.datum_publikace">publikovano: {{selectModel.datum_publikace}} <br></template>
    <template v-if="selectModel.kategorie_title">sekce: {{selectModel.kategorie_title}} <br></template>
    <template v-if="selectModel.user_title">autor: {{selectModel.user_title}} <br></template>
  </div>


  <input
      v-if="inputObj.params.extended && selectModel && selectModel.id"
      v-model="selectModel.title"
      type="text"
  >

  <VueMultiselect
      v-model="selectModel"
      :options="options"
      track-by="id"
      :custom-label="formatSelectItem"
      placeholder="Vyber hodnoty"
      @search-change="asyncSearch"
  >

    <template v-slot:singleLabel="{ option }">
      <strong style="font-size: 1.2rem;">{{ option.title }}</strong><br>
      <span v-if="option.obrazek_id" style="display:inline-block; max-height: 50px;">
                <image-preview :image-id="option.obrazek_id" />
      </span>
      <span v-if="option.kategorie_title">Kategorie: {{option.kategorie_title}}</span>
      <span v-if="option.datum_publikace">datum: {{option.datum_publikace}}</span>
    </template>

    <template v-slot:option="{ option, search }">
      <strong style="font-size: 1.2rem;">{{ option.title }}</strong><br>
      <span v-if="option.obrazek_id"  style="display:inline-block; max-height: 50px;">
          <image-preview :image-id="option.obrazek_id" />
      </span>
      <span v-if="option.kategorie_title">Kategorie: {{option.kategorie_title}}</span>
      <span v-if="option.datum_publikace">datum: {{option.datum_publikace}}</span>
      <!--
      <div>
        <pre>search {{option}}</pre>
      </div>

      <pre>search {{search}}</pre>
      -->
    </template>

    <template v-slot:noOptions>
      <div class="multiselect--no-results">Vyhledejte článek pomocí názvu</div>
    </template>

    <template v-slot:noResult>
      <div class="multiselect--no-results">Žádné výsledky pro tento dotaz.</div>
    </template>


  </VueMultiselect>


</template>

<script>
import VueMultiselect from 'vue-multiselect'
//import "vue-multiselect/dist/vue-multiselect.css"
import axios from "axios";
import ImagePreview from "@/components/general/ImagePreview";
let timer;
export default {
  name: "ObsahLinkSegment",
  components: {
    VueMultiselect,
    ImagePreview,

  },
  props: {
    "segmentsObj": [Object, Array],
    "inputObj": Object,
    "formObj": Object,
    "modelValue": [String, Object],
  },
  emits: ['update:modelValue'],
  data() {
    return {
      inputModel: null,
      options: [],
      selectModel: null
    }
  },
  created() {
    //console.log('link segment mounted selectModel', this.inputObj)
    this.selectModel = this.inputObj.obsah;
  },
  methods: {
    async asyncSearch (query) {
      //console.log("dev asyncFind", query)

      if(timer) clearInterval(timer);
      timer = setTimeout(
          () => {
            //this.is_input_change = false;
            //console.log('dev delay 1000',query, this.selectModel)
            axios.defaults.baseURL = this.getBaseRoot();
            axios({
              url: this.getApiConfig('obsah.link.path') + query,
              method: "get",
              withCredentials: true,
              headers: this.getRequestHeader(),
            }).then((res) => {
              this.options = res.data.response.list.data;
              //console.log("dev tags data", res.data.response.list.data)
            })
                .catch((err) => {
                  this.options = []
                  //console.log("DEV tags catch", err);
                })
                .finally(() => {
                  //console.log("DEV tags finally");
                })
          },
          500
      )
    },
    formatSelectItem ({ id, title }) {
      return `${title}`
    },
  },
  watch: {
    inputObj: {
      handler() {
        this.inputModel = this.inputObj.val;
      },
      deep: true,
      immediate: true
    },
    selectModel: {
      handler(newVal) {
        if(newVal) {
          //console.log('link segment emit this.selectModel', this.selectModel)
          //console.log('link segment emit selectModel', newVal)
          //DEV
          this.$emit('update:modelValue', newVal )
        }

      },
      deep: true,
      immediate: true
    },
  },
};
</script>