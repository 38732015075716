const texts ={
  global: {
    doc_title_suffix: 'CMS IVN-5',
  },
  image_component: {
    btn_image_upload_title: "Náhrát obrázek",
    btn_images_upload_title: "Náhrát obrázky",
    btn_image_upload_size_title: "Náhrát jen tuto velikost obrázku",
    placeholder_file: "Vyberte obrázek",
    placeholder_files: "Vyberte obrázky",
    placeholder_title: "Popisek obrázku",
    placeholder_copyright: "Informace o autorství",
    placeholder_browser: "Vyhledejte obrázek podle popisu, copyrightu nebo autora",
    upload_fail: "Nepodařilo se nahrát obrázek, kontaktujte správce systému.",
    loading_title: "Nahrávám obrázek, počkejte chvilku.",
    non_thumb_title: "Neexistuje náhled obrázek",
    remove_image_title: "Odebrat obrázek",
    remove_image_confirm: "Opravdu chcete odebrat tento obrázek?",
    multiselect: {
      no_result: "Žádné výsledky pro toto vyhledávání",
    }
  },


  "testovaci": "testovaci text",
}

export default texts;