<template >
  <div >
    <div
        class="d-grid d-md-flex flex-wrap mb-2"
    >
      <div class="d-none d-sm-block d-md-flex p-0">
        <label for="pass_original" class="form-label d-flex align-self-center m-0">Začátek</label>
      </div>
      <div class="mb-sm-2 mb-md-0 ms-md-2">
        <input
            type="datetime-local"
            v-model="inputModel.zacatek"
            id="pass_original"
            :class="'form-control d-md-flex' + (validation.zacatek.status === false?' is-invalid':'')"
            placeholder="heslo"
        />

        <span v-if="validation.zacatek.status === false" class="invalid-feedback">{{validation.zacatek.message}}</span>
      </div>
      <div class="d-none d-sm-blockW d-md-flex p-0 ms-md-2">
        <label for="pass_confirm" class="form-label d-flex align-self-center m-0">Konec</label>
      </div>
      <div class="ms-md-2">
        <input
            type="datetime-local"
            v-model="inputModel.konec"
            :class="'form-control d-md-flex' + (validation.konec.status === false?' is-invalid':'')"
            placeholder="heslo potvrzeni"
            id="pass_confirm"
        />

        <span v-if="validation.konec.status === false" class="invalid-feedback">{{validation.konec.message}}</span>
      </div>
    </div>

  </div>

  <div v-if="inputObj.templates && inputObj.templates.type=='rezervace_datumy'">
    <span
        class="btn btn-primary me-1 mb-1"
        v-for="(segmentBtnItem, segmentBtnIndex)  in inputObj.templates.list"
        @click="prefillDates(segmentBtnItem)"
    >
          {{segmentBtnItem.title}}
    </span>

  </div>




  <!--
    <pre>{{inputObj}}</pre>
      <pre>{{validation}}</pre>
      <pre>{{inputModel}}</pre>
  -->
</template>

<script>

export default {
  name: "DateRangeInput",
  props: {
    "inputObj": Object,
    "formObj": Object,
    "modelValue": [Array, Object],
  },
  data() {
    return {
      validation: {
        zacatek: {
          status: true,
          message: null,
        },
        konec: {
          status: true,
          message: null,
        },
      },
      password: {
        original: null,
        confirm: null,
        status: null,
        is_valid: false,
        min_lenght: 5,
        err: null,
        generated: false,
      },
      inputModel: {
        zacatek: null,
        konec: null,
      },
      timer: null,
      timer_delay: 500,
    }
  },
  mounted() {

    /*
    if(!this.inputObj.val){
      console.log('je prazdne datum zacatek/konec')
      this.inputModel = {
        zacatek: null,
        konec:null,
      }
    }else{
      this.inputModel = this.inputObj.val;
    }
    */
  },
  methods: {
    prefillDates(item) {
      this.inputModel = {
        zacatek: item.date_from,
        konec: item.date_to
      }
    },
    validateDateRange(dates) {
      let status = true;
      this.validation = {
        zacatek: {
          status: true,
          message: null,
        },
        konec: {
          status: true,
          message: null,
        },
      }

      if(!dates.zacatek) {
        status = false;
        this.validation.zacatek = {
          status: false,
          message: 'Vyplňte začátek platnosti',
        }
      }

      if(!dates.konec) {
        status = false;
        this.validation.konec = {
          status: false,
          message: 'Vyplňte konec platnosti',
        }
      }


      if(this.validation.zacatek.status != false && this.validation.konec.status != false) {
        const dtFrom = new Date(dates.zacatek);
        const dtTo = new Date(dates.konec);
        if(dtFrom > dtTo) {
          status = false;
          this.validation.konec = {
            status: false,
            message: 'Datum konce musí být až po datu začátku!',
          }
        }
      }
      return status ;
    },
  },
  watch: {
    inputObj: {
      handler() {
        if(this.inputObj.val) {
          this.inputModel = this.inputObj.val;
        }

      },
      deep: true,
      immediate: true
    },
    inputModel: {
      handler(newVal, oldVal) {

        if(this.validateDateRange(newVal)){
          this.$emit('update:modelValue', newVal)
        }else{
          //console.log('Neplatne datum')
          this.$emit(
              'update:modelValue',
              {
                zacatek: null,
                konec: null
              }
          )

        }
      },
      deep: true,
      immediate: true
    },
  },
};
</script>