<template >
  <div class="form-check">
    <!--
    <h2>TextInput - {{inputObj.title}} [{{inputObj.display}}]</h2>
    -->

    <label for="check_box_simple" class="form-check-label">
    <input
        type="checkbox"
        value="1"
        v-model="inputModel"
        id="check_box_simple"
        class="form-check-input"
    />
      <span>{{inputObj.templates.title}}</span>
    </label>
  </div>

  <!--
    <pre>inputObj:: {{inputModel}}</pre>
  -->
</template>
<script>

export default {
  name: "CheckboxSimpleInput",
  props: {
    "inputObj": Object,
    "formObj": Object,
    "modelValue": [Boolean, Number],
  },
  data() {
    return {
      inputModel: null,
    }
  },
  mounted() {
    if(this.inputObj.val != null && this.inputObj.val != 0) {
      this.inputModel = true;
    }else{
      this.inputModel = false;
    }
  },
  methods: {
    inputClick() {
      if(this.inputObj.slug && this.inputObj.slug.is_slug){
        this.inputObj.slug.disable_auto_fill = true;
      }
    }
  },
  watch: {
    inputModel: {
      handler(newVal) {
        this.$emit('update:modelValue', newVal )
      },
      deep: true,
      immediate: true
    },
  },
};
</script>