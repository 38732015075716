<template >
  <div class="card">
    <div
        v-for="(mena, m) in inputObj.templates.mena"
        :key="m"
        class="row mt-2 card-body"
    >
      <div class="col col-3 col-md-1">
        <country-flag
            :country='mena.flag'
            size="small"
        /> &nbsp;
        {{mena.title}}
      </div>


      <div
          v-html="displayPrice(inputModel[mena.ident])"
          class="col col-9  col-md-2"
      >
      </div>

      <div class="col col-12  col-md-3">
        <div class="input-group">
          <div class="input-group-text">Standartni</div>
          <input
              type="number"
              v-model="inputModel[mena.ident].regular"
              min="0"
              :step=inputObj.templates.form.regular.step
              :required="inputObj.required"
              class="form-control d-inline"
          />

        </div>
      </div>

      <div class="col col-12 col-md-3" v-if="inputObj.templates.is_sale">
        <div class="input-group">
          <div class="input-group-text">Sleva</div>

          <input
              type="number"
              v-model="inputModel[mena.ident].sale"
              min="0"
              :max="inputModel[mena.ident].regular"
              :step=inputObj.templates.form.sale.step
              @change="setUpSalemanual(mena.ident, inputModel[mena.ident])"
              class="form-control "
          />

        </div>
      </div>

      <div class="col col-12 col-md-3" v-if="inputObj.templates.is_sale">
        <div class="row mt-sm-2 mt-md-0">
          <div class="col col-3 text-end">
            <span class="">{{inputModel[mena.ident].saleProc}}%</span>
          </div>
          <div class="col col-9">
            <input
                type="range"
                v-model="inputModel[mena.ident].saleProc"
                from="0"
                to="100"
                @change="setUpSaleRange(mena.ident, inputModel[mena.ident])"
                class="form-range"
            >
          </div>
        </div>
        <!--

        -->

      </div>

      <div
          v-if="inputModel[mena.ident].err"
          class="col col-12 col-md-12 text-danger"
      >
        {{inputModel[mena.ident].err}}
      </div>
    </div>
  </div>




  <!--
   <pre>{{inputModel}}</pre>
  .regular
  <pre>inputObj {{inputObj.val}}</pre>


  <pre>{{inputObj.templates}}</pre>
-->
</template>

<script>

import axios from "axios";
import CountryFlag from "vue-country-flag-next"
import multiselect from "vue-multiselect";
import ImageComponent from "@/components/general/Image";
export default {
  name: "PriceInput",
  components: {
    CountryFlag,
  },
  props: {
    "inputObj": Object,
    "formObj": Object,
    "modelValue": [String, Number, Object],
  },
  data() {
    return {
      inputModel: {},
      tax: {},
      tax_proc_value: 21,
      timer: null,
      timer_delay: 500,
      dev_key: 'czk',
    }
  },
  created() {
    this.inputModel = {};
    for(const m_key in this.inputObj.templates.mena){
      let mena = {}
      mena[this.inputObj.templates.form.regular.ident] = null;
      //TODO podminak

      if(this.inputObj.templates.is_sale) {
        mena[this.inputObj.templates.form.sale.ident] = null;
        mena['saleProc'] = 0;
      }
      this.inputModel[this.inputObj.templates.mena[m_key].ident] = mena
    }
    if(this.inputObj.val) {
      for(const v_key in this.inputObj.val){
        for(const i_key in this.inputModel[v_key]) {
          this.inputModel[v_key][i_key] = this.inputObj.val[v_key][i_key];
        }
      }
    }
  },
  methods: {
    displayPrice(prices){
      let display_full
      if(prices.sale) {
        display_full = prices.regular - prices.sale;
      }else{
        display_full = prices.regular;
      }

      if(this.tax && this.tax.hodnota && display_full) {
        display_full = display_full + ' <small class="ms-1">(bez daně: ' + (display_full/(parseInt(100 + parseInt(this.tax.hodnota))/100)).toFixed(2) + ')</small> ';
      }
      return display_full;
    },

    setUpSaleRange(code, prices){
      if(prices.regular){
        this.inputModel[code].sale =  prices.regular * (prices.saleProc/100)
      }
    },
    setUpSalemanual(code, prices){
      if(prices.regular){
        this.inputModel[code].saleProc = parseInt(prices.sale/prices.regular * 100)
      }
    },
    async getTaxDetail(id){
      if(id) {
        axios.defaults.baseURL = this.getBaseRoot();
        //let apiPath = this.cfg.api.eshop.get_tax_detail.path + '/' + id
        let apiPath = this.getApiConfig('eshop.get_tax_detail.path') + '/' + id

        axios({
          url: apiPath,
          //method: this.cfg.api.eshop.get_tax_detail.method,
          method: this.getApiConfig('eshop.get_tax_detail.method'),
          withCredentials: true,
          headers: this.getRequestHeader(),
        }).then((res) => {
          //this.server_upload.server_list = res.data.response.files;
          //console.log("dev getTaxDetail data", res.data.response.detail.hodnota)
          this.tax = res.data.response.detail;
          //this.tax_proc_value = res.data.response.detail.hodnota;
        })
            .catch((err) => {
              this.tax = {}
              console.log("dev tax  getTaxDetail catch", err);
            })
      }else{
        this.tax = {}
      }

    },


  },
  watch: {
    'formObj.general.inputs.tax_id.val': {
      handler(newVal, oldVal) {
        if(newVal !=  oldVal){
          this.getTaxDetail(newVal)
        }
      },
      deep: true,
      immediate: true
    },

    tax: {
      handler(newVal) {
        console.log('dev tax', newVal)

      },
      deep: true,
      immediate: true
    },

    inputObj: {
      handler() {
        this.inputModel = this.inputObj.val;
      },
      deep: true,
      immediate: true
    },


    inputModel: {
      handler(newVal, oldVal) {
        //console.log('dev watch inputModel X', newVal, oldVal)
        for(const key in newVal){
          if(newVal[key].sale && newVal[key].sale > newVal[key].regular) {
            newVal[key].err = "Cena po sleve nemuze byt vetsi nez puvodni";
          }else{
            delete newVal[key].err;
          }

          console.log('dev watch inputModel item',key, newVal[key])
        }
        this.$emit('update:modelValue', newVal )
      },
      deep: true,
      immediate: true
    },

  },
};
</script>