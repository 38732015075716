<template>
  <div
    v-if="structure"
    class="dashboards-wrap"
  >
    <div
      v-for="(grid, index) in structure"

      class="dashboard-wrap"
   >
      <!--
      <h2>{{grid.grid.title}}</h2>
      -->
      <div
          v-if="grid && grid.grid"
          :class="'dashboard ' + (grid.grid.type?(' grid-' + grid.grid.type):' grid-default')"
      >
        <div
            v-for="(item, index) in grid.items"
            :key="grid.title"
            :class="'card grid-item '+ item.class"
        >

          <template
              v-if="item.content.type == 'html'"

          >
            <!--
            <div class="jumbotron m-2">
              <h1 class="display-6 fw-light">Hello, world!</h1>
              <p class="lead">This is a simple hero unit, a simple jumbotron-style component for calling extra attention to featured content or information.</p>
            </div>
            -->
            <div v-html="item.content.html"></div>

          </template>

          <template
              v-else-if="item.content.type == 'text'"
          >
            <div class="card-body">
              <h5
                  v-if="item.content.title"
                  class="card-title text-primary"
              >
                {{item.content.title}}
              </h5>
              <p
                  v-if="item.content.text"
                  class="card-text fs-6 text-secondary fw-light"
              >
                {{item.content.text}}
              </p>

              <template
                  v-if="item.content.btns"
              >
                <router-link
                    v-for="(btn) in item.content.btns"
                    :class="'mb-1 me-1 ' + btn.class"
                    :to="btn.path"
                >
                  <i v-if="btn.ico_pre" :class="btn.ico_pre"></i>
                  <span v-if="btn.title">{{btn.title}}</span>
                  <i v-if="btn.ico_post" :class="btn.ico_post"></i>
                </router-link>
              </template>
            </div>
          </template>

          <template
              v-else-if="item.content.type == 'btns'"
          >
            <div class="h-100">
              <div class="title fs-5 fw-lighter text-primary text-start w-100 ps-2">
                {{item.title}}
              </div>

              <div
                  :class="'btns-wrap ms-auto me-auto' + (item.content.class? (' '+ item.content.class):'')"
              >
                <template
                    v-for="(btn) in item.content.btns"
                >
                  <router-link
                      v-if="btn.type == 'router'"
                      :class="btn.class"
                      :to="btn.path"
                  >
                    <div>
                      <i v-if="btn.ico" :class="'icon ' + btn.ico"></i>
                      <span v-if="btn.title" class="title">{{btn.title}}</span>
                    </div>
                  </router-link>
                  <a
                      v-else-if="btn.type == 'link'"
                      :class="btn.class"
                      :href="btn.path"
                      :target="btn.target? btn.target: '_self'"
                  >
                    <div>
                      <i v-if="btn.ico" :class="'icon ' + btn.ico"></i>
                      <span v-if="btn.title" class="title">{{btn.title}}</span>
                    </div>
                  </a>
                  <div
                      v-else-if="btn.type == 'info'"
                      :class="btn.class"
                  >
                    <div>
                      <i v-if="btn.ico" :class="'icon ' + btn.ico"></i>
                      <span v-if="btn.title" class="title">{{btn.title}}</span>
                    </div>
                  </div>

                  <display-debug v-else>
                    <template #debugView>structure FULL {{structure}}</template>
                  </display-debug>
                </template>
                <!--

                -->
              </div>
            </div>


          </template>

          <template
              v-else-if="item.content.type == 'graph'"
          >
            [TODO graph]
            <display-debug>
              <template #debugView>graph: {{item}}</template>
            </display-debug>
          </template>

          <template
              v-else-if="item.content.type == 'list'"
          >
            <InfiniteScrollList
                :custom-config="item.content.list.config"
            >
              <template
                  v-if="item.content.list.config.template_type == 'obsah'"
                  #tableHead
              >
                <thead>
                <tr>
                  <th>nazev</th>
                  <th>stav</th>
                  <th>kategorie</th>
                  <th>akce</th>
                </tr>
                </thead>

              </template>

              <template
                  v-if="item.content.list.config.template_type == 'obsah'"
                  #listRow="{itemData}"
                  :ref="'listWrapCompomenetItem' + itemData.id"

              >

                <td>{{itemData.nazev}}</td>
                <td>{{itemData.kategorie_title}}</td>
                <td><strong>{{itemData.stav_title}}</strong></td>

                <td>
                  <router-link
                      :to="itemData.btn_edit"
                      class="btn btn-primary"
                  >edit</router-link>

                </td>


              </template>

              <template
                  v-if="item.content.list.config.template_type == 'flashMessages'"
                  #listItem="{itemData}"
                  :ref="'listWrapCompomenetItem' + itemData.flash_id"
              >

                <div>[listItem] {{itemData.flash_id}} {{itemData.nazev}} {{itemData.datum_odeslani}}</div>
                <div v-html="itemData.obsah"></div>


              </template>

              <template
                  v-if="item.content.list.config.template_type == 'flashMessages'"
                  #listItemDEV="{itemData}"
              >
                <pre>template itemData: {{itemData}}</pre>
              </template>


              <template
                  v-if="item.content.list.config.template_type == 'flashMessages'"
                  #listEmpty
              >
                <pre>nejsou zadna data</pre>
              </template>

              <template
                  v-if="item.content.list.config.template_type == 'flashMessages'"
                  #tableHead
              >
                <thead>
                <tr>
                  <th>id</th>
                  <th>predmet</th>

                </tr>
                </thead>
              </template>

            </InfiniteScrollList>
          </template>

          <template
              v-else-if="item.content.type == 'lists'"
          >
            <swiper
                :grabCursor="true"
                :mousewheel="true"
                :pagination="true"
                :modules="modules"
                :autoplay="{
                  delay: 20000,
                  disableOnInteraction: true,
                }"
                :keyboard="{enabled: true}"
                class="w-100 h-100"
            >
              <swiper-slide
                  v-for="(swiper, index) in item.content.lists"
                  class="bg-white"
              >
                <InfiniteScrollList
                    :custom-config="swiper.config"
                >
                  <template
                      v-if="swiper.config.template_type == 'obsah'"
                      #tableHead
                  >
                    <thead>
                    <tr>
                      <th>nazev</th>
                      <th>stav</th>
                      <th>kategorie</th>
                      <th>akce</th>
                    </tr>
                    </thead>

                  </template>
                  <template
                      v-if="swiper.config.template_type == 'obsah'"
                      #listRow="{itemData}"
                      :ref="'listWrapCompomenetItem' + itemData.id"

                  >

                    <td>{{itemData.nazev}}</td>
                    <td>{{itemData.kategorie_title}}</td>
                    <td><strong>{{itemData.stav_title}}</strong></td>

                    <td>
                      <router-link
                          :to="itemData.btn_edit"
                          class="btn btn-primary"
                      >edit</router-link>

                    </td>


                  </template>
                  <template
                      v-if="swiper.config.template_type == 'flashMessages'"
                      #listItem="{itemData}"
                      :ref="'listWrapCompomenetItem' + itemData.flash_id"
                  >

                    <div>[listItem] {{itemData.flash_id}} {{itemData.nazev}} {{itemData.datum_odeslani}}</div>
                    <div v-html="itemData.obsah"></div>


                  </template>
                  <template
                      v-if="swiper.config.template_type == 'flashMessages'"
                      #listItemDEV="{itemData}"
                  >
                    <pre>template itemData: {{itemData}}</pre>
                  </template>
                  <template
                      v-if="swiper.config.template_type == 'flashMessages'"
                      #listEmpty
                  >
                    <pre>nejsou zadna data</pre>
                  </template>
                  <template
                      v-if="swiper.config.template_type == 'flashMessages'"
                      #tableHead
                  >
                    <thead>
                    <tr>
                      <th>id</th>
                      <th>predmet</th>

                    </tr>
                    </thead>
                  </template>

                </InfiniteScrollList>
                <!--
                <display-debug>
                  <template #debugView>swiper ITEM  {{swiper }}</template>
                </display-debug>
                -->
              </swiper-slide>
            </swiper>
          </template>

          <template
              v-else-if="item.content.type == 'infobox'"
          >

            <swiper
                :grabCursor="true"
                :mousewheel="true"
                :pagination="true"
                :keyboard="{enabled: true}"
                :modules="modules"
                :autoplay="{
                  delay: 5000,
                  disableOnInteraction: true,
                }"
                class="w-100 h-100"
            >
              <swiper-slide
                  v-for="(box, index) in item.content.boxes"
                  :class="'infobox-list'"
              >
                <div :class="'infobox-item-wrap d-flex flex-column justify-content-between  w-100 h-100 p-2 p-lg-4' + (box.class? (' '+ box.class): ' bg-primary') ">
                  <div class="flex-fill d-flex justify-content-end align-items-end">
                    <span class="d-flex align-items-end mt-auto fw-bold number-size">
                      <template v-if="box.ico">
                        <i :class="'ico-size me-1 ' + box.ico"></i>
                      </template>
                      <template v-if="box.number">
                        <span>{{box.number}}</span>
                      </template>
                    </span>
                  </div>
                  <div class="flex-fill text-end text-size">
                    {{box.title}}
                  </div>
                </div>

                <!--
                <display-debug>
                  <template #debugView>swiper BOX  {{box }}</template>
                </display-debug>
-->
              </swiper-slide>
            </swiper>
          </template>

          <template
              v-else
          >
            [else]
          </template>




          <!--
                    <pre>
                      {{item.size}}
                      {{item.class}}
                    </pre>
          -->
        </div>
      </div>


    </div>

  </div>

  <display-debug>
    <template #debugView>structure FULL {{structure}}</template>
  </display-debug>


<!--


  <display-debug>
    <template #debugView>data {{data}}</template>
  </display-debug>


  <display-debug>
    <template #debugView>modul {{modul}}</template>
  </display-debug>
  -->
</template>

<script>
import { gsap, Back, Power1 } from "gsap"
import axios from "axios";
import InfiniteScrollList from '@/components/content/InfiniteScrollList'
import DashoboardListWrap from '@/components/general/dashboard/DashoboardListWrap'
import { Pagination, Keyboard, Autoplay } from 'swiper/modules'; // Navigation, , Scrollbar
import { Swiper, SwiperSlide } from 'swiper/vue';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
export default {
  name: "Dashboard",
  components: {
    gsap,
    Back,
    Power1,
    InfiniteScrollList,
    //DashoboardListWrap,
    Swiper,
    SwiperSlide,
  },
  props: {
    //"modul": String,
    "structure": {
      required: false,
      type: Object,
    },
    //"boxesData": Array,
    //"reloadSignal": String,
  },
  emits:[
    //'update:reloadSignal'
  ],
  data() {
    return {
      data: [],
    }
  },
  setup() {
    return {
      modules: [Keyboard, Pagination, Autoplay],
    };
  },
  methods: {
    async getData(id) {
      axios.defaults.baseURL = this.getBaseRoot();
      await axios({
        url: this.getApiConfig('dashboard.detail.path') + "?" + this.getApiConfig('dashboard.detail.input.id') + '=' + id,
        method: this.getApiConfig('dashboard.detail.method'),
        withCredentials: true,
        headers: this.getRequestHeader(),
      }).then((res) => {
        if(res.data.status) {

          this.data = res.data.response;
        }else{
          //alert('Neslo oznacit zpravu jako precetenou')
        }
      });
    },
  },
  mounted() {
    //this.getData(this.modul)
  }
};
</script>
<style scoped type="text/scss">

</style>