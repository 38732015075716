<template>
<!--
  <div  class="cms5-flash-notification-dev-wrap p-2">
    <pre>mouse {{x}}, {{y}}</pre>
    <pre>useV {{useV}}</pre>

    <pre>messages{{messages}}</pre>
  </div>
  -->
  <div
    v-if="display_list && messages && messages.length > 0"
    class="cms5-flash-notification-list-wrap p-2"
    ref="flash_wrap"
  >
    <div
        v-for="(item, index) in messages" :key="item"
        class="toast d-block mb-2 w-100"
        :ref="'flashMessageNo'+ item.flash_id"
    >
      <div class="toast-header ">

        <strong class="mr-auto">{{item.nazev}}</strong>
        <button
            @click.prevent="deletePlusMarkAsRead(index, item);"
            type="button" class="btn btn-sm ml-2 mb-1 close" data-dismiss="toast" aria-label="Close">
          <i class="bi bi-x"></i>
        </button>
      </div>
      <div class="toast-body">
        <span v-html="item.obsah"></span>
      </div>
    </div>


    <div
        class="w-100 text-end"
    >
      <router-link
          to="/flash-message/notification-list/"
          class="btn btn-link btn-sm fs-6"
      >
       <i class="bi bi-card-text"></i> Archiv oznameni
      </router-link>

    </div>


  </div>
  <div
    v-else-if="display_list"
    class="cms5-flash-notification-list-wrap empty p-2"
  >

    <div
        class="toast d-block mb-2 bg-danger text-white"
    >
      <div class="toast-body ">
        Nemate zadne nove oznameni
        <router-link
            to="/flash-message/notification-list/"
            class="d-inline btn btn-danger btn-sm fs-6"
        >
          <i class="bi bi-card-text "></i> Archiv
        </router-link>
      </div>
    </div>
  </div>
  <div
    @click.prevent="toggleList"
    class="cms5-flash-notification-icon-wrap -semihide animace"
    ref="iconToggle"
  >
    <div
        class="cms5-flash-messages-notification-wrap empty bg-primary btn"
    >
        <span
            id="bell"
            class="cms5-icon-mail">
          <i class="bi bi-bell-fill"></i>
        </span>

      <span
          v-if="message_new_count && message_new_count > 0"
          class="cms5-icon-number bg-danger"
      >
        {{message_new_count}}
      </span>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { gsap, Back, Power1 } from "gsap"
//import { useMouse, useWindowFocus, useElementVisibility } from '@vueuse/core'

export default {
  name: "FlashMessages",
  components: [
    gsap,
    axios,
  ],
  data() {
    return {
      display_list: false,
      messages: [],
      message_new_count: null,
      is_active_viewport: true,
      useV: null,
    }
  },
  setup() {


    /*
    const targetIco = this.$refs['flash_wrap']
    const targetIsVisible = useElementVisibility(targetIco)

    return {
      x,
      y,
    }
        */

  },
  methods: {
    ringBell() {
      const bell = gsap.timeline({ repeat: 5, yoyo: true, onComplete: this.pauseBell });
      bell.to("#bell", { rotation: 20, duration: 0.15 })
          .to("#bell", { rotation: -20, duration: 0.15 });
    },

    pauseBell() {
      setTimeout(() => {
        this.ringBell();
      }, 20000); // Pauza 30 sekund
    },

    toggleList(){
      this.display_list = !this.display_list;
    },

    async deletePlusMarkAsRead(index, item) {
      axios.defaults.baseURL = this.getBaseRoot();
      await axios({
        //url: this.cfg.api.user.read_flash_messages.path + '/' + item['flash_id'],
        //method: this.cfg.api.user.read_flash_messages.method ,
        url: this.getApiConfig('user.read_flash_messages.path') + '/' + item['flash_id'],
        method: this.getApiConfig('user.read_flash_messages.method') ,

        withCredentials: true,
        headers: this.getRequestHeader(),
      }).then((res) => {
        if(res.data.status) {
          this.messages.splice(index,1)
        }else{
          alert('Neslo oznacit zpravu jako precetenou')
        }
      });
    },

    async checkMessages() {
      //console.log('dev checkMessages is_active_viewport', this.is_active_viewport )
      //if(this.is_active_viewport) {}
        try {
          axios.defaults.baseURL = this.getBaseRoot();
          await axios({
            //url: this.cfg.api.user.check_flash_messages.path,
            //method: this.cfg.api.user.check_flash_messages.method,
            url: this.getApiConfig('user.check_flash_messages.path'),
            method: this.getApiConfig('user.check_flash_messages.method'),
            withCredentials: true,
            headers: this.getRequestHeader(),
          }).then((res) => {

            if(res.data.response.messages && res.data.response.messages.length > 0) {
              for(const i in res.data.response.messages) {
                if(!this.messages.some(message => message['flash_id'] === res.data.response.messages[i].flash_id)) {
                  this.messages.push(res.data.response.messages[i])
                }
              }
              this.messages.sort((a, b) => b.flash_id - a.flash_id); //todo lepsi sortovani
            }

            this.message_new_count = res.data.response.count;

            if(this.messages.length > 0){

              this.ringBell();
            }else{
              //this.message_new_count = null
              this.display_list = false;
            }
          }).finally(() => {
            this.checkReadMessages();
          });
        } catch (err) {
          //console.log('dev.checkMessages ERR', err)
        }
    },

    async checkReadMessages(){
      //todo delay
      if(this.messages && this.messages.length > 0) {
        for (const i in this.messages) {
          //todo check is not read
          if(this.isVisibleElelement('flashMessageNo' + this.messages[i].flash_id)) {
            //console.log('dev.checkReadMessages isVIS', this.messages[i].flash_id, this.isVisibleElelement('flashMessageNo' + this.messages[i].flash_id))
            axios.defaults.baseURL = this.getBaseRoot();
            await axios({
              //url: this.cfg.api.user.read_flash_messages.path + '/' + this.messages[i].flash_id,
              //method: this.cfg.api.user.read_flash_messages.method ,
              url: this.getApiConfig('user.read_flash_messages.path') + '/' + this.messages[i].flash_id,
              method: this.getApiConfig('user.read_flash_messages.method'),
              withCredentials: true,
              headers: this.getRequestHeader(),
            }).then((res) => {
              /*
              if(res.data.status) {
                this.messages.splice(index,1)
              }else{
                alert('Neslo oznacit zpravu jako precetenou')
              }
              */
            });

          }
        }
      }
      //this.isVisibleElelement('flashMessageNo39')



    },

    setViewPortActive(status) {
      console.log('setViewPortActive', status);
      this.is_active_viewport = status;
    },



    isVisibleElelement(elementRef) {
      //elementRef = 'flashMessageNo39';
      //const element = this.$refs.tlacitko;
      let element = this.$refs[elementRef];

      //console.log('dev.isVisibleElelement fce ', elementRef, element)
      //console.log('dev.isVisibleElelement fce ', elementRef, typeof element, element)
      /*
      console.log('dev.isVisibleElelement fce-iconToggle', this.$refs.iconToggle)
      console.log('dev.isVisibleElelement $refs ', this.$refs)

*/




      if(element){
        if (typeof element.length != "undefined" ){
          if (element.length > 0) {
            element = element[0];
          }else {
            return false;
          }
          //console.log('dev.isVisibleElelement fce LENGHT', elementRef, typeof element, element)
          //console.log('dev.isVisibleElelement fce LENGHT', element.length )
        }
        const rect = element.getBoundingClientRect();
        return (
            rect.top >= 0 &&
            rect.left >= 0 &&
            rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
            rect.right <= (window.innerWidth || document.documentElement.clientWidth)
        );
      }else{
        console.log('dev.isVisibleElelement INVISIBLE ', elementRef)
        return false
      }
    }
  },
  mounted() {

  },
  watch: {

  },
  created() {
    let is_active_viewport;

    window.addEventListener('focus', () => {
      this.setViewPortActive(true)
      console.log('Okno má fokus (je aktivní).');
      is_active_viewport = true;
    });

    window.addEventListener('blur', () => {
      this.setViewPortActive(false)
      console.log('Okno ztratilo fokus (není aktivní).');
      //this.countdownObj.is_paused = true;
      is_active_viewport = false;
    });



    //this.is_active_viewport = is_active_viewport;
    this.checkMessages();
    setInterval(() => this.checkMessages(), this.getConfig('messages_check_interval'));

  }
};
</script>