<template>
  <div
      v-if="status"
      class="debug-wrap"
  >
    <div class="label">
      debug
    </div>
    <pre

        class="debug-display"
    >
      <slot name="debugView">
        [neni co debugovat]
      </slot>
    </pre>
  </div>
</template>

<script>
export default {
  name: "DisplayDebug",
  data() {
    return {
        status: false,
    }
  },
  mounted() {
    console.log('dev-env-debug', process.env.VUE_APP_DEBUG_DISPLAY, Boolean(process.env.VUE_APP_DEBUG_DISPLAY))
    if(process.env.VUE_APP_DEBUG_DISPLAY === "true"){
      this.status = true;
    }else{
      this.status = false;
    }
    //this.status = true;
  },
};
</script>
<style scoped type="text/scss">
  .debug-wrap {
    border: 1px dashed red;
    //background-color: rgba(137,0,36,0.33);
    position: relative;
    margin-top: 1em;

    > .label {
      background: red;
      color: white;
      position: absolute;
      right: 0;
      top: 0;
      padding: .2em;
    }
    > pre{
      border: none;
      margin: 0;
      padding: .5em;
      text-indent: unset;
      background-color: rgba(50,0,0,0.05);
    }
  }
</style>