export default {
    beforeMount(el, binding) {

        const config = {
            displayRange: false,
            timerangePlusDateStart: false,
            timerange: false,
            hideYear: false,
            showDiff: false,
            displayCorrection: false,
        };

        for(const m in binding.modifiers) {
            if(binding.modifiers[m] == true) {
                config[m] = true;
            }
        }
        //console.log('dev.formatRange config', config)

        let dateFrom = new Date(binding.value.from);
        let dateTo = new Date(binding.value.to);
        let dateToDisplay = new Date(binding.value.to);

        if(config.displayCorrection) {
            dateToDisplay.setSeconds(dateTo.getSeconds() - 1);
        }


        //console.log('dev.directiveName format-date-range', binding)
        let monthNames = [
            "ledna",
            "února",
            "března",
            "dubna",
            "května",
            "června",
            "července",
            "srpna",
            "září",
            "října",
            "listopadu",
            "prosince"
        ];

        let rozdilMS = Math.abs(dateTo - dateFrom);

        // Převod na minuty
        let rozdilMinut = Math.floor(rozdilMS / 60000);

        // Převod na hodiny
        let rozdilHodin = Math.floor(rozdilMinut / 60);

        // Převod na dny
        let rozdilDni = Math.floor(rozdilHodin / 24);
        // Zbývající hodiny a minuty
        let zbyvajiciHodiny = rozdilHodin % 24;
        let zbyvajiciMinuty = rozdilMinut % 60;

        let output = '';

        if(config.timerangePlusDateStart) {
            el.innerHTML = formatHourMinute(dateFrom.getHours(), dateFrom.getMinutes());
            el.innerHTML += ' - '
            el.innerHTML += formatHourMinute(dateTo.getHours(), dateTo.getMinutes());

            el.innerHTML += ' | ' + dateFrom.getDate() + '. ' + monthNames[dateFrom.getMonth()] + ' ' + dateTo.getFullYear()
        }else if(config.timerange){
            el.innerHTML = formatHourMinute(dateFrom.getHours(), dateFrom.getMinutes());
            el.innerHTML += ' - '
            el.innerHTML += formatHourMinute(dateTo.getHours(), dateTo.getMinutes());
        }else{
            if (dateFrom.getFullYear() === dateTo.getFullYear()) {



                if (dateFrom.getMonth() === dateTo.getMonth() ) {
                    // Stejný měsíc a rok
                    if(dateFrom.getDate() == dateToDisplay.getDate()) {
                        output +=  dateFrom.getDate() + '. ' + monthNames[dateFrom.getMonth()]
                    }else{
                        output +=  dateFrom.getDate() + '. - ' + dateToDisplay.getDate() + '. ' + monthNames[dateFrom.getMonth()]
                    }

                } else {
                    // Různé měsíce, stejný rok
                    output += dateFrom.getDate() + '. ' + monthNames[dateFrom.getMonth()]  + ' - ' + dateToDisplay.getDate() + '. ' + monthNames[dateToDisplay.getMonth()]
                }

                if(!config.hideYear) {
                    output += ' ' + dateToDisplay.getFullYear();
                }

            } else {
                output += dateFrom.getDate() + '. ' + monthNames[dateFrom.getMonth()]  + ' ' + dateFrom.getFullYear()+ ' - ' + dateToDisplay.getDate() + '. ' + monthNames[dateToDisplay.getMonth()]  + ' ' + dateToDisplay.getFullYear()
            }

            el.innerHTML = output


        }

        if(config.displayRange) {

            let diffOutput = [];
            if(rozdilDni) {
                diffOutput.push(rozdilDni +' dnu');
            }
            if(zbyvajiciHodiny) {
                diffOutput.push(zbyvajiciHodiny +' hodin');
            }
            if(zbyvajiciMinuty) {
                diffOutput.push(zbyvajiciMinuty +' minut');
            }
            if(diffOutput) {
                el.innerHTML += ' | ' + diffOutput.join(', ');
            }
        }
    }
}

export function formatHourMinute(hour, minute) {
    if (minute < 10) {
        minute = '0' + minute;
    }
    return  hour + ':' + minute
}