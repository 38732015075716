<template>

  <div
      v-if="dataObj.dorucenky"
      class="list-group"
  >
    <h5>Doručeno</h5>
    <div
      v-for="(item,index) in dataObj.dorucenky"
      class="list-group-item"
    >
      {{item.jmeno}}
      <small
        v-if="item.datum_zobrazeno"
        class="text-secondary ms-auto"
        style="font-size: .75em;"
      >
        <i class="bi bi-eye"></i> {{item.datum_zobrazeno}}
      </small>
      <span
          v-else
          style="font-size: .75em;"
      >
        <i class="bi bi-eye-slash"></i>
      </span>
    </div>
  </div>

<!--
  <pre>dataObj: {{dataObj.dorucenky}}</pre>
-->
</template>

<script>
import './ViewTimeline.scss'
import ContentIncludeView from "@/views/ContentIncludeView";
import ModalWindow from '@/components/content/ModalWindow'
import axios from "axios";

let timer;
const countdown_key = 'rezervace_slot_count_status';
const timeRefreshIntervalMinutes = 10 * 60;
export default {
  name: "ViewTimeline",
  components: {
    ContentIncludeView,
    ModalWindow
  },
  props: {
    "dataObj": Object,
    "returnData": [Object, String, Number],
    "reloadSignal": [Object, String, Number],
  },
  emits:[
    'update:reloadSignal',
  ],
  data() {
    return {


      time_marker_position_left: 0,
      dev_modal_cfg: {
        display:false,
        is_header: true,
        is_close_btn: true,
        is_footer: true,
      },
      window: {},

      inputModel: null,
      workingSpot: null,
      new_slot_data: {
        save: false,
        submit: false,
      },
      formPrefill: {},
      createForm : {
        status: false
      },
      countdownObj: {
        is_active: false,
        is_paused: false,
        minutes: 10,
        displayValue: null
      },
      modalSlot: null,
      modalNewData: {
        save: false,
        submit: false,
      },

      modalEditSlot: null,
      modalEditData: {
        save: false,
        submit: false,
      },
    }
  },
  mounted() {
    //this.onWindowResize();
  },
  created() {

  },
  destroyed() {
    //DEV window.removeEventListener('resize', this.onWindowResize);
  },
  methods: {

    displayModalSlotEdit(item) {
      this.modalEditSlot = {
        name: 'Editace rezervace',
        router: {
          modul: 'rezervace-slot',
          action: 'edit/' + item.id
        },
        config: {
          "display": true,
          "is_header": true,
          "is_close_btn": true,
          "is_footer": true
        },
        data: {
          save: false,
          submit: false,
        },
        prefill: {
          general: {
            inputs: {
              restaurace_id: {
                readonly: true,
                //value: this.dataObj.restaurace_id
              },
              /*
              stav_id: {
                readonly: true,
                value: this.dataObj.setup.stav.potvrzena,
              }
              */
            }
          },
        },
      }
    },

    timeMarker(){
      const oneMinute = 60 * 1000;
      const currentDate = new Date(); //"2024-03-17 16:00:00"

      const otevrenoZacatek = new Date(this.dataObj.setup.datum.zacatek);
      const otevrenoKonec = new Date(this.dataObj.setup.datum.konec);

      const intervalMinutes = (otevrenoKonec.getTime() - otevrenoZacatek.getTime())/oneMinute + 60;
      const aktualniCas = (currentDate.getTime() - otevrenoZacatek.getTime())/oneMinute

      this.time_marker_position_left = Math.round((aktualniCas/intervalMinutes) * 100);
      if (this.time_marker_position_left < 0 || this.time_marker_position_left > 100){
        this.time_marker_position_left = null;
      }

      if(this.dataObj.data) {
        for(const k in this.dataObj.data) {
          for(const s in this.dataObj.data[k].slots) {
            const slotKonec = new Date(this.dataObj.data[k].slots[s].datum_do);
            if(slotKonec.getTime() < currentDate.getTime()) {
              this.dataObj.data[k].slots[s].is_deactive = true;
            }else{
              this.dataObj.data[k].slots[s].is_deactive = false;
            }
          }
        }
      }
    },

    countdown() {
      const intervalInSeconds = this.countdownObj.minutes * 60; // Pět minut v sekundách
      let remainingTime = intervalInSeconds;

      const updateCountdown = () => {
        const minutes = Math.floor(remainingTime / 60);
        const seconds = remainingTime % 60;
        const formattedTime = `${minutes}:${seconds.toString().padStart(2, '0')}`;

        this.countdownObj.displayValue = formattedTime;
        remainingTime--;

        if (remainingTime < 0) {
          if(this.countdownObj.is_active){
            clearInterval(interval);
            this.reloadData()
          }
        }
      };
      const interval = setInterval(updateCountdown, 1000);
    },

    reloadData(){
      this.$emit("update:reloadSignal", String(Math.random()));
    },

    setStav(slot, stav_id){
      let formData = new FormData;
      formData.append('slot_id', slot.id)
      formData.append('stav_id', stav_id)
      axios.defaults.baseURL = this.getBaseRoot();
      //let apiPath = this.cfg.api.rezervace.slot_change_slot_status.path
      let apiPath = this.getApiConfig('rezervace.slot_change_slot_status.path');
      axios({
        url: apiPath,
        data: formData,
        method: this.getApiConfig('rezervace.slot_change_slot_status.method'),
        withCredentials: true,
        headers: this.getRequestHeader(),
      }).then((res) => {
        slot.stav_title = res.data.response.stav.title
        slot.stav_id = res.data.response.stav.id
        //TODO vyhledat slot v grid vypisu
        //console.log('dev.setStav res', res.data.response.stav.title);
      })
    },

    createFullSlots(){
      if(this.dataObj.data) {
        for(const k in this.dataObj.data) {
          let fullSlots = [];
          for(const s in this.dataObj.data[k].slots) {
            if(this.dataObj.data[k].slots[s].delka_slot > 1) {
              for (let i = 1; i <= this.dataObj.data[k].slots[s].delka_slot; i++) {
                fullSlots.push(Math.ceil(this.dataObj.data[k].slots[s].datum_od_slot + i));
              }
            }else{
              fullSlots.push(Math.ceil(this.dataObj.data[k].slots[s].datum_od_slot + 1 ));
            }
          }
          this.dataObj.data[k].full_slots = fullSlots;
        }
      }
    },

    getStavClass(stav_id) {
      return 'stav-'+ this.dataObj.setup.stav[stav_id];
    },

    onWindowResize() {

      this.window  = {
        width: window.innerWidth,
        height: window.innerHeight,
      }

      if (this.window.width <= 480){
        this.window.type = 'mobile';
        this.window.class = 'viewport-mobile';
      }else{
        this.window.type = 'desktop';
        this.window.class = 'viewport-desktop';
      }

    },

    createNewSlot(spot) {
      this.workingSpot = spot;
      //start date
      const currentDateStart = new Date(this.dataObj.day);
      currentDateStart.setHours(
          (this.dataObj.setup.casovy_obal.zacatek.hodiny + this.dataObj.setup.casovy_obal.zacatek.js_korekce_hodiny),
          this.dataObj.setup.casovy_obal.zacatek.minuty
      )
      const minutesOffset = currentDateStart.getMinutes() + (spot.placeholder_new.slot.start -1) * this.dataObj.setup.slot_minuty
      currentDateStart.setMinutes(minutesOffset);

      //end date
      const currentDateEnd = new Date(this.dataObj.day);
      currentDateEnd.setHours(
          (this.dataObj.setup.casovy_obal.zacatek.hodiny + this.dataObj.setup.casovy_obal.zacatek.js_korekce_hodiny),
          this.dataObj.setup.casovy_obal.zacatek.minuty
      )
      currentDateEnd.setMinutes(minutesOffset);
      currentDateEnd.setMinutes(currentDateEnd.getMinutes() + (2 * this.dataObj.setup.slot_minuty));

      this.formPrefill.general.inputs.spot_id = {
        readonly: true,
        value: spot.id
      }

      this.formPrefill.general.inputs.date_range = {
        readonly: true,
        templates: null,
        value: {
          zacatek: currentDateStart.toISOString().slice(0, 19).replace("T", " "),
          konec: currentDateEnd.toISOString().slice(0, 19).replace("T", " "),
        }
      }
      this.createForm.status = true; //zobrazi formular
      if(this.$refs.form_create_new){ //scroll2form
        this.$refs.form_create_new.scrollIntoView({ behavior: 'smooth' })
      }

      this.createFullSlots()
    },

    handleSpotMouseOut(spot) {
      if(typeof spot.placeholder_new != "undefined" && typeof spot.placeholder_new.display != "undefined") {
        spot.placeholder_new.display = false;
      }
    },
    handleSlotMouseOut(spot) {
      if(typeof spot.placeholder_new != "undefined" && typeof spot.placeholder_new.display != "undefined") {
        spot.placeholder_new.display = false;
      }
    },

    handleSlotHover(display, slot) {
      if(display) {
        this.$refs['list_slot_' +  slot.id][0].classList.add('table-row-hover')
      }else{
        this.$refs['list_slot_' +  slot.id][0].classList.remove('table-row-hover')
      }
    },

    handleSpotMouseMove(event, spot, spot_id,  position) {
       if(timer) clearInterval(timer);
      timer = setTimeout(
          () => {
            //const _x = event.clientX; //TODO DEL
            //const _y = event.clientY; //TODO DEL

            const slot_pozice = Math.ceil(((event.clientX - position.x)/position.width*100)/this.dataObj.setup.slot_sirka); //sloty

            if(spot.full_slots.indexOf(slot_pozice)>= 0) {
              //console.log(`DEV EDIT Poloha myši slot OBSAZENO: ${recount}`);
              if(typeof spot.placeholder_new != "undefined") {
                spot.placeholder_new.display = false;
              }
            }else {
              spot.placeholder_new = {
                display: true,
                slot: {
                  start: slot_pozice
                },
                position: {
                  left: (slot_pozice - 1) * this.dataObj.setup.slot_sirka,
                  width: 2 * this.dataObj.setup.slot_sirka
                }
              }
              //console.log('dev.mouse', _x, _y, slot_pozice)
            }
          },
          25
      )
    },

    handleTableRowHover(display, slot) {
      if(display) {
        this.$refs['slot_' +  slot.id][0].classList.add('grid-item-hover')
      }else{
        this.$refs['slot_' +  slot.id][0].classList.remove('grid-item-hover')
      }
    },

    calcSpotPosition(){
      if(this.dataObj.data) {
        for(const k in this.dataObj.data) {
          const element = this.$refs['spot_'+ this.dataObj.data[k].id]
          if(element){
            this.dataObj.data[k].position = element[0].getBoundingClientRect();
          }
        }
      }
    },

    detectClose(event){
      console.log('[keydown]', event.key)
    },

  },
  watch: {
    modalEditSlot: {
      handler(newVal) {
        //console.log('dev.modalEditSlot', newVal);
      },
      deep: true,
      immediate: true
    },
    "modalEditSlot.data": {
      handler(newVal) {
        if(newVal && newVal.submit && newVal.save ){
          this.reloadData(); //reload
          //this.modalEditSlot.config.display = false //close modal
        }
      },
      deep: true,
      immediate: true
    },
    "modalCreateSlot.data": {
      handler(newVal) {
        if(newVal && newVal.submit && newVal.save ){
          this.reloadData(); //reload
          //this.modalCreateSlot.config.display = false //close modal
        }
      },
      deep: true,
      immediate: true
    },


    "countdownObj.is_active": {
      handler(newVal) {
        console.log('dev.countdownObj.is_active', newVal)
        localStorage.setItem(
            countdown_key,
            newVal
        )
      },
      deep: true,
      immediate: true
    },

    new_slot_data: {
      handler(newVal) {
        if(newVal && newVal.data){
          if (!this.workingSpot.slots || this.workingSpot.slots.length == 0) {
            this.workingSpot.slots = [];
          }

          let formData = new FormData;
          formData.append('form_data', JSON.stringify(newVal.data))
          axios.defaults.baseURL = this.getBaseRoot();
          //let apiPath = this.cfg.api.rezervace.view_prehled_slot_detail.path
          let apiPath = this.getApiConfig('rezervace.view_prehled_slot_detail.path')
          axios({
            url: apiPath,
            data: formData,
            method: this.getApiConfig('rezervace.view_prehled_slot_detail.method'),
            withCredentials: true,
            headers: this.getRequestHeader(),
          }).then((res) => {
            //console.log('dev.watch.new_slot_data', res.data.response)

            const newSlotData = res.data.response.slot;
            this.workingSpot.slots.push(newSlotData)
            this.dataObj.list.push(newSlotData)

            this.createForm.status = false;

            const spotRef = 'spot_'+ res.data.response.slot.spot_id;
            if(this.$refs[spotRef] && this.$refs[spotRef][0]){
                this.$refs[spotRef][0].scrollIntoView({ behavior: 'smooth' })
            }
          })
        }
      },
      deep: true,
      immediate: true
    },
    window: {
      handler(newVal) {
        this.calcSpotPosition();
      },
      deep: true,
      immediate: true
    },
  },
};
</script>