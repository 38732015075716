<template>
  <dashboard
      :structure="moduleData.structure"
  >
  </dashboard>
</template>

<script>
import dashboard from "@/components/general/Dashboard";
export default {
  name: "DashboardModule",
  components: {
    dashboard
  },
  emits: [
    //"update:reloadSignal"
  ],
  props: {
    "ident": String,
    "route": Object,
    "reloadSignal": String,
    "moduleData": Object,
    "returnData": [Object, String, Number],
    "config": [Object],
  },
  data() {
    return {
      //reloadSignalBox: null,
      //reloadSignalList: null
    }
  },
  mounted() {
    if(typeof this.moduleData.structure == "undefined" && typeof this.moduleData.redirect_path != "undefined") {
      this.$router.push(this.moduleData.redirect_path);
    }
  }
};
</script>