<template>

<!--
  <div class="fullscreen-message d-flex align-items-center justify-content-center">
    <div class="" style="border: 1px dotted olive">
      <div class="text-center">
        <div class="spinner-border text-secondary" role="status">
          <span class="visually-hidden">Loading...</span>
        </div>
      </div>
      <span>nacitam message</span>
    </div>
  </div>
  -->
  <div v-if="formStatus.showForm && formObj.templates">
    <div class="d-flex flex-wrap align-items-center mb-2">
      <span class="fw-medium me-1 mb-1 btn btn-dark">Vzory</span>
      <div
          v-for="(templatesItem, templatesIndex)  in formObj.templates"
          @key="templatesIndex"
          class="me-2"
      >
        <a
          @click.prevent="prefillTempaltesFormData(templatesIndex)"
          :class="'btn me-1 mb-1' + (templatesItem.is_active? ' btn-danger': ' btn-warning')"
        >
          <i v-if="templatesItem.ico" :class="templatesItem.ico"></i> {{templatesItem.title}}
        </a>
        <!--
        <router-link :to="'?template=' + templatesIndex">{{templatesItem.title}}</router-link>
        -->
      </div>
    </div>
  </div>

  <div ref="formResultPosition"></div>
  <div
      v-if="formStatus.is_submit && formStatus.is_loading"
       class="fullscreen-message d-flex align-items-center justify-content-center"

  >
    <div class="">
      <div class="text-center">
        <div class="spinner-border text-secondary" role="status">
          <span class="visually-hidden">Loading...</span>
        </div>
      </div>
      <span>Moment, systém zpracovává váš požadavek</span>
    </div>
  </div>
  <div
      v-else-if="formStatus.is_submit && !formStatus.is_loading && formStatus.is_validate"
      class="fullscreen-message d-flex align-items-center justify-content-center"
  >
    <div class="">
      <div class="text-center">
        <i class="bi bi-check-circle-fill fs-1 text-success mb-3"></i>
      </div>
      <div class="text-success text-center fs-2">V pořádku uloženo.</div>
      <ul v-if="hook_action" class="mt-2 list-group">
        <li
            v-for="(hookItem, hookIndex)  in hook_action"
            @key="hookIndex"
            class="list-group-item"
        >
          <strong>{{hookItem.title}}</strong>&nbsp;
          <span v-if="hookItem.status">
          {{hookItem.status}}<i class="bi bi-check"></i>
        </span>
          <span v-else>
           Čeká na spuštení, vyčkejte moment. <i class="bi bi-clock-fill"></i>
        </span>
        </li>
      </ul>
      <div
          v-else
        class="mt-3"
      >
        <div
            v-for="(linkItem, linkIndex)  in after_links"
            @key="linkIndex"
        >
          <router-link
              :to="linkItem.path"
              class="btn-outline-secondary link-secondary text-decoration-none mt-2"
          >
            <i v-if="linkItem.ico" :class="linkItem.ico+ ' me-2'"></i> {{linkItem.title}}
          </router-link>
        </div>
      </div>
      <div v-if="hook_action && hook_status" class=" mt-2">
        <div class="alert alert-success">
          <i class="bi bi-check-circle"></i> <span>{{hook_status}}</span>
        </div>

        <div
            class="mt-3"
        >
          <div
              v-for="(linkItem, linkIndex)  in after_links"
              @key="linkIndex"
          >
            <router-link
                :to="linkItem.path"
                class="btn-outline-secondary link-secondary text-decoration-none mt-2"
            >
              <i v-if="linkItem.ico" :class="linkItem.ico+ ' me-2'"></i> {{linkItem.title}}
            </router-link>
          </div>
        </div>
      </div>

    </div>
  </div>

  <div
      v-else-if="formStatus.is_submit && !formStatus.is_loading && !formStatus.is_validate"
      class="fullscreen-message invalid d-flex align-items-center justify-content-center"
  >
    <div>
      <div class="text-center">
        <i class="bi bi-exclamation-triangle-fill fs-1 text-danger"></i>
      </div>
      <span class="text-danger text-center fs-2">{{err.message}}</span>
      <!--
      <pre>{{err.message}}</pre>
      -->
    </div>
  </div>


  <form
      v-if="formStatus.showForm && !formStatus.is_loading"
      @submit.prevent="submitForm"
      class="d-xxl-flex flex-wrap g-2"
      novalidate
  >
    <fieldset
        v-for="(sectionItem, sectionIndex)  in formObj.body"

        @key="sectionIndex"
        class="border border-light rounded-4 flex-fill p-2 p-sm-4"
    >
      <template v-if="sectionItem.display">
        <legend
            v-if="Object.keys(formObj.body).length > 1"
            class="fs-4 fw-medium badge text-bg-light text-start"
        >
          <i v-if="sectionItem.ico" :class="sectionItem.ico + ' me-1 fs-5'"></i>
          {{sectionItem.title}}
        </legend>
        <div
            v-for="(inputItem, inputIndex)  in sectionItem.inputs"
            @key="inputIndex"
            v-show="inputItem.display"
            :style="inputItem.invalid? ' ': null"
            :class="'mb-2 p-2' + (inputItem.invalid?' border border-danger rounded-3 is-invalid':'')"
            :data-component="inputItem.component_name"
        >
          <strong v-if="inputItem.type!= 'hidden'" class="fs-4 text-secondary fw-lighter">{{inputItem.title}}</strong>&nbsp;<br>
          <component
              v-bind:is="inputItem.component_name"
              v-model="formData[inputItem.name]"
              :inputObj="inputItem"
              :formObj="formObj.body"
          ></component>


          <div
              v-if="inputItem.invalid && inputItem.invalid.message"
              class="ms-2"
          >
            <span class="is-invalid"></span>
            <div class="invalid-feedback">
              {{inputItem.invalid.message}}
            </div>
          </div>


<!--
          <div
              v-if="inputItem.invalid && inputItem.invalid.message"
              class="alert alert-danger fs-6 mt-1"
          >
            <i class="bi bi-exclamation-circle-fill me-2 p-0"></i>
            <span>{{inputItem.invalid.message}}</span>
          </div>
-->
          <div v-if="inputItem.hint" class="mt-1">
            <i class="bi bi-info-circle me-1"></i>
            <small class="fs-6" v-html="inputItem.hint"></small>
          </div>


          <!--
                  <span v-if="inputItem.invalid" :v-html="'Chyba: '+ inputItem.invalid.message"/>&nbsp;
          -->

          <!--
          <pre>component_name: {{inputItem}}</pre>
          -->


        </div>
      </template>

    </fieldset>

    <div class="btn-wrap text-start w-100">
    <div
        v-for="(btntItem, btnIndex)  in formObj.btns"
        @key="inputIndex"
    >
      <button
          :type="btntItem.type"
          class="btn btn-dark btn-lg"
      >
        {{btntItem.title}}
      </button>
    </div>
  </div>
  </form>

  <display-debug>
    <template #debugView>formData [@form] {{formData}}</template>
  </display-debug>

<!--
  <display-debug>
    <template #debugView>formObj.body {{formObj.body}}</template>
  </display-debug>


  <pre>formObj: {{formObj}}</pre>
  <pre>formObj.body: (formObj.body.general.inputs) {{formObj.body.general.inputs}}</pre>

  -->
</template>

<script>
import HiddenInput from "@/components/general/form/inputs/HiddenInput";
import TextInput from "@/components/general/form/inputs/TextInput";
import TextInputLang from "@/components/general/form/inputs/TextInputLang";
import TextareaInput from "@/components/general/form/inputs/TextareaInput";
import TextareaInputLang from "@/components/general/form/inputs/TextareaInputLang";
import SelectInput from "@/components/general/form/inputs/SelectInput";
import SegmentInput from "@/components/general/form/inputs/SegmentInput";
import ImageInput from "@/components/general/form/inputs/ImageInput";
import BooleanInput from "@/components/general/form/inputs/BooleanInput";
import TreeSelectInput from "@/components/general/form/inputs/TreeSelectInput";
import TreeSelectCustomInput from "@/components/general/form/inputs/TreeSelectCustomInput";
import TagsInput from "@/components/general/form/inputs/TagsInput";
import DateTimeInput from "@/components/general/form/inputs/DateTimeInput";
import CheckboxSimpleInput from "@/components/general/form/inputs/CheckboxSimpleInput";
import CheckboxMultiInput from "@/components/general/form/inputs/CheckboxMultiInput";
import RadioInput from "@/components/general/form/inputs/RadioInput";
import ImageThumb from "@/components/general/form/inputs/ImageThumb";
import GalleryInput from "@/components/general/form/inputs/GalleryInput";
import PasswordInput from "@/components/general/form/inputs/PasswordInput";
import MetaInput from "@/components/general/form/inputs/MetaInput";
import RangeInput from "@/components/general/form/inputs/RangeInput";
import DateRangeInput from "@/components/general/form/inputs/DateRangeInput";
import RestaraceJidloVariantaInput from "@/components/general/form/inputs/restaurace/RestaraceJidloVariantaInput";
import RestauraceSekceJidloInput from "@/components/general/form/inputs/restaurace/RestauraceSekceJidloInput";
import OpeningHoursRegularInput from "@/components/general/form/inputs/restaurace/OpeningHoursRegularInput";
import OpeningHoursByDateInput from "@/components/general/form/inputs/restaurace/OpeningHoursByDateInput";
import OrderInput from "@/components/general/form/inputs/OrderInput";
import AddressInput from "@/components/general/form/inputs/AddressInput";
import PriceInput from "@/components/general/form/inputs/PriceInput";
import StoreInput from "@/components/general/form/inputs/StoreInput";
import ParametersInput from "@/components/general/form/inputs/ParametersInput";
import SmartSelectInput from "@/components/general/form/inputs/SmartSelectInput";
import WikiParts from "@/components/general/form/inputs/WikiParts";


import axios from "axios";

let timer_form_refresh;
let timer_form_refresh_delay = 200;
export default {
  name: "FormGeneral",
  components: {
    axios,
    HiddenInput,
    TextInput,
    TextInputLang,
    TextareaInput,
    TextareaInputLang,
    SelectInput,
    SegmentInput,
    ImageInput,
    BooleanInput,
    TreeSelectInput,
    TagsInput,
    DateTimeInput,
    CheckboxSimpleInput,
    CheckboxMultiInput,
    RadioInput,
    ImageThumb,
    GalleryInput,
    PasswordInput,
    MetaInput,
    TreeSelectCustomInput,
    RangeInput,
    DateRangeInput,
    RestaraceJidloVariantaInput,
    RestauraceSekceJidloInput,
    OpeningHoursRegularInput,
    OpeningHoursByDateInput,
    OrderInput,
    AddressInput,
    PriceInput,
    StoreInput,
    ParametersInput,
    SmartSelectInput,
    WikiParts,
  },
  props: {
    "formTitle": String,
    "formObj": Object,
    "originalPath": String,
    "returnData": [Object, String, Number],
    "formPrefill": [Object],
  },
  emits:[
    'update:reloadSignal'
  ],
  data() {
    return {
      formData: {},
      err: null,
      hook_status: null,
      hook_action: [],
      after_links: [],
      formStatus: {
        "showForm": true,
        "is_loading": false,
        "is_submit": false,
        "is_validate": false,
        "is_saved": false,
      },
    }
  },
  methods: {
    setTemplate(key) {
      //this.prefillTempaltesFormData(key);
    },
    prefillTempaltesFormData(template_key){
      for (const t_key in this.formObj.templates) {
        this.formObj.templates[t_key].is_active = false;
      }
      if(template_key && this.formObj.templates && this.formObj.templates[template_key]) {
        this.formObj.templates[template_key].is_active = true;
        let aTemplate = this.formObj.templates[template_key]
        for (const section_key in this.formObj.body) {
          for (const input_key in this.formObj.body[section_key].inputs) {
            if(typeof aTemplate.inputs[input_key] != 'undefined') {
              //TODO pokud je prazdna hodnota?

              this.formObj.body[section_key].inputs[input_key].val = aTemplate.inputs[input_key]
            }else{
              //console.log('dev prefillTempaltesFormData V2 none', input_key)
              //this.formObj.body[section_key].inputs[input_key].val = null;
            }
          }
        }
      }
    },
    async submitForm(){

      this.formStatus.is_submit = true;

      let formData = new FormData();
      formData.append('form_data', JSON.stringify(this.formData));
      this.loadingStatus = true;
      const pseudothis = this


      axios.defaults.baseURL = this.getBaseRoot();;
      this.formStatus.is_loading = true
      await axios({
        //url: "/class/tags/save-action?id=1701",
        url: this.formObj.path,
        method: "post",
        data:  formData,
        withCredentials: true,
        headers: this.getRequestHeader(),
      }).then((res) => {
        //console.log("dev save OK", res.data.status)
        this.formStatus.is_submit = true
        if(res.data.status) {
          this.formStatus.is_validate = true;
          this.formStatus.showForm = false;
          this.hook_action = res.data.response.action_hook
          this.after_links = res.data.response.after_links;

          //emit to parents
          this.returnData.submit = true;
          this.returnData.save = true;
          this.returnData.id = res.data.response.id;
          this.returnData.values = this.formData;
          this.returnData.data = res.data.response.data_saved;

        }else{

          //console.log('form nevalidni', res.data.response.form_invalid)
          this.formStatus.is_validate = false;
          this.err = res.data.err;
          this.formStatus.showForm = true;
          //this.overwriteFormData();
          this.setInvalidateFormInputs(res.data.response.form_invalid);

          //emit to parents
          this.returnData.submit = true;
          this.returnData.save = false;
          //console.log("dev.formGeneral save SUC returnData", this.returnData)
        }
      }) .catch((err) => {
        // alert("dev submit err")
        //pseudothis.err = err;
        console.log("dev.formGeneral save catch", err)
        this.$refs.formResultPosition.scrollIntoView({ behavior: 'smooth' });
      })
      .finally((f) => {

        if(this.$refs.formResultPosition) {
          this.$refs.formResultPosition.scrollIntoView({ behavior: 'smooth' });
        }



        pseudothis.formStatus.is_loading = false;
        //console.log("dev save finally")
        pseudothis.runHooks()
      })
    },
    overwriteFormData(){
      //console.log('dev overwriteFormData', this.formData);
      for (const section_key in this.formObj.body) {
        for (const input_key in this.formObj.body[section_key].inputs) {
          console.log('overwriteFormData input',input_key, this.formObj.body[section_key].inputs[input_key]);
        }
      }
    },


    setInvalidateFormInputs(invalidate) {
      for (const section_key in this.formObj.body) {
        for (const input_key in this.formObj.body[section_key].inputs) {
          if(invalidate[input_key]){
            this.formObj.body[section_key].inputs[input_key].invalid = {
              "status": true,
              "message": invalidate[input_key].title
            }
            this.formObj.body[section_key].inputs[input_key].val = invalidate[input_key].val;
          }else{
            delete this.formObj.body[section_key].inputs[input_key].invalid;
          }
        }
      }
    },
    runHooks() {
      if (this.hook_action && this.hook_action.length > 0) {
        this.hook_status = 'Nezavírejte toto okno dokud nedoběhnou tyto akce. Dáme vám vedět jakmile bude hotovo.'
        this.runHookItem(0);
      }
    },
    async runHookItem(i) {
      if(this.hook_action[i]) {
        const pseudothis = this
        this.hook_action[i].status = "spusteno"
        await axios({
          url: this.hook_action[i].path,
          method: "get",
          withCredentials: true,
          headers: this.getRequestHeader(),
        }).then((res) => {
          pseudothis.hook_action[i].status = "spusteno2"
        }) .catch(function(err){
          pseudothis.hook_action[i].status = "CHYBA"
          //console.log('dev hook run item ERR',err,this.hook_action[i] )
        })
        .finally(function(){
          //console.log('dev hook run item finally',i, pseudothis.hook_action.length )
          pseudothis.hook_action[i].status = "Hotovo"
          i++;
          pseudothis.runHookItem(i);
        })
      }else{
        this.hook_status = 'Hotovo. Můžete pokračovat v další práci.' //'Hotovo. Můžete zavřít okno.'
      }
    },
    reloadData(){
      this.$emit("update:reloadSignal", String(Math.random()));
    },
    variableForm(){
      if(this.formData.nazev_select == 3) {
        //console.log('dev variableForm', this.formData.nazev_select)
        //this.formObj.inputs.nazev_textara.display = false;
      }else{
        //this.formObj.inputs.nazev_textara.display = true;
      }
    },
    prefillFormData(){
      for (const section_key in this.formObj.body) {
        for (const input_key in this.formObj.body[section_key].inputs) {
          this.formData[input_key] = this.formObj.body[section_key].inputs[input_key].val;
        }
      }

      //manual form prefill
      /*
      if (this.formPrefill){
        for (const section_key in this.formPrefill) {
          for (const input_key in this.formPrefill[section_key].inputs) {
            if (typeof this.formPrefill[section_key].inputs[input_key].value != "undefined"){
              this.formObj.body[section_key].inputs[input_key].val = this.formPrefill[section_key].inputs[input_key].value;
            }
            if (typeof this.formPrefill[section_key].inputs[input_key].readonly != "undefined"){
              this.formObj.body[section_key].inputs[input_key].readonly = this.formPrefill[section_key].inputs[input_key].readonly;
            }
            if (typeof this.formPrefill[section_key].inputs[input_key].required != "undefined"){
              this.formObj.body[section_key].inputs[input_key].required = this.formPrefill[section_key].inputs[input_key].required;
            }
          }
        }
      }
    */
    }
  },
  watch: {
    formPrefill: {
      handler(newVal, oldVal) {
        for (const section_key in this.formPrefill) {
          for (const input_key in this.formPrefill[section_key].inputs) {
            if (typeof this.formPrefill[section_key].inputs[input_key].value != "undefined"){
              this.formObj.body[section_key].inputs[input_key].val = this.formPrefill[section_key].inputs[input_key].value;
            }
            if (typeof this.formPrefill[section_key].inputs[input_key].readonly != "undefined"){
              this.formObj.body[section_key].inputs[input_key].readonly = this.formPrefill[section_key].inputs[input_key].readonly;
            }
            if (typeof this.formPrefill[section_key].inputs[input_key].required != "undefined"){
              this.formObj.body[section_key].inputs[input_key].required = this.formPrefill[section_key].inputs[input_key].required;
            }

            if (typeof this.formPrefill[section_key].inputs[input_key].templates != "undefined"){
              this.formObj.body[section_key].inputs[input_key].templates = this.formPrefill[section_key].inputs[input_key].templates;
            }
          }
        }
      },
      deep: true,
      immediate: true
    },
    formData: {
      handler(newVal, oldVal) {
        //console.log('dev formGeneral formData', newVal);
        if(this.formObj.action_refresh) {
          //timer_form_refresh_delay
          if(timer_form_refresh) clearInterval(timer_form_refresh);
          timer_form_refresh = setTimeout(
              () => {
               // console.log('dev timer_form_refresh', newVal)
                let formData = new FormData;
                formData.append('form_data', JSON.stringify(newVal))
                axios.defaults.baseURL = this.getBaseRoot();
                axios({
                  url: this.formObj.action_refresh,
                  data: formData,
                  method: 'POST',
                  withCredentials: true,
                  headers: this.getRequestHeader(),
                }).then((res) => {
                  //inputs
                  //console.log('dev set input', '-------------------------------------------')
                  for (const group_key in res.data.response.display_changes) {
                    for (const input_key in res.data.response.display_changes[group_key]) {
                      //input display
                      if(typeof res.data.response.display_changes[group_key][input_key].display != "undefined"){
                        this.formObj.body[group_key].inputs[input_key].display = res.data.response.display_changes[group_key][input_key].display;
                      }
                      //input prefil
                      if(
                          (
                              typeof res.data.response.display_changes[group_key][input_key].is_prefill != "undefined"
                              && res.data.response.display_changes[group_key][input_key].is_prefill
                              //&& typeof this.formObj.body[group_key].inputs[input_key].is_prefill == "undefined"
                          )
                      ){
                        // neni nastaveno
                        if(typeof this.formObj.body[group_key].inputs[input_key].is_prefill == "undefined") {
                          this.formObj.body[group_key].inputs[input_key].is_prefill = true;
                        }
                      }

                      //input value
                      if(typeof res.data.response.display_changes[group_key][input_key].value != "undefined"){

                       //console.log('dev set input', group_key,input_key, this.formObj.body[group_key].inputs[input_key].is_prefill)

                        if(
                            typeof this.formObj.body[group_key].inputs[input_key].val == "undefined"
                            || this.formObj.body[group_key].inputs[input_key].val.length == 0
                            || this.formObj.body[group_key].inputs[input_key].is_prefill == true
                        ) {
                          this.formObj.body[group_key].inputs[input_key].val = res.data.response.display_changes[group_key][input_key].value;
                          this.formData[input_key] = res.data.response.display_changes[group_key][input_key].value;
                        }

                        // this.formObj.body[group_key].inputs[input_key].val = res.data.response.display_changes[group_key][input_key].value;
                        // this.formData[input_key] = res.data.response.display_changes[group_key][input_key].value;
                      }

                      if(typeof res.data.response.display_changes[group_key][input_key].required != "undefined"){
                        this.formObj.body[group_key].inputs[input_key].required = res.data.response.display_changes[group_key][input_key].required;
                      }

                      //error
                      if(typeof res.data.response.display_changes[group_key][input_key].error != "undefined"){
                        //console.log('dev err', group_key, input_key, res.data.response.display_changes[group_key][input_key].error)
                        if(res.data.response.display_changes[group_key][input_key].error) {
                          this.formObj.body[group_key].inputs[input_key].invalid = {
                            status: true,
                            message: res.data.response.display_changes[group_key][input_key].error
                          };
                        }else{
                          this.formObj.body[group_key].inputs[input_key].invalid = {
                            status: false,
                          };
                          delete this.formObj.body[group_key].inputs[input_key].invalid;
                        }
                      }

                      //select-list
                      if(typeof res.data.response.display_changes[group_key][input_key].list != "undefined"){
                        this.formObj.body[group_key].inputs[input_key].templates.data = res.data.response.display_changes[group_key][input_key].list;
                      }

                    }
                  }
                  //sections
                  for (const group_key in res.data.response.section_changes) {
                    if(typeof res.data.response.section_changes[group_key].display != "undefined"){
                      this.formObj.body[group_key].display = res.data.response.section_changes[group_key].display;
                    }
                  }
                })
                .catch((err) => {
                  //this.options = []
                })
              },
              timer_form_refresh_delay
          )
        }
        this.variableForm();
      },
      deep: true,
      immediate: true
    },
  },
  mounted() {
    this.prefillFormData();
    this.prefillTempaltesFormData(this.$route.query.template);
   // this.animateListLoading()
  }
};
</script>