<template >
  <div v-if="structure && structure.length > 0">
    <ul class="list-group border border-light2 mb-2">
      <draggable
          :list="structure"
          itemKey="key_id"
          handle=".move"
          ghost-class="ghost"
      >
        <template #item="{ index, element }">
          <li
              class="list-group-item py-3"
          >

            <div
                v-for="(inputItem, inputIndex)  in inputObj.templates.item"
                class=""
            >
              <!--
              <pre>{{index}} {{element}}</pre>
              -->


              <!--
              <pre>{{inputIndex}} {{inputItem}}</pre>
-->
              <div v-if="inputItem.typ == 'text'">
                <div
                    v-if="inputObj.langs"
                    v-for="(langItem, langIndex)  in inputObj.langs"
                >
                  <span class="me-1">{{inputItem.title}}</span>
                  <country-flag
                      :country='langItem.flag'
                      size="small"
                  />

                  <input
                      type="text"
                      v-model="element[inputIndex][langIndex]"
                      :class="'form-control ' + (structureErr[index] && typeof structureErr[index].err_langs[inputIndex] !='undefined' && typeof structureErr[index].err_langs[inputIndex][langIndex] !='undefined'? ' is-invalid': '')"
                  />

                  <div
                      v-if="structureErr[index] && typeof structureErr[index].err_langs[inputIndex] !='undefined' && typeof structureErr[index].err_langs[inputIndex][langIndex] !='undefined'"
                      class="invalid-feedback"
                  >
                  {{structureErr[index].err_langs[inputIndex][langIndex]}}
                  </div>
                </div>
                <div v-else>
                  <span class="me-1">{{inputItem.title}}</span>
                  <input
                      type="text"
                      v-model="addForm[inputIndex]"

                      class="form-control"
                  />
                </div>


              </div>
              <div v-else-if="inputItem.typ == 'bool'">
                <div class="form-check form-switch">
                  <input
                      v-model="element[inputIndex]"
                      class="form-check-input"
                      type="checkbox"
                      role="switch"
                      :id="inputIndex + '_switch'"

                  >
                  <label
                      class="form-check-label"
                      :for="inputIndex+ '_switch'"
                  >
                    {{ inputItem.title }}
                  </label>
                </div>
              </div>
              <div v-else-if="inputItem.typ == 'range'">
<!--
                <div class="row">
                  <div class="col col-3">
                    [A]
                  </div>
                  <div class="col col-9">
                    [B]
                  </div>
                </div>
-->

                <div class="input-group">

                <template v-if="inputItem.range.render && inputItem.range.render.item">
                  <div
                      v-if="element[inputIndex] > 0"
                      class="input-group-text"
                  >
                    <i
                        v-for="(renderItem) in parseInt(element[inputIndex])"
                        :class="inputItem.range.render.item"
                     >
                    </i>
                  </div>
                  <div
                      v-else
                      v-html="inputItem.range.render.empty"
                      class="input-group-text"
                  >
                  </div>
                </template>
                <template v-else class="input-group-text">
                  <span>{{element[inputIndex]}}</span>
                </template>

                <input
                    v-model="element[inputIndex]"
                    type="range"
                    class="form-range form-control border-0 w-75 mt-2 ms-2"
                    :min="inputItem.range.from"
                    :max="inputItem.range.to"
                    :step="inputItem.range.step"
                >
              </div>
              </div>
            </div>

            <span class="right-top-btns">
              <span
                  @click.prevent="removeVariant(index, element)"
                  class="btn btn-outline "
              >
                <i class="bi bi-trash"></i>
                <!-- &nbsp;Odstranit-->

              </span>
              <span
                  v-if="structure.length> 1"
                  class="move btn btn-outline"
              >
                <i class="bi bi-arrows-move"></i>
                <!-- &nbsp;Přesunout-->

              </span>
            </span>
            <!--
                        <textarea
                            v-model="element.obsah"
                            :placeholder="'textarea key:' + index"
                        ></textarea>
                        <pre>[{{element.component_name}}]</pre>
            -->
            <component
                v-bind:is="element.component_name"
                v-model="element.obsah"
                :inputObj="element"
                :segmentsObj="modelValue"
            ></component>
          </li>
        </template>
      </draggable>
    </ul>
  </div>
  <div
      v-else
      class="alert alert-danger text-danger d-inline-block"
  >
    Jídlo zatím nemá varianty.
  </div>


  <div>
    <span
        @click.prevent="addVariantEmpty()"
        class="btn btn-outline-secondary btn-sm"
    >
      <i class="bi bi-plus me-1"></i> Přidat novou variantu jídla
    </span>
  </div>



<!--

  <pre>{{structure}}</pre>
  <pre>{{addForm}}</pre>
  <pre>{{inputObj.templates.item}}</pre>
    <pre>{{inputModel}}</pre>
-->



</template>

<script>
import draggable from "vuedraggable";
import CountryFlag from "vue-country-flag-next"
import axios from "axios";
let timer;
export default {
  name: "RestaraceJidloVariantaInput",
  components: {
    CountryFlag,
    draggable,
  },
  props: {
    "inputObj": Object,
    "formObj": Object,
    "modelValue": [Object, Array],
  },
  data() {
    return {
      structure: [],
      structureErr: {},
      inputModel: null,
      displayAddForm: false,
      addForm: {},
      timer: null,
      timer_delay: 750,
      is_locked: true,
    }
  },
  created() {
    this.inputModel = []
  },
  mounted() {

    if(this.inputObj.val){
      for(const key in this.inputObj.val) {
        if(this.inputObj.val[key]) {
          this.structure.push(this.inputObj.val[key])
          this.inputModel.push(this.inputObj.val[key])
        }
      }
    }

/*
    if(this.inputObj.val){
      for(const key in this.inputObj.val) {
        if(this.inputObj.val[key]) {
          this.inputModel.push(this.inputObj.val[key])
        }
      }
    }
*/
  },
  methods: {
    removeVariant(index,segment) {
      if (confirm("Opravdu chcete odstranit tuto variantu?") === true){
        this.structure.splice(index, 1);
      }
    },

    addVariantEmpty(){
      let emptyVarianta = {
        'key_id': Math.floor(Math.random() * Date.now()).toString(36)
      };
      for (const key in this.inputObj.templates.item) {
        if(this.inputObj.templates.item[key].typ == 'text') {
          if (this.inputObj.langs) {
            emptyVarianta[key] = {};
            for (const langKey in this.inputObj.langs) {
              emptyVarianta[key][langKey] = null;
            }
          } else {
            emptyVarianta[key] = null;
          }
        }else if(this.inputObj.templates.item[key].typ == 'range'){
          emptyVarianta[key] = this.inputObj.templates.item[key].range.from;
        }else{
          emptyVarianta[key] = null;
        }
      }
      this.structure.push(emptyVarianta);
    },

  },
  watch: {
    structure: {
      handler(newVal) {
        //todo setTimeout
        if(timer) clearInterval(timer);

        if(newVal && newVal.length > 0) {
          for(const k in newVal) {


            let validationResult = {
              status: true,
              err_langs: {
                nazev: {},
              },
            };

            //lang empty
            for(const l in newVal[k].nazev) {
              if(!newVal[k].nazev[l]) {
                validationResult.status = false;
                validationResult.err_langs.nazev[l] = "Vyplňte název varianty"
              }else if(newVal[k].nazev[l]){
                const tEl = newVal[k];
                const f = newVal.filter(function (el){
                  if(el.nazev[l] == tEl.nazev[l] && el.key_id != tEl.key_id) {
                    return el;
                  }
                });
                if(f.length > 0) {
                  validationResult.status = false;
                  validationResult.err_langs.nazev[l] = "Duplicitní název varianty, změnte název"
                }
              }
            }

            if(validationResult.status === false) {
              this.structureErr[k] = validationResult;
              newVal[k].validation_err = true;
            }else{
              //newVal[k].validation_err = false;
              delete newVal[k].validation_err;
              delete this.structureErr[k];
            }
          }
        }

        timer = setTimeout(
            () => {
              this.$emit('update:modelValue', newVal)
            },
            this.timer_delay
        )



      },
      deep: true,
      immediate: true
    },

    inputObj: {
      handler() {
        this.inputModel = this.inputObj.val;
      },
      deep: true,
      immediate: true
    },
/*
    inputModel: {
      handler(newVal) {
        console.log('DEV varianta input inputModel', newVal)
        this.$emit('update:modelValue', newVal)
      },
      deep: true,
      immediate: true
    },
    */
  },
};
</script>