<template>
    <component
        :is="currentComponent"
        v-if="currentComponent"
        :ident="router.modul"
        v-model:reloadSignal="reloadSignal"
        :moduleData="moduleObj"
        :returnData="returnData"
        :formPrefill="formPrefill"
        :config="config"
    />

    <!--

   <pre>returnData({{router.modul}}): {{returnData}}</pre>
     <component
        :ident="router.modul"
        v-model:reloadSignal="reloadSignal"
        v-bind:is="moduleObj.component"
        :moduleData="moduleObj"
        :returnData="returnData"
        :formPrefill="formPrefill"
    />
    <pre>
  ContentIncludeViewRestarauraceMenu status: {{status}}
  ContentIncludeViewRestarauraceMenu router: {{router}}
  ContentIncludeViewRestarauraceMenu formPrefill: {{formPrefill}}
  ContentIncludeViewRestarauraceMenu moduleObj: {{moduleObj.component}}

  </pre>
     -->

</template>
<script>
import loginLogic from "@/components/logic/Login";
import flashMessage from "@/components/general/FlashMessage";
import mainMenu from "@/components/general/MainMenu";
//content component
import { ref, defineAsyncComponent } from 'vue';
import axios from "axios";
export default {
  name: 'ContentIncludeView',
  components: {
    defineAsyncComponent
  },

  data() {
    return {
      status:{
        is_data: false,
        is_loading: false,
        is_err: false,
        err: null,
        title: null,
      },
      reloadSignal: null,
      listFilters: null,
      moduleObj: {},
      user: {},
      err: {},
      contentLoadingStatus: false,
      routes: {},
      dev: {
      },

      dev_log: [],
      currentComponent: null, // Aktuální načtená komponenta
      //compoName: 'DynamicComponent', // Název komponenty (můžete změnit)
      compoName: 'DevModule',
    }
  },
  props:{
    router: Object,
    returnData: [Object, String, Number],
    formPrefill: [Object],
    config: [Object],
  },
  emits:[
    'update:returnData',
    //'update:returnDataDev',
  ],
  methods: {
    async getModule(name){
      let apiPath = "/class/" + this.router.modul + "/" + this.router.action;

      if (typeof  this.router.id != undefined && this.router.id){
        apiPath += "/" +  this.router.id;
      }

      if (this.router.query != undefined && this.$router.query){
        let pathQuery = []
        const qObj = this.router.query;
        for (let key in qObj) {
          //if(this.cfg.general.allowed_query_names.includes(key))
          if(this.getConfig('allowed_query_names').includes(key)) {
            pathQuery.push(key + "=" + this.$route.query[key])
          }
        }

        //filtry
        const filters = localStorage.getItem(this.$route.params.modul +"_filter")
        if(this.$route.params.action == 'list' && filters && Object.keys(JSON.parse(filters)).length > 0) {
          //pathQuery.push( "filters=" + JSON.stringify(filters))
          pathQuery.push( "filters=" + filters);
        }

        //build path
        if(pathQuery.length > 0) apiPath += "?" + pathQuery.join("&");
      }
      axios.defaults.baseURL = this.getBaseRoot();
      await axios({
        url: apiPath,
        method: "get",
        withCredentials: true,
        headers: this.getRequestHeader(),
      }).then((res) => {
        this.status.is_data = false;
        if (res.data.status) {
          //this.status.is_data = false;
          this.status.is_err = false;
          this.moduleObj = res.data.response;
          this.status.title = 'jsou data';

          try {
            this.currentComponent =  defineAsyncComponent(() =>
                import(`@/modules/${this.moduleObj.component}`)
            );
          } catch (error) {
            console.error('dev.ContentIncludeViewRestaurace getModule ERR Chyba při načítání komponenty:', error);
            // Zde můžete přidat další logiku pro zacházení s chybou
          }


          //if (res.data.response.title) document.title = res.data.response.title;
          //if (res.data.response.title) this.setDocumentTitle(res.data.response.title, res.data.response.modul);
        }else{
          this.status.is_err = true;
          this.status.err = res.data.err;
          this.status.title = 'neni res status';
        }
      }).catch((err) => {
        this.status.is_err = true;
        //this.status.err = res.data.err;
        //this.status.err = err;
        //this.setDocumentTitle('CHYBA!(2)');
      })
      .finally(() => {
        //console.log("DEV finally", res);
        //this.status.is_loading = false;
        this.status.is_data = true;
        //this.status.title = 'finaly';
      })


    },
  },
  watch: {
    /* */
    moduleObj: {
     handler(newVal) {
        if(Object.keys(newVal).length > 0){
          console.log('dev.ContentIncludeViewRestaurace getModule watch moduleObj',Object.keys(newVal).length,  newVal)
        }

      },

      immediate: true,
      deep: true,
    },

    router: {
      handler(newVal) {
        if(Object.keys(newVal).length > 0){
          this.getModule('watch router');
        }
      },
      immediate: true,
      deep: true,
    },

  },
  /*
  created() {
  },
  mounted() {
  },

  destroyed() {
    this.dev_log.push('destroyed')
    console.log("dev.ContentIncludeViewRestaurace action:destroyed")
  },
  beforeDestroy() {
    this.dev_log.push('beforeDestroy')
    console.log("dev.ContentIncludeViewRestaurace action:destroyed")
  }
  */
}
</script>