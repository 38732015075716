import {type} from "@vueuse/core/index";

export function addFlash(ident, title) {
    localStorage.setItem(
        //this.cfg.general.flash_messages.root_local_storage_name + "__" + ident,
        this.getConfig('flash_messages.root_local_storage_name') + "__" + ident,
        title
    )
}

export function getDeviceViewport() {
    let returnWindow  = {
        resolution: {
            width: window.innerWidth,
            height: window.innerHeight,
        }
    }



    const mediaQuerySmall = window.matchMedia('screen and (max-width: 480px)')
    const mediaQueryMedium = window.matchMedia('screen and (max-width: 768px)')
    const mediaQueryOrientation = window.matchMedia('screen and (orientation: portrait)')
    const mediaQueryTouch = window.matchMedia('hover: none')

    if (mediaQuerySmall.matches) {
        returnWindow.type = 'mobile';
    }else if(mediaQueryMedium.matches){
        returnWindow.type = 'medium';
    }else{
        returnWindow.type = 'desktop';
    }

    if (mediaQueryOrientation.matches) {
        returnWindow.orientation = 'portrait';
    }else{
        returnWindow.orientation = 'landscape';
    }

    if (mediaQueryTouch.matches) {
        returnWindow.is_touch = true;
        returnWindow.is_mouse = false;
    }  else{
        returnWindow.is_touch = false;
        returnWindow.is_mouse = true;
    }
    return returnWindow;
}

export function setDocumentTitle(title, modul) {
    let titleParts = []
    titleParts.push(title)
    if(modul) titleParts.push(modul)
    titleParts.push(this.getText('global.doc_title_suffix'))
    document.title = titleParts.join(this.getText('global.doc_title_divider'));

}

export function getValueByPath(obj, path) {
    const parts = path.split('.');
    let current = obj;
    for (let i = 0; i < parts.length; i++) {
        if (current[parts[i]] == null) {
            return undefined;
        }
        current = current[parts[i]];
    }
    return current;
}

export function getText(path) {
    return this.getValueByPath(this[this.constsPropName].texts, path);
}

export function getConfig(path) {
    return this.getValueByPath(this[this.constsPropName].config, path);
}

export function getApiConfig(path) {
    return this.getValueByPath(this[this.constsPropName].api, path);
}

export function runAdhocFunction(moduleName, functionName, data) {
    let res;
    import(`./adhoc/${moduleName}.js`).then((module) => {
        module['constsPropName'] = this.constsPropName;
        module[this.constsPropName]= this[this.constsPropName]

        //nacteni ala main.js
        const utils = {};
        const requireUtils = require.context('@/utils', false, /\.js$/);

        requireUtils.keys().forEach((fileName) => {
            const moduleFunctions = requireUtils(fileName);
            Object.keys(moduleFunctions).forEach(fnName => {
                utils[fnName] = moduleFunctions[fnName];
                module[fnName] = moduleFunctions[fnName];
            });
        });
        res = module[functionName](data)
        //console.log('dev.InfiniteScrollList devGeneralUtil runAdhocFunction RUN2', res); //, data
        //return module[functionName](data);
    }).catch(error => {
        res = error;
        console.error('dev.InfiniteScrollList actionRowClick CHYBA při načítání souboru:', error);
    });
    return res;
}

export function superMerge(objectA, objectB) {
    return this.deepMergeItem(objectA, objectB);
}

export function deepMergeItem(iobj1, iobj2) {
//todo lepsi merge
    let obj1 = Object.assign({}, iobj1);
    let obj2 = Object.assign({}, iobj2);

    //console.log('DEVC dev.deepMergeItem', obj1, obj2);
    for (let key in obj2) {
        if (obj2.hasOwnProperty(key)) {
            if (obj2[key] instanceof Object && obj1[key] instanceof Object) {
                obj1[key] = this.deepMergeItem(obj1[key], obj2[key]);
            } else {
                obj1[key] = obj2[key];
            }
        }
    }
    //console.log('DEVC dev.deepMergeItem RES', obj1);
    return obj1;
}

export function deepFreeze (object) {
    Object.freeze(object);
    Object.getOwnPropertyNames(object).forEach(function (prop) {
        if (object.hasOwnProperty(prop)
            && object[prop] !== null
            && (typeof object[prop] === "object" || typeof object[prop] === "function")
            && !Object.isFrozen(object[prop]))
        {
            deepFreeze(object[prop]);
        }
    });
    return object;
}


export function adjustTextareaHeight(ref) {
    this.$refs[ref].style.height = "auto";
    this.$refs[ref].style.height = this.$refs[ref].scrollHeight + "px";
}

export function isObjectEmpty(obj) {
    let res = true;
    if(obj && typeof obj == "object"){
        for(const i in obj) {
            if (obj[i] != null && obj[i] != ''){
                res = false;
            }
        }
    }
    return res;
}
