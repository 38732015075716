<template>
  <div
      v-if="!is_preview_only"
      style="border: 0px solid red;"
      class="container-fluid d-md-flex p-0"
  >
    <div
        v-if="thumbPath && !is_form_only && !thumb_hide"
        class="img-thumbnail"
    >
      <!-- DEV spravne - zobrazuje se jako input-->
      <img
          :src="thumbPath"
          :title="(image_info.title? 'Title: '+ image_info.title:'') + (image_info.copyright?', Copyright: '+ image_info.copyright:'')"
          :alt="(image_info.title? 'Title: '+ image_info.title:'') + (image_info.copyright?', Copyright: '+ image_info.copyright:'')"
          :class="'image-preview-wrap '+ thumb_class"
      />

    </div>
    <div
        class="cms5-image-info ms-0 ms-md-2 "
    >
      <!-- edit & image delete -->
      <span
          v-if="is_delete && thumbPath"
          @click.prevent="editImage()"
          class="btn btn-sm btn-outline-primary mb-1"
      >
        <i class="bi bi-pencil-square"></i> Editovat tento obrázek
      </span>

      <span
          v-if="is_delete && thumbPath"
          @click.prevent="deleteImage()"
          class="btn btn-sm btn-outline-primary mb-1 ms-2"
      >
        <i class="bi bi-trash"></i> Odebrat tento obrázek
      </span>
      <!-- image form props -->
      <div
        class=""
      >
        <div
            v-if="is_editable_props"
            class="ms-md-2 d-grid d-md-flex flex-wrap"
        >
          <input v-model="image_info.title" type="text" class="col col-2 form-control mb-1" placeholder="Popisek obrázku">
          <input v-model="image_info.copyright" type="text" class="col col-3 form-control mb-1" placeholder="Info o copyrightu">

          <!-- tagy -->
          <multiselect
              v-model="image_info.tags"
              :options="image_tags_option"
              :multiple="true"
              :taggable="true"
              label="language"
              track-by="id"
              :custom-label="formatSelectTag"
              placeholder="Vyberte štítek"
              :close-on-select="true"
              :clear-on-select="true"
              @search-change="asyncSearchTag"
              @open="asyncSearchTag"
              @tag="addTag"
              tagPlaceholder="Vytvořit nový štítek z vyhledáváné fráze"
              class="form-control mb-1 p-0 border-0"
          >
          </multiselect>
          <div class="w-100 text-end">
            <span
                v-if="thumbPath"
                @click.prevent="deleteImage()"
                class="me-2"
            >
              <i class="bi bi-trash"></i> Odebrat tento obrázek
            </span>

            <button
                @click.prevent="saveImageProps"
                class="align-items-end btn btn-outline-secondary flex-shrink-0"
            >
              <i class="bi bi-save"></i> Uložit
            </button>
          </div>

        </div>
      </div>

      <!-- upload form - start -->
      <div
          v-if="is_upload"
          class="d-flex flex-wrap   "
      >

        <div
            v-show="!loadingStatus"
            class="d-grid d-md-flex "
        >
          <!--cms5-image-upload-form  input-group mb-1 flex-grow-0   -->
          <input
              type="file"
              ref="file_image_upload"
              accept="image/*"
              :placeholder="getText('image_component.placeholder_file')"
              @change="preUpload($event.target.files)"
              class="form-control"

          >
          <input
              v-if="is_input_title && is_image_props"
              type="text"
              ref="image_title"
              v-model="image_title"
              :placeholder="getText('image_component.placeholder_title')"
              disabled="true"
              class="form-control mt-1 mt-sm-0"
              style="display: none;"
          />
          <input
              v-if="is_input_copyright && is_image_props"
              ref="image_copyright"
              type="text"
              v-model="image_copyright"
              :placeholder="getText('image_component.placeholder_copyright')"
              disabled="true"
              class="form-control mt-1 mt-sm-0"
              style="display: none;"
          />
          <button
              @click.prevent="imageUpload()"
              ref="btnSubmit"
              disabled
              class="btn btn-outline-secondary flex-shrink-0 mt-1 mt-sm-0"
          >
            <template
                v-if="size_id"
            >
              {{getText('image_component.btn_image_upload_size_title')}}
            </template>
            <template v-else>
              {{getText('image_component.btn_image_upload_title')}}
            </template>
          </button>

          <!-- {{texts.image_component.btn_image_upload_title}} -->
          <div v-if="uploadErr" style="color: red;">{{uploadErr}}</div>
        </div>
        <!-- upload form - end -->
        <div v-if="loadingStatus">{{getText('image_component.loading_title')}}</div>
      </div>
      <!-- upload form - end -->

      <!-- image browser - start -->
      <div
          v-if="is_browser"
      >
        <multiselect
            v-model="browser_image_id"
            :options="browser_list"
            track-by="id"
            :searchable="true"
            :hideSelected="false"
            :custom-label="formatSelectItem"
            :placeholder="getText('image_component.placeholder_browser')"
            @search-change="asyncSearch"
            @open="asyncSearch"
        >
          <template v-slot:singleLabel="{ option }">
            <div style="display: inline-block;">
              <image-component
                  :image-id="option.id"
                  :is_preview_only="true"
              />
            </div>
            <div style="display: inline-block; margin-left: 1rem;">
              <strong v-if="option.title" style="font-size: 1.2rem;">{{ option.title }}</strong><br>
              <span v-if="option.copyright">copyright: {{ option.copyright }}</span><br>&nbsp;
              <span v-if="option.user_title">autor: {{ option.user_title }}</span>&nbsp;
            </div>
          </template>

          <template v-slot:option="{ option, search }" >
            <div class="d-flex align-items-center">
              <image-component
                  :image-id="option.id"
                  :is_preview_only="true"
                  thumb_class="nahled seznam"
                  class="flex-shrink-0 nahled"
              />
              <div class="flex-grow-1 ms-3">
                <div v-if="option.title" class="fw-medium fs-5 mb-3">{{ option.title }}</div>
                <div v-if="option.copyright" class="mb-1 text-secondary"><i class="bi bi-c-circle" style="font-size: .7rem;"></i> Copyright: {{ option.copyright }}</div>
                <div v-if="option.user_title" class="text-secondary"><i class="bi bi-person"></i> {{ option.user_title }}</div>
              </div>
            </div>
            <!--
           <div class="d-flex align-items-center">
             <div class="flex-shrink-0">
               <img src="..." alt="...">
             </div>
             <div class="flex-grow-1 ms-3">
               This is some content from a media component. You can replace this with any content and adjust it as needed.
             </div>
</div>

      <div class="multiselect_custom image">
             <image-component
                 :image-id="option.id"
                 :is_preview_only="true"
                 class="block-image"
             />
             <div class="block-desc">
               [img option]
               <strong v-if="option.title" style="font-size: 1.2rem;">{{ option.title }}</strong><br>
               <span v-if="option.copyright">copyright: {{ option.copyright }}</span><br>&nbsp;
               <span v-if="option.user_title">autor: {{ option.user_title }}</span>&nbsp;
             </div>
           </div>
-->
            <!--
                  <pre>{{option}}</pre>
                  -->
          </template>

          <template v-slot:noResult>
            <div class="multiselect--no-results">{{getText('image_component.multiselect.no_result')}}</div>
          </template>

          <template v-slot:noOptions>
            <div class="multiselect--no-results">{{getText('image_component.multiselect.no_result')}}</div>
          </template>
        </multiselect>
      </div>
      <!-- image browser - end -->

    </div>

  </div>
  <div
      v-else
  >
    <img
        :src="thumbPath"
        :title="(image_title? 'Title'+ image_title:'') + (image_title?' Copyrights:'+ image_title:'')"
        :alt="(image_title? 'Title: '+ image_title:'') + (image_title?' Copyrights:'+ image_title:'')"
        :class="'cms5-image-only me-2 image-preview-wrap '+ thumb_class"
    />
  </div>

  <!-- modal start-->
  <div v-if="editModal && editModal.config && editModal.config.display">
    <modal-window
        :title="editModal.title"
        :config="editModal.config"
    >
      <template #modal-content-slot>
        <component
            v-bind:is="'ContentIncludeView'"
            :router="editModal.router"
            :return-data="editModal.data"
            :form-prefill="editModal.prefill"
            :config="editModal.config"
        ></component>
      </template>
    </modal-window>
  </div>
  <!-- modal end-->

</template>



<script>
//this.getApiConfig('')
import multiselect  from 'vue-multiselect'
import "vue-multiselect/dist/vue-multiselect.css"
import ImagePreview from "@/components/general/ImagePreview";
import ImageComponent from "@/components/general/Image";
import texts from "@/components/config/texts"
import axios from "axios";
import ModalWindow from "@/components/content/ModalWindow";
import ContentIncludeView from "@/views/ContentIncludeView";
let timer;
export default {
  name: "ImageComponent",
  props: {
    "modelValue": [String, Number],
    "imageId": [String, Number],
    "typeId": [String, Number],
    "size_id": [String, Number],
    "is_upload": [Boolean],
    "is_browser": [Boolean],
    "is_delete": [Boolean],
    "is_image_props": [Boolean],
    "is_editable_props": [Boolean],
    "is_form_only": [Boolean],
    "is_input_title": [Boolean],
    "is_input_copyright": [Boolean],
    "is_preview_only": [Boolean],
    "thumb_type_id": [Boolean, String, Number],
    "thumb_class": [String],
    "thumb_hide": [Boolean],
    "class": [Boolean, String, Number],
  },
  components: {
    multiselect,
    ImagePreview,
    ImageComponent,
    ModalWindow,
    ContentIncludeView,
    texts,

  },
  emits: [
    'update:modelValue',
  ],
  data() {
    return {
      image_id: null,
      thumbPath: null,
      uploadErr: null,
      loadingStatus: false,
      image_title: null,
      image_copyright: null,
      image_tags: [],
      image_tags_option: [],
      browser_select: null,
      browser_list: [
      ],
      browser_image_id: null,
      image_info: {
        title: null,
        copyright: null,
        tags: [],
      },
      editModal: {
        title: 'Editace obrazku',
        router: {
          modul: 'image',
          action: 'edit'
        },
        config: {
          "display": false,
          "is_header": true,
          "is_close_btn": true,
          "is_footer": true
        },
        data: {
          save: false,
          submit: false,
        },
        prefill: {
          /*
          general: {
            inputs: {
              restaurace_id: {
                readonly: true,
                value: this.formObj.general.inputs.restaurace_id.val
              },
            }
          },
          */
        },
      },
    }
  },
  methods: {
    async loadTagsOnly(){

      if(typeof this.imageId != "undefined") {
        let apiPath = this.getBaseRoot() + this.getApiConfig('image.get_info_tags.path') + this.imageId
        //let apiPath = this.getBaseRoot() + this.cfg.api.image.get_info_tags.path + this.imageId

        axios.get( apiPath, {
          headers: this.getRequestHeader()
        }).then((res) => {
          this.image_info.tags = res.data.response.tags;
        }).catch((err) => {
          this.image_info.tags = [];
          //this.thumbPath = null
        })
      }
    },
    async saveImageProps(){
      let formData = new FormData();
      formData.append('image_id', this.imageId );
      formData.append('image_title', this.image_info.title );
      formData.append('image_copyright', this.image_info.copyright);
      formData.append('image_tags',  JSON.stringify(this.image_info.tags));

      axios.post( this.getBaseRoot() + this.getApiConfig('image.save_info.path'),
          formData,
          {
            headers: this.getRequestHeaderUpload()
          })
          .then((res) => {
            console.log('image image info res', res);
          })
          .catch((err) => {
            console.log('image image info failure', err);
          })
          .finally(() =>{
            //console.log('finally!!', pseudothis.loadingStatus);

          })


    },
    async asyncSearchTag (query) {
      console.log("dev asyncFind", query)

      if(timer) clearInterval(timer);
      timer = setTimeout(
          () => {
            //this.is_input_change = false;
            console.log('dev delay 1000',query, this.selectModel)
            axios.defaults.baseURL = this.getBaseRoot();
            //let apiPath = this.cfg.api.tags.select_list.path
            let apiPath = this.getApiConfig('tags.select_list.path')

            //if (this.inputObj.params && this.inputObj.params.search_path) apiPath = this.inputObj.params.search_path;
            if (query) apiPath += query
            axios({
              url: apiPath,
              method: "get",
              withCredentials: true,
              headers: this.getRequestHeader(),
            }).then((res) => {
              this.image_tags_option = res.data.response.list.data;
              //console.log("dev tags data", res.data.response.list.data)
            })
            .catch((err) => {
              this.image_tags_option = []
              console.log("DEV tags catch", err);
            })
            .finally(() => {
              //console.log("DEV tags finally");
            })
          },
          500
      )
    },
    formatSelectTag ({ id, title }) {
      return `${title}`
    },

    addTag (newTag) {
      let formData = new FormData;
      formData.append('tag_title', newTag)
      axios.defaults.baseURL = this.getBaseRoot();
      axios({
        //url: this.cfg.api.tags.create_simple.path,
        url: this.getApiConfig('tags.create_simple.path'),
        data: formData,
        method: "post",
        withCredentials: true,
        headers: this.getRequestHeader(),
      }).then((res) => {
        //this.options = res.data.response.list.data;
        if(res.data.status){
          const newTag = {
            'id': res.data.response.data.id,
            'title': res.data.response.data.nazev
          }
          this.image_tags.push(newTag)
          //this.selectModel.push(newTag)
        }else{
          alert("nepodarilo se pridat novy stitek")
        }
      })
          .catch((err) => {
            //console.log("DEV tags create catch", err);
          })
          .finally(() => {

            //console.log("DEV tags create finally");
          })




      const tag = {
        name: newTag,
        code: newTag.substring(0, 2) + Math.floor((Math.random() * 10000000))
      }



      //this.options.push(tag)
      //this.selectModel.push(tag)
    },
    async asyncSearch (query) {
      console.log("dev asyncFind image", query)
      if(timer) clearInterval(timer);
      timer = setTimeout(
          () => {
            //this.is_input_change = false;
            //console.log('dev delay 1000',query, this.selectModel)
            axios.defaults.baseURL = this.getBaseRoot();
            //let apiPath = this.cfg.api.image.browse_service.path + "?" + this.cfg.api.image.browse_service.input.type + "=" + this.typeId
            let apiPath = this.getApiConfig('image.browse_service.path') + "?" + this.getApiConfig('image.browse_service.input.type') + "=" + this.typeId

            //if(query) apiPath += "&" + this.cfg.api.image.browse_service.input.query + "=" + query
            if(query) apiPath += "&" + this.getApiConfig('image.browse_service.input.query') + "=" + query
            axios({
              url: apiPath,
              method: this.getApiConfig('image.browse_service.method') ,
              withCredentials: true,
              headers: this.getRequestHeader(),
            }).then((res) => {
              this.browser_list = res.data.response.list.data;
              //console.log("dev image browser data", res.data.response.list.data)
            })
            .catch((err) => {
                  this.browser_list = []
                  console.log("DEV tags catch", err);
            })
            .finally(() => {
                  //console.log("DEV tags finally");
            })
          },
          500
      )
    },
    formatSelectItem ({ id, title, copyright, user_title }) {
      return `${title}  ${id} ${copyright} ${user_title}`
    },
    imageUpload(){
      //const upload = this.$refs['file_image_upload'];
      let formData = new FormData();
      //formData.append('image_title', this.image_title);
      formData.append('image_file', this.file_data );
      formData.append('image_type', this.typeId);
      if(this.is_input_title && this.image_title) formData.append('image_title', this.image_title);
      if(this.is_input_copyright && this.image_copyright) formData.append('image_copyright', this.image_copyright);
      if(this.size_id) formData.append('image_size_id', this.size_id);
      //if(this.image_id) formData.append('id', this.image_id);
      if(this.imageId) formData.append('id', this.imageId);

      //console.log("dev upload", this.image_id)
      this.loadingStatus = true;

      axios.post( this.getBaseRoot() + this.getApiConfig('image.upload_service.path'), //this.cfg.api.image.upload_service.path
          formData,
          {
            headers: this.getRequestHeaderUpload()
      }).then((res) => {
          if (typeof res.data.err == 'undefined'){
            this.uploadErr = null;
            //console.log('SUCCESS UPLOAD!! 3', res.data.response.response.id);
            this.image_id= res.data.response.response.id;
            //console.log('[dev.image] RES this.image_id', this.image_id);
          }else{
            this.uploadErr = res.data.err.message;
            this.image_title = null;
          }
      }).catch((err) => {
        //console.log('image upload failuer',err );
        //console.log('[dev.image] ERR', err);
        //this.uploadErr = this.texts.image_component.upload_fail;
        this.uploadErr = this.getText('image_component.upload_fail');
      }).finally(() =>{
        //console.log('[dev.image] finally UPLOAD!!', this.loadingStatus);
        this.loadingStatus = false;
        this.$refs['btnSubmit'].disabled = true
        this.$refs['file_image_upload'].value = ''

        if(this.$refs['image_title']) {
          this.$refs['image_title'].value = true;
          this.$refs['image_title'].disabled = true;
          this.$refs['image_title'].style = "display: none;";
        }
        if(this.$refs['image_copyright']) {
          this.$refs['image_copyright'].disabled = true;
          this.$refs['image_copyright'].value = true
          this.$refs['image_copyright'].style = "display: none;";
        }
        this.image_title = null
        this.image_copyright = null

      })
    },
    preUpload(fileList) {
      let formData = new FormData();
      formData.append('image_file', fileList[0] );
      formData.append('image_type', this.typeId);
      formData.append('image_validate', 1 );

      this.file_data = fileList[0];
      axios.post( this.getBaseRoot() +  this.getApiConfig('image.upload_service.path'), //this.cfg.api.image.upload_service.path,
          formData,
          {
            headers: this.getRequestHeaderUpload()
          })
        .then((res) => {
        if (typeof res.data.err == 'undefined'){
          this.uploadErr = null;
          //console.log('preUploadSUCCESS!! 3',res.data.response.response.validation);
          if (res.data.response.response.validation) {
            this.$refs['btnSubmit'].disabled = false
            if(this.$refs['image_title'])  this.$refs['image_title'].disabled = false
            if(this.$refs['image_copyright']) this.$refs['image_copyright'].disabled = false
            if(this.$refs['image_title']) this.$refs['image_title'].style = null;
            if(this.$refs['image_copyright'])this.$refs['image_copyright'].style = null;


            this.uploadErr = null;
          }else{
            if(this.$refs['image_title']) this.$refs['image_title'].disabled = true
            if(this.$refs['image_copyright']) this.$refs['image_copyright'].disabled = true
            this.$refs['image_title'].style = "display: none;";
            this.$refs['image_copyright'].style = "display: none;";
            this.$refs['btnSubmit'].disabled = true
          }
         this.inputModel = res.data.response.response.id;
        }else{
          this.$refs['btnSubmit'].disabled = true
          this.uploadErr = res.data.err.message;
        }
      })
      .catch((err) => {
        console.log('image preupload failure', err);
        this.$refs['btnSubmit'].disabled = true
        this.uploadErr = 'asi se rozbilo API x';
      })
      .finally(() =>{
        //console.log('finally!!', pseudothis.loadingStatus);
        //pseudothis.loadingStatus = false;
        //pseudothis.$refs['btnSubmit'].disabled = true
        //pseudothis.$refs['file_image_upload'].value = ''
      })



      //console.log('dev preUpload', fileList, formData)



    },
    getThumb(imageId, note) {
      if(imageId) {
        //console.log('dev imageId v2 note', imageId, note)
        let apiPath = this.getBaseRoot() + this.getApiConfig('image.preview.path') + imageId
        if (this.thumb_type_id) {
          apiPath += "&type_id=" + this.thumb_type_id;
        }
        axios.get( apiPath, {
          headers: this.getRequestHeader()
        }).then((res) => {
          this.thumbPath = res.data.response.data.path + '?r=' + Math.random();
          this.image_info.title = res.data.response.data.title
          this.image_info.copyright = res.data.response.data.copyright
          //this.image_title = res.data.response.data.title;
          //this.image_copyright = res.data.response.data.copyright;
        }).catch((err) => {
          this.thumbPath = null
        }).finally((s)=>{
          //console.log('getThumb finally!!');
        })
      }else{
        this.thumbPath = null;
      }
    },
    editImage(){
      this.editModal.config.display = true;
      this.editModal.data.submit = false;
      this.editModal.data.saved = false;
      this.editModal.router.action = 'edit/' + this.image_id
      //this.modals.meal_create.prefill.general.inputs.restaurace_id.value = this.formObj.general.inputs.restaurace_id.val;
      //this.modals.meal_create.data.section_index = section_index;
      //this.modalOpen = this.modals.meal_create;
    },

    deleteImage(){
      //if (confirm(this.texts.image_component.remove_image_confirm) == true) {
      if (confirm(this.getText('image_component.remove_image_confirm')) == true) {
        this.image_id = null;
      }
    },
  },
  created() {

    this.image_id = this.modelValue;
    if(this.is_image_props) {
      this.loadTagsOnly();
    }
  },
  mounted() {

  },
  watch: {
    "editModal.data": {
      handler(newVal) {
        if(newVal.values) { //TODO data

          this.editModal.config.display = false;
          this.editModal.data.submit = false;
          this.editModal.data.saved = false;
          //console.log('dev editModal.data', newVal.values.id)
          this.getThumb(newVal.values.id, 'after_modal')
        }
      },
      deep: true,
      immediate: true
    },
    imageId: {
      handler(newVal) {
          this.getThumb(newVal, 'note-a');
      },
      deep: true,
      immediate: true
    },
    browser_image_id: {
      handler(newVal) {
        if(newVal) {
          this.$emit('update:modelValue', newVal.id )
          this.getThumb(newVal.id, 'note-b');
          this.browser_image_id = null
        }
      },
      deep: true,
      immediate: true
    },
    image_id: {
      handler(newVal) {
        //console.log('[dev.image] watch', newVal);
        this.$emit('update:modelValue', newVal )
        this.getThumb(newVal, 'note-c');
      },
      deep: true,
      immediate: true
    },
    modelValue: {
      handler(newVal) {
        this.$emit('update:modelValue', newVal )
        this.getThumb(newVal, 'note-d');
      },
      deep: true,
      immediate: true
    },
  }
};
</script>