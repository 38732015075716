<template >
  <div >
    <ul>
      <li
          v-for="(inputItem, index)  in inputObj.templates.data"
          @key="index"
          :ref="'checkbox_item' + inputItem.id" class="form-check"
      >
        <label :for="'check_box_multi_' + inputObj.name + '_' + inputItem.id" class="form-check-label">
          <input
              v-model="inputModel"
              type="checkbox"
              :value="inputItem.id"
              :id="'check_box_multi_' + inputObj.name + '_' + inputItem.id"
              class="form-check-input"
          />

          <span>{{inputItem.title}}</span>
        </label>
      </li>
    </ul>

    <div class="ms-4">
      <span
          v-if="typeof inputObj.templates.is_select_all != 'undefined' && inputObj.templates.is_select_all === true"
      >
        <span
            @click.prevent="selectAll"
            class="btn btn-sm btn-secondary me-1 mb-1"
        >
          <i class="bi bi-server"></i>
          Vybrat vše
        </span>

        <span
            @click.prevent="selectNone"
            class="btn btn-sm btn-secondary me-1 mb-1"
        >
          <i class="bi bi-x-circle"></i>
          Zrušit výber
        </span>
      </span>
      <span
          v-if="typeof inputObj.templates.filters != 'undefined' && inputObj.templates.filters && inputObj.templates.filters.length >0"
      >
     <span
         v-for="(filterItem, index)  in inputObj.templates.filters"
         @click.prevent="applyFilter(filterItem)"
         class="btn btn-sm btn-outline-secondary me-1 mb-1"
     >
       <i
           v-if="filterItem.ico_class"
           :class="filterItem.ico_class"
       ></i>
       {{filterItem.title}}
     </span>
    </span>
    </div>
  </div>
</template>
<script>

export default {
  name: "CheckboxMultiInput",
  props: {
    "inputObj": Object,
    "formObj": Object,
    "modelValue": [Array, Number],
  },
  data() {
    return {
      inputModel: null,
    }
  },
  mounted() {
      this.inputModel = this.inputObj.val;
  },
  methods: {
    selectAll(){
      this.inputModel = [];
      for (const i in this.inputObj.templates.data) {
        this.inputModel.push(this.inputObj.templates.data[i].id)
      }
    },
    selectNone(){
      this.inputModel = [];
    },

    applyFilter(filter){
      const filterId = filter.id.toString()
      console.log('dev.filter', filterId)
      for (const i in this.inputObj.templates.data) {
        console.log('dev.filter i', i, this.inputObj.templates.data[i].modules, this.inputObj.templates.data[i].modules.includes(filterId))
        if(this.inputObj.templates.data[i].modules.includes(filterId)){
          this.inputModel.push(this.inputObj.templates.data[i].id)
        }
        //
      }
    }
  },
  watch: {
    inputModel: {
      handler(newVal) {
        this.$emit('update:modelValue', newVal )
      },
      deep: true,
      immediate: true
    },
  },
};
</script>