<template >

  <div
      v-for="(langItem, langIndex)  in langs"
  >
    <label :for="'input_lang_' + langIndex">
      <country-flag
          :country='langItem.flag'
          size="small"
      />
      <span class="ms-2 d-inline-block">{{langItem.title}}</span>
    </label>



    <input
        v-if="textLengths[langIndex]"
        type="text"
        v-model="inputModel[langIndex]"
        :ref="'lang_' + langIndex"
        class="form-control"
        @click.prevent="focusLang = langIndex"
        @select="getSelectionText"

    />
    <textarea
      v-else
      v-model="inputModel[langIndex]"
      :ref="'lang_' + langIndex"
      class="form-control"
      @click.prevent="focusLang = langIndex"
      @select="getSelectionText"
    />

  </div>

  <display-debug>
    <template #debugView>pairtext selectedText  {{selectedText }}</template>
  </display-debug>

  <!--



  <display-debug>
    <template #debugView>pairtext textLengths {{textLengths}}</template>
  </display-debug>

  <display-debug>
    <template #debugView>pair text inputModel {{inputModel}}</template>
  </display-debug>


  <display-debug>
    <template #debugView>pair text modelValue {{modelValue}}</template>
  </display-debug>

  <display-debug>
    <template #debugView>pair text langs {{langs}}</template>
  </display-debug>


  <display-debug>
    <template #debugView>pair config {{config}}</template>
  </display-debug>
   -->
</template>


<script>
import axios from "axios";
import VueMultiselect from 'vue-multiselect'
let timer;

import CountryFlag from "vue-country-flag-next"
import ContentIncludeView from "@/views/ContentIncludeView";
import ModalWindow from "@/components/content/ModalWindow";
export default {
  name: "WikiTextareaLang",
  components: {
    CountryFlag,
    VueMultiselect,
    ContentIncludeView,
    ModalWindow,
  },
  props: {
    "modelValue": {
      type: [Object, Number, String],
      required: false,
      default: {}
    },
    "langs": {
      type: Object,
      required: false,
      default: {}
    },
    "config": {
      type: Object,
      required: false,
      default: {}
    }

  },
  emits: ['update:modelValue'],
  data() {
    return {
      textLengthLimit: 100,
      inputModel: {},
      textLengths: {},
      focusLang: null,
      selectedText: null,
    }
  },
  created() {
    if(this.langs){
      for (const i in this.langs) {
        this.inputModel[i] = null;
      }
    }
  },
  mounted() {

  },
  methods: {
    getSelectionText() {
      const textArea = this.$refs['lang_' + this.focusLang][0];
      if (textArea) {
        this.selectedText = textArea.value.substring(textArea.selectionStart, textArea.selectionEnd);
      }
    },

      detectElement(input){
        if(input){
          if(input.length <= this.textLengthLimit){
            return true;
          }else{
            return false;
          }
        }else{
          return true;
        }
      }
  },
  watch: {
    textLengths: {
      handler(newVal, oldVal) {
        if(this.$refs['lang_' + this.focusLang]){
          //this.$refs['lang_' + this.focusLang][0].focus()

          this.$nextTick(() => {
            const textarea = this.$refs['lang_' + this.focusLang][0];
            textarea.focus();
            if (typeof textarea.selectionStart == "number") {
              textarea.selectionStart = textarea.selectionEnd = textarea.value.length;
            } else if (typeof textarea.createTextRange != "undefined") {
              const range = textarea.createTextRange();
              range.collapse(false);
              range.select();
            }
          });
        }
       },
      deep: true,
      immediate: true
    },


    inputModel: {
      handler(newVal) {

        if(this.langs){
          for (const i in this.langs) {
            this.textLengths[i] = this.detectElement(newVal[i]);

            /*
            if(newVal[i]){
              this.textLengths[i] = newVal[i].length
            }else{
              this.textLengths[i] = 0;
            }
            */

          }
        }


        if(timer) clearInterval(timer);
        timer = setTimeout(
            () => {
              if(newVal){



                this.$emit('update:modelValue', newVal )
              }else{
                this.$emit('update:modelValue', null )
              }
            },
            300
        )
      },
      deep: true,
      immediate: true
    },

    modelValue: {
      handler(newVal) {
        if(newVal){
          if(this.langs){
            for (const i in this.langs) {
              this.inputModel[i] = newVal[i];
            }
          }
          //this.inputModel = this.modelValue;
        }
      },
      deep: true,
      immediate: true
    },
/*


    */
  },
};
</script>