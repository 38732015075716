<template >
  <!--
  <pre>
    TextAreaSegment.inputModel {{modelValue.length}}
    {{modelValue}}
  </pre>
  -->
  <textarea
      v-if="modelValue && modelValue.length> lengthLimit"
      :value="modelValue"
      ref="input"
      class="form-control"
  >
    {{modelValue}}
  </textarea>

  <input
    v-else
    ref="textarea"
    type="text"
    :value="modelValue"
    @input="$emit('update:modelValue', $event.target.value)"
    class="form-control"
  >

</template>

<script>

export default {
  name: "TextSegment",
  props: {
    "segmentsObj": [Object, Array],
    "inputObj": Object,
    "formObj": Object,
    "modelValue": [String, Object],
  },
  emits: ['update:modelValue'],
  data() {
    return {
      lengthLimit: 100,
      inputModel: null,
    }
  },
  mounted() {
    this.inputModel = this.inputObj.obsah;
  },
  methods: {

  },
  watch: {
    inputObj: {
      handler() {
        //  this.inputModel = this.inputObj.val;
      },
      deep: true,
      immediate: true
    },
    inputModel( newVal){
      //console.log("dev inputModel TextAreaSegment xxxx", newVal)
      this.$emit('update:modelValue', newVal )
    },
  },
};
</script>